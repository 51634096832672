import React from 'react';
import { Component } from 'react';
import axios from 'axios'
import './catalogDashBoard.css'
import AskPassword from './askPassword';

//checked from backend
// filter first 6 above 60%
// show similarity to blogger
// fix cookie issue

const ShowVisibility = (val)=>{
      if(val===true)
            return "checked"
      else
            return null
}


class BrandsDashboard extends Component {
      state = {
            brands: null,
            currentBrand: null,
            currentBrandIndex: null,
            password: null

      }

      fetchData = async () => {
            let data = await axios.post('/api/catalog/fetchData', { password: this.state.password })
            if (data.error) {
                  if (data.error === "Wrong Password")
                        console.log("Wrong password")

            } else {
                  this.setState({
                        brands: data.data.allBrands,
                  })
                  if (this.state.currentBrandIndex)
                        this.setState({
                              currentBrand: this.state.brands[this.state.currentBrandIndex]
                        })
                  else
                        this.setState({
                              currentBrand: this.state.brands[0]
                        })
            }

      }
      handleSubmit = async () => {
            let passwordValue = document.querySelector('input#admin-password').value
            await new Promise(res => {
                  this.setState({
                        password: passwordValue
                  }, res);
            })

            await this.fetchData();
      }

      handleCheck = (e)=>{

      }

      updateVisibilitySubcat = async(e, index)=>{
            let newVal = !this.state.currentBrand.subcategories[index].visibility;
            let brandName = this.state.currentBrand.name;
            let subcat = e.target.id
            await axios.post('/api/catalog/setVisibility',{ password: this.state.password,brand: brandName, subcategory:subcat,visibility:newVal});
            await this.fetchData();
      }

      updateVisibilityBrand = async(e)=>{
            let newVal = !this.state.currentBrand.visibility;
            let brandName = this.state.currentBrand.name;
            await axios.post('/api/catalog/setVisibility',{ password: this.state.password,brand: brandName, subcategory:"all",visibility:newVal });
            await this.fetchData();
      }


      render() {
            let content;
            if (this.state.currentBrand) {
                  content = (
                        <div>
                              <div className='brandHeading'>
                                    <h2>Brands' products Catalog </h2>
                              </div>
                              <div className='brands-body-wrapper'>
                                    <div className='brandspage'>
                                          <h2>{this.state.currentBrand.name} <label class="switch"><input type="checkbox" id="current-brand" onChange={(e)=>{this.updateVisibilityBrand(e)}} checked={this.state.currentBrand.visibility} ></input><span class="slider round"></span></label><select name="brands" id="brands">
                                                {this.state.brands.map(e => <option value={e.name} key={e.name}>{e.name}</option>)}
                                          </select></h2>
                                          <ul>
                                                {this.state.currentBrand.subcategories.map((Category,index) => <li key={Category.name}>
                                                      <div className='category-count'>
                                                            <span className='cat-name'>{Category.name}</span>
                                                            <div className='right-float-counts-brands'>
                                                                  <span className='cat-available-count'>{Category.availableCount}</span>
                                                                  <span className='cat-total-count'>{Category.totalCount}</span>
                                                                  <label class="switch"><input type="checkbox" id={Category.name} onChange={(e)=>{this.updateVisibilitySubcat(e,index)}} checked={Category.visibility} ></input><span class="slider round"></span></label>

                                                            </div>
                                                            <br />
                                                      </div>
                                                </li>
                                                )}
                                          </ul>
                                    </div>
                              </div>

                              <div className='message-box-brandspage'>
                                    <div>
                                          <div className='message-content'></div>
                                          <button className='message-box-ok'>OK</button>
                                    </div>
                              </div>
                        </div>
                  )
            }
            return (
                  <>
                        {this.state.currentBrand ? <div>{content}</div> : <AskPassword handleSubmit={this.handleSubmit} />}
                  </>
            )
      }
}

export default BrandsDashboard;
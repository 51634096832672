import {
  combineReducers
} from "redux";
import { setEndUser } from "../actions";
import {actions} from '../constants'
const user = (state = {}, action) => {
  if(action.type === actions.SET_USER){
    return action.data;
  }
  if(action.type===actions.UPDATE_PROFILE){
    //console.log(action.data)
    let data = JSON.parse(JSON.stringify(state))
    data.name=action.value.name
    data.instahandle=action.value.instahandle
    data.mobileNumber= action.value.mobileNumber
    data.email=action.value.email
    data.fblink=action.value.fblink
    data.twlink= action.value.twlink
    data.ytlink=action.value.profile_imgytlink
    data.description= action.value.description
    data.blog= action.value.blog
    data.username = action.value.username
    return data
  }
  if(action.type===actions.UPDATE_PIMG){
    //console.log(action.data)
    let data = JSON.parse(JSON.stringify(state))
    data.profile_img = action.value.profile_img
   return data;
  }
  return state;
}
const loadingState = (state = false, action) => {
  if(action.type === actions.TOGGLE_LOADING_STATE){
    return action.loading;
  }
  return state;
}

const modalloading = (state = false, action) => {
  if(action.type === actions.MODAL_LOADING_STATE){
    return action.loading;
  }
  return state;
}

const loadingText = (state = 'Loading..', action) => {
  if(action.type === actions.SET_LOADING_TEXT){
    return action.text;
  }
  return state;
}
const blogger = (state = [], action) => {
  if(action.type === actions.SET_BLOGGER){
    return action.blogger;
  } 
  else if(action.type === actions.TOGGLE_POST){
    let data = state.filter(ele => {
      if(ele.sml_id === action.item.sml_id){
          return false;
      }
      else return true
    })
    return data;
  }  
  else if(action.type === actions.APPEND_BLOGGER){
    let data = [...state,action.item]
    return data;
  }
  else if(action.type === actions.BLOGGER_CONCAT){
    let data = state.concat(action.data);
    return data
  }
  return state;
}
const crossSelldata = (state = [], action) =>
{
 if(action.type === actions.SET_CROSSSELL)
 {
   return action.crossSell
 }
 else if(action.type === actions.SET_CROSSSELL_VISIBILITY_OFF){
   let data = JSON.parse(JSON.stringify(state));
   let newdata = data.map((ele,index)=>{
     if(ele.sml_id === action.sml_id){
        let crossSell = ele.crossSell.map(item=>{
          if(item.title === action.cat){
            item.showToPublic = false
          }
          return item;
        })
      ele.crossSell = crossSell;
     }
    // ele.sort((x,y))
     return ele;
   })
   return newdata
 }
 else if(action.type === actions.SET_CROSSSELL_VISIBILITY_ON){
  let data = JSON.parse(JSON.stringify(state));
  let newdata = data.map((ele,index)=>{
    if(ele.sml_id === action.sml_id){
       let crossSell = ele.crossSell.map(item=>{
         if(item.title === action.cat){
           item.showToPublic = true
         }
         return item;
       })
     ele.crossSell = crossSell;
    }
    return ele;
  })
  return newdata
}
 return state
}


const products = (state = [], action) =>
{
 if(action.type === actions.SET_PRODUCTS)
 {
   return action.products
 }
 else if(action.type === actions.SET_PRODUCTS_VISIBILITY_OFF){
    let data = JSON.parse(JSON.stringify(state));
    //console.log('poduct off')
    let newdata = data.map((ele,index)=>{
     if(ele.product_id === action.product_id){
       ele.showToPublic = false
     }
     return ele;
    })
    return newdata
  }
  else if(action.type === actions.SET_PRODUCTS_VISIBILITY_ON){
    let data = JSON.parse(JSON.stringify(state));
    //console.log('poduct on')
    let newdata = data.map((ele,index)=>{
     if(ele.product_id === action.product_id){
       ele.showToPublic = true
     }
     return ele;
    })
    return newdata
  }
 return state
}

//PUBLIC PAGE
const publicCrossSell = (state = [], action) =>
{
 if(action.type === actions.SET_PUBLICCROSSSELL)
 {
   return action.publicCrossSell
 }
 return state
}


const publicProducts = (state = [], action) =>
{
 if(action.type === actions.SET_PUBLICPRODUCTS)
 {
   return action.publicProducts
 }
 return state
}




const totalCount = (state = 0, action) => {
  if(action.type === actions.SET_TOTAL_BLOGGER_COUNT){
    return action.total;
  }
  return state;
}
const isAuthenticated = (state = false, action) =>{
  if(action.type === actions.IS_AUTHENTICATED){
    return action.value
  }
  return state;
}
const isUserAuthenticated = (state = false, action) =>{
  if(action.type === actions.IS_USER_AUTHENTICATED){
    return action.value
  }
  return state;
}
const loginText = (state = '',action)=>{
  if(action.type === actions.SET_LOGIN_TEXT){
    return action.text;
  }
  return state;
}


const pageVisits = (state = 0,action)=>{
  if(action.type === actions.PAGE_VISITS){
    return action.pageVisits;
  }
  return state;
}

//#region ShowtoPublic OFF data
const visibilityOffdata = (state=[], action)=>{
  if(action.type === actions.SET_GREY_DATA){
    return action.data
  }
  else if(action.type === actions.TOGGLE_GREY){
    let data = state.filter(ele =>{
      if(ele.sml_id === action.item.sml_id){
        return false;
      }
      else return true;
    })
    return data;
  }
  else if(action.type === actions.APPEND_GREY){
    let data = [...state,action.item]
    return data;
  }
  else if(action.type === actions.SET_GREYDATA_PAGEDATA){
    let data = state.concat(action.data)
    return data;
  }
  return state;
}

const unCuratedData = (state=[], action)=>{
  if(action.type === actions.NOT_CURATED_DATA){
    return action.data
  }
  return state;
}

//#endregion
const blogger_public = (state=[],action)=>{
  if(action.type === actions.SET_BLOGGER__PUBLIC){
    return action.data
  }
  if(action.type === actions.BLOGGER_STYLE_PAGEDATA){
    let data = state.concat(action.data)
    return data;
  }
  return state
}

const bloggerOpenLooks = (state=[],action)=>{
  if(action.type === actions.SET_BLOGGER_OPEN_LOOKS){
    if(action.page==1) 
      return action.data
    else 
      return [...state,...action.data]
  }
  return state
}

const bloggerOpenLooksRequested = (state=true,action)=>{
  if(action.type === actions.SET_BLOGGER_OPEN_LOOKS_REQUESTED){
    return action.data
  }
  return state
}
const bloggerInfo = (state={},action)=>{
  if(action.type === actions.SET_BLOGGER_INFO){
    return action.data
  }
  return state
}
const visibilityOffTotal = (state=0,action)=>{
  if(action.type === actions.SET_GREYDATA_TOTAL){
    return action.total
  }
  return state;
}
const bloggerlist = (state=[],action)=>{
  if(action.type === actions.SET_BLOGGER_LIST){
    return action.value
  }
  else if(action.type === actions.SET_BLOGGER_LIST_PAGEDATA){
    let data = JSON.parse(JSON.stringify(state))
    data = data.concat(action.value)
    return data
  }
  return state
}
const totalList = (state = 0,action)=>{
  if(action.type === actions.BLOGGER_LIST_TOTAL){
    return action.total
  }
  return state
}
const EndUserInfo= (state={},action)=>{
  if(action.type === actions.SET_END_USER){
    return action.value
  }
  return state
}

const bloggerCounts = (state={},action)=>{
  if(action.type === actions.BLOGGER_COUNTS){
    return action.bloggercounts
  }
  return state
}

const userSavedLooks = (state=[],action)=>{
  if(action.type === actions.SAVED_LOOKS){
    return action.value
  }
  return state
}
const sml_id = (state="",action)=>{
  if(action.type === actions.SET_SML_ID){
    return action.sml_id
  }
  return state
}

const endUserProfile = (state={},action)=>{
  if(action.type ===actions.USER_PROFILE){
    return action.profile
  }
  return state
}

const isCuratorAuthenticated = (state= false,action)=>{
  if(action.type === actions.CURATOR_LOGIN){
    console.log(action)
    return action.value
  }
  return state
}
const isSubscribedUser = (state=false,action)=>{
  if(action.type ===actions.SUBSCRIBE){
    return action.subscribe
  }
  return state
}

const outfitMetadata = (state={ratings:"one", occasions:[], pinned:false, showToPublic:true ,season: null},action) =>{
  if(action.type == actions.SET_OUTFIT_METADATA){
    return action.data
  }
  return state
}

const bloggerMetadata = (state={skinTone:null, bodyshape:null},action) =>{
  if(action.type == actions.SET_BLOGGER_METADATA){
    console.log("metadata")
    return action.data
  }
  return state
}

const exploreLooks = (state=[],action) =>{
  if(action.type == actions.USER_EXPLORE_LOOKS){
    if(action.page==1)
      return action.data
    else
      return [...state,...action.data]
  }
  return state
}

const exploreLooksHasNextPage = (state=true,action) =>{
  if(action.type == actions.USER_EXPLORE_LOOKS_NEXT_PAGE){
      return action.value
  }
  return state
}


const savedLooks = (state=[],action) =>{
  if(action.type == actions.USER_SAVED_LOOKS){
    if(action.page==1)
      return action.data
    else
      return [...state,...action.data]
  }
  return state
}

const savedLooksLength = (state=0,action) =>{
  if(action.type == actions.USER_SAVED_LOOKS_LENGTH){
    return action.total
  }
  return state
}

const myLooks = (state=[],action) =>{
  if(action.type == actions.USER_MY_LOOKS){
    if(action.page!=1)
      return [...state,...action.data]
    else
      return action.data
  }
  return state
}

const bloggerName = (state="",action) =>{
  if(action.type == actions.BLOGGER_NAME){
    return action.name
  }
  return state
}

const boardProfile = (state={imageUrl :"", name :"", givenName : "", familyName :"", username : ""},action) => {
  if(action.type ==actions.BOARD_PROFILE){
    return action.data
  }
  return state
}

const hasUserFilledPreferences = (state=false,action) =>{
  if(action.type == actions.FILLED_USER_PREFERENCES){
    return action.hasFilled
  }
  return state
}

export default combineReducers({
  user,
  loadingState,
  loadingText,
  totalCount,
  isAuthenticated,
  isUserAuthenticated,
  blogger,
  products,
  publicProducts,
  publicCrossSell,
  bloggerName,
  crossSelldata,
  loginText,
  visibilityOffdata,
  blogger_public,
  bloggerOpenLooks,
  bloggerInfo,
  visibilityOffTotal,
  modalloading,
  bloggerlist,
  totalList,
  pageVisits,
  bloggerCounts,
  EndUserInfo,
  userSavedLooks,
  sml_id,
  endUserProfile,
  isSubscribedUser,
  isCuratorAuthenticated,
  outfitMetadata,
  bloggerMetadata,
  exploreLooks,
  savedLooks,
  myLooks,
  savedLooksLength,
  boardProfile,
  hasUserFilledPreferences,
  exploreLooksHasNextPage,
  unCuratedData,
  bloggerOpenLooksRequested,
});
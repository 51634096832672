import React, {Component} from 'react';
import {connect} from "react-redux";
import { Col,Row,Modal} from 'react-bootstrap';
import NavBar from './navbar';
import {Instagram,Grid3x3, List, HeartFill, Chat,ThreeDotsVertical} from 'react-bootstrap-icons';
import Header from './header';
import '../styles/style.css'
import '../styles/blogger_store.css'
import {fetchBloggerData, setoffImage, setonImage,getPublicProfile} from '../actions';
import Loader from './Loader'
import { Redirect } from 'react-router';

class publicProfile extends Component{
    constructor(props){
        super(props)
        // props.getBloggerProfile(1,props.user.instahandle);
        console.log(document.location.pathname.split('/'))
        props.getPublicProfile(1,document.location.pathname.split('/').pop)
        this.state={
            pageNo:1,
            pageSize:40,
            tabActive: 'first',
            open:false,
            top: 0,
            left: 0,
            type:'',
            item:''
        }
        this.setActiveKey = this.setActiveKey.bind(this)
        // this.closeModal = this.closeModal.bind(this)
        // this.toggleBlogger = this.toggleBlogger.bind(this)
       // this.gotoProduct = this.gotoProduct.bind(this)
    }
   /*  componentWillReceiveProps(nextProps) {
        this.setActiveKey();
    } */
    setActiveKey=(key)=>{   
        this.setState({
            tabActive: key
        });
    }
    render()
    {
        
        return(
            <>
              <>
                <NavBar 
                // title={this.props.user.name+'\'s store'} page="home"
                /> 
                <div className="appCapsule">
                    <Header 
                    // profile_img={this.props.user.profile_img}
                    />
                    <div className="app-header">
                        <Row>
                            <Col> 
                                <span className="section-title medium" style={{justifyContent:'center'}}>
                                    {/* {this.props.user.name} */}
                                    </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span> <Instagram color="red" size="18"/>&nbsp;&nbsp;
                                {/* {this.props.user.instahandle} */}
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span>My everyday effortless Styles.</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span>Tap any image to shop products similar to my looks.</span>
                            </Col>
                        </Row>
                    </div>
                    <div className="section full mb-2">
                        <div className="mt-2 pe-2 ps-2">
                            <div className="row">
                                {/* {this.props.totalCount > 0?
                                    this.props.blogger.map((item,index)=>{
                                        return(
                                        <>
                                           <div className="col-4 mb-2" style={{position:'relative'}}> 
                             
                                                <a href={`/shop/bloggerShop/index:${index}`} target="_self">
                                                    <img src={item.img_Url} alt="image" className="imaged w-100" />
                                                  
                                                </a>
                                                <span className="imgid">{item.sml_id}</span> 
                                                <br/>
                                            </div>
                                            
                                          </>
                                        )
                                    })
                                    :<div style={{textAlign:'center'}}>
                                        <p>No data available.</p>
                                    </div>
                                } */}
                            </div>
                            <br/>
                   
                        </div>
                    </div>
                </div>
                </>
                
            </>
        );
    }
}
const mapStateToProps  = state =>({
    
    // user: state.user,
    // loadingState: state.loadingState,
    blogger: state.blogger,
    totalCount: state.totalCount,
    publicProfiledata: state.publicProfiledata
});
const mapDispatchToProps = dispatch =>({
    // getBlogger: (pageNo,insta) => dispatch(fetchBloggerData(pageNo,insta)),
    getPublicProfile:(pageNo, insta) => dispatch(getPublicProfile(pageNo,insta))
   
});
export default connect(mapStateToProps,mapDispatchToProps)(publicProfile)
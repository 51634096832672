import React, { Component } from 'react';
import { Col, Row, Modal, Tab, Nav, Button } from 'react-bootstrap';
import NavBar from './../navbar';
import { userLogin, userLogout, setUserPreferences } from '../../actions';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import PublicLoginModal from './publicLoginModal';
import { X, ThreeDotsVertical, ChevronDoubleLeft, BookmarksFill } from 'react-bootstrap-icons'
import { connect } from "react-redux";
import Swal from 'sweetalert2';
import { SaveUserPreferences, GetUserPreferences } from './../../api/publicApis'
import ImageUploader from 'react-image-upload'
import 'react-image-upload/dist/index.css'
import Axios from 'axios';



class Preferences extends Component {
      constructor() {
            super();
            this.state = {
                  requested: false,
                  requestedImagePath: null,
                  errored: false,
                  loading: false,
                  responseData: [],
                  currentCat: null
            };
            this.masterCatsList = ["One-Piece", "Topwear", "Bottomwear", "Outerwear", "Footwear", "Bags", "Accesories"]
      }



      componentDidMount = async () => {


      }
      getImageFileObject = async (imageFile) => {
            console.log(imageFile);
            if (!imageFile || !imageFile.dataUrl) return;
            this.setState({ loading: false })
            let data = new FormData()
            data.append('file', imageFile.file)
            let result = await Axios.post('/lens-image', data, { // receive two parameter endpoint url ,form data 
            })
            if (result.data.response) {
                  let responseData = []
                  let response = result.data.response
                  this.masterCatsList.forEach(cat => {
                        if (response[cat] && response[cat].length > 0) {
                              responseData.push({
                                    masterCategory: cat,
                                    products: response[cat]
                              })
                        }

                  })
                  let currentCat = responseData[0].masterCategory
                  this.setState({ responseData, currentCat });
            }
            else {
                  this.setState({ errored: true })
            }
            this.setState({ loading: false })
      }
      runAfterImageDelete = (file) => {
            console.log({ file })
      }


      updateCurrentCat = (cat)=>{
            this.setState({currentCat: cat})
      }


      render() {
            let target = window.navigator.userAgent.toLowerCase().includes('wv')?'_self':'_blank'; 
            console.log(this.state)
            let products = this.state.responseData.length>0?this.state.responseData.filter(cat => cat.masterCategory == this.state.currentCat)[0].products.map(item1 => {
                        return <Col xs={6} md={3} lg={3} style={{ position: "relative" }} className='mb-2'>
                        <a href={item1.product_url} className="item" target="_blank" onClick={() => { this.countProductClick(item1.product_id) }}>
                              <div className="imageWrapper">
                                    <img src={item1.image_url} alt="image" className="modalimaged fit-product-images" />
                              </div>
                              <div className="in">
                                    <div>
                                          <h4 className="mb-05">{item1.title}</h4>
                                          <div className="text-muted">
                                                {item1.description}
                                                <div className="mt-05"><strong>&#8377;{item1.price}&nbsp;</strong><span>{item1.brand}</span></div>
                                          </div>
                                    </div>
                              </div>
                        </a>

                  </Col>}):null
            return (
                  <>

                        <NavBar title={<a href='/'><img style={{ height: '1em' }} src='/static/images/stl_logo.JPG' /></a>} page="home" />
                        <div className="appCapsule">

                              <div className="app-header">
                                    {!this.state.requested ?
                                          <img className='lens-request-image' src='./static/images/desktop/lens_banner.png' /> :
                                          <img className='lens-request-image' src={this.state.requestedImagePath} />
                                    }
                                    <div className='upload-image-wrapper-class'>
                                          <ImageUploader
                                                style={{ height: '30vh', width: '40vw', background:'rgba(0 0 0 0)'}}
                                                onFileAdded={(img) => this.getImageFileObject(img)}
                                                onFileRemoved={(img) => this.runAfterImageDelete(img)}
                                                

                                          />
                                    </div>

                              </div>
                              {this.state.loading == false ? <div>
                                    <div className='m-2'>
                                          {this.state.responseData.map(cat => <button className={cat.masterCategory == this.state.currentCat ? 'btn btn-dark  me-1 mb-1' : 'btn btn-outline-dark me-1 mb-1 mt-1'} onClick={()=>this.updateCurrentCat(cat.masterCategory)}>{cat.masterCategory}</button>)}
                                    </div>
                                    <Row className='p-3'>
                                          {products}
                                    </Row>
                              </div> : null}



                        </div>
                  </>
            );
      }
}


const mapStateToProps = state => ({
      isUserAuthenticated: state.isUserAuthenticated,
      lensData: state.lensData
})
const mapDispatchToProps = dispatch => ({
      googleUserLogin: (googleProfile) => dispatch(userLogin(googleProfile)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Preferences);
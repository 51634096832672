import React, {useState} from 'react'
import imgsrc from '../../images/vector4.png';
import '../../styles/style.css'
import '../../styles/login.css'
import {connect} from "react-redux";
import {userLogin, userLogout} from '../../actions'
import { Redirect } from 'react-router';
import PublicNavbar from './publicNavbar';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import ReactGA from 'react-ga';
import mixpanel, { init } from 'mixpanel-browser';

mixpanel.init("1a8ebbbb5ab0d0b35dc91b5e3b1578e1" ,{
    debug :true,
    ignore_dnt: true
})
const clientId = "806676170053-kdqovn15piaombk6nubcaurib6ds9f09.apps.googleusercontent.com";
class Login extends React.Component
{
    constructor(props){
      super(props)
      this.state = {
        reqLogin:false
      }
      /* this.onSignIn = this.onSignIn.bind(this)
      this.signInFail = this.signInFail.bind(this) */
    }
    componentDidUpdate(prevState, prevProps){ 
      if(this.props.isUserAuthenticated){
        if(this.props.params && this.props.params.match.next)
          window.location.href = this.props.params.match.next
        else
          window.location.href = '/explore'
      }
    }

    render()
    {
      const googleResponse = (response) =>{
        let details = response.profileObj
        this.setState({
          reqLogin: true
        })
        this.props.googleUserLogin(details)
        ReactGA.set({userId: details.name});
        mixpanel.people.set(details.email,{
          "company": "sml_User",
          "$name": details.name,
          "$email": details.email,
                  })
        mixpanel.register({
            "First Login Date": new Date().toISOString(),
            "Visitor type" : "End user"
        });

        mixpanel.identify();
        mixpanel.track("User login",{
                distinct_id: details.email,
                name: details.name,
                email: details.email,
                googleId: details.googleid,
            })
        mixpanel.alias(details.email)
        
      }
      const logout = (response)=>{
        console.log('logout',response)
        localStorage.clear()
        this.props.userLogout()
        window.location.href = '/public/home'
      }
        return (
            <>
            {/* <div id="loader">
              <div className="spinner-border text-primary" role="status" />
            </div> */}
            <PublicNavbar title="Login"/> 
            <div id="appCapsule" className="pt-0">
              <div className="login-form" style={{marginTop:"40%"}}>
                <h3>Please Login with google to save looks</h3>
                <div className="section">
                  <img
                    src={imgsrc}
                    alt="image"
                    className="form-image"
                  />
                </div>
                
                
                <div className="google-login">
                  {this.props.isLogin?
                   <GoogleLogout
                   clientId={clientId}
                   buttonText="Logout"
                   onLogoutSuccess={logout}
                  />
                  :<GoogleLogin
                    clientId={clientId}
                    buttonText="Sign-in with Google"
                    onSuccess={googleResponse}
                    onFailure={googleResponse}
                    cookiePolicy={'single_host_origin'}
                  />
                  }   
                </div>
              </div>
            </div>
          </>
        )
    }
}
const mapStateToProps = state => ({
    isLogin: state.isLogin,
    isUserAuthenticated : state.isUserAuthenticated,
    state : state
})
const mapDisparchToProps = (dispatch) =>({
  googleUserLogin : (googleProfile) => dispatch(userLogin(googleProfile)),
  userLogout:()=>dispatch(userLogout())
})
export default connect(mapStateToProps, mapDisparchToProps)(Login);

import { black } from 'material-ui/styles/colors';
import { white } from 'material-ui/styles/colors';
import React from 'react'
import { Navbar, Container, Nav, NavDropdown, Modal } from "react-bootstrap";
import { List, X, Person, Archive, Gear, BoxArrowDownRight, BoxArrowInDownRight, ChevronLeft } from 'react-bootstrap-icons';
import {connect} from 'react-redux'
import {Logout, UserLogout} from '../actions'
import mixpanel, { init } from 'mixpanel-browser';
import ReactGA from 'react-ga'
mixpanel.init("1a8ebbbb5ab0d0b35dc91b5e3b1578e1" ,{
    debug :true,
    ignore_dnt: true
})
//Header Scrolled
// Animated header style
//----
const SideMenu = (props) => {
    const scroll = React.useRef(null);
    const handleScroll = () => {
        let scrolly = window.scrollY;
        if (scrolly > 20) {       
            scroll.current.classList.add("is-active")
            //console.log(scroll.current.className)
        }
        else
            scroll.current.classList.remove("is-active")
    }
   
    
    /*const searchFocus = React.useRef(null)
     const toggleSearch = () => {
        const node = search.current.classList.contains('show');
        if (node) {
            search.current.classList.remove("show")
        }
        else {
            search.current.classList.add("show")
            searchFocus.current.focus();
        }
    } */
    const [open, setOpen] = React.useState(false);
    const modalOpen = () => {
        setOpen(true)
    }
    const modalClose = () => {
        setOpen(false)
    }
    const goBack = (e) => {
        e.preventDefault()
        if(props.page === "editprofile"){
            window.location.href = "/shop/mystore"
        }
        if (window.location.pathname.includes('shop/outfit')){
            window.location.href = '/shop'
        }
        else
            window.history.go(-1)
    }
    const handleLogout = () =>{
        props.Logout();
    }
    const handleUserLogout = () =>{
        props.UserLogout();
    }
    return (
        <>
            {/* App Header */}
            <div className="scrolled is-active mt-2" ref={scroll}>
                <div className="left">
                    {props.page === 'home'?<a href="#" className="headerButton" onClick={modalOpen}>
                            <List size={30} color={black} />
                        </a>
                        :<a href="#" className="headerButton" onClick={goBack}>
                            <ChevronLeft size={30} />
                        </a>
                    }
                </div>
                <div className="pageTitle">
                    {props.title}
                </div>
            </div>
            <Modal show={open} onHide={modalClose}
                className="panelbox panelbox-left"
                dialogRole = "document"
            >
                <Modal.Body className="p-0">
                        <ul className="listview flush transparent no-line image-listview mt-2">
                    {props.isAuthenticated && <>
                            <li>
                                <a onClick={()=>{ReactGA.event({category: 'Side Menu', action:"Store public view clicked", lable:"Store public view clicked", value: 1});
                                            mixpanel.track("Store public view clicked");}} href={`/${props.user.username}`} target="_self" className="item">
                                    <div className="in">
                                        Store public view                        
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a onClick={()=>{ReactGA.event({category: 'Side Menu', action:"Store backend view clicked", lable:"Store backend view clicked", value: 1});
                                            mixpanel.track("Store backend view clicked");}} href="/shop/mystore" target="_self" className="item">
                                    <div className="in">
                                        Store backend view                          
                                    </div>
                                </a>
                            </li>
                        <li>
                            <a onClick={()=>{ReactGA.event({category: 'Side Menu', action:"Edit store profile clicked", lable:"Edit store profile clicked", value: 1});
                                            mixpanel.track("Edit store profile clicked");}} href="/shop/editprofile" target="_self" className="item">
                                <div className="in">
                                    Edit store profile                          
                                </div>
                            </a>
                        </li>
                            <li>
                                <a onClick={()=>{ReactGA.event({category: 'Side Menu', action:"Store Analytics clicked", lable:"Store Analytics clicked", value: 1});
                                            mixpanel.track("Store Analytics clicked");}} href="/shop/analytics" target="_self"  className="item" >
                                    <div className="in">
                                        Store Analytics    
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a onClick={()=>{ReactGA.event({category: 'Side Menu', action:"Sales clicked", lable:"Sales clicked", value: 1});
                                            mixpanel.track("Sales clicked");}} href="/shop/amazonSales" target="_self"  className="item" >
                                    <div className="in">
                                        Your Sales    
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a onClick={()=>{ReactGA.event({category: 'Side Menu', action:"Bank Details clicked", lable:"Bank Details clicked", value: 1});
                                            mixpanel.track("Bank Details clicked");}} href="/shop/bankDetails" target="_self"  className="item" >
                                    <div className="in">
                                        Bank Details    
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a onClick={()=>{ReactGA.event({category: 'Side Menu', action:"Blogger Handbook clicked", lable:"Blogger Handbook clicked", value: 1});
                                            mixpanel.track("Blogger Handbook clicked");}} href="https://drive.google.com/file/d/1QNp11LvMI5s5Jn4ReXJ9mRMfQdr-PnkR/view?usp=sharing" target="_blank"  className="item" >
                                    <div className="in">
                                        Blogger Handbook
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_self"  className="item" onClick={handleLogout}>
                                    <div className="in">
                                        Store Logout    
                                    </div>
                                </a>
                            </li>
                            <div class="divider mt-2 mb-3"></div>
                            </>
                        }

                    <li>
                                <a onClick={()=>{ReactGA.event({category: 'Side Menu', action:"Explore page clicked", lable:"Explore page clicked", value: 1});
                                            mixpanel.track("Explore page clicked");}} href="/" className="item">
                                    <div className="in">
                                       All Looks
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a onClick={()=>{ReactGA.event({category: 'Side Menu', action:"Bodyshape Looks clicked", lable:"Bodyshape Looks clicked", value: 1});
                                            mixpanel.track("Bodyshape Looks clicked");}} href="/my-looks" className="item">
                                    <div className="in">
                                       Bodyshape Looks
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a onClick={()=>{ReactGA.event({category: 'Side Menu', action:"Saved Looks clicked", lable:"Saved Looks clicked", value: 1});
                                            mixpanel.track("Saved Looks clicked");}} href="/saved-looks" className="item" target="_self">
                                    <div className="in">
                                            Saved Looks
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a onClick={()=>{ReactGA.event({category: 'Side Menu', action:"Preferences tab clicked", lable:"Preferences tab clicked", value: 1});
                                            mixpanel.track("Preferences tab clicked");}} href="/user/preferences" className="item" target="_self">
                                    <div className="in">
                                            Preferences
                                    </div>
                                </a>
                            </li>
                            
                            {props.isUserAuthenticated ?  <>
                            
                            <li>
                                <a href="#" target="_self"  className="item" onClick={handleUserLogout}>
                                    <div className="in">
                                        User Logout    
                                    </div>
                                </a>
                            </li> </>: <>
                            
                        <li>
                                <a onClick={()=>{ReactGA.event({category: 'Side Menu', action:"User Login tab clicked", lable:"User Login tab clicked", value: 1});
                                            mixpanel.track("User Login tab clicked");}} href= {`/user/login?next=${window.location.pathname}`} className="item">
                                    <div className="in">
                                    User Login
                                    </div>
                                </a>
                            </li>
                            </>
                        } 
                            <div class="divider mt-2 mb-3"></div>
                           {!props.isAuthenticated && <> <li>
                                <a onClick={()=>{ReactGA.event({category: 'Side Menu', action:"Store Login tab clicked", lable:"Store Login tab clicked", value: 1});
                                            mixpanel.track("Store Login tab clicked");}} href="/shop/login" className="item">
                                    <div className="in">
                                       Store Login
                                    </div>
                                </a>
                            </li>
                            </>}
                            <li>
                                <a onClick={()=>{ReactGA.event({category: 'Side Menu', action:"STL Store tab clicked", lable:"STL Store tab clicked", value: 1});
                                            mixpanel.track("STL Store tab clicked");}} href="/blogger-signup" className="item" target="_self">
                                    <div className="in" style={{'color': '#e75480'}}>
                                            Launch Your Store
                                        </div>
                                </a>
                            </li>
                            <li>
                                <a onClick={()=>{ReactGA.event({category: 'Side Menu', action:"ToS, Privacy Policy tab clicked", lable:"ToS, Privacy Policy tab clicked", value: 1});
                                            mixpanel.track("ToS, Privacy Policy tab clicked");}} href="https://drive.google.com/drive/u/3/folders/1wDxiMs0qD6s2H54Fe4Yv6kqUSpzWu7pH" className="item" target="_blank">
                                    <div className="in">
                                    ToS, Privacy Policy
                                        </div>
                                </a>
                            </li>                   
                    </ul>
                </Modal.Body>
        </Modal>
        </>
    );
}
const matStateToProps = state => ({
    isAuthenticated: state.isAuthenticated,
    isUserAuthenticated : state.isUserAuthenticated,
    user: state.user,
})
const mapDispatchToProps = dispatch => ({
    Logout:()=> dispatch(Logout()),
    UserLogout:()=> dispatch(UserLogout()),
})
export default connect(matStateToProps, mapDispatchToProps)(SideMenu)
import React, { Component } from 'react';
import { Col, Row, Modal, Tab, Nav, Button } from 'react-bootstrap';
import NavBar from './../navbar';
import { userLogin, updateUserLoginState} from '../../actions';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import PublicLoginModal from './publicLoginModal';
import { X, ThreeDotsVertical, ChevronDoubleLeft, BookmarksFill } from 'react-bootstrap-icons'
import { connect } from "react-redux";
import Swal from 'sweetalert2';
import { SaveUserPreferences, GetUserPreferences } from './../../api/publicApis'
import ImageUploader from 'react-image-upload'
import 'react-image-upload/dist/index.css'
import Axios from 'axios';
import QueryString from 'query-string';



class History extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  loading: true,
                  total_pages: 10,
                  current_page: 1,
                  responseData: [],
            };
            props.updateUserLoginState()
            this.getResponse()
            
      }

      getResponse = async () => {
            let result = await Axios.post('/user/lens-history', { current_page: this.state.current_page })
            if (result.data.response != undefined) {
                  let loading = false
                  let responseData = result.data.response
                  let total_pages = result.data.total_pages
                  this.setState({ loading, responseData, total_pages });
            } else {
                  this.setState({ errored: true })
            }
      }

      componentDidMount = async () => {

      }

      render() {
            console.log(this.state);
            let queries = this.state.responseData.length > 0 ? this.state.responseData.map(item1 => {
                  return <Col xs={4} md={4} lg={4} style={{ position: "relative" }} className='mb-2'>
                        <a href={`${window.location.origin}/lens-url?img_url=${item1.imgUrl}`} target='_blank'>
                              <div className="imageWrapper" style={{boxShadow: "0 0 3px #9E9E9E"}}>
                                    <img src={item1.imgUrl} alt="image" className="modalimaged fit-product-images" />
                              </div>
                        </a>

                  </Col>
            }) : null
            return (
                  <>

                        <NavBar title={<img style={{ height: '1em', width: '50%' }} src='/static/images/stl_logo.JPG' />} page="home" />
                        <div className="appCapsule">

                              {this.state.loading == false || this.state.errored == true
                                    ? this.state.responseData.length ?
                                          <div>
                                                <Row className='p-1 m-2'>
                                                      <h2 className='m-2 mb-4'>Your Lens history</h2>
                                                      {queries}
                                                </Row>
                                          </div> :
                                          <div className='m-5' style={{textAlign:'center'}}>
                                                <h2 style={{color:'#888',marginTop:'5rem', marginBottom:'2px', fontWeight:800, fontSize:'2.5rem'}}>No results found</h2>
                                                <p>Your STL Lens history searches will appear here.</p>
                                          </div>
                                    : <div className='m-5'>
                                          <center>
                                                <div className="spinner-border mx-5 my-2" role="status">
                                                      <span className="sr-only">Loading...</span>
                                                </div>
                                                <h3>Finding previous searches...</h3>
                                          </center>
                                    </div>}


                        </div>
                        {this.state.total_pages>1 ? <div className='m-3 align-bottom'>
                              <center>
                                    <button className='p-2 m3 btn btn-secondary' disabled={this.state.current_page <= 1} 
                                    onClick={()=>this.setState({loading: true,current_page: this.state.current_page-1}, this.getResponse)}
                                    >Previous</button>
                                    <button className='m-3 btn btn-secondary' disabled={this.state.current_page >= this.state.total_pages}
                                    onClick={()=>this.setState({loading: true,current_page:this.state.current_page+1} , this.getResponse)}
                                    >next</button>
                              </center>
                        </div>: null}
                  </>
            );
      }
}


const mapStateToProps = state => ({
      isUserAuthenticated: state.isUserAuthenticated,
      lensHistory: state.lensHistory
})
const mapDispatchToProps = dispatch => ({
      googleUserLogin: (googleProfile) => dispatch(userLogin(googleProfile)),
      updateUserLoginState: () => dispatch(updateUserLoginState()),
})
export default connect(mapStateToProps, mapDispatchToProps)(History);
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { Col, Row, Modal, Tab, Nav, Button } from 'react-bootstrap';
import NavBar from './../navbar';
import { userLogin, userLogout, setUserPreferences } from '../../actions';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import PublicLoginModal from './publicLoginModal';
import { X, ThreeDotsVertical, ChevronDoubleLeft, BookmarksFill } from 'react-bootstrap-icons'
import { connect } from "react-redux";
import Swal from 'sweetalert2';
import { SaveUserPreferences, GetUserPreferences, trackMixPanelEvent } from './../../api/publicApis'
import ImageUploader from 'react-image-upload'
import 'react-image-upload/dist/index.css'
import Axios from 'axios';
import QueryString from 'query-string';
import LinkLogo from './../../images/hyperlink-icon.svg'
import WhatsappLogo from './../../images/whatsapp.svg'
import sweetalert2 from 'sweetalert2';


class Preferences extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  requested: false,
                  requestedImagePath: null,
                  errored: false,
                  loading: true,
                  responseData: [],
                  currentCat: null,
                  responseReceived: false,
                  dailyLimitReached: false,
                  searchHash: null,
                  showAllProducts: false
            };
            this.masterCatsList = ["Topwear", "Bottomwear", "One-Piece", "Outerwear", "Footwear", "Bags", "Accesories"]
            this.imgUrl = QueryString.parse(window.location.search).img_url
            if (!this.imgUrl) window.location.href = "/lens"
            this.getResponse()
      }

      getResponse = async () => {
            let data = {
                  img_url: this.imgUrl
            }
            let result = await Axios.post('/lens-url', data)
            if (result.data.response) {
                  let responseData = []
                  let response = result.data.response
                  let searchHash = result.data.searchHash;
                  this.masterCatsList.forEach(cat => {
                        if (response[cat] && response[cat].length > 0) {
                              responseData.push({
                                    masterCategory: cat,
                                    products: response[cat]
                              })
                        }

                  })
                  let currentCat = responseData[0] ? responseData[0].masterCategory : null;
                  let loading = false
                  let dailyLimitReached = result.data.dailyLimitReached == true ? true : false;
                  if (responseData.map(c => c.masterCategory).includes("Topwear") && responseData.map(c => c.masterCategory).includes("Bottomwear")) {
                        responseData = responseData.filter(c => c.masterCategory != "One-Piece");
                  }
                  this.setState({ loading, responseData, currentCat, dailyLimitReached, searchHash });
            } else {
                  this.setState({ errored: true })
            }
      }


      componentDidMount = async () => {


      }

      updateCurrentCat = (cat) => {
            if (this.state.currentCat != cat)
                  this.setState({ currentCat: cat, showAllProducts: false })
      }


      render() {
            let target = window.navigator.userAgent.toLowerCase().includes('wv') ? '_self' : '_blank';
            let productsLength = this.state.showAllProducts ? 8 : 4;
            let products = null
            let loadMoreButton = null
            if (this.state.responseData.length > 0) {
                  let allProducts = this.state.responseData.filter(cat => cat.masterCategory == this.state.currentCat)[0].products
                  products = allProducts.slice(0, productsLength).map(item1 => {
                        return (
                              <Col xs={6} md={3} lg={3} style={{ position: "relative" }} className='mb-2 reduced-paddings' onClick={() => {
                                    trackMixPanelEvent("Lens result product clicked")
                              }}>
                                    <a onClick={()=>{
                                                if(window.NativeAndroid){
                                                      window.NativeAndroid.goToSimilarProductsPage(item1.product_url);
                                                      return false
                                                } else 
                                                      return true;
                                          }}
                                          href={window.NativeAndroid?"#":item1.product_url} className="item" target="_blank" >
                                          <div className="imageWrapper">
                                                <img src={item1.image_url} alt="image" className="fit-product-images" />
                                          </div>
                                          <div className="in">
                                                <div>
                                                      <h4 className="mb-05">{item1.title}</h4>
                                                      <div className="text-muted">
                                                            {/* {item1.description.substring(0, 10) + "..."} */}
                                                            <div className="mt-05"><strong>&#8377;{item1.price}&nbsp;</strong><span>{item1.ecommerce}</span></div>
                                                      </div>
                                                </div>
                                          </div>
                                    </a>

                              </Col>
                        )
                  })
                  loadMoreButton = allProducts.length > 4 && this.state.showAllProducts == false ?
                        <button className='btn btn-primary' onClick={() => {
                              this.setState({ showAllProducts: true })
                        }}>Show more</button>
                        : null

            }


            return (
                  <>

                        <NavBar title={<img style={{ height: '1em', width: '50%' }} src='/static/images/stl_logo.JPG' />} page="home" />
                        <div className="appCapsule">

                              <div className="app-header">
                                    <div className='upload-image-wrapper-class'>
                                          <img className='lens-request-image' src={this.imgUrl} />
                                    </div>

                              </div>
                              {this.state.responseData.length && <center className='my-1'>
                                    {this.state.searchHash ? <>
                                          <br />
                                          <a className='btn-light m-0 px-0'
                                                onClick={() => {
                                                      trackMixPanelEvent("Lens result copy to clipboard button clicked")
                                                      const urlToShare = `https://${window.location.host}/lens-result/${this.state.searchHash}`
                                                      navigator.clipboard.writeText(urlToShare)
                                                      if (window.NativeAndroid) {
                                                            window.NativeAndroid.copyToClipboard(urlToShare);
                                                      }
                                                      Swal.fire({
                                                            icon: 'success',
                                                            title: 'Link copied to clipboard',
                                                      })
                                                }}>Share this search
                                                <img src={LinkLogo} style={{ height: "1rem" }} className='social-share-button-imgs' />
                                          </a>
                                          {/* <a href={'/lens-result/'+this.state.searchHash} className='link-dark mt-5 mx-4' style={{display:"inline-block"}} target='_blank'><p><ins>Share</ins></p></a> */}
                                    </>
                                          : null}
                              </center>}
                              {this.state.loading == false || this.state.errored == true
                                    ?
                                    this.state.responseData.length ?
                                          <div>
                                                <div className='mx-2 my-1'>
                                                      {this.state.responseData.map(cat => <button className={cat.masterCategory == this.state.currentCat ? 'btn btn-dark  me-1 mb-1' : 'btn btn-outline-dark me-1 mb-1 mt-1'} onClick={() => {
                                                            this.updateCurrentCat(cat.masterCategory)
                                                            trackMixPanelEvent(`Lens result category button ${cat.masterCategory}`);
                                                      }}>{cat.masterCategory}</button>)}
                                                </div>
                                                <Row className='px-1'>
                                                      {products}
                                                </Row>
                                                <center>
                                                      {loadMoreButton}
                                                </center>
                                          </div> :
                                          this.state.dailyLimitReached ?
                                                <div className='m-5'><h3>You have done more than 30 searches in a day. Please try again tomorrow. </h3></div>
                                                : <div className='m-5'>
                                                      <h3>No similar products found. Possible Reasons:</h3>
                                                      <ul style={{ listStyleType: "disc" }}>
                                                            <li>Try another angle where the outfit is clearly visible.</li>
                                                            <li>No similar products available in India.</li>
                                                            <li>Lens only works on Womens' Western Wear. We will add Ethnic wear, and Mens' wear soon.</li>
                                                      </ul>
                                                </div>
                                    : <div className='m-5'><center>
                                          <div className="spinner-border mx-5 my-2" role="status">
                                                <span className="sr-only">Loading...</span>
                                          </div>
                                          <h3>Finding similar products...</h3>
                                    </center></div>}

                              {this.state.responseData.length && <center className='mt-1'>
                                    {this.state.searchHash ? <>
                                          {isMobile && window.NativeAndroid && window.NativeAndroid.shareAPKLinkToWhatsapp && <center> <a className='m-0 px-0' style={{ outline: "0", marginRight: "1rem" }}
                                                onClick={() => {
                                                      trackMixPanelEvent("Lens result Whatsapp share button clicked")
                                                      window.NativeAndroid.shareAPKLinkToWhatsapp()
                                                }} >
                                                <img src='/static/images/invite-gif2.gif' className='whatsapp-baner-share-img' />
                                          </a></center>}
                                    </>
                                          : null}
                              </center>}
                              <center>

                              </center>

                        </div>
                  </>
            );
      }
}


const mapStateToProps = state => ({
      isUserAuthenticated: state.isUserAuthenticated,
      lensData: state.lensData
})
const mapDispatchToProps = dispatch => ({
      googleUserLogin: (googleProfile) => dispatch(userLogin(googleProfile)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Preferences);
import React, { useEffect, useState } from 'react'
import { Blurhash } from 'react-blurhash'
import { isMobile } from 'react-device-detect';

const LensDailyImage = ({trackMixPanelEvent,image_url,post_url,thumb_id,blurHash}) => {
	const [isImageLoaded,setImageLoaded] = useState(false)
	useEffect(()=>{
		const img = new Image()
		img.onload=()=>{
			setImageLoaded(true)
		}
		img.src=image_url
	},[image_url])

    return (
    <div className="col-6 mb-2" style={{ position: 'relative' }}>
	<a href={post_url} target="_blank">
		{
			!isImageLoaded? <Blurhash hash={blurHash || 'LEHV6nWB2yk8pyo0adR*.7kCMdnj'} height={ isMobile? '60vw' : '700px'} width={'100%'} resolutionX={32} resolutionY={32} punch={1} />
			:<img onClick={() => {
				trackMixPanelEvent(`thumb_${thumb_id} onboarding image clicked`)
			}} src={image_url} alt="image" className="imaged w-100" />
		}
	</a>
	<br />
</div>
  )
}

export default LensDailyImage
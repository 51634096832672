import React, { Component } from 'react';
import { Col, Row, Modal, Tab, Nav, Button } from 'react-bootstrap';
import NavBar from './../navbar';
import { userLogin, userLogout, setUserPreferences } from '../../actions';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import PublicLoginModal from './publicLoginModal';
import { X, ThreeDotsVertical, ChevronDoubleLeft, BookmarksFill } from 'react-bootstrap-icons'
import { connect } from "react-redux";
import Swal from 'sweetalert2';
import { SaveUserPreferences, GetUserPreferences } from './../../api/publicApis'
import ReactGA from 'react-ga';
import mixpanel, { init } from 'mixpanel-browser';

mixpanel.init("1a8ebbbb5ab0d0b35dc91b5e3b1578e1" ,{
    debug :true,
    ignore_dnt: true
})

const clientId = "806676170053-kdqovn15piaombk6nubcaurib6ds9f09.apps.googleusercontent.com";

class Preferences extends Component {
  constructor() {
    super();
    this.state = {
      height: '',
      weight: '',
      budget: '',
      shape: '',
      skin_tone: '',
      googleLoginModal: false,
    };
    this.onHeightChange = this.onHeightChange.bind(this);
    this.onWeightChange = this.onWeightChange.bind(this);
    this.onBudgetChange = this.onBudgetChange.bind(this);
    this.onShapeChange = this.onShapeChange.bind(this);
    this.onSkinChange = this.onSkinChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onHeightChange = (e) => {
    this.setState({
      height: e.target.value,
    });
  }
  onWeightChange = (e) => {
    this.setState({
      weight: e.target.value,
    });
  }
  onBudgetChange = (e) => {
    this.setState({
      budget: e.target.value,
    });
  }
  onShapeChange = (e) => {
    this.setState({
      shape: e.target.value,
    });
  }
  onSkinChange = (e) => {
    this.setState({
      skin_tone: e.target.value,
    });
  }
  onSubmit = async (e) => {
    console.log(this.state)
    
    if(this.state.budget=="" || this.state.height=="" || this.state.shape=="" || this.state.skin_tone=="" || this.state.weight=="" )
    {
      Swal.fire({
        icon:"warning",
        title:"Values Missing",
        text:"Please fill all fields required"
      })
      return 
    }
    if(this.props.isUserAuthenticated){
      await this.savePreferences()
    } else {
      this.setState({googleLoginModal : true})
    }
    
  }

  savePreferences = async(email,profileObject) =>{
    const userPreferences = {
      height: this.state.height,
      weight: this.state.weight,
      budget: this.state.budget,
      shape: this.state.shape,
      skin_tone: this.state.skin_tone
    };
    if(this.props.isUserAuthenticated){
      this.props.setUserPreferences(true)
      await SaveUserPreferences(userPreferences)
      
    } else {
      await SaveUserPreferences(userPreferences,email,profileObject)
    }
    Swal.fire({
      icon:"success",
      title:"Preferences Saved !",
      text:"Continue to explore looks for set preferences."
    })

  }

  googleResponse = async (response) =>{
    let details = response.profileObj
    this.setState({googleLoginModal : false})
    await this.props.googleUserLogin(details)
    ReactGA.set({userId: details.name});
        mixpanel.register({
            "First Login Date": new Date().toISOString(),
            "Visitor type" : "End user"
        });
        mixpanel.identify(details.email);
        mixpanel.track("User login",{
                name: details.name,
                email: details.email,
                googleId: details.googleid,
            })
        mixpanel.alias(details.email)
        mixpanel.people.set({
                "company": "sml_User",
                "$name": details.name,
                "$email": details.email,
                        })
    await this.savePreferences(details.email,details)
    
  }

  componentDidMount = async()=>{
    if(this.props.isUserAuthenticated){
      let result = await GetUserPreferences()
      await new Promise( res => this.setState(result.preferences,res))
      if(this.state.height){
        this.props.setUserPreferences(true)
      }
    }
  }

  render() {
    let target = window.navigator.userAgent.toLowerCase().includes('wv')?'_self':'_blank'; 
    return (
      <>

        <NavBar title= {<a href='/'><img style={{height: '1em'}} src='/static/images/stl_logo.JPG' /></a>} page="home" />
        <div className="appCapsule">

          <div className="app-header">

            <div>
              <h3 class="mt-2 mb-1">Please add your body details,<br></br> and we will show you personalized looks</h3><br></br>
              <h3 class="mb-1">Height</h3>
              <div class="btn-group" role="group">
                <input type="radio" class="btn-check" name="height" id="height1" value="short" checked={this.state.height === "short"} onChange={this.onHeightChange} />
                <label class="btn btn-outline-primary" for="height1">Short<br></br>Below 5'</label>

                <input type="radio" class="btn-check" name="height" id="height2" value="medium" checked={this.state.height === "medium"} onChange={this.onHeightChange} />
                <label class="btn btn-outline-primary" for="height2">Medium<br></br> 5' - 5'5"</label>

                <input type="radio" class="btn-check" name="height" id="height3" value="tall" checked={this.state.height === "tall"} onChange={this.onHeightChange} />
                <label class="btn btn-outline-primary" for="height3">Tall <br></br> Above 5'6"</label>
              </div>
              <br/>

              <div class="mt-1"></div>
              <h3 class="mt-2 mb-1">Weight</h3>
              <div class="btn-group" role="group">
                <input type="radio" class="btn-check" name="weight" value="Petite" id="weight1" checked={this.state.weight === "Petite"} onChange={this.onWeightChange} />
                <label class="btn btn-outline-primary" for="weight1">Petite</label>

                <input type="radio" class="btn-check" name="weight" value="Medium" id="weight2" checked={this.state.weight === "Medium"} onChange={this.onWeightChange} />
                <label class="btn btn-outline-primary" for="weight2">Medium</label>

                <input type="radio" class="btn-check" name="weight" value="Plus" id="weight3" checked={this.state.weight === "Plus"} onChange={this.onWeightChange} />
                <label class="btn btn-outline-primary" for="weight3">Plus</label>

                <input type="radio" class="btn-check" name="weight" value="Athletic" id="weight4" checked={this.state.weight === "Athletic"} onChange={this.onWeightChange} />
                <label class="btn btn-outline-primary" for="weight4">Athletic</label>

              </div>
              <br/>
              <div class="mt-1"></div>
              <h3 class="mt-2 mb-1">Budget</h3>
              <div class="btn-group" role="group">
                <input type="radio" class="btn-check" name="budget" value="low" id="budget1" checked={this.state.budget === "low"} onChange={this.onBudgetChange} />
                <label class="btn btn-outline-primary" for="budget1"><i class="fa fa-rupee"></i></label>

                <input type="radio" class="btn-check" name="budget" value="medium" id="budget2" checked={this.state.budget === "medium"} onChange={this.onBudgetChange} />
                <label class="btn btn-outline-primary" for="budget2"><i class="fa fa-rupee"></i><i class="fa fa-rupee"></i></label>

                <input type="radio" class="btn-check" name="budget" value="high" id="budget3" checked={this.state.budget === "high"} onChange={this.onBudgetChange} />
                <label class="btn btn-outline-primary" for="budget3"><i class="fa fa-rupee"></i><i class="fa fa-rupee"></i><i class="fa fa-rupee"></i></label>
              </div>
              <br/>

              <div class="mt-1"></div><br></br>
              <h3 class="mt-2 mb-1">Body Shape</h3>
              <div class="btn-group" role="group">
                <Row>
                  <Row>
                    <Col>
                    <input type="radio" class="btn-check" name="shape" value="Apple" id="shape1" checked={this.state.shape === "Apple"} onChange={this.onShapeChange} />
                    <label for="shape1" className='m-1'><img src='/static/images/body_shape/1.png' className={this.state.shape === "Apple"?"border border-3  border-secondary":""}/></label>
                    </Col>
                    <Col>
                    <input type="radio" class="btn-check" name="shape" value="Pear" id="shape2" checked={this.state.shape === "Pear"} onChange={this.onShapeChange} />
                    <label for="shape2" className='m-1'><img src='/static/images/body_shape/2.png' className={this.state.shape === "Pear"?"border border-3  border-secondary":""} /></label>
                    </Col>
                    <Col>
                    <input type="radio" class="btn-check" name="shape" value="Inverted Triangle" id="shape3" checked={this.state.shape === "Inverted Triangle"} onChange={this.onShapeChange} />
                    <label for="shape3" className='m-1'><img src='/static/images/body_shape/3.png' className={this.state.shape === "Inverted Triangle"?"border border-3  border-secondary":""} /></label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={2}></Col>
                    <Col>
                    <input type="radio" class="btn-check" name="shape" value="Rectangle" id="shape4" checked={this.state.shape === "Rectangle"} onChange={this.onShapeChange} />
                    <label for="shape4" className='m-1'><img src='/static/images/body_shape/4.png'  className={this.state.shape === "Rectangle"?"border border-3  border-secondary":""}/></label>
                    </Col>
                    <Col> 
                    <input type="radio" class="btn-check" name="shape" value="Hourglass" id="shape5" checked={this.state.shape === "Hourglass"} onChange={this.onShapeChange} />
                    <label for="shape5" className='m-1'><img src='/static/images/body_shape/5.png'  className={this.state.shape === "Hourglass"?"border border-3  border-secondary":""}/></label>
                    </Col>
                    <Col xs={2}></Col>
                  </Row>
                </Row>
              </div>

              <div class="mt-1"></div><br/>
              <h3 class="mt-2 mb-1">Skin Tone</h3>
              <div class="btn-group" role="group">
                <Row>
                  <Row>
                    <Col>
                    <input type="radio" class="btn-check" name="skin_tone" value="Light" id="skin1" checked={this.state.skin_tone === "Light"} onChange={this.onSkinChange} />
                    <label for="skin1" className='m-1'><img src='/static/images/skin_tone/1.png' className={this.state.skin_tone === "Light"?"border border-3  border-secondary":""}/></label>
                    </Col>
                    <Col>
                    <input type="radio" class="btn-check" name="skin_tone" value="Fair" id="skin2" checked={this.state.skin_tone === "Fair"} onChange={this.onSkinChange} />
                    <label for="skin2" className='m-1'><img src='/static/images/skin_tone/2.png' className={this.state.skin_tone === "Fair"?"border border-3  border-secondary":""}/></label>
                    </Col>

                    <Col>
                    <input type="radio" class="btn-check" name="skin_tone" value="Medium" id="skin3" checked={this.state.skin_tone ==="Medium"} onChange={this.onSkinChange} />
                    <label for="skin3" className='m-1'><img src='/static/images/skin_tone/3.png' className={this.state.skin_tone === "Medium"?"border border-3  border-secondary":""}/></label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={2}></Col>
                    <Col>
                      <input type="radio" class="btn-check" name="skin_tone" value="Mid-brown" id="skin4" checked={this.state.skin_tone === "Mid-brown"} onChange={this.onSkinChange} />
                      <label for="skin4" className='m-1'><img src='/static/images/skin_tone/4.png' className={this.state.skin_tone === "Mid-brown"?"border border-3  border-secondary":""}/></label>
                    </Col>
                    <Col>
                      <input type="radio" class="btn-check" name="skin_tone" value="Dark-brown" id="skin5" checked={this.state.skin_tone === "Dark-brown"} onChange={this.onSkinChange} />
                      <label for="skin5" className='m-1'><img src='/static/images/skin_tone/5.png' className={this.state.skin_tone === "Dark-brown"?"border border-3  border-secondary":""}/></label>
                    </Col>
                    <Col xs={2}></Col>
                  </Row>
                </Row>
              </div>

              <div>
                <Row>
                  <button onClick={this.onSubmit} size="25" className='btn btn-primary me-1 mb-1 rounded mt-1 pl-1 save-unsave-btn btn-md' style={{ background: "#f12a2c !important",width : "33%", marginLeft:'33%' }}>Save</button>
                </Row>
              </div>
            </div>
          </div>
          


        </div>
        <Modal
              show={this.state.googleLoginModal}
              onHide={this.handleGoogleLoginClose}
              className="product-modal google-login-modal"
          >
              <Modal.Header>
                  <a href="#" onClick={this.handleGoogleLoginClose} style={{ marginLeft: '90%' }}>
                      <X color="white" size={30} />
                  </a>
              </Modal.Header>

              <Modal.Body>
                  <Row>
                      <h2>Please login to continue</h2>
                    <div className="google-login">
                          {this.props.isLogin?
                          <GoogleLogout
                          clientId={clientId}
                          buttonText="Logout"
                          // onLogoutSuccess={logout}
                          />
                          :<GoogleLogin
                              clientId={clientId}
                              buttonText="Sign-in with Google"
                              onSuccess={this.googleResponse}
                              // onFailure={this.googleResponse}
                              cookiePolicy={'single_host_origin'}
                          />
                          }   
                      </div>
                  </Row>
              </Modal.Body>
          </Modal>
      </>
    );
  }
}


const mapStateToProps = state => ({
  isUserAuthenticated: state.isUserAuthenticated,
})
const mapDispatchToProps = dispatch => ({
  googleUserLogin : (googleProfile) => dispatch(userLogin(googleProfile)),
  setUserPreferences: (hasFilled) => dispatch(setUserPreferences(hasFilled)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Preferences);
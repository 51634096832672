import React from 'react'
import { XLg} from 'react-bootstrap-icons';
import PublicNavbar from './publicNavbar';
class userProfile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            disable: true,
            name: '',
            mobileNumber: '',
            pwd1: '',
            pwd2: '',
            pwdChange: false,
            pwderr:''
        }
        this.profileChange = this.profileChange.bind(this)
        this.changePassword = this.changePassword.bind(this)
    }
    profileChange(){
        this.setState({
            disable: false
        })
    }
    changePassword(){
        this.setState({
            pwdChange:true
        })
    }
    render() {
        let target = window.navigator.userAgent.toLowerCase().includes('wv')?'_self':'_blank'; 
        return (
            <>
                <PublicNavbar title="profile" />
                <div className="appCapsule">
                    <div className="section full mt-2 mb-2">
                        {this.state.disable && !this.state.pwdChange &&
                            <>
                                <div className="form-button-group">
                                    <input value="Edit Profile" className="btn btn-primary btn-block btn-lg" onClick={this.profileChange} />
                                </div>
                                <div className="form-button-group">
                                    <input value="Change Password" className="btn btn-primary btn-block btn-lg" onClick={this.changePassword} />
                                </div>
                            </>
                        }
                        {
                            this.state.pwdChange && this.state.disable ?
                                <>
                                    <div className="section-title">Change Password</div>
                                    <div className="wide-block pb-1 pt-1">
                                        <div className="close">
                                            <span onClick={() => this.setState({ pwdChange: false, pwderr: '' })} style={{ cursor: 'pointer' }}>
                                                <XLg />
                                            </span>
                                        </div>
                                        <form>
                                            <ul className="listview image-listview no-line no-space flush">
                                                <li>
                                                    <div className="item">
                                                        <div className="in">
                                                            <div className="form-group basic">
                                                                <div className="input-wrapper">
                                                                    <label className="form-label" htmlFor="oldPassword">
                                                                        Old password
                                                                    </label>
                                                                    <input
                                                                        type="password"
                                                                        className="form-control"
                                                                        id="oldPassword"
                                                                        placeholder=""
                                                                        onChange={this.checkedPwd}
                                                                    />
                                                                    <div className="validation">{this.state.pwderr.oldpassword}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="item">
                                                        <div className="in">
                                                            <div className="form-group basic">
                                                                <div className="input-wrapper">
                                                                    <label className="form-label" htmlFor="newPassword">
                                                                        New password
                                                                    </label>
                                                                    <input
                                                                        type="password"
                                                                        className="form-control"
                                                                        id="newPassword"
                                                                        placeholder=""
                                                                        onChange={this.checkedPwd}
                                                                    />
                                                                    <i className="clear-input">
                                                                        <ion-icon name="close-circle" />
                                                                    </i>
                                                                    <div className="validation">{this.state.pwderr.newPassword}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="item">
                                                        <div className="in">
                                                            <div className="form-group basic">
                                                                <div className="input-wrapper">
                                                                    <label className="form-label" htmlFor="newPassword2">
                                                                        Retype New password
                                                                    </label>
                                                                    <input
                                                                        type="password"
                                                                        className="form-control"
                                                                        id="newPassword2"
                                                                        placeholder=""
                                                                        onChange={this.checkedPwd}
                                                                    />
                                                                    <i className="clear-input">
                                                                        <ion-icon name="close-circle" />
                                                                    </i>
                                                                    <div className="validation">{this.state.pwderr.newPassword2}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <div className="form-button-group">
                                                <input value="Save" className="btn btn-primary btn-block btn-lg" onClick={this.savePwd} />
                                            </div>
                                        </form>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="section-title">Profile Details</div>
                                    <div className="wide-block pb-1 pt-1">
                                        {!this.state.disable &&
                                        <div className="close">
                                            <span onClick={() => this.setState({ disable: true})} style={{ cursor: 'pointer' }}>
                                                <XLg />
                                            </span>
                                        </div>
                                        }
                                        <form>
                                            <ul className="listview image-listview no-line no-space flush">
                                                <li>
                                                    <div className="item">
                                                        <div className="in">
                                                            <div className="form-group basic">
                                                                <div className="input-wrapper">
                                                                    <label className="form-label" htmlFor="name">
                                                                        Name
                                                                    </label>
                                                                    <input
                                                                        type="name"
                                                                        className="form-control"
                                                                        id="name"
                                                                        placeholder="Test test"
                                                                        disabled={this.state.disable}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="item">
                                                        <div className="in">
                                                            <div className="form-group basic">
                                                                <div className="input-wrapper">
                                                                    <label className="form-label" htmlFor="mobileNumber">
                                                                        Phone Number
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="mobileNumber"
                                                                        placeholder="7005294070"
                                                                        disabled={this.state.disable}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </form>
                                        {!this.state.disable && !this.state.pwdChange &&
                                        <div className="form-button-group">
                                            <input type="submit" value="Save" className="btn btn-primary btn-block btn-lg" />
                                        </div>
                                        }
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </>
        )
    }
}
export default userProfile;
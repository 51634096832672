import React, { Component } from "react";
import axios from 'axios';
import LoadingSpinner from './LoadingSpinner';
import {Pagination, Layout, Alert} from "element-react";
import './shoppable.css';
import logo from './images/header-img.jpeg'


     
class shoppableItems extends Component {
  constructor(props) {
    super(props);
    const { params } = this.props.match;
    this.state = {
        styleId: params.style_id.substr(1),
        category: params.cat.substr(1),
        credit:params.insta.substr(1),
        allProductData : [],
        activePage: 1,
        pageSize: 10,
        totalProducts: 0,
        loading: false,
    };
    /* this.setPageNumber = this.setPageNumber.bind(this);
    this.setPageSize = this.setPageSize.bind(this); */
  }
  componentDidMount() {
    this.getCrossSell(this.props);        
  }
  handleClick(event){
    // event.preventDefault();
    let el = event.currentTarget;
    // window.location.href=el.href;
    document.location.href=el.href;
    return false;
  }
    /* componentWillReceiveProps(nextProps){
    console.log("Will recieve props",this.props.user, nextProps.user)
    //if(Object.keys(this.props.user).length !== Object.keys(nextProps.user).length){
    this.getCrossSell(this.props)
   // }
  }  */
  /*  setPageNumber(page_num)
  {
    this.setState(state => {
      state.activePage = page_num;
      state.visibleData = state.allProductData.slice((this.state.activePage - 1)*this.state.pageSize , this.state.activePage*this.state.pageSize);
      return state;
    });
    console.log(this.state.activePage)
  } */
 /*  setPageSize(size){
    this.setState(state => {
      state.pageSize = size;
      state.visibleData = state.allProductData.slice((this.state.activePage - 1)*this.state.pageSize , this.state.activePage*this.state.pageSize);
      return state;
    });
  } */
  getCrossSell(props)
  {
    this.setState({
      loading: true
    })
    let _this = this;
    axios.get(`/getCrossSell/?styleId=${this.state.styleId}&shoppable_items=${this.state.category}`)
    .then((response)=> {
      console.log("getting Response",response.data);
      if(response.data.error){
        this.setState({loading: false});
        //_this.props.setUserDetails({});
        Notification({
          type: "error",
          title: "Error",
          message: response.data.error,
          duration: 0
        })
      } else{
        this.setState({
          allProductData : response.data.data,
          totalProducts: response.data.data.length,
          loading: false
         // visibleData: response.data.data.slice((this.state.activePage - 1)*this.state.pageSize , this.state.activePage*this.state.pageSize)
          // ats: response.data.ats
        },()=>{
          //console.log("Inside funtion",this.state.allProductData.length, 'total:',this.state.totalProducts);
        });
      }

    }).catch(function (error) {
      _this.setState({loading: false})
    })
  }
  render() {
   /*  let width = document.body.clientWidth;
    let layout="total, sizes, prev, pager, next, jumper";
    if(width && width < 600){
      layout = "sizes, prev, next, jumper"
    } else {
      layout="total, sizes, prev, pager, next, jumper";
    } */
   /*  const pager = <Pagination 
        pageSizes={[10,20,40]}
        itemsCountPerPage={12}
        total={this.state.totalProducts}
        pageSize={this.state.pageSize}
        currentPage={this.state.activePage}
        onSizeChange={this.setPageSize}
        onCurrentChange={this.setPageNumber}
        layout={layout}
      ></Pagination> */
    return (
      <div>
          <div>
          <div className ="navbar">
            <div className = "heading">
            <a href="#">
                <img src={logo} alt="header.jpeg"/>
              </a>
            </div>
          </div>
          {
            this.state.loading ? <LoadingSpinner/>: (
              <div>
                   <div className="products-container">
          {this.state.totalProducts>0 && 
          <div>
          {/* <Layout.Row className="my-pagination">
            {pager}
          </Layout.Row> */}
          <Layout.Row>
            <div className="products-list">
              {this.state.allProductData.map((item) => {
                return (
                  <div className="product-container" key={item.v_id}>
                    <div className="product-image">
                        {(navigator.userAgent.match(/(iPad|iPhone|iPod)/i))?
                        <a href={`https://linksredirect.com/?cid=140263&source=linkkit&url=https%3A%2F%2Fwww.myntra.com%2F${item.product_id}`} target="_self">
                        <div className="product-image">
                            <img src={`https://us-central1-analog-mantra-247706.cloudfunctions.net/resize/?url=${item.product_image_url}&width=300&quality=60`} alt={item.product_id} />
                        </div>
                        </a>
                        :<a href={`https://linksredirect.com/?cid=140263&source=linkkit&url=https%3A%2F%2Fwww.myntra.com%2F${item.product_id}`} target="_parent" onClick={e=>this.handleClick(e)}>
                            <div className="product-image">
                                <img src={`https://us-central1-analog-mantra-247706.cloudfunctions.net/resize/?url=${item.product_image_url}&width=300&quality=60`} alt={item.product_id} />
                            </div>
                        </a>}
                    </div>
                  </div>
                )
              })}
            </div>
          </Layout.Row>
          {/* <Layout.Row className="my-pagination">
            {pager}
          </Layout.Row> */}
          </div>
        }
        {
          this.state.allProductData.length<1 && this.state.totalProducts < 1 &&
          <div className="my-alert">
            <Alert title={`No Reviewed Products for ${this.state.category} Category`} type="error" closable={false} />
          </div>
        } 
      </div>   
              </div>
            )
          }
        </div>
      </div>
    );
  }
}
export default shoppableItems;

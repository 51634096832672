import React, { Component } from 'react';
import { connect } from "react-redux";
import { Col, Row, Modal, Tab, Nav,Button } from 'react-bootstrap';
import NavBar from '../navbar';
import { Instagram, Grid3x3, List, HeartFill, Chat, ThreeDotsVertical, EyeSlashFill,
    Bootstrap, Twitter, Facebook, Envelope, Youtube , Box} from 'react-bootstrap-icons';
import Header from '../header';
import '../../styles/style.css'
import '../../styles/blogger_store.css'
import { fetchBloggerDataCurator, setoffImage, setonImage, visibilityOffCurator ,
     fetchBloggerMetadataCurator , setBloggersData , setVisdibilityOff , curatorNotCuratedData } from '../../actions';
import { updateBloggerMetadata } from '../../api/publicApis'
import Loader from '../Loader'
import { Redirect } from 'react-router';
import { NavigateBeforeSharp } from '@material-ui/icons';
import Axios from 'axios';
import Swal from 'sweetalert2';
class SignupBlogger extends Component {
    constructor(props) {
        super(props)
        let username = this.props.match.params.bloggerUsername
        props.fetchBloggerMetadataCurator(username )
        this.state = {
            pageNo: 2,
            pageSize: 30,
            key: 'first',
            open: false,
            top: 0,
            left: 0,
            type: '',
            item: '',
            morePage:30,
            pg:2,
            mp:30,
            checked:false,
            username: this.props.match.params.bloggerUsername,
            urls :['','','',''],
            postCodes : ['','','',''],
        }
    }


    async handleSubmit(type){
      if(type=='reject'){
            let data = await Axios.post('/curator/accept-signup',{accepted: false, username: this.username})
            window.location.href = '/curator/new-signups'
      } else if(type=='accept'){
            let postCodes = this.state.postCodes
            if(postCodes[0] && postCodes[1] && postCodes[2] && postCodes[3]){
                  await Axios.post('/curator/accept-signup',{accepted: true,postCodes: this.state.postCodes})
                  window.location.href = '/curator/new-signups'
            } else Swal.fire("4 posts required","Please enter 4 urls of posts",'error')
      }
    } 

    async handleMetadataChange(type,field,value){
        let queryField,queryValue;
        queryField = field
        queryValue = value
        await updateBloggerMetadata(this.state.username,queryField,queryValue)
        this.props.fetchBloggerMetadataCurator(this.state.username )
    }

    updateUrls(e,i){
      e.preventDefault();
      let newUrl = e.target.value;
      let postCode = newUrl.split('instagram.com/p/')
      if(postCode[1]) 
            postCode = postCode[1].split('/')[0]
      if(!newUrl.includes('instagram.com/p/'))
            postCode = null
      if(postCode){
            if(this.state.postCodes.includes(postCode))
                  Swal.fire("Duplicate Url","Please enter unique urls",'error')
            else {
                  let urls = this.state.urls
                  let postCodes = this.state.postCodes
                  urls[i] = newUrl
                  postCodes[i] = postCode
                  this.setState({urls,postCodes})
            }
      } else Swal.fire("Invalid Url","Please enter valid url of post",'error')
    }

    render() {
        console.log(this.props.blogger)
        console.log(this.props.isAuthenticated)
        const modalStyle = {
            top: this.state.top + 'px',
            left: Math.abs(this.state.left - 126) + 'px',
            width: '160px'
        }
        // console.log(this.props.user)
        return (
            <>
                {(!this.props.isAuthenticated) ? <Redirect push to='/curator/login' />
                    : this.props.loadingState ? <Loader type="main"/>
                        : <>
                            <NavBar title={this.state.username + '\'s store'} page="home" />
                            <div className="appCapsule">
                                {/* <Header profile_img={this.props.user.profile_img} /> */}
                                <div className="app-header">
                                    <Row>
                                        <Col>
                                            <h1 className="section-title medium" style={{ justifyContent: 'center' }}>{this.state.username}</h1>
                                            <div className='m-3'>
                                                <h3>Skin Tone</h3><br/>
                                                <div class="btn-group" role="group">
                                                    
                                                    {["Light","Fair","Medium","Mid-brown","Dark-brown"].map(skintone=>
                                                        <>
                                                            <input type="radio" class="btn-check" name="skintone" id={skintone} checked={this.props.bloggerMetadata.skintone===skintone} /> 
                                                            <label class="btn btn-outline-secondary" for={skintone} onClick={()=>{this.handleMetadataChange("skintone","skintone",skintone)}}>{skintone} </label>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div className='m-3'>
                                                <h3>Height</h3><br/>
                                                <div class="btn-group" role="group">
                                                    
                                                    {["Short (Below 5')","Medium (5'-5'5\")","Tall (Above 5'6\")"].map(height=>
                                                        <>
                                                            <input type="radio" class="btn-check" name="height" id={height} checked={this.props.bloggerMetadata.height===height} /> 
                                                            <label class="btn btn-outline-secondary" for={height} onClick={()=>{this.handleMetadataChange("height","height",height)}}>{height} </label>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div className='m-3'>
                                                <h3>Weight</h3><br/>
                                                <div class="btn-group" role="group">
                                                    
                                                    {["Petite","Medium","Plus","Athletic"].map(weight=>
                                                        <>
                                                            <input type="radio" class="btn-check" name="weight" id={weight} checked={this.props.bloggerMetadata.weight===weight} /> 
                                                            <label class="btn btn-outline-secondary" for={weight} onClick={()=>{this.handleMetadataChange("weight","weight",weight)}}>{weight} </label>
                                                        </>
                                                    )}
                                                </div>
                                            </div>  
                                            <div className='m-3'>
                                                <h3>Bodyshape</h3><br/>
                                                <div class="btn-group" role="group">
                                                    
                                                    {["Apple","Pear","Inverted Triangle","Rectangle","Hourglass"].map(bodyshape=>
                                                        <>
                                                            <input type="radio" class="btn-check" name="bodyshape" id={bodyshape} checked={this.props.bloggerMetadata.bodyshape===bodyshape} /> 
                                                            <label class="btn btn-outline-secondary" for={bodyshape} onClick={()=>{this.handleMetadataChange("bodyshape","bodyshape",bodyshape)}}>{bodyshape} </label>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                          {this.state.urls.map((url,i)=> <Row className='m-1'>
                                          <Col xs={8}><input type="url" id={i} name="url" value={url} onChange={(e)=> this.updateUrls(e,i)}></input></Col>
                                          <Col xs={3}>{this.state.postCodes[i]}</Col>
                                        
                                        </Row>)}
                                    </Row>
                                    <Row>
                                          <Col xs={2}></Col>
                                          <Col xs={8}>
                                                <Button className='btn btn-success m-2' onClick={()=> this.handleSubmit('accept')}>Accept</Button>
                                                <Button className='btn btn-danger m-2'  onClick={()=> this.handleSubmit('reject')}>Reject</Button>
                                          </Col>
                                          <Col xs={2}></Col>
                                    </Row>
                                </div>
                            </div>
                        </>
                }
            </>
        );
    }
}
const mapStateToProps = state => ({
    isAuthenticated: state.isCuratorAuthenticated,
    loadingState: state.loadingState,
    blogger: state.blogger,
    bloggerMetadata : state.bloggerMetadata,
});
const mapDispatchToProps = dispatch => ({
    fetchBloggerMetadataCurator: (username ) => dispatch(fetchBloggerMetadataCurator(username )),
    setBloggersData : (data) => dispatch(setBloggersData(data)),
    setVisdibilityOff: (data) => dispatch(setVisdibilityOff(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SignupBlogger)
import React from 'react'
import {Row,Col} from 'react-bootstrap'
class Page404 extends React.Component{
    constructor(props){
        super(props)
        this.goBack = this.goBack.bind(this)
    }
    goBack(e){
        e.preventDefault()
        window.history.go(-1)
    }
    render(){

        return(
            <>
                <div className="main">
                    <div className="content">
                        <Row>
                            <Col>
                                <span>404</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span>Opps! the page you're looking for is not found.</span>
                            </Col>
                        </Row>
                        <Row>
                            <a href="#" onClick={this.goBack}>
                                go back
                            </a>
                        </Row>
                    </div>
                </div>
            </>
        )
    }
}
export default Page404
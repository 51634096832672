import React from 'react'
import {Instagram,Twitter, Facebook, Envelope, Youtube, FileEarmarkPpt, List, HeartFill, Chat, Type, Bootstrap} from 'react-bootstrap-icons';
class profile extends React.Component
{
render()
{
return( 
<>
  <div className="appHeader bg-primary text-light">
    <div className="left">
      <a href="#" className="headerButton goBack">
        <ion-icon name="chevron-back-outline" />
      </a>
    </div>
    <div className="pageTitle">Profile</div>
    <div className="right"></div>
  </div>
  {/* * App Header */}
  {/* App Capsule */}
  <div id="appCapsule">
    <div className="section full mt-2 mb-2">
      <div className="section-title">Profile Picture</div>
      <div className="wide-block pb-2 pt-2">
        <form>
          <div className="custom-file-upload" id="fileUpload1">
            <input
              type="file"
              id="fileuploadInput"
              accept=".png, .jpg, .jpeg"
            />
            <label htmlFor="fileuploadInput">
              <span>
                <strong>
                  <ion-icon name="cloud-upload-outline" />
                  <i>Tap to Upload</i>
                </strong>
              </span>
            </label>
          </div>
        </form>
      </div>
    </div>
    <div className="section full mt-2 mb-2">
      <div className="section-title">Profile Details</div>
      <div className="wide-block pb-1 pt-1">
        <form>
          <ul className="listview image-listview no-line no-space flush">
          <li>
              <div className="item">
                <div className="icon-box bg-primary">
                <Type></Type><ion-icon name="mail-outline" />
                </div>
                <div className="in">
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="form-label" htmlFor="name">
                        Name
                      </label>
                      <input
                        type="name"
                        className="form-control"
                        id="name"
                        placeholder=""
                      />
                      <i className="clear-input">
                        <ion-icon name="close-circle" />
                      </i>
                    </div>
                    <div className="input-info">Enter your name</div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="item">
                <div className="icon-box bg-primary">
                <Envelope></Envelope><ion-icon name="mail-outline" />
                </div>
                <div className="in">
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="form-label" htmlFor="email">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder=""
                      />
                      <i className="clear-input">
                       <ion-icon name="close-circle" />
                      </i>
                    </div>
                    <div className="input-info">Enter your e-mail address</div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="item">
                <div className="icon-box bg-primary">
                  <FileEarmarkPpt></FileEarmarkPpt><ion-icon name="lock-closed-outline" />
                </div>
                <div className="in">
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="form-label" htmlFor="password6">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password6"
                        placeholder=""
                        autoComplete="off"
                      />
                      <i className="clear-input">
                        <ion-icon name="close-circle" />
                      </i>
                    </div>
                    <div className="input-info">Enter your password</div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="icon-box bg-primary">
                <FileEarmarkPpt></FileEarmarkPpt> <ion-icon name="lock-closed-outline" />
                </div>
                <div className="in">
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="form-label" htmlFor="cpassword6">
                        Confirm Password
                      </label>
                      <input
                        type="cpassword"
                        className="form-control"
                        id="cpassword6"
                        placeholder=""
                        autoComplete="off"
                      />
                      <i className="clear-input">
                        <ion-icon name="close-circle" />
                      </i>
                    </div>
                    <div className="input-info">Re-enter your password</div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="icon-box Instagram">
                  <Instagram color='red'></Instagram>
                <ion-icon name="lock-closed-outline" />
                
                </div>
                <div className="in">
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="form-label" htmlFor="insta">
                        Insta Handle
                      </label>
                      <input
                        type="isnta"
                        className="form-control"
                        id="insta"
                        placeholder=""
                        autoComplete="off"
                      />
                      <i className="clear-input">
                        <ion-icon name="close-circle" />
                      </i>
                    </div>
                    <div className="input-info">Enter your instagram handle</div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="icon-box ">
                  <Bootstrap color='orange'></Bootstrap><ion-icon name="lock-closed-outline" />
                </div>
                <div className="in">
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="form-label" htmlFor="blog">
                        Blog Name
                      </label>
                      <input
                        type="blog"
                        className="form-control"
                        id="blog"
                        placeholder=""
                        autoComplete="off"
                      />
                      <i className="clear-input">
                        <ion-icon name="close-circle" />
                      </i>
                    </div>
                    <div className="input-info">Enter your Blog Name</div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="icon-box bi bi-facebook">
                  <Facebook color='blue'>
                  </Facebook>
                  <ion-icon name="lock-closed-outline" />
                </div>
                <div className="in">
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="form-label" htmlFor="facebook">
                        Facebook
                      </label>
                      <input
                        type="facebook"
                        className="form-control"
                        id="facebook"
                        placeholder=""
                        autoComplete="off"
                      />
                      <i className="clear-input">
                        <ion-icon name="close-circle" />
                      </i>
                    </div>
                    <div className="input-info">Enter your Facebook Link</div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="icon-box bi bi-twitter">
                  <Twitter color='#1E74FD'></Twitter><ion-icon name="bi bi-twitter" />
                </div>
                <div className="in">
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="form-label" htmlFor="twitter">
                        Twitter
                      </label>
                      <input
                        type="twitter"
                        className="form-control"
                        id="twitter"
                        placeholder=""
                        autoComplete="off"
                      />
                      <i className="clear-input">
                        <ion-icon name="close-circle" />
                      </i>
                    </div>
                    <div className="input-info">Enter your Twitter Link</div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="icon-box ">
                <Youtube color='red'></Youtube>  <ion-icon name="lock-closed-outline" />
                </div>
                <div className="in">
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="form-label" htmlFor="youtube">
                        Youtube
                      </label>
                      <input
                        type="youtube"
                        className="form-control"
                        id="youtube"
                        placeholder=""
                        autoComplete="off"
                      />
                      <i className="clear-input">
                        <ion-icon name="close-circle" />
                      </i>
                    </div>
                    <div className="input-info">Enter your Youtube Link</div>
                  </div>
                </div>
              </div>
              <div className="form-group boxed">
            <div className="input-wrapper">
              <label className="form-label" htmlFor="address5">
                Description
              </label>
              <textarea
                id="description"
                rows={2}
                className="form-control"
                defaultValue={""}
              />
              <i className="clear-input">
                <ion-icon name="close-circle" />
              </i>
            </div>
          </div>
            </li>
          </ul>
        </form>
        <div className="form-button-group">
          <input type="submit" value="Edit Profile" className="btn btn-primary btn-block btn-lg" />
           </div>
      </div>
    </div>
  </div>
 
  {/* * App Bottom Menu */}
  {/* App Sidebar */}
  <div
    className="modal fade panelbox panelbox-left"
    id="sidebarPanel"
    tabIndex={-1}
    role="dialog"
  >   
  </div>
</>
   )
}
}
export default profile;
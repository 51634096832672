import React, {Component} from 'react';
import './home.css';
import Mobileview from './mobileView';
import DesktopView from './desktopView';
import {BrowserView, MobileView} from 'react-device-detect';
import {isMobile} from 'react-device-detect';

class blogger_demo extends Component{
    render()
    {
        
    if(isMobile)
    {
        return(
     
                <div className="main-container">
                    <Mobileview/>
                </div>
            
        );
    }
    else
    {
    return(
        
            <div className="main-container">
                <DesktopView/>
            </div>
      
        );
    }    
}
}
export default blogger_demo;
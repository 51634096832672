import React from 'react';
import {Modal,TextField,Typography,Grid,FormControlLabel,
    Checkbox,Button,
} from '@material-ui/core';
// import { Accordion, Card } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card'
import "bootstrap/dist/css/bootstrap.min.css";
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import Agreement from './components/agreement';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import  {bloggerData,sendOTPToMobile,sendVoiceOTPToMobile,verifyNumberOTP,sendOTPToEmail,verifyEmailOTP} from './api/publicApis';
import './mobile.css';
import mixpanel, { init } from 'mixpanel-browser';
import Navbar from '../src/components/navbar'

mixpanel.init("1a8ebbbb5ab0d0b35dc91b5e3b1578e1" ,{
    debug :true,
    ignore_dnt: true
})

function getQueryParam(param) {
    param = param.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    var regexS = "[\\?&]" + param + "=([^&#]*)",
        regex = new RegExp( regexS ),
        results = regex.exec(document.URL);
    if (results === null || (results && typeof(results[1]) !== 'string' && results[1].length)) {
        return '';
    } else {
        return decodeURIComponent(results[1]).replace(/\+/g, ' ');
    }
};


var inviter = getQueryParam("welcome_blogger");
mixpanel.register({
    "Inviter": inviter,
    "Visitor type" : "Blogger"
});
var distinct_id = getQueryParam("welcome_blogger");
mixpanel.identify(distinct_id);
mixpanel.track("Login page Loaded mobile", {"Page Viewed mobile": "Login Page"});


function getModalStyle() {
    const top = '20%' 
    const left = '20%'
  
    return {
      top: top,
      left: left,
      transform: `translate(-${top}, -${left})`,
    };
  }
  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      overflow:'scroll',
      height:'80%'
    },
  }));
   //form sign up data
   const formData = {
    name:'',
    email:'',
    number:'',
    instaHandel:'',
    cc:''
};

export default function MobileView(){
    const classes = useStyles();
    //states for the function
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const [emailOtpRequested, setEmailOtpRequested] = React.useState(false);
    const [phoneOtpRequested, setPhoneOtpRequested] = React.useState(false);
    const [nameError, setNameError] = React.useState(false);
    const [emailError, setEmailError] = React.useState(false);
    const [ccError, setCcError] = React.useState(false);
    const [numberError, setNumberError] = React.useState(false);
    const [instaError, setInstaError] = React.useState(false);
    const [isCheckedAgreement, setCheckedAgreement] = React.useState(false);
    const [phoneVerified, setPhoneVerified] = React.useState(false);
    const [mailVerified, setMailVerified] = React.useState(false);
    const [resentOtp,setResentOtp] = React.useState(false)
    const [openmodal, setOpenmodal] = React.useState(false)
    const [scroll, setScroll] = React.useState('paper');
    const descriptionElementRef = React.useRef(null);
    const [validateMessage,setValidateMessage] = React.useState()
    const [mailOTPMatch,setMailOTPMatch] = React.useState(false)
    const [phoneOTPMacht,setPhoneOTPMatch] = React.useState(false)
    React.useEffect(() => {
    if (openmodal) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openmodal]);

    //open close modal
    const handleOpen = () => {
        setOpen(true);
        mixpanel.identify(distinct_id);
            mixpanel.track("opened sign up page mobile",{
            })
    };   
    const handleClose = () => {
        setOpen(false);
        setResentOtp(false);   
        Object.keys(formData).forEach(key=>formData[key]='');
        setNameError(false);
        setNumberError(false);
        setEmailError(false);
        setCcError(false);
        setCheckedAgreement(false);
        setEmailOtpRequested(false);
        setPhoneOtpRequested(false);
        setPhoneVerified(false);
        setMailVerified(false);
        setValidateMessage()
        setPhoneOTPMatch(false)
        setMailOTPMatch(false)          
    };
    const closeModal = () =>{
        setOpenmodal(false)
    }

    //form submit function
    const formSubmit= async(event) =>{
        event.preventDefault();
        if(!nameError && !emailError && mailVerified && !numberError && !instaError && isCheckedAgreement){
            let res = await bloggerData(formData)
            if(res.data.data === 'success')
                setValidateMessage(<p style={{color:'green'}}>Sucessfully Signup.</p>)
            else if(res.data.data === 'exists')
            setValidateMessage(<p style={{color:'red'}}>This user is already registered.</p>)
            else  setValidateMessage(<p style={{color:'red'}}>Opps! Something went Wrong.</p>)
            mixpanel.identify(distinct_id);
            mixpanel.track("Successful sigup mobile",{
                name: formData.name,
                email: formData.email,
                number: formData.number,
                instaHandel: formData.instaHandel,
                cc: formData.cc  
            })
            mixpanel.people.set({
                "company": "sml_blogger",
                "$name": formData.name,
                "$email": formData.email,
                "$phone": formData.number,
                        })
        }
        else{
            setValidateMessage(<p style={{color:'red'}}>Opps! All the form fileds are not entererd or some values are incorrect.</p>)
        }
    }

    //open agreement on click
    const openAgreement = event =>{
        event.preventDefault();
        //setFormmodal(false);
        setOpenmodal(true); 
        setScroll('paper');
        
    }
    //sent Phone OTP
    const verifyPhone=async()=>{
        if(!numberError && formData.number !='' && !ccError && formData.cc !=''){
            setPhoneOtpRequested(true);
            let res = await sendOTPToMobile(formData.cc,formData.number);
            if(res.data.statusText === 'OK'){
               
                setResentOtp(true)
            }
        }  
    }
    //sent Email OTP
    const verifyEmail=async()=>{
        if(!emailError && formData.email !=''){
            setEmailOtpRequested(true)
            let res = await sendOTPToEmail(formData.email)
            if(res.data.statusText == 'OK')
            { 
            }
        }
    }
    //verify OTP
    const verifyOtp = async(event,type) =>{
        console.log(event.target.value)
        if(type === 'phone' && event.target.value.match(/[0-9]{4}/)){
            let res = await verifyNumberOTP(formData.cc,formData.number, event.target.value);
            console.log(res)
            if(res.data.statusText === 'OK'){
                setPhoneVerified(true)
                setResentOtp(false)
            }
            else setPhoneOTPMatch(true)
        }
        if(type === 'email' && event.target.value.match(/[0-9]{4}/)){
            let res = await verifyEmailOTP(formData.email, event.target.value)
            if(res.data.statusText === 'OK')
                setMailVerified(true)
            else setMailOTPMatch(true)
        }
    }

    //check the validity of form
    const handelFormChange = (e,type)=>{
        if(type === 'name'){
            if(e.target.value != ''){
                setNameError(false)
                formData.name = e.target.value;
            }
            else setNameError(true);
        }
        else if(type === 'phone'){
            if(e.target.value.match(/^[0-9]{10}$/g)){
                setNumberError(false);
                formData.number = (e.target.value).toString();
            }
            else {
                setResentOtp(false)
                setPhoneVerified(false)
                setPhoneOtpRequested(false);
                setNumberError(true);
            }
        }
        else if(type === 'email'){
            if(e.target.value.match(/^[A-Za-z0-9+-._]+@[A-Za-z0-9]+\.[a-zA-Z]+$/)){  
                setEmailError(false)
                formData.email = e.target.value;
            }
            else {
                setMailVerified(false)
                setEmailOtpRequested(false) 
                setEmailError(true)
            }
        }
        else if(type === 'insta'){
            if(e.target.value.match(/^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/)){
                formData.instaHandel = e.target.value;
                setInstaError(false)
            }
            else setInstaError(true)
        }
        else{
            if(e.target.value.match(/[0-9]{2}/)){
                formData.cc = e.target.value;
                setCcError(false)
            }             
            else setCcError(true)
        }
    }
    //request voice OTP
    const requestVoiceOtp =async()=>{
        await sendVoiceOTPToMobile(formData.cc,formData.number)
    }
   //click agreement
   const agreementClick = event =>{ 
       if(event.target.innerHTML === 'Disagree'){
           setCheckedAgreement(false);
           setOpenmodal(false);
       }
       else{
           setCheckedAgreement(true);
           setOpenmodal(false);
       }
   }

  
        return(  
            <>
            <Navbar title= {<a href='/'><img style={{height: '1em'}} src='/static/images/stl_logo.JPG' /></a>} page = 'home'/>
            <div className="mt-5">
                 <div className="header">
                    <img src='./static/images/mobile/animated header.gif' alt='animated-header' className="imgcss"/>
                 </div>
                <div className="animated-chill">
                    <img src='./static/images/mobile/01.png' alt='animation-chill' className="imgcss"/>
                    <div className="waitlist">
                        <img src='./static/images/mobile/c_03.gif' alt="waitlist" onClick={handleOpen}  className="imgcss"/>
                    </div>
                </div>
                <div className="mid">
                    <img src='./static/images/mobile/02.png' alt="old-vs-new" className="imgcss"/>
                    <div className="join-waitlist">
                    <img src='./static/images/mobile/c_05.gif' alt="animated-header-waitlist" className="imgcss" onClick={handleOpen}/>
                            </div>
                </div>
                <div className="faqs">
                    <img src='./static/images/mobile/c_06.gif' className="imgcss" alt="FAQ's" />
                </div>
                {/* <div className="faq-question">
                 <div className="questions">
                    <Accordion className="faqscss">
                    <Card className="faqscss">
                        <Accordion.Toggle as={Card.Header} eventKey="5">
                        What % of profit do I earn per sale?
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="5">
                            <Card.Body>You will earn a flat 4% of every sale that happens through your storefront.</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    </Accordion>
                    </div>
                    <div className="questions">
                    <Accordion className="faqscss">
                    <Card className="faqscss">
                        <Accordion.Toggle as={Card.Header} eventKey="4">
                        When will the money be credited to my bank account?
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="4">
                            <Card.Body>After a sale is verified by our eCommerce partner, the respective profit will be credited to your bank account in 31 days. 
                                        It takes this much time because eCommerce companies have a 30-45 days return window. Only after the return window is closed can the accumulated profit can be transferred to your account. 
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    </Accordion>
                    </div>
                    <div className="questions">
                    <Accordion className="faqscss">
                    <Card className="faqscss">
                        <Accordion.Toggle as={Card.Header} eventKey="3">
                        What is the signup process?
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="3">
                            <Card.Body>Please fill up the form and provide us the required information to complete the signup process. We will notify you over email once your store is live.</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    </Accordion>
                    </div>
                    <div className="questions">
                    <Accordion className="faqscss"> 
                    <Card className="faqscss">
                        <Accordion.Toggle as={Card.Header} eventKey="2">
                        Do I have to post my content separately on your platform?
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>No. The beauty of signing up with ShopMyLook is that all your content gets automatically pulled from your public Instagram profile and becomes instantly shoppable</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    </Accordion>
                    </div>
                    <div className="questions">
                    <Accordion className="faqscss">
                    <Card className="faqscss">
                        <Accordion.Toggle as={Card.Header} eventKey="1">
                        Can I add my own products?
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>At present, this feature is unavailable.</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    </Accordion>
                    </div>
                <div className="questions">
                <Accordion className="faqscss">
                    <Card className="faqscss">
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                        How do I get in touch with you?
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                            Please an email to contact@couchfashion.com
                             </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    </Accordion>
                     </div>             
                </div>  */}
           <div className="faq-question">
            
            <Accordion  className="faqscss">
                <Accordion.Item className="faqscss" eventKey="0">
                    <Accordion.Header className="faqscss"> Who are we?</Accordion.Header>
                     <Accordion.Body className="faqscss">
                        ShopTheLooks is a fashion-tech product that automatically turns photo &amp; reels posted by influencers into shop-the-look. Thus enabling the creators to monetize their entire instagram content. 
                     </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className="faqscss" eventKey="1">
                    <Accordion.Header className="faqscss"> What % of profit do I earn per sale?</Accordion.Header>
                    <Accordion.Body className="faqscss">
                    You will earn upto 10% of every sale that happens through your storefront.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className="faqscss" eventKey="2">
                    <Accordion.Header className="faqscss">When will the money be credited to my bank account?</Accordion.Header>
                    <Accordion.Body className="faqscss">
                    After a sale is verified by our eCommerce partner, the respective profit will be credited to your bank account in 31 days. 
                    It takes this much time because eCommerce companies have a 30-45 days return window. Only after the return window is closed can the accumulated profit can be transferred to your account.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className="faqscss" eventKey="3">
                    <Accordion.Header className="faqscss"> What is the signup process?</Accordion.Header>
                    <Accordion.Body className="faqscss">
                    Please fill up the form and provide us the required information to complete the signup process. We will notify you over email once your store is live.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className="faqscss" eventKey="4">
                    <Accordion.Header className="faqscss">Do I have to post my content separately on your platform?</Accordion.Header>
                    <Accordion.Body className="faqscss">
                    No. The beauty of signing up with ShopTheLook is that all your content gets automatically pulled from your public Instagram profile and becomes instantly shoppable. </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className="faqscss" eventKey="5">
                    <Accordion.Header className="faqscss"> Can I add my own products?</Accordion.Header>
                    <Accordion.Body className="faqscss">
                    At present, this feature is unavailable. </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className="faqscss" eventKey="6">
                    <Accordion.Header className="faqscss"> How do I get in touch with you?</Accordion.Header>
                    <Accordion.Body className="faqscss">
                        Write to us - 
                        <a href="mailto:influencers@shopthelooks.com" target="_blank">
                            &nbsp;influencers@shopthelooks.com
                        </a> 
                        <div>
                            <pre className="address">
                            {
                                 `Address - Shop The Looks Tech Pvt. Ltd.\n91springboard, B1/H3, Mohan C0-Operative,\nNew Delhi-110044`
                            }
                            </pre>                       
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
                       
            </div>
                    <div className="footer">
                    <img src='./static/images/mobile/c_13.gif' alt="footer-img"/>
                </div>
            <div className="toast-box toast-bottom show bg-info" style={{bottom : '0', justifyContent: 'center'}}>
                    <div className="in">
                    <a href='/' className='p2'><div style={{width: '25px', height: '25px'}} ><img src= '/static/images/explore/earth.png' ></img></div></a>
                        <div className="text" style={{marginLeft: '7px'}}>
                            <a href='/' className='text-white'> Click to explore Shop The Looks world</a>
                        </div>
                    </div>
            </div> 
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                <div className={classes.paper} style={modalStyle}>
                    <Typography id="simple-modal-title" component="h1" variant="h6" align="center">Sign Up</Typography>
                    <div id="simple-modal-description"> 
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField required id="name" name="name" label="Your Name" error={nameError} onChange={e=>handelFormChange(e,'name')} fullWidth/>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField required id="email" name="email" label="Email" error={emailError} onChange={e=>handelFormChange(e,'email')} fullWidth/>
                            </Grid> 
                            <Grid item xs={4}>
                                {(mailVerified)?<CheckBoxIcon style={{color:'green'}}/>
                                    :(emailOtpRequested)?<TextField required id="mail" name="mail" label="OTP" inputProps={{maxLengt:4}} onChange={e=>verifyOtp(e,'email')} error={mailOTPMatch}/>
                                    :<Button variant="contained" onClick={e=>verifyEmail(e)}>Verify</Button>
                                }
                            </Grid> 
                            <Grid item xs={3}>
                                <TextField 
                                    required 
                                    id="cc" name="cc" 
                                    label="CC" 
                                    inputProps={{maxLength:2}} 
                                    error ={ccError}
                                    onChange={e=>handelFormChange(e,'cc')}
                                />
                            </Grid> 
                            <Grid item xs={5}>
                                <TextField 
                                    required 
                                    id="number" 
                                    name="number" 
                                    label="Mobile No."
                                    fullWidth
                                    error={numberError}
                                    onChange={e=>handelFormChange(e,'phone')}
                                    inputProps={{maxLength:10}}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                {(phoneVerified)?<CheckBoxIcon style={{color:'green'}}/>
                                    :(phoneOtpRequested)?<TextField required id="phone_otp" name="phone_otp" label="OTP" inputProps={{maxLength:4}} onChange={e=>verifyOtp(e,'phone')} error={phoneOTPMacht}/>
                                    :<Button variant="contained" onClick={e=>verifyPhone(e)}>Verify</Button>
                                }
                            </Grid>
                            <Grid item xs={6} style={{ display: "flex",flexDirection:"row-reverse" }}>
                                    {resentOtp && !phoneVerified &&<Button variant="contained" onClick={requestVoiceOtp}
                                        helperText="By clicking on resend you will recieve OTP via call"
                                    >
                                        Resend Otp</Button>
                                    }
                             </Grid>
                            <Grid item xs={12}>
                                 <h6>CC = Country Code</h6> 
                            </Grid>
                            <Grid item xs={12}>
                                <TextField 
                                    required 
                                    id="instaHandle" 
                                    name="instaHandle" 
                                    label="Instagram Handle" 
                                    fullWidth
                                    onChange={e=>handelFormChange(e,'insta')}
                                    error={instaError}
                                />
                            </Grid>
                            <Grid item xs={12}> 
                            <FormControlLabel control={<Checkbox color="secondary" name="agreement" value="yes" checked={isCheckedAgreement} onClick={()=>{ setCheckedAgreement(!isCheckedAgreement)}}/>}
                                    label={
                                        <div>
                                            <span>I accept</span> <a href="#" onClick={e=>openAgreement(e)}> the agreement.</a>
                                        </div>
                                    } 
                                />
                            </Grid>
                            <div style={{textAlign:'center',width:'100%'}}>
                                <Button variant="contained"
                                style={{width:"70%"}}
                                onClick={formSubmit}
                                >
                                    Sign Up
                                </Button>
                            </div>
                            <div style={{textAlign:'center',width:'100%'}}>
                                {validateMessage}
                            </div> 
                        </Grid>
                    </div>
                </div>
            </Modal>
            <Dialog
                open={openmodal}
                onClose={closeModal}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth
                maxWidth='xl'
            >
                <DialogTitle id="scroll-dialog-title">
                    <span style={{display:'flex',float:'right',cursor:'pointer',opacity:0.5}} onClick={closeModal}>X</span>
                </DialogTitle>
                    <DialogContent dividers={scroll === 'paper'}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            style={{width:"100%"}}
                            ref={descriptionElementRef}
                            tabIndex={-1}
                        >
                            <Agreement/>
                        </DialogContentText>
                    </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={e=>agreementClick(e)} color="primary">
                        Disagree
                    </Button>
                    <Button  variant="contained" onClick={e=>agreementClick(e)} color="primary">
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
            </div> 
        </>  
        )
    }

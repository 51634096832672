import React, {Component} from 'react';
import {connect} from "react-redux";
import { Col,Row,Button} from 'react-bootstrap';
import PublicNavbar from './navbar';
import Header from './header';
import '../styles/style.css'
import '../styles/blogger_store.css'
import Loader from './Loader'
import { Link } from 'react-router-dom';


class bloggerNotFound extends Component{

    render()
    {
        return(
            <>
                {this.props.loadingState?<Loader/>
                :<>
                <PublicNavbar title="Blogger Not Found" page="home"/> 
                <div className="appCapsule">
                    <Header profile_img={"./static/images/desktop/women-chill.gif"}/>
                    <div className="app-header">
                        <Row>
                            <Col>
                                <span>The blogger page has been updated to a new location.</span>
                            </Col>
                        </Row>
                    </div>
                    <div className="section full mb-2 mt-3">
                        <p className='m-2'>Checkout the best of bloggers <a href='/explore'><strong>HERE</strong></a></p>
                    </div>
                </div>
                </>
                }
            </>
        );
    }
}
export default bloggerNotFound
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Col, Row, Modal, Tab, Nav,Button } from 'react-bootstrap';
import NavBar from './navbar';
import { Instagram, Grid3x3, List, HeartFill, Chat, ThreeDotsVertical, EyeSlashFill,
    Bootstrap, Twitter, Facebook, Envelope, Youtube } from 'react-bootstrap-icons';
import Header from './header';
import '../styles/style.css'
import '../styles/blogger_store.css'
import { fetchBloggerData, setoffImage, setonImage, visibilityOff } from '../actions';
import Loader from './Loader'
import { Redirect } from 'react-router';
import { NavigateBeforeSharp } from '@material-ui/icons';
import Axios from 'axios';
import { isMobile } from 'react-device-detect';
import mixpanel, { init } from 'mixpanel-browser';
import ReactGA from 'react-ga';
mixpanel.init("1a8ebbbb5ab0d0b35dc91b5e3b1578e1" ,{
    debug :true,
    ignore_dnt: true
})
class blogger_store extends Component {
    constructor(props) {
        super(props)
        props.getBlogger(1, 0,this.props.user.instahandle);
        props.visibilityOff(1,0,this.props.user.instahandle)
        this.state = {
            pageNo: 2,
            pageSize: 30,
            key: 'first',
            open: false,
            top: 0,
            left: 0,
            type: '',
            item: '',
            morePage:30,
            pg:2,
            mp:30,
            checked:false,
            
        }
        this.setActiveKey = this.setActiveKey.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.toggleBlogger = this.toggleBlogger.bind(this)
        this.toggleBloggerPinned = this.toggleBloggerPinned.bind(this)
        this.loadMore = this.loadMore.bind(this)
        this.loadMoregrey = this.loadMoregrey.bind(this)
        this.toggle = React.createRef();
        // this.gotoProduct = this.gotoProduct.bind(this)
    }
  /*    componentDidMount() {
       
     } */
    setActiveKey = (key) => {
        this.setState({
            key: key
        });
    }
    openToggle(e, item, type,pinnedType) {
        this.setState({
            open: true,
            top: e.clientY,
            left: e.clientX,
            type: type,
            pinnedType:pinnedType,
            item
        })
        if(type === 'on'){
            this.setState({checked :false})
        }
        else
            this.setState({checked :true})
        if(pinnedType === 'on')
            this.setState({pinChecked:false})
        else
            this.setState({pinChecked:true})
    }
    closeModal() {
        this.setState({
            open: false
        })
    }
    toggleBlogger(e) {
        console.log('toggle-type',this.state.type, e.target.checked)
        if ( this.state.type === 'off' && !e.target.checked) {
            this.props.bloggersetOff(this.state.item);
        }
        else if (this.state.type === 'on' && e.target.checked) {
            this.props.bloggersetOn(this.state.item);
        }
        this.setState({
            open: false
        })
    }

    toggleBloggerPinned(e) {
        let item=this.state.item,type
        let sml_id =this.state.item.sml_id,insta = this.state.item.insta
        console.log(e.target.checked)
        if (  !e.target.checked) 
            type = 'off'
        else if ( e.target.checked) 
            type = 'on'
        console.log(sml_id,type)
        ReactGA.event({category: 'Backend Blogger store page', action:`image Pinned ${type}`, lable:`image Pinned ${type}`, value: 1});
        mixpanel.track(`image Pinned ${type}`)
        Axios.post('/api/sml/setPinned',{type,sml_id,insta}).then(()=>{
            this.setState({open: false});
            this.props.getBlogger(1, 0,this.props.user.instahandle);
        })
        
        this.setState()
    }

// load more data
   loadMore(){
       this.setState({
           pageNo:this.state.pageNo+1,
           morePage:this.state.pageNo*this.state.pageSize
       });
       console.log(this.state.pageNo, this.state.morePage)
       this.props.getBlogger(this.state.pageNo,this.props.blogger.length,this.props.user.instahandle)
   }
   //load more visibility off data
   loadMoregrey(){
    this.setState({
        pg:this.state.pg+1,
        mp:this.state.pg*this.state.pageSize
    });
    //console.log(this.state.pageNo, this.state.morePage)
    this.props.visibilityOff(this.state.pageNo,this.props.visibilityOffdata.length,this.props.user.instahandle)
}
    render() {
        console.log(this.props.isAuthenticated)
        const modalStyle = {
            top: this.state.top + 'px',
            left: Math.abs(this.state.left - 126) + 'px',
            width: '160px'
        }
        // console.log(this.props.user)
        return (
            <>
                {(!this.props.isAuthenticated) ? <Redirect push to='/shop/login' />
                    : this.props.loadingState ? <Loader type="main"/>
                        : <>
                            <NavBar title= {<a href='/'><img style={{height: '1em'}} src='/static/images/stl_logo.JPG' /></a>} page="home" />
                            <div className="appCapsule">
                                <Header profile_img={this.props.user.profile_img} />
                                <div className="app-header">
                                    <Row>
                                        <Col>
                                            <span className="section-title medium" style={{ justifyContent: 'center' }}>{this.props.user.name}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <a href={"https://www.instagram.com/"+this.props.user.instahandle} target="_new"> 
                                                <Instagram color="red" size="18" />
                                            </a>
                                            {
                                                typeof this.props.user.blog!=="undefined" && this.props.user.blog!=="" &&
                                               <a href={this.props.user.blog} target="_new"> 
                                                     &nbsp;&nbsp;<Bootstrap color='orange' size="18"></Bootstrap>
                                                </a>
                                            }
                                            {
                                                typeof this.props.user.fblink!=="undefined" && this.props.user.fblink!=="" &&
                                                <a href={this.props.user.fblink} target="_new"> 
                                                     &nbsp;&nbsp;<Facebook color='blue' size="18"/>
                                                </a>
                                            }
                                            {
                                                typeof this.props.user.twlink!=="undefined" && this.props.user.twlink!=="" &&
                                                <a href={this.props.user.twlink} target="_new"> 
                                                    &nbsp;&nbsp; <Twitter color='#1E74FD' size="18"/>
                                                </a>
                                            }
                                            {
                                                typeof this.props.user.ytlink!=="undefined" && this.props.user.ytlink!=="" &&
                                                <a href={this.props.user.ytlink} target="_new"> 
                                                    &nbsp;&nbsp; <Youtube color='red' size="18"/>
                                                </a>
                                            }
                                        </Col>
                                    </Row>
                                    {typeof this.props.user.description !== "undefined"?
                                        <Row>
                                            <Col>{this.props.user.description}</Col>
                                        </Row>

                                        :
                                        <>
                                        <Row>
                                            <Col>
                                                <span>My everyday effortless Styles.</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <span>Tap any image to shop products similar to my looks.</span>
                                            </Col>
                                        </Row>
                                        </>
                                    }
                                     <Row>
                                            <Col>
                                                <span>You can edit the store description, add social media links from the <a href='/shop/editprofile' >edit profile</a> button in side menu.</span><br></br>
                                                <span> Kindly contact your Account Manager for any further modifications in the store.</span>
                                            </Col>
                                        </Row>
                                </div>
                                <div className="section full mb-2">
                                    <div className="mt-2 pe-2 ps-2">
                                        <Tab.Container id="left-tabs-example" activeKey={this.state.key} onSelect={key => this.setActiveKey(key)}>

                                            {/* <Nav>
                                                <Row style={{width:"100%"}}>
                                                    <Col xs={6}>
                                                        <Nav.Item style={{textAlign:"center"}}>
                                                            <Nav.Link eventKey="first">
                                                               <Grid3x3 size="20" color="black" style={{textAlign:'center'}}/>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                        {this.state.key === 'first'?
                                                            <hr style={{
                                                                border:"1px solid black",
                                                                width:"100%"
                                                            }}
                                                            />
                                                            : ''
                                                        }
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Nav.Item style={{textAlign:"center"}}>
                                                            <Nav.Link eventKey="second">
                                                               <EyeSlashFill size="20" color="black"/>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                        {this.state.key === 'second'?
                                                            <hr style={{
                                                                border:"1px solid black",
                                                                width:"100%"
                                                            }}
                                                            />
                                                            : ''
                                                        }
                                                    </Col>
                                                </Row>
                                            </Nav> */}

                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">
                                                    <div className="row mt-2">
                                                        {this.props.totalCount > 0 ?
                                                            this.props.blogger.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        <div className="col-4 mb-2" style={{ position: 'relative' }}>
                                                                            <div className="toggle-img" onClick={e => this.openToggle(e, item, 'off',item.pinned?'off':'on')}>
                                                                                <span><ThreeDotsVertical /></span>
                                                                            </div>
                                                                            <a href={`/shop/outfit_${item.outfit}`} target="_blank">
                                                                                <img src={item.img_Url} alt="image" className="imaged w-100" />

                                                                            </a>
                                                                            {/* <span className="imgid">{item.sml_id}</span>  */}
                                                                            <br />
                                                                        </div>

                                                                    </>
                                                                )
                                                            })
                                                            : <div style={{ textAlign: 'center' }}>
                                                                <p>No data available.</p>
                                                            </div>
                                                        }
                                                        {
                                                            this.props.blogger.length < this.props.totalCount?
                                                            <Button variant="outline-dark" onClick={this.loadMore}>load more</Button>
                                                            :<div style={{textAlign:'center'}}>
                                                                <span> No more data. </span>
                                                            </div>
                                                        }

                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                    <br />
                                                    {/* {this.props.visibilityOffdata.length <= 0 ? 
                                                        <div style={{textAlign:"center"}}>
                                                            <span> No data availabe.</span>
                                                        </div>
                                                        : <div className="row mt-2">
                                                            {
                                                                this.props.visibilityOffdata.map((item, index) => {
                                                                    return (
                                                                        <div className="col-4 mb-2" style={{ position: 'relative' }}>
                                                                            <div className="toggle-img" onClick={e => this.openToggle(e, item, 'on')}>
                                                                                <span><ThreeDotsVertical /></span>
                                                                            </div>
                                                                            <a href="#" target="_self">
                                                                                <img src={item.img_Url} alt="image" className="imaged w-100 filter-grayscale" />
                                                                            </a>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    }
                                                     {this.props.visibilityOffdata.length < this.props.visibilityOffTotal?
                                                        <Button variant="outline-dark" onClick={this.loadMoregrey}>load more</Button>
                                                        :<div style={{textAlign:'center'}}>
                                                            <span> No more data. </span>
                                                        </div>
                                                    } */}
                                                </Tab.Pane>
                                            </Tab.Content>

                                        </Tab.Container>
                                    </div>
                                </div>
                                <div className="blogger-count full mb-2">
                                    <div className='counterVal'>Page Visits <strong>{this.props.pageVisits}</strong></div>
                                </div>
                                {isMobile ?
                                <footer className="footer page-footer font-small bg-info fixed-bottom">
                                <p>You are accesssing your store backend. To explore STL platform - <a onClick={()=>{ReactGA.event({category: 'Backend Blogger store page', action: 'bottom bar clicked', lable:'bottom bar clicked', value: 1});
                                                    mixpanel.track("bottom bar clicked STL store visited");}} href='/explore' className='text-white'>Click Here</a>.</p>
                                </footer>
                                : null}
                            </div>
                        </>
                }
                <Modal
                    show={this.state.open}
                    onHide={this.closeModal}
                    className='set-img-visibility'
                    style={modalStyle}
                >
                    <Modal.Body>
                        {/* <Row className="mb-1">
                            <Col xs={8}>
                                <span>visibility</span>
                            </Col>
                            <Col xs={4}>
                                <label className="switch">
                                    <input type="checkbox" onChange={this.toggleBlogger} defaultChecked={this.state.checked}/>
                                    <span className="slider round"></span>
                                </label>
                            </Col>
                        </Row> */}
                        <Row className="mb-1">
                            <Col xs={8}>
                                <span>pin image</span>
                            </Col>
                            <Col xs={4}>
                                <label className="switch">
                                    <input type="checkbox" onChange={this.toggleBloggerPinned} defaultChecked={this.state.pinChecked}/>
                                    <span className="slider round"></span>
                                </label>
                            </Col>
                        </Row>
                        {/*  <Row className="mt-1">
                               <Col xs={8}>
                                    <span>Delete</span>
                               </Col>
                               <Col xs={4}>
                                    <label className="switch">
                                        <input type="checkbox" onChange={()=>alert('abc')}/>
                                        <span className="slider round"></span>
                                    </label>
                               </Col>
                           </Row> */}
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}
const mapStateToProps = state => ({
    isAuthenticated: state.isAuthenticated,
    user: state.user,
    loadingState: state.loadingState,
    blogger: state.blogger,
    totalCount: state.totalCount,
    visibilityOffdata: state.visibilityOffdata,
    visibilityOffTotal: state.visibilityOffTotal,
    pageVisits: state.pageVisits
});
const mapDispatchToProps = dispatch => ({
    getBlogger: (pageNo,skip, insta) => dispatch(fetchBloggerData(pageNo,skip, insta)),
    bloggersetOff: (item) => dispatch(setoffImage(item)),
    bloggersetOn: (item) => dispatch(setonImage(item)),
    visibilityOff: (pageNo,skip,insta) => dispatch(visibilityOff(pageNo,skip,insta))
});
export default connect(mapStateToProps, mapDispatchToProps)(blogger_store)
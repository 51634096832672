import { connect } from 'react-redux';
import React from 'react'
import NavBar from './navbar';
import { Redirect } from 'react-router';
import { editProfile, uploadProfileImage } from '../actions';
import '../styles/editprofile.css';
import { btoa } from 'btoa'
import { Button , Modal } from 'react-bootstrap'
import { X, ThreeDotsVertical, ChevronDoubleLeft , BookmarksFill } from 'react-bootstrap-icons'
// import {uploadProfileImage} from '../api/publicApis';
import {
  Instagram, Twitter, Facebook, Envelope, Youtube, FileEarmarkPpt, List,
  HeartFill, Chat, Type, Bootstrap, PersonCircle, Check2, XLg
} from 'react-bootstrap-icons';
import {
  sendOTPToMobile, sendVoiceOTPToMobile, verifyNumberOTP, sendOTPToEmail, verifyEmailOTP,
  checkEmail, checkUsername, checkNumber, checkInstahandle, updatePassword
} from '../api/publicApis';
import Swal from 'sweetalert2';
import { Row, Col } from 'react-bootstrap'
import Cropper from 'react-easy-crop'
let errors = {}
let pwderr={}
class EditProfile extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      instahandle: "",
      mobileNumber: "",
      profile_img: "",
      description: "",
      fblink: "",
      twlink: "",
      ytlink: "",
      blog: "",
      username: "",
      disabled: true,
      errors: {},
      validate: true,
      reqMailtOtp: false,
      reqPhoneOtp: true,
      emailVerified: false,
      phoneVerified: false,
      instaValid: false,
      cc: 91,
      pwdChange:false,
      pwderr:{},
      newpassword:'',
      oldpassword:'',
      newpassword2:'',
      mailChange: false,
      phoneChange: false,
      showCropper: false,
      crop: {x:0,y:0},
      zoom:1
    }
    this.pimg = React.createRef();
    this.profileimage = React.createRef();
    this.pimgform = React.createRef();
    this.handleChange = this.handleChange.bind(this)
    this.profileChange = this.profileChange.bind(this)
    this.saveChanges = this.saveChanges.bind(this)
    this.openpimg = this.openpimg.bind(this)
    this.getBase64 = this.getBase64.bind(this)
    this.requesPhoneOtp = this.requesPhoneOtp.bind(this)
    this.requesEmailOtp = this.requesEmailOtp.bind(this)
    this.verEmailOtp = this.verEmailOtp.bind(this)
    this.verPhoneOtp = this.verPhoneOtp.bind(this)
    this.resendOtp = this.resendOtp.bind(this)
    this.changePassword = this.changePassword.bind(this)
    this.checkedPwd = this.checkedPwd.bind(this)
    this.savePwd = this.savePwd.bind(this)
    let selectedFile =''
    this.setCrop = this.setCrop.bind(this)
    this.setZoom = this.setZoom.bind(this)
    this.readFile = this.readFile.bind()
    this.handleSubmitPhoto = this.handleSubmitPhoto.bind(this)
    this.onCropComplete = this.onCropComplete.bind(this)
    
  }
  setCrop = (obj)=>{
    this.setState({crop:obj})
  }
  setZoom = (obj)=>{
    this.setState({zoom:obj})
  }
  onCropComplete = (croppedArea, croppedAreaPixels)=>{
    console.log(croppedArea,croppedAreaPixels)
    this.setState({croppedAreaPixels})
  }
  componentDidMount() {
    this.setState({
      name: this.props.user.name,
      email: this.props.user.email,
      instahandle: this.props.user.instahandle,
      mobileNumber: this.props.user.mobileNumber,
      profile_img: this.props.user.profile_img,
      description: this.props.user.description,
      fblink: this.props.user.fblink,
      twlink: this.props.user.twlink,
      ytlink: this.props.user.ytlink,
      blog: this.props.user.blog,
      username: this.props.user.username
    })
  }

  readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  getCroppedImage= async ()=>{

    const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', error => reject(error))
        image.setAttribute('crossOrigin', 'anonymous')
        image.src = url
    })
    const crop = this.state.croppedAreaPixels
    const image = await createImage(this.selectedFile)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    canvas.width = 400
    canvas.height = 400

    ctx.drawImage(
        image,
        crop.x,
        crop.y,
        crop.width,
        crop.height,
        0,
        0,
        canvas.width,
        canvas.height
    )
    return canvas.toDataURL("image/jpeg").split(';base64,')[1].toString()
    
  }

  async handleSubmitPhoto(){

    let profile_img = await this.getCroppedImage()
    let imgdata = new FormData()
    imgdata.append('profile_img',imgdata);
    imgdata.append("_id", this.props.user._id)
    let userdata =
    {
      _id: this.props.user._id,
      profile_img: profile_img,
    }
    this.props.uploadProfileImage(userdata, userdata)
    this.setState({ showCropper: false})
    // this.profileimage.current.src = URL.createObjectURL(imgdata)

  }

  async handleChange(e) {
    e.preventDefault();
    //let errors ={}
    if (e.target.id === 'username') {
      const targetValue = e.target.value
      const targetId = e.target.id
      console.log (targetValue)
      if (/^[a-zA-Z0-9-]*$/.test(targetValue) && targetValue.length <= 15) {
        let exist = await checkUsername(targetValue, this.props.user._id)
        if (exist.data.statustext === 'exist') {
          errors[targetId] = "username already exist, please choose another username."
        }
        else if (exist.data.statustext === 'OK') {
          this.setState({
            [targetId]: targetValue,
            validate: true
          })
          errors[targetId] = "";
        }
      }
      else {
        errors[targetId] = "user name should have maximum 15 characters and It can be a mix of numbers, letters, and dash -"
        this.setState({
          validate: false,
        })
      }
    }
    else if (e.target.id === 'email') {
      const targetId = e.target.id
      const targetValue = e.target.value
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(targetValue)) {
        this.setState({
          mailChange:true
        })
        let exist = await checkEmail(targetValue, this.props.user._id)
        if (exist.data.statustext === 'exist') {
          errors[targetId] = "Email already registered."
        }
        else if (exist.data.statustext === 'OK') {
          this.setState({
            [targetId]: targetValue,
            validate: true
          })
          errors[targetId] = ""
        }
      }
      else {
        errors[targetId] = "invalid email address."
        this.setState({
          validate: false,
        })
      }
    }
    else if (e.target.id === 'mobileNumber') {
      const targetValue = e.target.value
      const targetId = e.target.id
      // console.log(e.target.value)
      if (/^\d{10}$/.test(Number(targetValue))) {
        //console.log('match')
        this.setState({
          phoneChange:true
        })
        let exist = await checkNumber(targetValue, this.props.user._id)
        if (exist.data.statustext === 'exist') {
          errors[targetId] = "Phone Number already registered."
        }
        else if (exist.data.statustext === 'OK') {
          this.setState({
            [targetId]: targetValue,
            validate: true
          })
          errors[targetId] = ""
        }
      }
      else {
        errors[targetId] = "invalid Phone Number."
        this.setState({
          validate: false,
        })
      }
    }
    else if (e.target.id === 'instahandle') {
      const targetValue = e.target.value
      const targetId = e.target.id
      if ((/^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/).test(targetValue) &&  targetValue.length < 30) {
        let exist = await checkInstahandle(targetValue, this.props.user._id)
        if (exist.data.statustext === 'exist') {
          errors[targetId] = "instahandle already exist."
        }
        else if (exist.data.statustext === 'OK') {
          this.setState({
            [targetId]: targetValue,
            instaValid: true,
            validate: true
          })
          errors[targetId] = ""
        }
      }
      else {
        errors[targetId] = "invalid instahandle."
        this.setState({
          validate: false,
        })
      }
    }
    else if (e.target.id === 'name') {
      const targetValue = e.target.value
      const targetId = e.target.id
      if (/\w+/.test(targetValue) && targetValue.length < 30) {
        this.setState({
          [targetId]: targetValue,
          validate: true
        })
        errors[targetId] = ""
      }
      else {
        errors[targetId] = "name field cannot be empty and must be less than 30 characters."
        this.setState({
          validate: false,
        })
      }
    }
    else if (e.target.id === 'cc') {
      const targetValue = e.target.value
      const targetId = e.target.id
      if (/^\d{2}$/.test(Number(targetValue))) {
        errors[targetId] = ""
        this.setState({
          cc: targetValue,
          validate: true
        })
      }
      else {
        errors[targetId] = "Invalid Country Code."
        this.setState({
          validate: false
        })
      }
    }
    else {
      this.setState({ [e.target.id]: e.target.value })
    }
    this.setState({
      errors
    })
  }
  profileChange(e) {
    e.preventDefault();
    this.setState({ 
      disabled: false,
      pwdChange: false
     })
  }
  saveChanges(e) {
    e.preventDefault();
    console.log(this.props.user._id)
    let userdata =
    {
      _id: this.props.user._id,
      name: this.state.name,
      email: this.state.email,
      instahandle: this.state.instahandle,
      mobileNumber: this.state.mobileNumber,
      description: this.state.description,
      profile_img: this.state.profile_img,
      fblink: this.state.fblink,
      twlink: this.state.twlink,
      ytlink: this.state.ytlink,
      blog: this.state.blog,
      username: this.state.username
    }
    let mailflag=true, phoneflag=true
    if(this.state.mailChange){ 
      if (this.state.emailVerified && this.state.errors.email === '') {
        //this.props.editProfile(userdata)
        mailflag=true
      }
      else mailflag=false
    }
    if(this.state.phoneChange){
      if (this.state.errors.mobileNumber === '' && this.state.phoneVerified) {
        //this.props.editProfile(userdata)
        phoneflag=true
      }
      else  phoneflag=false
    }
    console.log(mailflag,phoneflag, (this.state.errors.name === ''||typeof this.state.errors.name==="undefined"), this.state.errors.username, this.state.errors.instahandle)
    if(mailflag && phoneflag && (typeof this.state.errors.name === "undefined" || this.state.errors.name === '') && (typeof this.state.errors.username === "undefined" || this.state.errors.username === '') && (typeof this.state.errors.instahandle === "undefined" || this.state.errors.instahandle === '')){
      this.props.editProfile(userdata)
    }
    else {
      Swal.fire({
        icon: 'error',
        title: 'Validation error',
        text: 'Please insert all required field.'
      })
    }
  }
  uploadProfileImg = async (e) => {
    e.persist();
    var profile_img = ''
    e.preventDefault();
    let file = e.target.files[0]
    this.selectedFile = await this.readFile(file)
    this.setState({ showCropper: true})
  }

  openpimg = (e) => {
    e.preventDefault();
    this.pimg.current.click()
  }
  async requesEmailOtp() {
    console.log(this.state.email)
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)) {
      this.setState({ emailValid: true })
      let res = await sendOTPToEmail(this.state.email)
      this.setState({ emailValid: true })
      if (res.data.statusText === 'OK') {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'OTP send to your email.',
          showConfirmButton: false,
          timer: 5000
        })
        this.setState({
          reqMailtOtp: true
        })
      }
    }
  }
  async requesPhoneOtp() {
    console.log(this.state.mobileNumber, this.state.cc)
    if(Object.keys(this.state.cc).length === 0){
      Swal.fire({
        position: 'top-end',
        title: 'Please input your country code.',
        showConfirmButton: false,
        timer: 5000
      })
    }
    else if ((/^\d{10}$/.test(Number(this.state.mobileNumber))) && (/^\d{2}$/.test(Number(this.state.cc)))) {
      console.log('click')
      this.setState({
        phoneValid: true
      })
      let res = await sendOTPToMobile(this.state.cc, this.state.mobileNumber)
      if (res.data.statusText === 'OK') {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'OTP send to your Phone Number.',
          showConfirmButton: false,
          timer: 5000
        })
      }
    }
  }
  async resendOtp() {
    if (/^\d{10}$/.test(this.state.mobileNumber && /d{2}/.test(this.state.cc))) {
      let res = await sendVoiceOTPToMobile(this.state.cc, this.state.mobileNumber)
      if (res.data.statusText === 'OK') {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'You\'ve requested another OTP. You\'ll receive another OTP in a short time',
          showConfirmButton: false,
          timer: 2000
        })
      }
    }
  }
  async verEmailOtp(e) {
    console.log(e.target.value)
    if (/\d{4}/.test(Number(e.target.value))) {
      let res = await verifyEmailOTP(this.state.email, e.target.value)
      if (res.data.statusText === 'OK') {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'OTP has been verified.',
          showConfirmButton: false,
          timer: 5000
        })
        this.setState({
          emailVerified: true
        })
      }
      else if (res.data.statusText === 'OTP does not match') {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'OTP, you\'ve input wrong OTP.',
          showConfirmButton: false,
          timer: 5000
        })
        this.setState({
          emailVerified: false
        })
      }
    }
  }
  async verPhoneOtp(e) {
    console.log(e.target.value)
    if (/\d{4}/.test(Number(e.target.value))) {
      let res = await verifyNumberOTP(this.state.cc, this.state.mobileNumber, e.target.value)
      if (res.data.statusText === 'OK') {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'OTP has been verified.',
          showConfirmButton: false,
          timer: 5000
        })
        this.setState({
          phoneVerified: true
        })
      }
      else if (res.data.status === 500) {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Opps! you\'ve entered wrong OTP.',
          showConfirmButton: false,
          timer: 5000
        })
        this.setState({
          phoneVerified: true
        })
      }
    }
  }
  changePassword(){
    this.setState({
      pwdChange: true
    })
  }
  async checkedPwd(e){
    if(e.target.id === 'oldPassword'){
      this.setState({
        oldpassword: e.target.value
      })
    }
    else if(e.target.id === 'newPassword'){
      if(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(e.target.value)){
        this.setState({
          newpassword: e.target.value
        })
        pwderr[e.target.id] =''
      }
      else{
        pwderr[e.target.id] ='Password must contain atleast 8 characters, one uppercase letter, one lowercase letter, one number and one special character.'
      }
    }
    else if(e.target.id === "newPassword2"){
      if(e.target.value === this.state.newpassword){
        pwderr[e.target.id] = ''
        this.setState({
          newpassword2:e.target.value
        })
      }
      else{
        pwderr[e.target.id] = 'Password do not match.'
      }
    }
    this.setState({
      pwderr
    })
  }
  //save password
 async savePwd(){
    if(this.state.pwderr.newPassword === '' && this.state.pwderr.newPassword2 === '' && this.state.newpassword!=='' && this.state.newpassword2!==''){
      console.log('ok')
      let res = await updatePassword(this.props.user._id, this.state.oldpassword, this.state.newpassword)
      if(res.data.statusText === 'OK'){
        this.setState({
          pwdChange:false
        })
        Swal.fire({
          icon:'success',
          title:'Password Reset',
          text:'Passord successfully changed.'
        })
      }
      else if(res.data.statusText==='notmatch'){
        pwderr["oldpassword"] = "Password do not match."
        this.setState({
          pwderr
        })
      }
    }
  }
  render() {
    return (
      <>
        {!this.props.isAuthenticated ? <Redirect push to='/shop/login' /> :
          <div>
            <div>
              <NavBar page="editprofile" />
            </div>
            <div id="appCapsule">

              <div className="section full mt-2 mb-2">
                <div className="profileimg">
                  <img src={this.props.user.profile_img !== null ? this.props.user.profile_img : PersonCircle} className="imgprofile" ref={this.profileimage}>
                  </img>
                  <div>
                    {!this.state.disabled &&
                      <>
                        <form encType='multipart/form-data' ref={this.pimgform}>
                          <input type="file" name='profile_img' onChange={this.uploadProfileImg} style={{ display: 'none' }} ref={this.pimg} />
                          <a href="#" onClick={this.openpimg}>Change Profile Picture</a>
                        </form>
                      </>
                    }
                  </div>
                </div> 
                {this.state.disabled && !this.state.pwdChange &&
                  <>
                    <div className="form-button-group">
                      <input value="Edit Profile" className="btn btn-primary btn-block btn-lg" onClick={this.profileChange} />
                    </div>
                    <div className="form-button-group">
                      <input value="Change Password" className="btn btn-primary btn-block btn-lg" onClick={this.changePassword} />
                    </div>
                  </>
                }
                {this.state.pwdChange && this.state.disabled ? 
                <>
                  <div className="section-title">Change Password</div>
                  <div className="wide-block pb-1 pt-1">
                    <div className="close">
                        <span onClick={() => this.setState({ pwdChange: false,pwderr:'' })} style={{cursor:'pointer'}}>
                          <XLg />
                        </span>
                    </div>
                    <form>
                      <ul className="listview image-listview no-line no-space flush">
                        <li>
                            <div className="item">
                              <div className="in">
                                <div className="form-group basic">
                                  <div className="input-wrapper">
                                    <label className="form-label" htmlFor="oldPassword">
                                      Old password
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      id="oldPassword"
                                      placeholder=""
                                      onChange={this.checkedPwd}
                                    />
                                    <div className="validation">{this.state.pwderr.oldpassword}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                              <div className="in">
                                <div className="form-group basic">
                                  <div className="input-wrapper">
                                    <label className="form-label" htmlFor="newPassword">
                                      New password
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      id="newPassword"
                                      placeholder=""
                                      onChange={this.checkedPwd}
                                    />
                                    <i className="clear-input">
                                      <ion-icon name="close-circle" />
                                    </i>
                                    <div className="validation">{this.state.pwderr.newPassword}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                              <div className="in">
                                <div className="form-group basic">
                                  <div className="input-wrapper">
                                    <label className="form-label" htmlFor="newPassword2">
                                      Retype New password
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      id="newPassword2"
                                      placeholder=""
                                      onChange={this.checkedPwd}
                                    />
                                    <i className="clear-input">
                                      <ion-icon name="close-circle" />
                                    </i>
                                    <div className="validation">{this.state.pwderr.newPassword2}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </li>
                      </ul>
                      <div className="form-button-group">
                        <input value="Save" className="btn btn-primary btn-block btn-lg" onClick={this.savePwd} />
                      </div>
                    </form>
                  </div>
                </>
                  :
                  <>
                    <div className="section-title">Profile Details</div>
                    <div className="wide-block pb-1 pt-1">
                      {!this.state.disabled &&
                        <div className="close">
                          <span onClick={() => this.setState({ disabled: true })}>
                            <XLg />
                          </span>
                        </div>
                      }
                      <form>
                        <ul className="listview image-listview no-line no-space flush">
                          <li>
                            <div className="item">
                              <div className="icon-box bg-primary">
                                <Type></Type><ion-icon name="mail-outline" />
                              </div>
                              <div className="in">
                                <div className="form-group basic">
                                  <div className="input-wrapper">
                                    <label className="form-label" htmlFor="name">
                                      Name
                                    </label>
                                    <input
                                      type="name"
                                      className="form-control"
                                      id="name"
                                      placeholder=""
                                      defaultValue={this.props.user.name}
                                      disabled={this.state.disabled}
                                      onChange={this.handleChange}
                                    />
                                    <i className="clear-input">
                                      <ion-icon name="close-circle" />
                                    </i>
                                    {!this.state.disabled &&
                                      <div className="validation">{this.state.errors.name}</div>
                                    }
                                  </div>
                                  {!this.state.disabled &&
                                  <div className="input-info">Enter your name</div>
                                  }
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <div className="icon-box bg-primary">
                                <Type></Type><ion-icon name="mail-outline" />
                              </div>
                              <div className="in">
                                <div className="form-group basic">
                                  <div className="input-wrapper">
                                    <label className="form-label" htmlFor="username">
                                      Username
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="username"
                                      placeholder=""
                                      defaultValue={this.props.user.username}
                                      disabled={this.state.disabled}
                                      onChange={this.handleChange}
                                    />
                                    <i className="clear-input">
                                      <ion-icon name="close-circle" />
                                    </i>
                                    {!this.state.disabled &&
                                      <div className="validation">{this.state.errors.username}</div>
                                    }
                                  </div>
                                  {!this.state.disabled &&
                                  <div className="input-info">Enter your username</div>
                                  }
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="item">
                              <div className="icon-box bg-primary">
                                <Envelope></Envelope><ion-icon name="mail-outline" />
                              </div>
                              <div className="in">
                                <div className="form-group basic">
                                  <div className="input-wrapper">
                                    <Row>
                                      <Col xs={9}>
                                        <label className="form-label" htmlFor="email">
                                          Email
                                        </label>
                                        <input
                                          type="email"
                                          className="form-control"
                                          id="email"
                                          placeholder=""
                                          defaultValue={this.props.user.email}
                                          disabled={this.state.disabled}
                                          onChange={this.handleChange}
                                        />
                                      </Col>
                                      <Col xs={3}>
                                        {
                                          this.state.disabled ? '' :
                                            this.state.emailVerified ?
                                              <Check2 color="green" />
                                              : !this.state.emailValid ?
                                                <Button type="button" onClick={this.requesEmailOtp}>Verify</Button>
                                                :
                                                <>
                                                  <label className="form-label" htmlFor="emailOTP">
                                                    OTP
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="----"
                                                    onChange={this.verEmailOtp}
                                                    style={{ textAlign: 'center' }}
                                                  />
                                                </>
                                        }
                                        <i className="clear-input">
                                          <ion-icon name="close-circle" />
                                        </i>
                                      </Col>
                                    </Row>
                                    {!this.state.disabled &&
                                      <div className="validation">{this.state.errors.email}</div>
                                    }
                                  </div>
                                  {!this.state.disabled &&
                                  <div className="input-info">Enter your e-mail address</div>
                                  }
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            {window.innerWidth > 800 ?
                              <div className="item">
                                <div className="icon-box bg-primary">
                                  <Type></Type><ion-icon name="mail-outline" />
                                </div>
                                <div className="in">
                                  <div className="form-group basic">
                                    <div className="input-wrapper">
                                      <Row>
                                        {!this.state.disabled &&
                                          <Col xs={3}>
                                            <label className="form-label" htmlFor="cc">
                                              CC
                                            </label>

                                            <input
                                              type="text"
                                              className="form-control"
                                              id="cc"
                                              placeholder="cc"
                                              onChange={this.handleChange}
                                            />
                                          </Col>
                                        }
                                        <Col xs={this.state.disabled ? 12 : 6}>
                                          <label className="form-label" htmlFor="name">
                                            Mobile Number
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="mobileNumber"
                                            placeholder=""
                                            defaultValue={this.props.user.mobileNumber}
                                            disabled={this.state.disabled}
                                            onChange={this.handleChange}
                                          />
                                        </Col>
                                        {
                                          this.state.disabled ? '' :
                                            this.state.phoneVerified ?
                                              <Col xs={3}>
                                                <Check2 color="green" />
                                              </Col>
                                              : !this.state.phoneValid ?
                                                <Col xs={3}>
                                                  <Button variant="primary" onClick={this.requesPhoneOtp}>Verify</Button>
                                                </Col>
                                                :
                                                <Col xs={3}>
                                                  <label className="form-label" htmlFor="otp">
                                                    OTP
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    id="mobileNumber"
                                                    placeholder="----"
                                                    onChange={this.verPhoneOtp}
                                                    style={{ textAlign: 'center' }}
                                                  />
                                                </Col>
                                        }
                                      </Row>
                                      <i className="clear-input">
                                        <ion-icon name="close-circle" />
                                      </i>
                                      {/* {!this.state.disabled &&
                                 <div className="validation">{this.state.errors.mobileNumber}</div>
                                } */}
                                      {!this.state.disabled &&
                                        <Row>
                                          <Col xs={4}>
                                            <div className="validation">{this.state.errors.cc}</div>
                                          </Col>
                                          <Col xs={8}>
                                            <div className="validation">{this.state.errors.mobileNumber}</div>
                                          </Col>
                                        </Row>
                                      }
                                    </div>
                                    <Row>
                                      <Col xs={3}>
                                        {
                                          !this.state.disabled &&
                                          <div className="input-info">Enter country Code</div>
                                        }
                                      </Col>
                                      <Col xs={6}>
                                      {!this.state.disabled &&
                                        <div className="input-info">Enter your mobile number.</div>
                                      }
                                      </Col>
                                      {!this.state.disabled && !this.state.phoneVerified && this.state.phoneValid &&
                                        <Col xs={3}>
                                          <Button variant="primary" onClick={this.resendOtp}>resend</Button>
                                        </Col>
                                      }
                                    </Row>
                                  </div>
                                </div>
                              </div>
                              :
                              <>
                                <div className="item">
                                  <div className="icon-box bg-primary">
                                    <Type></Type><ion-icon name="mail-outline" />
                                  </div>
                                  <div className="in">
                                    <div className="form-group basic">
                                      <div className="input-wrapper">
                                        <Row>
                                          {!this.state.disabled &&
                                            <Col xs={3}>
                                              <label className="form-label" htmlFor="cc">
                                                CC
                                              </label>

                                              <input
                                                type="text"
                                                className="form-control"
                                                id="cc"
                                                placeholder="cc"
                                                preventDefault={this.state.cc}
                                                onChange={this.handleChange}
                                              />
                                            </Col>
                                          }
                                          <Col xs={this.state.disabled ? 12 : 9}>
                                            <label className="form-label" htmlFor="name">
                                              Mobile Number
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="mobileNumber"
                                              placeholder=""
                                              defaultValue={this.props.user.mobileNumber}
                                              disabled={this.state.disabled}
                                              onChange={this.handleChange}
                                            />
                                          </Col>

                                        </Row>
                                        <i className="clear-input">
                                          <ion-icon name="close-circle" />
                                        </i>
                                        {/* {!this.state.disabled &&
                                 <div className="validation">{this.state.errors.mobileNumber}</div>
                                } */}
                                        {!this.state.disabled &&
                                          <Row>
                                            <Col xs={4}>
                                              <div className="validation">{this.state.errors.cc}</div>
                                            </Col>
                                            <Col xs={8}>
                                              <div className="validation">{this.state.errors.mobileNumber}</div>
                                            </Col>
                                          </Row>
                                        }
                                      </div>
                                      <Row>
                                        <Col xs={6}>
                                          {!this.state.disabled &&
                                            <div className="input-info">Enter country Code</div>
                                          }
                                        </Col>
                                        <Col xs={this.state.disabled ? 12 : 6}>
                                        {!this.state.disabled &&
                                          <div className="input-info">Enter your mobile number.</div>
                                        }
                                        </Col>
                                        {/*  {!this.state.disabled && !this.state.phoneVerified && this.state.phoneValid &&
                                <Col xs={3}>
                                   <Button variant="primary" onClick={this.resendOtp}>resend</Button>
                                </Col>
                                } */}
                                      </Row>
                                    </div>
                                  </div>
                                </div>
                                <div className="item">
                                  <div className="in">
                                    <div className="form-group basic">
                                      <div className="input-wrapper">
                                        <Row style={{ marginLeft: "40px" }}>
                                          {
                                            this.state.disabled ? '' :
                                              this.state.phoneVerified ?
                                                <Col xs={6}>
                                                  <Check2 color="green" />
                                                </Col>
                                                : !this.state.phoneValid ?
                                                  <Col xs={3}>
                                                    <Button variant="primary" onClick={this.requesPhoneOtp}>Verify</Button>
                                                  </Col>
                                                  :
                                                  <>
                                                    <Col xs={6}>
                                                      <label className="form-label" htmlFor="otp">
                                                        OTP
                                                      </label>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        id="mobileNumber"
                                                        placeholder="----"
                                                        onChange={this.verPhoneOtp}
                                                        style={{ textAlign: 'center' }}
                                                      />
                                                    </Col>
                                                    <Col xs={6}>
                                                      <Button variant="primary" onClick={this.resendOtp}>resend</Button>
                                                    </Col>
                                                  </>
                                          }
                                        </Row>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            }
                          </li>
                          <li>
                            <div className="item">
                              <div className="icon-box Instagram">
                                <Instagram color='red'></Instagram>
                                <ion-icon name="lock-closed-outline" />

                              </div>
                              <div className="in">
                                <div className="form-group basic">
                                  <div className="input-wrapper">
                                    <label className="form-label" htmlFor="insta">
                                      Insta Handle
                                    </label>
                                    <input
                                      type="isnta"
                                      className="form-control"
                                      id="instahandle"
                                      placeholder=""
                                      autoComplete="off"
                                      defaultValue={this.props.user.instahandle}
                                      disabled={this.state.disabled}
                                      onChange={this.handleChange}
                                    />
                                    <i className="clear-input">
                                      <ion-icon name="close-circle" />
                                    </i>
                                    {!this.state.disabled &&
                                      <div className="validation">{this.state.errors.instaValid}</div>
                                    }
                                  </div>
                                  {!this.state.disabled &&
                                  <div className="input-info">Enter your instagram handle</div>
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="icon-box ">
                                <Bootstrap color='orange'></Bootstrap><ion-icon name="lock-closed-outline" />
                              </div>
                              <div className="in">
                                <div className="form-group basic">
                                  <div className="input-wrapper">
                                    <label className="form-label" htmlFor="blog">
                                      Blog Name
                                    </label>
                                    <input
                                      type="blog"
                                      className="form-control"
                                      id="blog"
                                      placeholder=""
                                      autoComplete="off"
                                      defaultValue={this.props.user.blog}
                                      disabled={this.state.disabled}
                                      onChange={this.handleChange}
                                    />
                                    <i className="clear-input">
                                      <ion-icon name="close-circle" />
                                    </i>
                                  </div>
                                  {!this.state.disabled &&
                                  <div className="input-info">Enter your Blog Name</div>
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="icon-box bi bi-facebook">
                                <Facebook color='blue'>
                                </Facebook>
                                <ion-icon name="lock-closed-outline" />
                              </div>
                              <div className="in">
                                <div className="form-group basic">
                                  <div className="input-wrapper">
                                    <label className="form-label" htmlFor="facebook">
                                      Facebook
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="fblink"
                                      placeholder=""
                                      autoComplete="off"
                                      defaultValue={this.props.user.fblink}
                                      disabled={this.state.disabled}
                                      onChange={this.handleChange}
                                    />
                                    <i className="clear-input">
                                      <ion-icon name="close-circle" />
                                    </i>
                                  </div>
                                  {!this.state.disabled &&
                                  <div className="input-info">Enter your Facebook Link</div>
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="icon-box bi bi-twitter">
                                <Twitter color='#1E74FD'></Twitter><ion-icon name="bi bi-twitter" />
                              </div>
                              <div className="in">
                                <div className="form-group basic">
                                  <div className="input-wrapper">
                                    <label className="form-label" htmlFor="twitter">
                                      Twitter
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="twlink"
                                      placeholder=""
                                      autoComplete="off"
                                      defaultValue={this.props.user.twlink}
                                      disabled={this.state.disabled}
                                      onChange={this.handleChange}
                                    />
                                    <i className="clear-input">
                                      <ion-icon name="close-circle" />
                                    </i>
                                  </div>
                                  {!this.state.disabled &&
                                  <div className="input-info">Enter your Twitter Link</div>
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="icon-box ">
                                <Youtube color='red'></Youtube>  <ion-icon name="lock-closed-outline" />
                              </div>
                              <div className="in">
                                <div className="form-group basic">
                                  <div className="input-wrapper">
                                    <label className="form-label" htmlFor="youtube">
                                      Youtube
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="ytlink"
                                      placeholder=""
                                      autoComplete="off"
                                      defaultValue={this.props.user.ytlink}
                                      disabled={this.state.disabled}
                                      onChange={this.handleChange}
                                    />
                                    <i className="clear-input">
                                      <ion-icon name="close-circle" />
                                    </i>
                                  </div>
                                  {!this.state.disabled &&
                                  <div className="input-info">Enter your Youtube Link</div>
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="form-group boxed">
                              <div className="input-wrapper">
                                <label className="form-label" htmlFor="address5">
                                  Description
                                </label>
                                <textarea
                                  id="description"
                                  rows={2}
                                  className="form-control"
                                  defaultValue={this.props.user.description || ""}
                                  disabled={this.state.disabled}
                                  onChange={this.handleChange}
                                />
                                <i className="clear-input">
                                  <ion-icon name="close-circle" />
                                </i>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </form>
                      {!this.state.disabled &&
                        <div className="form-button-group">
                          <input type="submit" value="Save Changes" className="btn btn-primary btn-block btn-lg" onClick={this.saveChanges} />
                        </div>
                      }
                    </div>
                  </>
                }
              </div>
            </div>
            {/*  <div
              className="modal fade panelbox panelbox-left"
              id="sidebarPanel"
              tabIndex={-1}
              role="dialog"
            >
            </div> */}
          </div>}
          <Modal
                    show={this.state.showCropper}
                    className="profile-picture-modal"
                >
                    <Modal.Header>
                        <a href="#" onClick={this.handleGoogleLoginClose} style={{ marginLeft: '90%' }}>
                            <X color="white" size={30} />
                        </a>
                    </Modal.Header>

                    <Modal.Body>
                        <div className='custom-file-upload'>
                            <Cropper
                              image={this.selectedFile}
                              crop={this.state.crop}
                              zoom={this.state.zoom}
                              aspect={4 / 4}
                              onCropChange={this.setCrop}
                              onCropComplete={this.onCropComplete}
                              onZoomChange={this.setZoom}
                              cropShape='round'
                              showGrid={false}
                              zoomWithScroll={true}
                            />
                            
                        </div>
                        <Button onClick={this.handleSubmitPhoto}>Save Image</Button>
                    </Modal.Body>
                </Modal>
      </>
    )
  }
}
const mapStateToProps = state => (
  {
    user: state.user,
    isAuthenticated: state.isAuthenticated
  }
)
const mapDispatchToProps = dispatch => (
  {
    editProfile: user => dispatch(editProfile(user)),
    uploadProfileImage: (imgdata, profile_img) => dispatch(uploadProfileImage(imgdata, profile_img))
  }
)
export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
import React from 'react';
import { Component } from 'react';
import { isMobile } from "react-device-detect";
import { Col, Container, Row, Image, Card, Button, Modal } from 'react-bootstrap';
import {userLogin, userLogout} from '../../actions';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import PublicNavbar  from './publicNavbar';
import '../../styles/style.css'
import '../../styles/bloggerShop.css';
import { X, ThreeDotsVertical, ChevronDoubleLeft , BookmarksFill } from 'react-bootstrap-icons'
import { connect } from "react-redux";
import { fetchPublicCrossSellData, fetchPublicProducts , getSavedLooks , addLook , deleteLook } from '../../actions'
import Loader from '../Loader'
import axios from 'axios';
import ReactGA from 'react-ga';
import mixpanel, { init } from 'mixpanel-browser';

mixpanel.init("1a8ebbbb5ab0d0b35dc91b5e3b1578e1" ,{
    debug :true,
    ignore_dnt: true
})

const clientId = "806676170053-kdqovn15piaombk6nubcaurib6ds9f09.apps.googleusercontent.com";
class PublicLoginModal extends Component {
    constructor(props) {
        super(props)
       /*  let insta = document.location.pathname.split('/');
        insta.pop();
        insta = insta.pop();
        insta = insta.pop(); */
        this.state = {
        }
    }
    
    handleGoogleLoginClose = ()=>{
        this.props.closeModal()
    }
    googleResponse = async (response) =>{
        let details = response.profileObj
        this.setState({
          reqLogin: true
        })
        await this.props.googleUserLogin(details)
        ReactGA.set({userId: details.name});
        mixpanel.register({
            "First Login Date": new Date().toISOString(),
            "Visitor type" : "End user"
        });
        mixpanel.identify(details.email);
        mixpanel.track("User sigup",{
                name: details.name,
                email: details.email,
                googleId: details.googleid,
            })
        mixpanel.alias(details.email)
        mixpanel.people.set({
                "company": "sml_User",
                "$name": details.name,
                "$email": details.email,
                        })
        this.handleGoogleLoginClose()
    }
    render() {
        let target = window.navigator.userAgent.toLowerCase().includes('wv')?'_self':'_blank'; 
        return (
            <>
                <Modal
                    show={!this.props.isUserAuthenticated &&this.props.showModal}
                    onHide={this.handleGoogleLoginClose}
                    className="product-modal google-login-modal"
                >
                    <Modal.Header>
                        <button className='btn btn-text-dark' onClick={this.handleGoogleLoginClose} style={{ marginLeft: '90%' }}>
                            <X color="white" size={30} />
                        </button>
                    </Modal.Header>

                    <Modal.Body>
                        <Row>
                            <h2>Please login to continue</h2>
                          <div className="google-login">
                                {this.props.isLogin?
                                <GoogleLogout
                                clientId={clientId}
                                buttonText="Logout"
                                // onLogoutSuccess={logout}
                                />
                                :<GoogleLogin
                                    clientId={clientId}
                                    buttonText="Sign-in with Google"
                                    onSuccess={this.googleResponse}
                                    // onFailure={this.googleResponse}
                                    cookiePolicy={'single_host_origin'}
                                />
                                }   
                            </div>
                        </Row>
                    </Modal.Body>
                </Modal>
                        
            </>
        )
    }
}
const mapStateToProps = state => ({
    isUserAuthenticated : state.isUserAuthenticated,
    EndUserInfo : state.EndUserInfo
});
const mapDispatchToProps = dispatch => ({
    googleUserLogin : (googleProfile) => dispatch(userLogin(googleProfile)),
    userLogout:()=>dispatch(userLogout())
});
export default connect(mapStateToProps, mapDispatchToProps)(PublicLoginModal);

import React from 'react';
import ReactDOM from 'react-dom';
/* import './index.css'; */
/* import App from './App'; */
import blogger from './blogger' 
import * as serviceWorker from './serviceWorker';
import { Route, BrowserRouter , Switch ,Redirect } from 'react-router-dom';
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
/* import "./App_pinkTheme.css";
import "./theme/cf-elemet-theme/index.css"; */
import reducer from "./reducers"
import { createStore, applyMiddleware} from "redux";
import {Provider} from 'react-redux';
import shoppableItems from './shoppableItems'; 
import { i18n } from 'element-react';
import locale from 'element-react/src/locale/lang/en';
import videoShoppableItems from './videoShoppableItems';
import blogger_demo from './blogger_demo'
import brands_demo from './brands_demo';
import blogger_store from './components/blogger_store'
import bloggerShop from './components/bloggerShop'
import userLogin from './components/public/login';
import userProfile from './components/public/uerProfile'
import bloggerCounterDashboard from './components/bloggerCounterDashboard';
// import publicHome from './components/public/publicHome';
import Cart from './components/public/cart'
import login from './components/login';
import Register from './components/register';
import catalogDashboard from './components/CatalogDashboard/catalogDashboard';
import EditProfile from './components/EditProfile';
import profile from './components/profile'
import ReduxThunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist' // imports from redux-persist
import storage from 'redux-persist/lib/storage'
import { PersistGate } from 'redux-persist/integration/react'
import publicStore from './components/public/publicStore'
import publicProfile from './components/PublicProfile';
import Preferences from './components/public/preferences';
import publicShop from './components/public/publicShop';
import Page404 from './components/404page';
import forgotPassword from './components/forgot-password';
import ResetPassword from './components/resetPassword';
import Explore from './components/public/explore';
import BloggerNotFound from './components/blogger_not_found';
import Bank_Details from './components/Bank_Details';
import Csv from './components/Csv';
import curatorLogin from './components/Curator/login'
import curatorStore from './components/Curator/curatorStore'
import curatorShop from './components/Curator/curatorShop'
import bloggerList from './components/Curator/bloggerList'
import CuratorNewSignups from './components/Curator/curatorNewSignups'
import SignupBlogger from './components/Curator/signUpBlogger'
import Lens from './components/public/lens'
import LensUrl from './components/public/lensUrl'
import LensHistory from './components/public/LensHistory'
import ReactGA from 'react-ga';
import LensResult from './components/public/LensResult';
import LensDailyUpdatedgrid from './components/public/lensDailyUpdatedgrid';
const TRACKING_ID = "UA-233715802-1";
ReactGA.initialize(TRACKING_ID);

i18n.use(locale);
const persistConfig = { // configuration object for redux-persist
  key: 'root',
  storage, // define which storage to use
  whitelist: ['user','isAuthenticated','bloggerInfo','isUserAuthenticated','endUserProfile','isCuratorAuthenticated','hasUserFilledPreferences']
}
const persistedReducer = persistReducer(persistConfig, reducer)
const store = createStore(persistedReducer,
    applyMiddleware(ReduxThunk)
  )
  const  persistor = persistStore(store);
  ReactGA.pageview(window.location.pathname + window.location.search);

const routing = (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Cart} />
      <Route exact path="/lens" component={Lens} />
      <Route exact path="/lens-result/:searchHash" component={LensResult} />
      <Route exact path="/lens-history" component={LensHistory} />
      <Route exact path="/lens-url" component={LensUrl} />
      <Route exact path="/lens-daily" component={LensDailyUpdatedgrid} />
      <Route exact path="/blogger-signup" component={blogger_demo} />
      <Route exact path="/brands-signup" component={brands_demo} />
      <Route exact path="/blogger-not-found" component={ BloggerNotFound }/>
      <Route exact path="/brandsCatalogDashboard" component={ catalogDashboard }/>
      <Route exact path="/user/login" component={userLogin}/>
      <Route exact path="/user/profile" component={userProfile}/> 
      <Route exact path="/user/preferences" component={Preferences}/> 
      <Route exact path="/explore" ><Redirect to='/'/></Route>
      <Route exact path="/my-looks" component={Cart}/> 
      <Route exact path="/saved-looks" component={Cart}/> 
      <Route exact path="/shop" component={blogger_store}/>
      <Route exact path="/shop/mystore" component={blogger_store}/>
      <Route exact path="/shop/login" component={login}/>
      <Route exact path="/shop/amazonSales" component={Csv}/>
      <Route exact path="/shop/bankDetails" component={Bank_Details}/>
      <Route exact path="/shop/register" ><Redirect to='/blogger-signup'/></Route>
      <Route exact path="/shop/editprofile" component={EditProfile}/>
      <Route exact path="/shop/analytics" component={bloggerCounterDashboard}/>
      <Route exact path="/shop/forgot-password" component={forgotPassword}/>
      <Route exact path="/shop/resetpassword/link/email:email/token:token" component={ResetPassword}/>
      <Route exact path="/video_Id:vid/shoppable_items:cat" component={videoShoppableItems}/>
      <Route exact path="/shop/profile" component={profile}/>
      <Route exact path="/curator" component={curatorLogin}/>
      <Route exact path="/shop/:outfitid" component={bloggerShop}/>
      <Route exact path="/curator/login" component={curatorLogin}/>
      <Route exact path="/curator/bloggers" component={bloggerList}/>
      <Route exact path="/curator/new-signups" component={CuratorNewSignups}/>
      <Route exact path="/curator/new-signups/:bloggerUsername" component={SignupBlogger}/>
      <Route exact path="/curator/:bloggerUsername" component={curatorStore}/>
      <Route exact path="/curator/:bloggerUsername/:outfitid" component={curatorShop}/>
      <Route path='/outfit/:insta/:outfitid' component={publicShop}/>
      <Route exact path="/:insta" component={publicStore}/>

      <Route component={Page404}/>
    </Switch>
  </BrowserRouter>
  
)
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
        {routing}
      </PersistGate>
  </Provider>, document.getElementById('root'));

// ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
import React from 'react'
import '../styles/style.css'
import './register.css';
// import './../../src/styles/bank_details.css';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { SaveBankDetails, GetBankDetails } from '../api/publicApis';
import NavBar from './navbar'
import {setbnkDetail} from '../actions'

class Bank_Details extends React.Component
{
  constructor() {
    super();
    this.state = {
      account_name: "",
      account_num:"",
      ifsc_code: "",
      pan_num: "",
      confirmAcc_num: '',
      error: {
        account_name: '',
        account_num: '',
        ifsc_code: '',
        pan_num: '',
        confirmAcc_num: ''
    }
    };
    this.onAccChange = this.onAccChange.bind(this);
    this.onAccNameChange = this.onAccNameChange.bind(this);
    this.onIfscChange = this.onIfscChange.bind(this);
    this.onPanChange = this.onPanChange.bind(this);
    this.onConfirmAccChange = this.onConfirmAccChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onAccChange = (e) => {
    
    this.setState({
      account_num: e.target.value,
    });
  }
  onAccNameChange = (e) => {
    this.setState({
      account_name: e.target.value,
    });
  }
  onIfscChange = (e) => {
    this.setState({
      ifsc_code: e.target.value,
    });
  }
  onPanChange = (e) => {
    this.setState({
      pan_num: e.target.value,
    });
  }
  onConfirmAccChange = (e) => {
    this.setState({
      confirmAcc_num: e.target.value,
    });
  }
  onSubmit = async (e) => {
    console.log(this.state)
    e.preventDefault();
    if(this.state.account_num ==="" || this.state.account_name==="" || this.state.ifsc_code==="" || this.state.pan_num==="" || this.state.confirmAcc_num==="" )
    {
      Swal.fire({
        icon:"warning",
        title:"Values Missing",
        text:"Please fill all fields required"
      })
      return 
    }
     if(this.props.isAuthenticated && this.validate()){
      // if(this.props.isAuthenticated){
      await this.saveDetails()
  }
}
saveDetails = async(email) =>{
  const bankDetails = {
    account_name: this.state.account_name,
    account_num: this.state.account_num,
    ifsc_code: this.state.ifsc_code,
    pan_num: this.state.pan_num
  };
  if(this.props.isAuthenticated){
    let res=await SaveBankDetails(bankDetails)
    if (res.data.statustext === 'success') {
        Swal.fire({
          icon:"success",
          title:"Bank Details Saved !",
        })
      }
  }
}
componentDidMount = async()=>{
  if(this.props.isAuthenticated){
    let result = await GetBankDetails()
    await new Promise( res => this.setState(result.bankDetails,res))
    console.log(result)
    if(this.state.ifsc_code){
      this.props.SaveBankDetails(true)
    }
  }
}
validate(){
      let errors = {};
      let isValid = true;   
      if (this.state.pan_num !== "undefined") {        
        var pattern = new RegExp(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/);
        if (!pattern.test(this.state.pan_num)) {
          isValid = false;
          errors["pan_num"] = "*Please enter valid PAN number.";
        }
      }
  
      // if (this.state.ifsc_code !== "undefined") {        
      //   var codePattern = new RegExp(/^[A-Za-z]{4}\d{7}$/);
      //   if (!codePattern.test(this.state.ifsc_code )) {
      //     isValid = false;
      //     errors["ifsc_code"] = "*Please enter valid IFSC code.";
      //   }
      // }

      if (this.state.account_num !== "undefined") {
        if (!this.state.account_num.match(/^[0-9]{9,18}$/)) {
          isValid = false;
          errors["account_num"] = "*Please enter valid account number";
        }
      }
      if (this.state.account_num!== "undefined" && this.state.confirmAcc_num !== "undefined") {
        if (this.state.account_num !== this.state.confirmAcc_num) {
          isValid = false;
          errors["confirmAcc_num"] = "*Account number don't match.";
        }
      }

      this.setState({
        error: errors
      });
      return isValid;
  }
  render() {
    console.log(this.props.isAuthenticated)
    return (
        <>

        {!this.props.isAuthenticated ? <Redirect push to='/shop/login' /> :
        <>
            <NavBar title= "Bank Details" page="home"></NavBar>      
                        <div id="appCapsule" className='p-4'>
                            <div className="login-form mt-5">
                            <div className="section">
                                <h4>Please fill/edit your bank details</h4>
                            </div>
                            <div className="section mt-2 mb-5"></div>
                                <form onSubmit={this.onSubmit} id="registerF" className="needs-validation">
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                    <input 
                                    className="form-control"
                                    type="text"
                                    name="account_name" 
                                    value={this.state.account_name}
                                    onChange={this.onAccNameChange}
                                    placeholder="Enter fullname" 
                                    id="Account_holder_name" />
                                    <i className="clear-input">
                                        <ion-icon name="close-circle" />
                                    </i>
                                    <div className="validation">{this.state.error.account_name}</div>
                                </div>
                                </div>

                                
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                
                                    <input 
                                    type="text" 
                                    name="ifsc_code" 
                                    value={this.state.ifsc_code}
                                    onChange={this.onIfscChange}
                                    className="form-control"
                                    placeholder="Enter IFSC code " 
                                    id="IFSC_code" />
                                    <div className="validation">{this.state.error.ifsc_code}</div>
                                    </div>
                                </div> 
                                
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                    
                                    <input 
                                    // type="number" 
                                    name="pan_num" 
                                    value={this.state.pan_num}
                                    onChange={this.onPanChange}
                                    className="form-control"
                                    placeholder="Enter your PAN" 
                                    id="PAN_number" />
                                    <div className="validation">{this.state.error.pan_num}</div>
                                    </div>
                                </div> 
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                    
                                    <input 
                                    // type="text" 
                                    name="account_num" 
                                    value={this.state.account_num}
                                    onChange={this.onAccChange}
                                    className="form-control"
                                    placeholder="Enter Account number" 
                                    id="password" />
                                    <div className="validation">{this.state.error.account_num}</div>
                                    </div>
                                </div>

                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                    
                                    <input 
                                    // type="number" 
                                    name="confirmAcc_num" 
                                    value={this.state.confirmAcc_num}
                                    onChange={this.onConfirmAccChange}
                                    className="form-control"
                                    placeholder="Confirm Account number " 
                                    id="confirm_Account_number" />
                                    <div className="validation">{this.state.error.confirmAcc_num}</div>
                                    </div>
                                </div> 
                                
                                <div className="form-button-group">
                                <input type="submit" value="submit" className="btn btn-primary btn-block btn-lg" />
                                </div>
                                </form>
                            </div>
                            </div>
       </>                     
   } 

    </>
    )
  }
}


const mapStateToProps = state => (
    {
      user: state.user,
      isAuthenticated: state.isAuthenticated
    }
  )

  const mapDispatchToProps = dispatch => ({
    setbnkDetail: (hasFilled) => dispatch(setbnkDetail(hasFilled)),
  })
export default connect(mapStateToProps, mapDispatchToProps) (Bank_Details)
import React from 'react';

   const LoadingSpinner = () => (
     <div class="wrapper_loading">
      <div class="loading">
        <i className="fa fa-spinner fa-spin" />
      </div>
     </div>
   );

   export default LoadingSpinner;

import React, { Component } from "react";
import axios from 'axios';
import LoadingSpinner from './LoadingSpinner';
import {Pagination, Layout, Alert} from "element-react";
import './shoppable.css';



     
class videoShoppableItems extends Component {
  constructor(props) {
    super(props);
    const { params } = this.props.match;
    this.state = {
        videoStyleId: params.vid.substr(1),
        category: params.cat.substr(1),
        allProductData : [],
        activePage: 1,
        pageSize: 12,
        totalProducts: 0,
        loading: false
        //visibleData:[]
    };
    this.setPageNumber = this.setPageNumber.bind(this);
    this.setPageSize = this.setPageSize.bind(this);

  }
  componentDidMount() {
    this.getCrossSell(this.props);        
  }
    componentWillReceiveProps(nextProps){
    console.log("Will recieve props",this.props.user, nextProps.user)
    //if(Object.keys(this.props.user).length !== Object.keys(nextProps.user).length){
    this.getCrossSell(this.props)
   // }
  } 
   setPageNumber(page_num)
  {
    this.setState(state => {
      state.activePage = page_num;
      state.visibleData = state.allProductData.slice((this.state.activePage - 1)*this.state.pageSize , this.state.activePage*this.state.pageSize);
      return state;
    });
    console.log(this.state.activePage)
  }
  setPageSize(size){
    this.setState(state => {
      state.pageSize = size;
      state.visibleData = state.allProductData.slice((this.state.activePage - 1)*this.state.pageSize , this.state.activePage*this.state.pageSize);
      return state;
    });
  }
  getCrossSell(props)
  {
    this.setState({
      loading: true
    })
    let _this = this;
    axios.get(`/videocrossSell/?video_Id=${this.state.videoStyleId}&shoppable_items=${this.state.category}`)
    .then((response)=> {
      console.log("getting Response",response.data);
      if(response.data.error){
        this.setState({loading: false});
        //_this.props.setUserDetails({});
        Notification({
          type: "error",
          title: "Error",
          message: response.data.error,
          duration: 0
        })
      } else{
        this.setState({
          allProductData : response.data.data,
          totalProducts: response.data.data.length,
          loading: false
          //visibleData: response.data.data.slice((this.state.activePage - 1)*this.state.pageSize , this.state.activePage*this.state.pageSize)
          // ats: response.data.ats
        },()=>{
          console.log("Inside funtion",this.state.allProductData.length, 'total:',this.state.totalProducts);
        });
      }

    }).catch(function (error) {
      _this.setState({loading: false})
    })
  }
  render() {
   /*  let width = document.body.clientWidth;
    let layout="total, sizes, prev, pager, next, jumper";
    if(width && width < 600){
      layout = "sizes, prev, next, jumper"
    } else {
      layout="total, sizes, prev, pager, next, jumper";
    }
    const pager = <Pagination 
        pageSizes={[12,24,36,48,60,72,84,96,108,120]}
        itemsCountPerPage={12}
        total={this.state.totalProducts}
        pageSize={this.state.pageSize}
        currentPage={this.state.activePage}
        onSizeChange={this.setPageSize}
        onCurrentChange={this.setPageNumber}
        layout={layout}
      ></Pagination> */
    return (
      <div>
          <div>
          <div className ="navbar">
            <div className = "heading">
              <span>SHOPPABLE ITEMS</span>
            </div>
          </div>
          {
            this.state.loading ? <LoadingSpinner/>: (
              <div>
                   <div className="products-container">
          {this.state.totalProducts>0 && 
          <div>
          {/* <Layout.Row className="my-pagination">
            {pager}
          </Layout.Row> */}
          <Layout.Row>
            <div className="products-list">
              {this.state.allProductData.map((item) => {
                return (
                  <div className="product-container" key={item.v_id}>
                    <div className="product-image">
                        <a target="_blank" href={this.state.allProductData.length>0 ? `https://myntra.com/${item.product_id}` : `#}`}>
                            <div className="product-image">
                                <img src={`https://us-central1-analog-mantra-247706.cloudfunctions.net/resize/?url=${item.product_image_url}&width=300&quality=60`} alt={item.product_id} />
                            </div>
                            <div className="product-id">
                                STYLE ID &nbsp;{item.product_id}
                            </div>
                        </a>
                    </div>
                  </div>
                )
              })}
            </div>
          </Layout.Row>
         {/*  <Layout.Row className="my-pagination">
            {pager}
          </Layout.Row> */}
          </div>
        }
        
        {/* {this.props.articleType.length<=0 &&
          <div className="my-alert">
            <Alert title="Please select the style gallery category from the dropdown to display" type="error" closable={false} />
          </div>
        } */}
        {
          this.state.allProductData.length<1 && this.state.totalProducts < 1 &&
          <div className="my-alert">
            <Alert title={`No Reviewed Products for ${this.state.category} Category`} type="error" closable={false} />
          </div>
        } 
      </div>   
              </div>
            )
          }
        </div>
      </div>
    );
  }
}
export default videoShoppableItems;

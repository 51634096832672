import React from "react";
import { Navbar, Container, Nav, NavDropdown, Modal } from "react-bootstrap";
import { List, X, Person, Archive, Gear, BoxArrowDownRight, BoxArrowInDownRight, ChevronLeft } from 'react-bootstrap-icons';
import { connect } from 'react-redux'
import {Logout, UserLogout} from '../../actions'
import mixpanel, { init } from 'mixpanel-browser';
import ReactGA from 'react-ga'

import { Link } from 'react-router-dom';
import {trackMixPanelEvent} from './../../api/publicApis';
mixpanel.init("1a8ebbbb5ab0d0b35dc91b5e3b1578e1" ,{
    debug :true,
    ignore_dnt: true
})
//Header Scrolled
// Animated header style
//----
const PublicNavbar = (props) => {
    let target = window.navigator.userAgent.toLowerCase().includes('wv')?'_self':'_blank'; 
    let isWebView = window.navigator.userAgent.toLowerCase().includes('wv') ? true : false;
    const scroll = React.useRef(null);
    const handleScroll = () => {
        let scrolly = window.scrollY;
        if (scrolly > 20) {
            scroll.current.classList.add("is-active")
            //console.log(scroll.current.className)
        }
        else
            scroll.current.classList.remove("is-active")
    }

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return (() => {
            window.removeEventListener('scroll', handleScroll)
        })
    })
    /*const searchFocus = React.useRef(null)
     const toggleSearch = () => {
        const node = search.current.classList.contains('show');
        if (node) {
            search.current.classList.remove("show")
        }
        else {
            search.current.classList.add("show")
            searchFocus.current.focus();
        }
    } */
    const [open, setOpen] = React.useState(false);
    const modalOpen = () => {
        setOpen(true)
    }
    const modalClose = () => {
        setOpen(false)
    }
    const goBack = (e) => {
        e.preventDefault()
        console.log(window.location.pathname)
        if (window.location.pathname.includes('/outfit/')) {
            let username = window.location.pathname.split('/')[2]
            window.location.href = `/${username}`
        }
        else
            window.history.go(-1)
    }
    const handleLogout = () =>{
        props.Logout();
    }
    const handleUserLogout = () =>{
        props.UserLogout();
    }
    return (
        <>
            {/* App Header */}
            <div className="appHeader bg-primary scrolled" ref={scroll}>
                <div className="left">
                    {props.page === 'home' ? <a href="#" className="headerButton" onClick={modalOpen}>
                        <List size={30} />
                    </a>
                        : <a href="#" className="headerButton" onClick={goBack}>
                            <ChevronLeft size={30} />
                        </a>
                    }
                </div>
                <div className="pageTitle">
                    {props.title}
                </div>
            </div>
            <Modal show={open} onHide={modalClose}
                className="panelbox panelbox-left"
                dialogRole="document"
            >
                {/*  <Modal.Header>
                    <a href="#" className="close-sidebar-button" onClick={modalClose}>
                                <X/>    
                    </a>
                </Modal.Header> */}
                <Modal.Body className="p-0">
                    {/*  <div className="profileBox">
                        <div className="image-wrapper">
                            <a href="#" className="close-sidebar-button" onClick={modalClose}>
                                <X/>    
                            </a>
                        </div>
                    </div> */}
                    {/* * profile box */}
                    <ul className="listview flush transparent no-line image-listview mt-2">
                    {props.isUserAuthenticated ?  <>
                        {window.location.pathname != "/lens-daily" &&  window.location.pathname != "/lens-url"  && isWebView && 
                                <li onClick={()=>{
                                    trackMixPanelEvent("Lens daily button clicked");
                                    if(window.NativeAndroid){
                                        window.NativeAndroid.goToLensDailyPage()
                                    }
                                    }}> 
                                    <Link to="#" className='item'>
                                        <div className="in" style={{ 'color': '#e75480' }}>
                                            Lens Home
                                        </div>
                                    </Link>
                                </li> 
                            }
                        <li onClick={()=>{
                            trackMixPanelEvent("Lens history button clicked");
                        }}>
                            <Link to="/lens-history"   className="item">
                                <div className="in" style={{'color': '#e75480'}}>
                                    Lens History
                                </div>
                            </Link>
                        </li>  
                    </>: null}
                    <div class="divider mt-2 mb-3"></div>
                    {props.isAuthenticated && <>
                            <li>
                                <a onClick={()=>{ReactGA.event({category: 'Side Menu', action:"Store public view clicked", lable:"Store public view clicked", value: 1});
                                            mixpanel.track("Store public view clicked");}} href={`/${props.user.username}`}  className="item">
                                    <div className="in">
                                        Store public view                        
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a onClick={()=>{ReactGA.event({category: 'Side Menu', action:"Store backend view clicked", lable:"Store backend view clicked", value: 1});
                                            mixpanel.track("Store backend view clicked");}} href="/shop/mystore"  className="item">
                                    <div className="in">
                                        Store backend view                          
                                    </div>
                                </a>
                            </li>
                        <li>
                            <a onClick={()=>{ReactGA.event({category: 'Side Menu', action:"Edit store profile clicked", lable:"Edit store profile clicked", value: 1});
                                            mixpanel.track("Edit store profile clicked");}} href="/shop/editprofile"  className="item">
                                <div className="in">
                                    Edit store profile                          
                                </div>
                            </a>
                        </li>
                            <li>
                                <a onClick={()=>{ReactGA.event({category: 'Side Menu', action:"Store Analytics clicked", lable:"Store Analytics clicked", value: 1});
                                            mixpanel.track("Store Analytics clicked");}} href="/shop/analytics"   className="item" >
                                    <div className="in">
                                        Store Analytics    
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a onClick={()=>{ReactGA.event({category: 'Side Menu', action:"Sales clicked", lable:"Sales clicked", value: 1});
                                            mixpanel.track("Sales clicked");}} href="/shop/amazonSales"   className="item" >
                                    <div className="in">
                                        Your Sales    
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a onClick={()=>{ReactGA.event({category: 'Side Menu', action:"Bank Details clicked", lable:"Bank Details clicked", value: 1});
                                            mixpanel.track("Bank Details clicked");}} href="/shop/bankDetails"   className="item" >
                                    <div className="in">
                                        Bank Details    
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a onClick={()=>{ReactGA.event({category: 'Side Menu', action:"Blogger Handbook clicked", lable:"Blogger Handbook clicked", value: 1});
                                            mixpanel.track("Blogger Handbook clicked");}} href="https://drive.google.com/file/d/1QNp11LvMI5s5Jn4ReXJ9mRMfQdr-PnkR/view?usp=sharing" target="_blank"  className="item" >
                                    <div className="in">
                                        Blogger Handbook
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#"   className="item" onClick={handleLogout}>
                                    <div className="in">
                                        Store Logout    
                                    </div>
                                </a>
                            </li>
                            <div class="divider mt-2 mb-3"></div>
                            </>
                        }

                    <li>
                                <a onClick={()=>{ReactGA.event({category: 'Side Menu', action:"Explore page clicked", lable:"Explore page clicked", value: 1});
                                            mixpanel.track("Explore page clicked");}} href="/" className="item">
                                    <div className="in">
                                       All Looks
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a onClick={()=>{ReactGA.event({category: 'Side Menu', action:"Bodyshape Looks clicked", lable:"Bodyshape Looks clicked", value: 1});
                                            mixpanel.track("Bodyshape Looks clicked");}} href="/my-looks" className="item">
                                    <div className="in">
                                       Bodyshape Looks
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a onClick={()=>{ReactGA.event({category: 'Side Menu', action:"Saved Looks clicked", lable:"Saved Looks clicked", value: 1});
                                            mixpanel.track("Saved Looks clicked");}} href="/saved-looks" className="item" >
                                    <div className="in">
                                            Saved Looks
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a onClick={()=>{ReactGA.event({category: 'Side Menu', action:"Preferences tab clicked", lable:"Preferences tab clicked", value: 1});
                                            mixpanel.track("Preferences tab clicked");}} href="/user/preferences" className="item" >
                                    <div className="in">
                                            Preferences
                                    </div>
                                </a>
                            </li>
                            
                            {props.isUserAuthenticated ?  
                            <>
                                {target=='_blank' && <li>
                                    <a href="#"   className="item" onClick={handleUserLogout}>
                                        <div className="in">
                                            User Logout    
                                        </div>
                                    </a>
                                </li>} 
                            </> : <>
                            
                        {target=='_blank' &&<li>
                                <a onClick={()=>{ReactGA.event({category: 'Side Menu', action:"User Login tab clicked", lable:"User Login tab clicked", value: 1});
                                            mixpanel.track("User Login tab clicked");}} href= {`/user/login?next=${window.location.pathname}`} className="item">
                                    <div className="in">
                                    User Login
                                    </div>
                                </a>
                            </li>}
                            </>
                        } 
                            <div class="divider mt-2 mb-3"></div>
                           {!props.isAuthenticated && <> <li>
                                <a onClick={()=>{ReactGA.event({category: 'Side Menu', action:"Store Login tab clicked", lable:"Store Login tab clicked", value: 1});
                                            mixpanel.track("Store Login tab clicked");}} href="/shop/login" className="item">
                                    <div className="in">
                                       Store Login
                                    </div>
                                </a>
                            </li>
                            </>}
                            <li>
                                <a onClick={()=>{ReactGA.event({category: 'Side Menu', action:"STL Store tab clicked", lable:"STL Store tab clicked", value: 1});
                                            mixpanel.track("STL Store tab clicked");}} href="/blogger-signup" className="item" >
                                    <div className="in" style={{'color': '#e75480'}}>
                                            Launch Your Store
                                        </div>
                                </a>
                            </li>
                            <li>
                                <a onClick={()=>{ReactGA.event({category: 'Side Menu', action:"ToS, Privacy Policy tab clicked", lable:"ToS, Privacy Policy tab clicked", value: 1});
                                            mixpanel.track("ToS, Privacy Policy tab clicked");}} href="https://drive.google.com/drive/u/3/folders/1wDxiMs0qD6s2H54Fe4Yv6kqUSpzWu7pH" className="item" target="_blank">
                                    <div className="in">
                                    ToS, Privacy Policy
                                        </div>
                                </a>
                            </li>                   
                    </ul>
                </Modal.Body>
            </Modal>
        </>
    );
}
const matStateToProps = state => ({
    isAuthenticated: state.isAuthenticated,
    isUserAuthenticated : state.isUserAuthenticated,
    user: state.user,
})
const mapDispatchToProps = dispatch => ({
    Logout:()=> dispatch(Logout()),
    UserLogout:()=> dispatch(UserLogout()),
})
export default connect(matStateToProps, mapDispatchToProps)(PublicNavbar)
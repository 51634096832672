import React from 'react';
import {
    Modal, TextField, Typography, Grid, FormControlLabel,
    Checkbox, Button,
} from '@material-ui/core';
import Accordion from "react-bootstrap/Accordion";
import Card from 'react-bootstrap/Card';
import "bootstrap/dist/css/bootstrap.min.css";
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import Agreement from './components/agreement';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import './desktop.css';
import mixpanel, { init } from 'mixpanel-browser';
import { bloggerData, sendOTPToMobile, sendVoiceOTPToMobile, verifyNumberOTP, sendOTPToEmail, verifyEmailOTP } from './api/publicApis';
import SideMenu from '../src/components/sidemenu'

mixpanel.init("1a8ebbbb5ab0d0b35dc91b5e3b1578e1" ,{
    debug :true,
    ignore_dnt: true
})

function getQueryParam(param) {
    param = param.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    var regexS = "[\\?&]" + param + "=([^&#]*)",
        regex = new RegExp( regexS ),
        results = regex.exec(document.URL);
    if (results === null || (results && typeof(results[1]) !== 'string' && results[1].length)) {
        return '';
    } else {
        return decodeURIComponent(results[1]).replace(/\+/g, ' ');
    }
};


var inviter = getQueryParam("welcome_blogger");

mixpanel.register({
    "Inviter": inviter,
    "Visitor type" : "Blogger"
});
var distinct_id = getQueryParam("welcome_blogger");
mixpanel.identify(distinct_id);
mixpanel.track("Login page Loaded", {
    "Page Viewed": "Login Page",
});

//modal position
function getModalStyle() {
    const top = '50%'
    const left = '50%'

    return {
        top: top,
        left: left,
        transform: `translate(-${top}, -${left})`,
    };
}
//modal styles
const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: '50%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: 'scroll'
    },
}));

//form sign up data
const formData = {
    name: '',
    email: '',
    number: '',
    instaHandel: '',
    cc: ''
};
export default function DesktopView() {

    const classes = useStyles();
    //states for the function
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const [emailOtpRequested, setEmailOtpRequested] = React.useState(false);
    const [phoneOtpRequested, setPhoneOtpRequested] = React.useState(false);
    const [nameError, setNameError] = React.useState(false);
    const [emailError, setEmailError] = React.useState(false);
    const [ccError, setCcError] = React.useState(false);
    const [numberError, setNumberError] = React.useState(false);
    const [instaError, setInstaError] = React.useState(false);
    const [isCheckedAgreement, setCheckedAgreement] = React.useState(false);
    const [phoneVerified, setPhoneVerified] = React.useState(false);
    const [mailVerified, setMailVerified] = React.useState(false);
    const [resentOtp, setResentOtp] = React.useState(false)
    const [openmodal, setOpenmodal] = React.useState(false)
    const [scroll, setScroll] = React.useState('paper');
    const descriptionElementRef = React.useRef(null);
    const [validateMessage, setValidateMessage] = React.useState()
    const [mailOTPMatch, setMailOTPMatch] = React.useState(false)
    const [phoneOTPMacht, setPhoneOTPMatch] = React.useState(false)
    React.useEffect(() => {
        if (openmodal) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [openmodal]);

    //open close modal
    const handleOpen = () => {
        setOpen(true);
        // mixpanel.identify(distinct_id);
            mixpanel.track("opened sign up page",{
            })
    };
    const handleClose = () => {
        setOpen(false);
        setResentOtp(false);
        Object.keys(formData).forEach(key => formData[key] = '');
        setNameError(false);
        setNumberError(false);
        setEmailError(false);
        setCcError(false);
        setCheckedAgreement(false);
        setEmailOtpRequested(false);
        setPhoneOtpRequested(false);
        setPhoneVerified(false);
        setMailVerified(false);
        setValidateMessage()
        setPhoneOTPMatch(false)
        setMailOTPMatch(false)
    };
    const closeModal = () => {
        setOpenmodal(false)
    }

    //form submit function
    const formSubmit = async (event) => {
        event.preventDefault();
        if (!nameError && !emailError && mailVerified && !numberError && !instaError && isCheckedAgreement) {
            let res = await bloggerData(formData)
            if (res.data.data === 'success')
                setValidateMessage(<p style={{ color: 'green' }}>Sucessfully Signup.</p>)
            else if (res.data.data == 'exists')
                setValidateMessage(<p style={{ color: 'red' }}>This user is already registered.</p>)
            else setValidateMessage(<p style={{ color: 'red' }}>Opps! Something went Wrong.</p>)
            mixpanel.identify(distinct_id);
            mixpanel.track("Successful sigup",{
                name: formData.name,
                email: formData.email,
                number: formData.number,
                instaHandel: formData.instaHandel,
                cc: formData.cc,
                // "User ID": distinct_id,
            })
            mixpanel.alias(distinct_id)
            mixpanel.people.set({
                "company": "sml_blogger",
                "$name": formData.name,
                "$email": formData.email,
                "$phone": formData.number,
                        })
        }
        else {
            setValidateMessage(<p style={{ color: 'red' }}>Opps! All the form fileds are not entererd or some values are incorrect.</p>)
        }
    }

    //open agreement on click
    const openAgreement = event => {
        event.preventDefault();
        //setFormmodal(false);
        setOpenmodal(true);
        setScroll('paper');

    }
    //sent Phone OTP
    const verifyPhone = async () => {
        if (!numberError && formData.number != '' && !ccError && formData.cc != '') {
            setPhoneOtpRequested(true);
            let res = await sendOTPToMobile(formData.cc, formData.number);
            if (res.data.statusText === 'OK') {
                setResentOtp(true)
            }
        }
    }
    //sent Email OTP
    const verifyEmail = async () => {
        if (!emailError && formData.email != '') {
            setEmailOtpRequested(true)
            let res = await sendOTPToEmail(formData.email)
            if (res.data.statusText == 'OK') {
            }
        }
    }
    //verify OTP
    const verifyOtp = async (event, type) => {
        console.log(event.target.value)
        if (type === 'phone' && event.target.value.match(/[0-9]{4}/)) {
            let res = await verifyNumberOTP(formData.cc, formData.number, event.target.value);
            console.log(res)
            if (res.data.statusText === 'OK') {
                setPhoneVerified(true)
                setResentOtp(false)
            }
            else setPhoneOTPMatch(true)
        }
        if (type === 'email' && event.target.value.match(/[0-9]{4}/)) {
            let res = await verifyEmailOTP(formData.email, event.target.value)
            if (res.data.statusText === 'OK')
                setMailVerified(true)
            else setMailOTPMatch(true)
        }
    }

    //check the validity of form
    const handelFormChange = (e, type) => {
        if (type === 'name') {
            if (e.target.value != '') {
                setNameError(false)
                formData.name = e.target.value;
            }
            else setNameError(true);
        }
        else if (type === 'phone') {
            if (e.target.value.match(/^[0-9]{10}$/g)) {
                setNumberError(false);
                formData.number = (e.target.value).toString();
            }
            else {
                setResentOtp(false)
                setPhoneVerified(false)
                setPhoneOtpRequested(false);
                setNumberError(true);
            }
        }
        else if (type === 'email') {
            if (e.target.value.match(/^[A-Za-z0-9+-._]+@[A-Za-z0-9]+\.[a-zA-Z]+$/)) {
                setEmailError(false)
                formData.email = e.target.value;
            }
            else {
                setMailVerified(false)
                setEmailOtpRequested(false)
                setEmailError(true)
            }
        }
        else if (type === 'insta') {
            if (e.target.value.match(/^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/)) {
                formData.instaHandel = e.target.value;
                setInstaError(false)
            }
            else setInstaError(true)
        }
        else {
            if (e.target.value.match(/[0-9]{2}/)) {
                formData.cc = e.target.value;
                setCcError(false)
            }
            else setCcError(true)
        }
    }
    //request voice OTP
    const requestVoiceOtp = async () => {
        await sendVoiceOTPToMobile(formData.cc, formData.number)
    }
    //click agreement
    const agreementClick = event => {
        if (event.target.innerHTML === 'Disagree') {
            setCheckedAgreement(false);
            setOpenmodal(false);
        }
        else {
            setCheckedAgreement(true);
            setOpenmodal(false);
        }
    }

    return (
        <>
            <SideMenu page = 'home'/>
        <div>
            <div className="header">
                <Grid container>
                    <Grid item sm={6} lg={6}>
                        <div className="header-left">
                            <img src='./static/images/desktop/women-chill.gif' alt="left-header" />
                        </div>
                    </Grid>
                    <Grid item sm={6} lg={6}>
                        <div className="header-right-up-d">
                            <img src='./static/images/desktop/animated header.gif' alt="animated-header" />
                        </div>
                        <div className="header-right-mid">
                            <img src='./static/images/desktop/insta.gif' alt="animated-header-insta" />
                        </div>
                        <div className="header-right-down">
                            <img src='./static/images/desktop/d_06.gif' alt="animated-header-waitlist" onClick={handleOpen} />
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="intro">
                <img src='./static/images/desktop/d_10.png' alt="intro" />
            </div>
            <div className="old-vs-new">
                <img src='./static/images/desktop/d_14.gif' alt="old-vs-new" />
            </div>
            <div className="partner-profit">
                <Grid container>
                    <Grid sm={6} lg={6}>
                        <div className="profit-margin">
                            <img src='./static/images/desktop/d_21.png' alt="profit-margin" onClick={handleOpen} />
                        </div>
                    </Grid>
                    <Grid sm={6} lg={6}>
                        <div className="partners">
                            <img src='./static/images/desktop/d_18.gif' alt="partners" />
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className='faq'>
                <img src='./static/images/desktop/d_26.gif' alt="faq" />
            </div>
            <div className="faq-question">
                <div className="questions">
                    <Accordion className="faqscss">
                        <Accordion.Item className="faqscss  pink-bg" eventKey="0">
                            <Accordion.Header className="faqscss"> <span className="d-questions">Who are we?</span> </Accordion.Header>
                            <Accordion.Body className="faqscss">
                                ShopTheLooks is a fashion-tech product that automatically turns photo &amp; reels posted by influencers into shop-the-look. Thus enabling the creators to monetize their entire instagram content.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className="faqscss" eventKey="1">
                            <Accordion.Header className="faqscss"> What % of profit do I earn per sale?</Accordion.Header>
                            <Accordion.Body className="faqscss">
                                You will earn upto 10% of every sale that happens through your storefront.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className="faqscss  pink-bg" eventKey="2">
                            <Accordion.Header className="faqscss">When will the money be credited to my bank account?</Accordion.Header>
                            <Accordion.Body className="faqscss">
                                After a sale is verified by our eCommerce partner, the respective profit will be credited to your bank account in 31 days.
                                It takes this much time because eCommerce companies have a 30-45 days return window. Only after the return window is closed can the accumulated profit can be transferred to your account.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className="faqscss" eventKey="3">
                            <Accordion.Header className="faqscss"> What is the signup process?</Accordion.Header>
                            <Accordion.Body className="faqscss">
                                Please fill up the form and provide us the required information to complete the signup process. We will notify you over email once your store is live.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className="faqscss  pink-bg" eventKey="4">
                            <Accordion.Header className="faqscss">Do I have to post my content separately on your platform?</Accordion.Header>
                            <Accordion.Body className="faqscss">
                                No. The beauty of signing up with ShopTheLook is that all your content gets automatically pulled from your public Instagram profile and becomes instantly shoppable. </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className="faqscss" eventKey="5">
                            <Accordion.Header className="faqscss"> Can I add my own products?</Accordion.Header>
                            <Accordion.Body className="faqscss">
                                At present, this feature is unavailable. </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className="faqscss  pink-bg" eventKey="6">
                            <Accordion.Header className="faqscss"> How do I get in touch with you?</Accordion.Header>
                            <Accordion.Body className="faqscss">
                                Write to us -
                                <a href="mailto:influencers@shopthelooks.com" target="_blank">
                                    &nbsp;influencers@shopthelooks.com
                                </a>
                                <div>
                                    <pre className="address">{

                                        `Address - Shop The Looks Tech Pvt. Ltd.\n91springboard, B1/H3, Mohan C0-Operative, \n New Delhi, Delhi-110044`}
                                    </pre>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
            <div className="footer">
                <footer>
                    <img src='./static/images/desktop/brand-landing-6.png' alt="footer-img" />
                </footer>
            </div>
            {/* <div className="toast-box toast-bottom show bg-info" style={{bottom : '0', justifyContent: 'center'}}>
                    <div className="in">
                    <a href='/' className='p2' ><div style={{width: '25px', height: '25px'}} ><img src= '/static/images/explore/earth.png' ></img></div></a>
                        <div className="text" style={{marginLeft: '7px'}} >
                        <a href='/' className='text-white' > Click to explore Shop The Looks world</a>
                        </div>
                    </div>
            </div>  */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={classes.paper} style={modalStyle}>
                    <form>
                        <Typography id="simple-modal-title" component="h1" variant="h6" align="center">Sign Up</Typography>
                        <div id="simple-modal-description">
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} lg={12}>
                                    <TextField
                                        required id="name"
                                        name="name"
                                        label="Your Name"
                                        onChange={e => handelFormChange(e, 'name')}
                                        helperText={nameError ? 'Name field cannot be empty.' : ''}
                                        error={nameError}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item sm={10} lg={10}>
                                    <TextField
                                        required id="email"
                                        name="email" label="Email"
                                        onChange={e => handelFormChange(e, 'email')}
                                        helperText={emailError ? 'Email field is empty or not in correct format' : ''}
                                        error={emailError}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item sm={2} lg={2}>
                                    {(mailVerified) ? <CheckBoxIcon style={{ color: 'green' }} />
                                        : (emailOtpRequested) ? <TextField required id="mail" name="mail" label="OTP" inputProps={{ maxLengt: 4 }} onChange={e => verifyOtp(e, 'email')} error={mailOTPMatch} />
                                            : <Button variant="contained" onClick={e => verifyEmail(e)}>Verify</Button>
                                    }
                                </Grid>
                                <Grid item xs={2} sm={2} lg={2}>
                                    <TextField
                                        required id="cc"
                                        name="cc"
                                        label="CC"
                                        inputProps={{ maxLength: 2 }}
                                        onChange={e => handelFormChange(e, 'cc')}
                                        helperText={ccError ? 'CC is required' : ''}
                                        error={ccError}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item sm={8} lg={8}>
                                    <TextField
                                        required id="number"
                                        name="number"
                                        label="Mobile No."
                                        inputProps={{ maxLength: 10 }}
                                        onChange={e => handelFormChange(e, 'phone')}
                                        helperText={numberError ? 'Phone Number cannot be empty and must be of 10 digits.' : ''}
                                        error={numberError}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item sm={2} lg={2}>
                                    {(phoneVerified) ? <CheckBoxIcon style={{ color: 'green' }} />
                                        : (phoneOtpRequested) ? <TextField required id="phone_otp" name="phone_otp" label="OTP" inputProps={{ maxLength: 4 }} onChange={e => verifyOtp(e, 'phone')} error={phoneOTPMacht} />
                                            : <Button variant="contained" onClick={e => verifyPhone(e)}>Verify</Button>
                                    }
                                </Grid>
                                <Grid item sm={6} lg={6}>
                                    <Typography>CC = Country Code</Typography>
                                </Grid>
                                <Grid item sm={6} lg={6} style={{ display: "flex", flexDirection: "row-reverse" }}>
                                    {resentOtp && !phoneVerified && <Button variant="contained" onClick={requestVoiceOtp}
                                        helperText="By clicking on resend you will recieve OTP via call"
                                    >
                                        Resend Otp</Button>
                                    }
                                </Grid>
                                <Grid item sm={12} lg={12}>
                                    <TextField
                                        required id="instaHandle"
                                        name="instaHandle"
                                        label="Instagram Handle"
                                        onChange={e => handelFormChange(e, 'insta')}
                                        helperText={instaError ? 'Instagram Handle is empty or not in correct format' : ''}
                                        error={instaError}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12}>
                                    <FormControlLabel control={<Checkbox color="secondary" name="agreement" value="yes" checked={isCheckedAgreement} onClick={()=>{ setCheckedAgreement(!isCheckedAgreement)}}/>}
                                        label={
                                            <div>
                                                <span>I accept</span> <a href="#" onClick={e => openAgreement(e)}> the agreement.</a>
                                            </div>
                                        }
                                    />
                                </Grid>
                                <div style={{ textAlign: 'center', width: '100%' }}>
                                    <Button variant="contained"
                                        style={{ width: "70%" }}
                                        onClick={formSubmit}
                                    >
                                        Sign Up
                                    </Button>
                                </div>
                                <div style={{ textAlign: 'center', width: '100%' }}>
                                    {validateMessage}
                                </div>
                            </Grid>
                        </div>
                    </form>
                </div>
            </Modal>
            <Dialog
                open={openmodal}
                onClose={closeModal}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth='lg'
                maxWidth='lg'
            >
                <DialogTitle id="scroll-dialog-title">
                    <span style={{ display: 'flex', float: 'right', cursor: 'pointer', opacity: 0.5 }} onClick={closeModal}>X</span>
                </DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        style={{ width: "100%" }}
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <Agreement />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={e => agreementClick(e)} color="primary">
                        Disagree
                    </Button>
                    <Button variant="contained" onClick={e => agreementClick(e)} color="primary">
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    </>
    );
}

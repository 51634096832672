import React, {useState} from 'react'
import imgsrc from '../images/vector4.png';
import '../styles/style.css'
import '../styles/login.css'
import {connect} from "react-redux";
import {LoginAction} from '../actions'
import { Redirect } from 'react-router';
import { history } from 'react-router-dom';
class Login extends React.Component
{
    constructor(props){
      super(props)
      this.state = {
        email:'',
        password:'',
        mailError:'',
        pwdError:false,
        loginErr:''
      }
      this.handleForm = this.handleForm.bind(this);
      this.setformData = this.setformData.bind(this)
    }
    handleForm(event){
      let validate = true;
      event.preventDefault();
      if(this.state.password === ''){
        validate = false;
        this.setState({
          pwdError: true
        })
      }
      if(this.state.email === ''){
        validate = false;
        this.setState({
          mailError:<p style={{color:'red'}}>email field cannot be empty.</p>
        })
      }
      if(!this.state.email.match(/^[A-Za-z0-9+-._]+@[A-Za-z0-9]+\.[a-zA-Z]+$/)){
        validate = false;
        this.setState({
          mailError:<p style={{color:'red'}}>Invalid email. Please enter valid email.</p>
        })
      }
      if(validate){
        this.props.LoginAction(this.state.email,this.state.password);
      }
    }
    setformData(e,value){
      this.setState({
        pwdError:false,
        mailError:''
      })
      if(value === 'pwd'){
        this.setState({
          password: e.target.value
        })}
      else{
        console.log(e.target.value)
        this.setState({
          email: e.target.value
        })
      }
      return;
    }
    componentDidUpdate(prevState, prevProps){
      if(this.props.isAthenticated){
        window.location.href = '/shop'
      }
      if(this.props.loginStatus){
        if(this.props.loginText === 'invalid'){
          this.setState({
            loginErr: 'email or password is incorrect.'
          })
        }
        else{
          this.setState({
            loginErr: 'email does not exist.'
          })
        }
      }
    }
    render()
    {
        return (
            <>
            {/* <div id="loader">
              <div className="spinner-border text-primary" role="status" />
            </div> */}
            <div id="appCapsule" className="pt-0">
              <div className="login-form mt-1">
                <div className="section">
                  <img
                    src={imgsrc}
                    alt="image"
                    className="form-image"
                  />
                </div>
                <div className="section mt-1">
                  <h1>Get started</h1>
                  <h4>Fill the form to log in</h4>
                </div>
                <div className="section mt-1 mb-5">
                  <form>
                    <div className="form-group boxed">
                      <div className="input-wrapper">
                        <input
                          type="email"
                          className="form-control"
                          id="email1"
                          placeholder="Email address"
                          onChange={e=>this.setformData(e,'email')}
                        />
                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                      {this.state.mailError}
                    </div>
                    <div className="form-group boxed">
                      <div className="input-wrapper">
                        <input
                          type="password"
                          className="form-control"
                          id="password1"
                          placeholder="Password"
                          autoComplete="off"
                          onChange={e=>this.setformData(e,'pwd')}
                        />
                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                      {this.state.pwdError && <p style={{color:'red'}}>Passsword field cannot be empty</p>}
                    </div>
                    <div className="form-links mt-2">
                      <div>
                        <a href="/shop/register">Register Now</a>
                      </div>
                      <div>
                        <a href="/shop/forgot-password" className="text-muted">
                          Forgot Password?
                        </a>
                      </div>
                    </div>
                    {console.log(this.props.isAuthenticated, this.props.loginText)}
                    {this.state.loginErr}
                    {this.props.isAuthenticated?<Redirect to ='/shop/mystore'/>
                      :this.props.loginText === 'invalid'?<p style={{color:'red'}}>email or password incorrect.</p>
                      :this.props.loginText==="not_exist"?<p style={{color:'red'}}>email does not exists.</p>:''
                    }
                    
                    <div className="form-button-group">
                      <button type="submit" className="btn btn-primary btn-block btn-lg" onClick={this.handleForm}>
                        Log in
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </>
        )
    }
}
const mapStateToProps = state => ({
  loginStatus: state.loginStatus,
  loginText: state.loginText,
  isAuthenticated: state.isAuthenticated
})
const mapDisparchToProps = (dispatch) =>({
  LoginAction : (email,pwd) => dispatch(LoginAction(email,pwd))
})
export default connect(mapStateToProps, mapDisparchToProps)(Login);

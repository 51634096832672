import React, {useState} from 'react'
import axios from 'axios'
import {userRegister, checkUsername} from '../api/publicApis';
import '../styles/style.css'
import './register.css'
import swal from 'sweetalert';

class Register extends React.Component
{
  constructor() {
    super();
    this.state = {
      input: {},
      errors: {}
    };     

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({
      input
    });
  }

 async handleSubmit(event) {
   let errors = {}
    event.preventDefault();
    let flag = false
    let exist = await checkUsername(this.state.input.username,'na')
    if(exist.data.statustext ==='exist'){
      flag=false;
      errors["username"] = "username already exist."
      this.setState({
        errors:errors
      })
    }
    else{
      flag=true
    }
    if(flag && this.validate()){
        // console.log("Value",this.state);
        let input = {};                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
        input["name"] = this.state.input.name;
        input["email"] = this.state.input.email;
        input["Instahandle"] = this.state.input.Instahandle;
        input["username"] = this.state.input.username;
        input["pnumber"] = this.state.input.pnumber;
        input["password"] = this.state.input.password;
        input["confirm_password"] = this.state.input.confirm_password;
        this.setState({input:input});
        let result = await userRegister(input)
        console.log(result);
        if(result.data.statusText === 'OK')
        {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
          swal({
            text: "Successfully registered. Please login to Continue!",
          });
          setTimeout(window.location.href='/shop/login',5000)
        }
        else if (result.data.statusText ==="Exists")
        {
          swal("This user is already registered.")
        }
        else
          swal("Oops!", "Something went wrong!", "error");
    }
  }

  validate(){
      let input = this.state.input;
      let errors = {};
      let isValid = true;   
      if (!input["name"]) {
        isValid = false;
        errors["name"] = "Please enter your full name";
      }
      if (!input["Instahandle"]) {
        isValid = false;
        errors["Instahandle"] = "Please enter your Instagram handle";
      }
      if (!input["username"]) {
        isValid = false;
        errors["username"] = "Please enter a username";
      }
      if (!input["pnumber"]) {
        isValid = false;
        errors["pnumber"] = "Please enter your mobile number.";
      }

      if (!input["email"]) {
        isValid = false;
        errors["email"] = "Please enter your email Address.";
      }
  
      if (typeof input["email"] !== "undefined") {        
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(input["email"])) {
          isValid = false;
          errors["email"] = "Please enter valid email address.";
        }
      }

      if (!input["password"]) {
          isValid = false;
          errors["password"] = "password cannot be empty.";
      }
  
      if (!input["confirm_password"]) {
        isValid = false;
        errors["confirm_password"] = "Re-enter your password";
      }

      if (typeof input["password"] !== "undefined") {
        var pwdpattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
        if(!pwdpattern.test(input["password"])){
          isValid = false;
          errors["password"] = "Password must contain atleast 8 characters, one uppercase letter, one lowercase letter, one number and one special character.";
        }
      }
      if (typeof input["password"] !== "undefined" && typeof input["confirm_password"] !== "undefined") {
        if (input["password"] !== input["confirm_password"]) {
          isValid = false;
          errors["password"] = "Passwords don't match.";
        }
      }

      this.setState({
        errors: errors
      });
      return isValid;
  }

  render() {
    return (
<div>
<div className="appHeader no-border transparent position-absolute">
    <div className="left">
      <a href="#" className="headerButton goBack">
        <ion-icon name="chevron-back-outline" />
      </a>
    </div>
    <div className="pageTitle" />
    <div className="right">
      <a href="/login" className="headerButton">
        Login
      </a>
    </div>
  </div>
  <div id="appCapsule">
     <div className="login-form">
       <div className="section">
         <h1>Register</h1>
         <h4>Fill the form to join us</h4>
       </div>
       <div className="section mt-2 mb-5"></div>
        <form onSubmit={this.handleSubmit} id="registerF" className="needs-validation">
        <div className="form-group boxed">
            <div className="input-wrapper">
            {/* <label for="username">Fullname:</label> */}
            <input 
              className="form-control"
              type="text" 
              name="name" 
              value={this.state.input.name}
              onChange={this.handleChange}
              placeholder="Enter fullname" 
              id="name" />
              <i className="clear-input">
                <ion-icon name="close-circle" />
              </i>
              <div className="validation">{this.state.errors.name}</div>
          </div>
          </div>

          <div className="form-group boxed">
            <div className="input-wrapper">
            {/* <label for="email">Email Address:</label> */}
            <input 
              type="text" 
              name="email" 
              value={this.state.input.email}
              onChange={this.handleChange}
              className="form-control" 
              placeholder="Enter email" 
              id="email" />
              <div className="validation">{this.state.errors.email}</div>
          </div>
          </div>
          <div className="form-group boxed">
            <div className="input-wrapper">
            {/* <label for="password">Confirm Password:</label> */}
            <input 
              type="text" 
              name="Instahandle" 
              value={this.state.input.Instahandle}
              onChange={this.handleChange}
              className="form-control"
              placeholder="Enter Instahandle" 
              id="Instahandle" />
              <div className="validation">{this.state.errors.Instahandle}</div>
              </div>
          </div> 
          <div className="form-group boxed">
            <div className="input-wrapper">
            {/* <label for="password">Confirm Password:</label> */}
            <input 
              type="text" 
              name="username" 
              value={this.state.input.username}
              onChange={this.handleChange}
              className="form-control"
              placeholder="Enter a Username" 
              id="username" />
              <div className="validation">{this.state.errors.username}</div>
              </div>
          </div> 
          <div className="form-group boxed">
            <div className="input-wrapper">
            {/* <label for="password">Confirm Password:</label> */}
            <input 
              type="tel" 
              name="pnumber" 
              value={this.state.input.pnumber}
              onChange={this.handleChange}
              className="form-control"
              placeholder="Enter your mobile number(without +91)" 
              id="pnumber" />
              <div className="validation">{this.state.errors.pnumber}</div>
              </div>
          </div> 
          <div className="form-group boxed">
            <div className="input-wrapper">
            {/* <label for="password">Password:</label> */}
            <input 
              type="password" 
              name="password" 
              value={this.state.input.password}
              onChange={this.handleChange}
              className="form-control"
              placeholder="Enter password" 
              id="password" />
              <div className="validation">{this.state.errors.password}</div>
              </div>
          </div>

          <div className="form-group boxed">
            <div className="input-wrapper">
            {/* <label for="password">Confirm Password:</label> */}
            <input 
              type="password" 
              name="confirm_password" 
              value={this.state.input.confirm_password}
              onChange={this.handleChange}
              className="form-control"
              placeholder="Enter confirm password" 
              id="confirm_password" />
              <div className="validation">{this.state.errors.confirm_password}</div>
              </div>
          </div> 
        
          <div className="form-button-group">
          <input type="submit" value="Register" className="btn btn-primary btn-block btn-lg" />
           </div>
        </form>
      </div>
      </div>
      </div>
    );
  }
}
export default Register
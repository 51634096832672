import React from 'react';
import { Component } from 'react';
import { Col, Container, Row, Image, Card, Button, Modal } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas'
import imagesrc from '../images/demo.jpg';
import cross from '../images/croos.jpeg';
import tempimage from '../images/blogger-store-header.png';
import Navbar from './navbar';
import '../styles/style.css'
import '../styles/bloggerShop.css';
import { X, ThreeDotsVertical } from 'react-bootstrap-icons'
import { connect } from "react-redux";
import { fetchBloggerCounts } from '../actions'
import { getImageData } from '../api/publicApis'
import { getBloggerCounts, getBloggerSubscriberCount } from './../api/publicApis';
import Loader from './Loader'
import { Redirect } from 'react-router';

class bloggerCounterDashboard extends Component {
    constructor(props) {
        super(props)
        // props.getCounts(this.props.user.instahandle);
        console.log(this.props)
        this.state = {
            action: '',
            bloggercounts:{
                username:"",
                total:0,
                pageVisitCount:[],
            },
            totalSubscriber:0,
            insta:this.props.user.instahandle
        };
    }

    componentDidMount(){
        getBloggerCounts(this.state.insta ).then( response=>{
            console.log(response.data)
            this.setState({bloggercounts:response.data.result})
        })
        getBloggerSubscriberCount(this.state.insta ).then( response=>{
            console.log(response.data)
            this.setState({totalSubscriber:response.data.result})
        })
    }
    totalsavedCount = () => {
        const content = this.state.bloggercounts.pageVisitCount;
        let sum = 0;
        content.forEach(function(obj){
            sum += obj.savedCount;
          });
          return sum        
}

    render() {
        const score = this.totalsavedCount()
        const totalSubscriber = this.state.totalSubscriber

        console.log(this.props)
        return (
            <>

                {!this.props.isAuthenticated ? <Redirect push to='/shop/login' />
                    : <>
                        <Navbar title={this.props.user.name + '\'s store'} page="home" />
                        <div className="appCapsule">
                            <div style={{width:"80%",margin:"10%"}} >
                            <h3>Profile Visits : {this.state.bloggercounts.total}</h3>
                            <h3>Total Subcriber : { totalSubscriber ? <span>{totalSubscriber}</span>: 0}</h3>
                            <h3>Total Saved Looks : { score ?<span>{score}</span> :0}</h3>
                            <div class="table-responsive">
                                <table className='table table-responsive table-striped' style={{marginTop:"50px",width:"100%"}}>
                                    <thead  style={{textAlign:"center",fontWeight:"600",borderBottom:"2px solid rgb(120,120,120)",borderTop:"2px solid rgb(120,120,120)"}}> 
                                            <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Outfit</th>
                                            <th scope="col"></th>
                                            <th scope="col">Visits</th>
                                            <th scope="col">Saved Looks</th></tr>
                                    </thead>
                                    <tbody>
                                        {this.state.bloggercounts.pageVisitCount.map((outfit,i) => {
                                            return (
                                                <>
                                                {(outfit.showToPublic) ? <>
                                                <tr key={outfit.outfit} style={{textAlign:"center"}}>
                                                    <td>{i}</td>
                                                    <td><a href={`/outfit/${this.state.bloggercounts.username}/${outfit.outfit}`} target='_blank'>{outfit.outfit}</a></td>
                                                    <td><a href={`/outfit/${this.state.bloggercounts.username}/${outfit.outfit}`} target='_blank'>
                                                        <img className='outfitImg' src={outfit.img_Url}></img></a></td>
                                                    <td>{outfit.count}</td>
                                                    <td>{outfit.savedCount}</td>
                                                </tr>
                                                </> :null}
                                                </>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            </div>
                            
                        </div>
                    </>
                }
                


            </>
        )
    }
}
const mapStateToProps = state => ({
    isAuthenticated: state.isAuthenticated,
    user: state.user
});
const mapDispatchToProps = dispatch => ({
    // getCounts: (insta) => dispatch(fetchBloggerCounts(insta)),

});
export default connect(mapStateToProps, mapDispatchToProps)(bloggerCounterDashboard);

import React from 'react';
import { Component } from 'react';
import { Col, Container, Row, Image, Card, Button, Modal } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas'
import imagesrc from '../../images/demo.jpg';
import cross from '../../images/croos.jpeg';
import tempimage from '../../images/blogger-store-header.png';
import Navbar from './../navbar';
import '../../styles/style.css'
import '../../styles/bloggerShop.css';
import { X, ThreeDotsVertical } from 'react-bootstrap-icons'
import { connect } from "react-redux";
import { fetchCrossSellDataCurator, fetchProducts, setCrossSellVisibility , fetchOutfitMetadataCurator } from '../../actions'
import { Rating } from 'react-simple-star-rating'
import { updateOutfitMetadata } from '../../api/publicApis'
import { getImageData } from './../../api/publicApis'
import axios from 'axios'
import Loader from './../Loader'
import { Redirect } from 'react-router';

class curatorShop extends Component {
    constructor(props) {
        super(props)
        
        const { outfitid , bloggerUsername } = this.props.match.params;
        props.getCrossSell(bloggerUsername , outfitid)
        props.fetchOutfitMetadataCurator(bloggerUsername , outfitid)
        this.state = {
            show: false,
            open: false,
            top: 0,
            left: 0,
            type: '',
            sml_id: '',
            product_id: '',
            cat: '',
            action: '',
            // index: params.index.substr(1),
            checked: false,
            username : bloggerUsername,
            outfit_id : outfitid,
            occasionsKeys : ["Officewear","Casual","Party","Sportwear","Loungewear"
            ,"Date Wear","Ethnic casual","Indo-western"],
            curatorLog:[],
            showToCurateButton: true
        };
        this.updateLog();
        this.handleClose = this.handleClose.bind(this)
        this.toggleCrossSell = this.toggleCrossSell.bind(this)
        this.closeToggle = this.closeToggle.bind(this)
        this.setVisibility = this.setVisibility.bind(this)
        this.getImage = this.getImage.bind(this)
    }
    componentDidMount() {
        // let index = this.state.index.split(':').pop()
        // let timer = setInterval(() => {
        //     if (document.querySelector('#list'+index)) {
        //         document.querySelector('#list'+index).scrollIntoView(true)
        //         clearInterval(timer);
        //     }
        // }, 500);
    }
    handleClose(e,sml_id, cat) {
        e.preventDefault();
        if (this.state.show) {

            this.setState({ show: false })
        }
        else {
            this.setState({
                show: true,
                sml_id,
                cat
            })
            this.props.getProducts(sml_id, cat)
        }
    }
    //toggle crossSell/product visibility modal
    toggleCrossSell(e, type, cat, id, action) {
        if (type === 'crossSell') {
            this.setState({
                top: e.clientY,
                left: e.clientX,
                open: true,
                type,
                sml_id: id,
                cat,
                action
            })

        }
        else if (type === 'product') {
            this.setState({
                top: e.clientY,
                left: e.clientX,
                open: true,
                type,
                product_id: id,
                action
            })
        }
        if (action === 'on') {
            this.setState({
                checked: false
            })
        }
        else if (action === 'off') {
            this.setState({
                checked: true
            })
        }
        this.updateLog()
    }
    async getImage(product_id) {
        let image = axios.get(`/sml/getImage/?id=${product_id}`)
        return image
    }
    //close crossSell/product modal
    closeToggle() {
        this.setState({
            open: false
        })
    }
    //set visibil(ity on/off
    setVisibility(e) {
        this.setState({
            open: false
        })
        if (this.state.action === 'off' && !e.target.checked) {
            this.props.setCrossSellVisibility(
                this.state.type,
                this.props.sml_id,
                this.state.product_id,
                this.props.user.instahandle,
                this.state.cat,
                this.state.action
            )
        }
        else if (this.state.action === 'on' && e.target.checked) {
            this.props.setCrossSellVisibility(
                this.state.type,
                this.props.sml_id,
                this.state.product_id,
                this.props.user.instahandle,
                this.state.cat,
                this.state.action
            )
        }
        this.updateLog()
    }

    updateLog = async()=>{
        let data = await axios.get(`/curator/logs?username=${this.state.username}&outfit=${this.state.outfit_id}`);
        let logs = data.data.logs
        this.setState({curatorLog: logs})
    }

    async handleMetadataChange(type,field,value){
        let queryField,queryValue;
        if(type==="occasions"){
            queryField = "occasions"
            queryValue = {...this.props.outfitMetadata.occasions}
            queryValue[field] = value
        } else {
            queryField = field
            queryValue = value
        }
        await updateOutfitMetadata(this.state.username,this.state.outfit_id,queryField,queryValue)
        if(type=="toCurate")
            this.setState({showToCurateButton: false})
        this.props.fetchOutfitMetadataCurator(this.state.username , this.state.outfit_id)
        await this.updateLog()
    }
    render() {
        const modalStyle = {
            top: this.state.top + 'px',
            left: Math.abs(this.state.left - 126) + 'px',
            width: '160px'
        }
        return (
            <>
                {!this.props.isAuthenticated ? <Redirect push to='/curator/login' />
                    : this.props.loadingState ? <Loader type="main" />
                        : <>
                            <Navbar title="Shop" page="shop" />
                            <div className="appCapsule">
                                <div className="pt-2 pb-2 ps-1 pe-1">
                                    {this.props.crossSelldata.map((element, index) => {
                                        return (
                                            <>
                                                <div className="cross-sell mt-1" id={"list"+index}>
                                                    <div className="street_style">
                                                        <img src={element.img_Url} alt="image" className="imagedbox" style={{ maxWidth: '100%' }} />
                                                    </div>
                                                    < div>
                                                        <div className='m-3'>
                                                            <h3>Occasions</h3>
                                                            <Row>
                                                                {this.state.occasionsKeys.map(key=>{
                                                                    return (
                                                                        <Col className='col-6' style={{textAlign:"left"}}>
                                                                            <div className='form-check form-check-inline'>
                                                                            <input type="checkbox" className='form-check-input' checked={this.props.outfitMetadata&&this.props.outfitMetadata.occasions[key]} /> 
                                                                                <label class="checkbox-inline form-check-label" onClick={ ()=>{ this.handleMetadataChange("occasions",key,!this.props.outfitMetadata.occasions[key]) }}>
                                                                                {key}
                                                                                </label>
                                                                            </div>
                                                                        </Col>
                                                                    )
                                                                })}
                                                            </Row>
                                                        </div>
                                                        <div className='m-3'>
                                                            <h3>Outfit Rating</h3><br/>
                                                            <div class="btn-group" role="group">
                                                                
                                                                {["one","two","three"].map(rating=>
                                                                    <>
                                                                        <input type="radio" class="btn-check" name="ratings" id={rating} checked={this.props.outfitMetadata.ratings===rating} /> 
                                                                        <label class="btn btn-outline-secondary" for={rating} onClick={()=>{this.handleMetadataChange("ratings","ratings",rating)}}>{rating} Star</label>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className='m-3'>
                                                            <h3>season</h3><br/>
                                                            <div class="btn-group" role="group">
                                                                
                                                                {["Summer","Winter","Both"].map(season=>
                                                                    <>
                                                                        <input type="radio" class="btn-check" name="season" id={season} checked={this.props.outfitMetadata.season===season} /> 
                                                                        <label class="btn btn-outline-secondary" for={season} onClick={()=>{this.handleMetadataChange("season","season",season)}}>{season} </label>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className='m-3'>
                                                            <Row>
                                                                <Col>
                                                                    {this.props.outfitMetadata.pinned?
                                                                    <Button onClick={ ()=>{ this.handleMetadataChange("pin","pinned",false)}}>Unpin The Post</Button>
                                                                    :<Button className='btn-secondary' onClick={ ()=>{ this.handleMetadataChange("pin","pinned",true)}}>Pin The Post</Button>}
                                                                </Col>
                                                                <Col>
                                                                {this.props.outfitMetadata.showToPublic?
                                                                    <Button onClick={ ()=>{ this.handleMetadataChange("showToPublic","showToPublic",false)}}>Hide The Post</Button>
                                                                    :<Button className='btn-secondary' onClick={ ()=>{ this.handleMetadataChange("showToPublic","showToPublic",true)}}>Show The Post</Button>}
                                                                </Col>
                                                                <Col>
                                                                {this.props.outfitMetadata.availableForSnap?
                                                                    <Button onClick={ ()=>{ this.handleMetadataChange("availableForSnap","availableForSnap",false)}}>Disable for snap</Button>
                                                                    :<Button className='btn-secondary' onClick={ ()=>{ this.handleMetadataChange("availableForSnap","availableForSnap",true)}}>Enable for snap</Button>}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="cross_sell_cat me-1 ms-1">
                                                        <ul className="listview image-listview media mb-2">
                                                            <Row>
                                                                {element.crossSell.map(cross => {
                                                                    return (
                                                                        <>
                                                                            <Col xs={6} className="cross_sell_data">
                                                                                {!cross.showToPublic ?
                                                                                    <li style={{ position: 'relative' }} className="visibilityOff">
                                                                                        <a href={e=>e.preventDefault()} className="item">
                                                                                            <Row className="newdesign">
                                                                                                <Col xs={5} >
                                                                                                    <div className="imageWrapper">
                                                                                                        <img src={cross.firstImage} alt="image" className="imagednew cross-greyscale" />
                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col xs={7}>

                                                                                                    <div className="in cross">
                                                                                                        {/* <span className="cross-sell-title">{cross.title}</span> */}
                                                                                                        <div>
                                                                                                            <span className="cross-sell-title">{cross.title}</span>
                                                                                                            <div className="text-muted-11" style={{ display: 'flex', flexDirection: 'row' }}>
                                                                                                                &#8377;
                                                                                                                {typeof cross.price === 'undefined' || cross.price === null ? <span>
                                                                                                                    799
                                                                                                                </span> : <span>
                                                                                                                    {cross.price}
                                                                                                                </span>}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </a>
                                                                                        <div className="toggle-img" onClick={e => this.toggleCrossSell(e, 'crossSell', cross.title, element.sml_id, 'on')} checked>
                                                                                            <span>
                                                                                                <ThreeDotsVertical />
                                                                                            </span>
                                                                                        </div>
                                                                                    </li>
                                                                                    :
                                                                                    <li style={{ position: 'relative' }} className="showToPublic">
                                                                                        <a href="#" className="item" onClick={(e) => this.handleClose(e,element.sml_id, cross.title)}>
                                                                                            <Row className="newdesign">
                                                                                                <Col xs={5} >
                                                                                                    <div className="imageWrapper">
                                                                                                        <img src={cross.firstImage} alt="image" className="imagednew" />
                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col xs={7}>

                                                                                                    <div className="in cross">
                                                                                                        {/* <span className="cross-sell-title">{cross.title}</span> */}
                                                                                                        <div>
                                                                                                            <span className="cross-sell-title">{cross.title}</span>
                                                                                                            <div className="text-muted-11" style={{ display: 'flex', flexDirection: 'row' }}>
                                                                                                                &#8377;
                                                                                                                {typeof cross.price === 'undefined' || cross.price === null ? <span>
                                                                                                                    799
                                                                                                                </span> : <span>
                                                                                                                    {cross.price}
                                                                                                                </span>}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </a>
                                                                                        <div className="toggle-img" onClick={e => this.toggleCrossSell(e, 'crossSell', cross.title, element.sml_id, 'off')}>
                                                                                            <span>
                                                                                                <ThreeDotsVertical />
                                                                                            </span>
                                                                                        </div>
                                                                                    </li>
                                                                                }

                                                                            </Col>
                                                                        </>
                                                                    )
                                                                })
                                                                }
                                                            </Row>
                                                        </ul>
                                                    </div>
                                                    <div>
                                                        <Row>
                                                            <Col></Col>
                                                            <Col></Col>
                                                            <Col>
                                                                {element.toCurate==true && this.state.showToCurateButton?
                                                                    <Button onClick={ ()=>{ this.handleMetadataChange("toCurate","toCurate",false)}}>Mark Curated</Button>:null
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                    <div className="blogger-count full mb-2">
                                        <div className='counterVal'>Page Visits <strong>{this.props.pageVisits}</strong></div>
                                    </div>
                                    <div>
                                    <table className='table table-striped' style={{margingTop:"100px"}}>
                                        <thead>
                                            <tr>
                                                <th>Sr No</th>
                                                <th>Email</th>
                                                <th>Field</th>
                                                <th>Value</th>
                                                <th>Category</th>
                                                <th>Product</th>
                                                <th>Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.curatorLog.map((log,i) =>
                                                
                                                    <tr>
                                                        <td>{i}</td>
                                                        <td>{log.curatorEmail}</td>
                                                        <td>{log.field}</td>
                                                        <td>{log.value?'shown':'hidden'}</td>
                                                        <td>{log.category||''}</td>
                                                        <td>{log.product ||''}</td>
                                                        <td>{log.date}</td>
                                                    </tr>
                                            )}
                                        </tbody>
                                    </table> 
                                    </div>
                                </div>
                            </div>
                        </>
                }

                <Modal
                    show={this.state.show}
                    onHide={this.state.handleClose}
                    className="product-modal"
                    scrollable
                >
                    <Modal.Header>
                        <a href="#" onClick={this.handleClose} style={{ marginLeft: '90%' }}>
                            <X color="white" size={30} />
                        </a>
                    </Modal.Header>

                    <Modal.Body>
                        <Row>
                        {this.props.modalloading?<Loader type="modalmain"/>:this.props.products.map(item1 => {
                                return (
                                    <>
                                        {
                                            item1.showToPublic
                                                ? <Col xs={6} md={3} lg={3} style={{ position: "relative" }}>
                                                    
                                                        <a href={`${item1.product_url}`} className="item" target="_self">
                                                            <div className="imageWrapper">
                                                                <img src={item1.product_image_url} alt="image" className="modalimaged" />

                                                            </div>
                                                            <div className="in">
                                                                <div>
                                                                    <h4 className="mb-05">{item1.title}</h4>
                                                                    <h4 style={{color : 'red'}} className="mb-05">{item1.availability ?'available':'Out of stock' }</h4>
                                                                    <div> {item1.rating >= 3.5 ? <Rating
                                                                                        initialValue={item1.rating}
                                                                                        size={20}
                                                                                        transition
                                                                                        allowHalfIcon
                                                                                        readonly/> : null }</div>
                                                                    <div className="text-muted">
                                                                        {item1.description.substring(0, 10)+ "..."}
                                                                        <div className="mt-05"><strong>&#8377;{item1.price}&nbsp;</strong><span>{item1.brand}</span></div>
                                                                        <div className="mt-05">{`${(item1.score*100).toFixed(2)}%`} Similar</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    <div className="toggle-img" onClick={e => this.toggleCrossSell(e, 'product', 'NA', item1.product_id, 'off')}>
                                                        <span>
                                                            <ThreeDotsVertical />
                                                        </span>
                                                    </div>
                                                </Col>
                                                :
                                                <Col xs={6} md={3} lg={3} style={{ position: "relative" }}>
                                                    <a href={`${item1.product_url}`} className="item borderRed" target="_self">
                                                        <div className="imageWrapper">
                                                            <img src={item1.product_image_url} alt="image" className="greyscale" />

                                                        </div>
                                                        <div className="off">
                                                            <div>
                                                                <h4 className="mb-05">{item1.title}</h4>
                                                                <div className="text-muted">
                                                                    {item1.description}
                                                                    <div className="mt-05"><strong>&#8377;{item1.price}&nbsp;</strong><span>{item1.brand}</span></div>
                                                                    <div className="mt-05">{`${(item1.score*100).toFixed(2)}%`} Similar</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <div className="toggle-img" onClick={e => this.toggleCrossSell(e, 'product', 'NA', item1.product_id, 'on')}>
                                                        <span>
                                                            <ThreeDotsVertical />
                                                        </span>
                                                    </div>
                                                </Col>
                                        }

                                    </>
                                )
                            })}
                        </Row>
                    </Modal.Body>
                </Modal>
                <Modal
                    show={this.state.open}
                    onHide={this.closeToggle}
                    className='crossSell-product-visibility'
                    style={modalStyle}
                >
                    <Modal.Body>
                        <Row className="mb-1">
                            <Col xs={8}>
                                <span>visibility</span>
                            </Col>
                            <Col xs={4}>
                                <label className="switch">
                                    <input type="checkbox" onChange={this.setVisibility} defaultChecked={this.state.checked} />
                                    <span className="slider round"></span>
                                </label>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>

            </>
        )
    }
}
const mapStateToProps = state => ({
    isAuthenticated: state.isCuratorAuthenticated,
    user: state.user,
    loadingState: state.loadingState,
    crossSelldata: state.crossSelldata,
    products: state.products,
    totalCount: state.totalCount,
    modalloading: state.modalloading,
    pageVisits: state.pageVisits,
    sml_id:state.sml_id,
    outfitMetadata : state.outfitMetadata
    
});
const mapDispatchToProps = dispatch => ({
    getCrossSell: (username,outfit_id) => dispatch(fetchCrossSellDataCurator(username,outfit_id)),
    getProducts: (sml_id, cat) => dispatch(fetchProducts(sml_id, cat)),
    setCrossSellVisibility: (type, sml_id, product_id, insta, cat, action) => dispatch(setCrossSellVisibility(type, sml_id, product_id, insta, cat, action)),
    fetchOutfitMetadataCurator : (username,outfit)=> dispatch(fetchOutfitMetadataCurator(username,outfit)) 
});
export default connect(mapStateToProps, mapDispatchToProps)(curatorShop);

import React, { useState } from 'react'
import imgsrc from '../../images/vector4.png';
import '../../styles/style.css'
import '../../styles/login.css'
import { connect } from "react-redux";
import { isCuratorLogin } from '../../actions'
import { Redirect } from 'react-router';
import PublicNavbar from './../public/publicNavbar';
import Axios from 'axios';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import blogger from '../../blogger';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const clientId = "806676170053-kdqovn15piaombk6nubcaurib6ds9f09.apps.googleusercontent.com";
class CuratorLogin extends React.Component {
      constructor(props) {
            super(props)
            this.state = {
                  reqLogin: false,
                  bloggersData: []
            }
            /* this.onSignIn = this.onSignIn.bind(this)
            this.signInFail = this.signInFail.bind(this) */
      }

      async componentDidMount() {
            let response = await Axios.post('/api/curator/fetchBloggers')
            let bloggers = response.data.bloggersData;
            this.setState({
                  bloggersData: bloggers
            })
      }

      render() {
            
            
            return (
                  <>
                        {/* <div id="loader">
              <div className="spinner-border text-primary" role="status" />
            </div> */}
                        <PublicNavbar title="Blogger List" />
                        <div id="appCapsule" className="pt-0" style={{marginTop:"5rem"}}>
                              <div className='float-right' style={{width:"30%"}}><Link to='/curator/new-signups'><Button size='40' className='btn btn-secondary'>New Signups</Button></Link></div>
					<table className='table table-striped' style={{margingTop:"100px"}}>
						<thead>
							<tr>
								<th>Sr No</th>
								<th>Name</th>
								<th>Username</th>
								<th>instahandle</th>
                                                <th>Shop Built</th>
                                                <th>Active Posts</th>
                                                <th>Inactive Posts</th>
                                                <th>Amazon ID</th>
								<th>Email</th>
								<th>Phone No.</th>
                                                <th>Signup Date</th>

							</tr>
						</thead>
						<tbody>
							{this.state.bloggersData.map((blogger,i) =>
								
									<tr>
										<td>{i}</td>
										<td>{blogger.name}</td>
										<td>
											<a href={`/curator/${blogger.username}`} target='_blank'>
											{blogger.username}
											</a>
										</td>
										<td>{blogger.instahandle}</td>
                                                            <td>{blogger.crossSellCompleted}</td>
                                                            <td>{blogger.visible_outfits}</td>
                                                            <td>{blogger.invisible_outfits}</td>
                                                            <td>{blogger.amazon_tracking_id}</td>
										<td>{blogger.email}</td>
										<td>{blogger.mobileNumber}</td>
                                                            <td>{blogger.dateOfCreation}</td>
									</tr>
							)}
						</tbody>
					</table>
                        </div>
                  </>
            )
      }
}
const mapStateToProps = state => ({
      isCuratorAuthenticated: state.isCuratorAuthenticated
})
const mapDisparchToProps = (dispatch) => ({
      isCuratorLogin: () => dispatch(isCuratorLogin())
})
export default connect(mapStateToProps, mapDisparchToProps)(CuratorLogin);

import React, { Component } from 'react';
import { connect } from "react-redux";
import { Col, Row, Button, Tab, Nav,  Modal} from 'react-bootstrap';
import PublicNavbar from '../navbar';
import { Instagram, Bootstrap, Twitter, Facebook, Youtube, Grid3x3, Box, Vr, Hr,  X  } from 'react-bootstrap-icons';
import Header from '../header';
import PublicLoginModal from './publicLoginModal';
import '../../styles/style.css'
import '../../styles/blogger_store.css'
import {
    bloggerStyle, blogger_info, handleSubscribe, isSubscribed,
    updateUserLoginState, getBloggerOpenLooks, setBloggerOpenLooks ,
    fetchPublicProducts, setPublicProductsData } from '../../actions';
import { GetUserPreferences, addSavedLook, removeSavedLook } from './../../api/publicApis'
import Loader from '../Loader'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom';
import axios from 'axios';
import BottomBar from './bottomBar';
import queryString from 'query-string';
import mixpanel, { init } from 'mixpanel-browser';
import ReactGA from 'react-ga';
import { isMobile } from 'react-device-detect';
import { Rating } from 'react-simple-star-rating';
mixpanel.init("1a8ebbbb5ab0d0b35dc91b5e3b1578e1", {
    debug: true,
    ignore_dnt: true
})


class publicStore extends Component {
    constructor(props) {
        super(props)
        let username = this.props.match.params.insta
        if (username.toLowerCase() != username)
            window.location.href = `/${username.toLowerCase()}`
        let params = queryString.parse(this.props.location.search)
        this.props.updateUserLoginState()
        this.state = {
            pageNo: 1,
            openLooksPage: 1,
            pageSize: 30,
            tabActive: 'second',
            instahandle: username,
            morePage: 0,
            googleLoginModal: false,
            toSubsbribe: false,
            subscribeValue: null,
            categoriesFiltered: params.category || "All",
            occasionFiltered: params.occassion || "All",
            allCats: {
                "All": "All",
                "Topwear": "Topwear",
                "dresses": "Dresses",
                "pants": "Trousers",
                "jeans": "Jeans",
                "skirts": "Skirts",
                "jumpsuits": "One-Piece"
            },
            allOccasions: ["All", "Officewear", "Casual", "Party", "Sportwear", "Loungewear"
                , "Date Wear", "Ethnic casual", "Indo-western"],

        }
        
        this.state.categoriesFiltered = Object.keys(this.state.allCats).filter(c => this.state.allCats[c] === this.state.categoriesFiltered)[0]
        props.getbloggerStyle(1, username, this.state.categoriesFiltered, this.state.occasionFiltered);
        props.getBloggerOpenLooks(1, username, this.state.categoriesFiltered, this.state.occasionFiltered);
        props.getBloggerInfo(username)
        this.setActiveKey = this.setActiveKey.bind(this)
        this.loadMore = this.loadMore.bind(this)
        axios.post(`/count/blogger/${this.state.instahandle}`);
    }
    /*  componentWillReceiveProps(nextProps) {
         this.setActiveKey();
     } */
    setActiveKey = (key) => {
        console.log(key)
        this.setState({
            tabActive: key
        });
    }

    closeModal = ()=>{
        this.setState({googleLoginModal:false})
    }
    updateFilters = async (type, value) => {
        let resetFilter = type == 'occasions' ? 'categoriesFiltered' : 'occasionFiltered'
        let filterName = type == 'occasions' ? 'occasionFiltered' : 'categoriesFiltered'
        let stateObj = { pageNo: 1 }
        stateObj[filterName] = value
        stateObj[resetFilter] = 'All'
        await new Promise(res => this.setState(stateObj, res))
        await this.props.getbloggerStyle(1, this.state.instahandle, this.state.categoriesFiltered, this.state.occasionFiltered);
        this.props.getBloggerOpenLooks(1, this.state.instahandle, this.state.categoriesFiltered, this.state.occasionFiltered)
        const url = new URL(window.location);
        if (this.state.categoriesFiltered === "All")
            url.searchParams.delete('category')
        else
            url.searchParams.set('category', this.state.allCats[this.state.categoriesFiltered])
        if (this.state.occasionFiltered === "All")
            url.searchParams.delete('occassion')
        else
            url.searchParams.set('occassion', this.state.occasionFiltered)
        window.history.pushState(null, '', url.toString());
    }

    handleClose(e, sml_id, cat) {
        e.preventDefault()
        if (this.state.showProducts) {

            this.setState({ showProducts: false })
            this.props.hidePreviousProducts()
        }
        else {
            this.setState({
                showProducts: true,
                sml_id,
                cat
            })
            this.props.PublicProducts(sml_id, cat)
        }
    }

    handleSubscribe = async (subscribe) => {
        console.log(this.props.isUserAuthenticated)
        ReactGA.event({ category: 'Blogger store page viewed', action: `${this.props.bloggerInfo.username} subscribe button clicked`, lable: `${this.props.bloggerInfo.username}'s subscribe button clicked`, value: 1 });
        mixpanel.track(`${this.props.bloggerInfo.username} subscribe button clicked`)
        if (!this.props.isUserAuthenticated)
            this.setState({
                googleLoginModal: true,
                toSubsbribe: true,
                subscribeValue: subscribe
            })
        else {
            this.props.handleSubscribe(this.props.bloggerInfo.username, subscribe)
        }
        console.log(this.state)
    }
    // load more data
    loadMore(type) {
        if (type != 'openLooks') {
            this.props.getbloggerStyle(this.state.pageNo + 1, this.state.instahandle, this.state.categoriesFiltered, this.state.occasionFiltered)
            this.setState({
                pageNo: this.state.pageNo + 1,
                morePage: this.state.pageNo * this.state.pageSize + 30
            });
        } else {
            this.props.getBloggerOpenLooks(this.state.openLooksPage + 1, this.state.instahandle, this.state.categoriesFiltered, this.state.occasionFiltered)
            this.setState({
                openLooksPage: this.state.openLooksPage + 1
            });
        }
    }
    componentDidMount() {
        if( isMobile && !window.navigator.userAgent.toLowerCase().includes('wv'))
            window.location = "https://play.google.com/store/apps/details?id=com.shopthelooks";
        // this.props.isSubscribed(this.props.bloggerInfo.username)
        mixpanel.track("Store Page Loaded", {
            "Page Viewed": "Store Page",
        });
        
    }
    componentDidUpdate() {
        if (this.props.isUserAuthenticated && this.state.googleLoginModal)
            this.setState({
                googleLoginModal: false
            })
        if (this.state.toSubsbribe) {
            if (!this.props.isSubscribedVal) {
                this.props.handleSubscribe(this.props.bloggerInfo.username, true)
            }
            else {
                this.setState({
                    toSubsbribe: false,
                    subscribeValue: null
                })
            }
        }
    }
    handleSaveLooks = async (sml_id, value) => {
        if (this.props.isUserAuthenticated) {
            let result;
            let button_value
            if (value) {
                button_value = "saved"
            } else {
                button_value = "unsaved"
            }
            ReactGA.event({ category: 'Blogger page', action: `${button_value} button clicked`, label: `${button_value}`, value: 1 })
            mixpanel.track(`${button_value} button clicked`)
            if (value)
                result = await addSavedLook(sml_id)
            else
                result = await removeSavedLook(sml_id)
            if (result.data.status === "OK") {
                let crossSellData = [...this.props.bloggerOpenLooks]
                crossSellData.forEach(cross => {
                    if (cross.sml_id == sml_id)
                        cross.isSavedLook = value
                })
                this.props.setBloggerOpenLooks(1, crossSellData)
            } else {
                this.props.setIsUserAuthenticated(false);
            }
        } else {
            this.setState({
                googleLoginModal: true
            })
        }
    }


    render() {
        let target = window.navigator.userAgent.toLowerCase().includes('wv')?'_self':'_blank'; 
        console.log(this.props.bloggerLooksRequested)
        let CatsButtons = Object.keys(this.state.allCats).map(cat => this.state.categoriesFiltered != cat ?
            <button key={cat} type="button" className="btn btn-outline-dark outlined rounded shadowed me-1 mb-1 btn-sm" onClick={() => this.updateFilters('categories', cat)}>{this.state.allCats[cat]}</button>
            : <button key={cat} type="button" className="btn btn-dark rounded shadowed me-1 mb-1 btn-sm">{this.state.allCats[this.state.categoriesFiltered]}</button>)

        let occasionsButtons = this.state.allOccasions.map(occ => this.state.occasionFiltered != occ ?
            <button key={occ} type="button" className="btn btn-outline-dark outlined rounded shadowed me-1 mb-1 btn-sm" onClick={() => this.updateFilters('occasions', occ)}>{occ}</button>
            : <button key={occ} type="button" className="btn btn-dark rounded shadowed me-1 mb-1 btn-sm">{this.state.occasionFiltered}</button>)

        return (
            <>
                {this.props.loadingState && !this.props.bloggerInfo.name ? <Loader />
                    : <>
                        <PublicNavbar title={<a href='/'><img style={{ height: '1em' }} src='/static/images/stl_logo.JPG' /></a>} page="home" /><br></br>
                        <div className="appCapsule">
                            <Header profile_img={this.props.bloggerInfo.profile_img} />
                            <div className="app-header">
                                <Row>
                                    <Col>
                                        <span className="section-title medium" style={{ justifyContent: 'center' }}>{this.props.bloggerInfo.name}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <span> <Instagram color="red" size="18" />&nbsp;&nbsp;{this.props.bloggerInfo.instahandle}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {
                                            typeof this.props.bloggerInfo.blog !== "undefined" && this.props.bloggerInfo.blog !== "" &&
                                            <a href={this.props.bloggerInfo.blog} target="_new">
                                                &nbsp;&nbsp;<Bootstrap color='orange' size="18"></Bootstrap>
                                            </a>
                                        }
                                        {
                                            typeof this.props.bloggerInfo.fblink !== "undefined" && this.props.bloggerInfo.fblink !== "" &&
                                            <a href={this.props.bloggerInfo.fblink} target="_new">
                                                &nbsp;&nbsp;<Facebook color='blue' size="18" />
                                            </a>
                                        }
                                        {
                                            typeof this.props.bloggerInfo.twlink !== "undefined" && this.props.bloggerInfo.twlink !== "" &&
                                            <a href={this.props.bloggerInfo.twlink} target="_new">
                                                &nbsp;&nbsp; <Twitter color='#1E74FD' size="18" />
                                            </a>
                                        }
                                        {
                                            typeof this.props.bloggerInfo.ytlink !== "undefined" && this.props.bloggerInfo.ytlink !== "" &&
                                            <a href={this.props.bloggerInfo.ytlink} target="_new">
                                                &nbsp;&nbsp; <Youtube color='red' size="18" />
                                            </a>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <span>My everyday effortless styles.</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <span>Tap any image to shop products similar to my looks.</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.props.isSubscribedVal ?
                                            <button size="25" className='btn btn-light-unsave me-1 mb-1 rounded mt-1 save-unsave-btn' onClick={() => this.handleSubscribe(false)}>Unsubscribe</button> :
                                            <button size="25" className='btn btn-pinterest me-1 mb-1 rounded mt-1 pl-1 save-unsave-btn' onClick={() => this.handleSubscribe(true)}>Subscribe</button>
                                        }
                                    </Col>
                                </Row>
                            </div>
                            <div className="section full mb-2">
                                <div className='horizontal-buttons-div '><button type="button" className="btn btn-text-dark me-1 mb-1">Occasions  : </button>{occasionsButtons}</div>
                                <div className='horizontal-buttons-div mb-1'><button type="button" className="btn btn-text-dark me-1 mb-1">Categories : </button>{CatsButtons}</div>
                                <Tab.Container id="left-tabs-example" activeKey={this.state.tabActive} onSelect={key => this.setActiveKey(key)}>

                                    <Nav>
                                        <Row style={{ width: "100%" }}>
                                            <Col xs={6}>
                                                <Nav.Item style={{ textAlign: "center" }}>
                                                    <Nav.Link eventKey="second">
                                                        <Hr size="20" color="black" />
                                                    </Nav.Link>
                                                </Nav.Item>
                                                {this.state.tabActive === 'second' ?
                                                    <hr style={{
                                                        border: "1px solid black",
                                                        width: "100%"
                                                    }}
                                                    />
                                                    : ''
                                                }
                                            </Col>
                                            <Col xs={6}>
                                                <Nav.Item style={{ textAlign: "center" }}>
                                                    <Nav.Link eventKey="first">
                                                        <Grid3x3 size="20" color="black" style={{ textAlign: 'center' }} />
                                                    </Nav.Link>
                                                </Nav.Item>
                                                {this.state.tabActive === 'first' ?
                                                    <hr style={{
                                                        border: "1px solid black",
                                                        width: "100%"
                                                    }}
                                                    />
                                                    : ''
                                                }
                                            </Col>
                                        </Row>
                                    </Nav>

                                    <Tab.Content>
                                        <Tab.Pane eventKey="second">
                                            <>
                                                {this.props.bloggerOpenLooks.map((crossSell, i) => {
                                                    return <div key={crossSell.sml_id} id={crossSell.sml_id} className='pt-2 pb-2 ps-1 pe-1 my-looks-outfit-post'>
                                                        <div className="cross-sell mt-1" id={"list" + i}>
                                                            <div className="street_style">
                                                                {/* <a href={`/outfit/${crossSell.username}/outfit${crossSell.outfit}`} target='_blank'> */}
                                                                <img src={crossSell.img_Url} alt="image" className="imagedbox" style={{ maxWidth: '100%' }} />
                                                                {/* </a> */}
                                                                <div className="cart" onClick={() => this.handleSaveLooks(crossSell.sml_id, !crossSell.isSavedLook, 'explore')}>
                                                                    {crossSell.isSavedLook ?
                                                                        <button size="25" className='btn btn-light-unsave me-1 mb-1 rounded mt-1 save-unsave-btn'>Unsave</button> :
                                                                        <button size="25" className='btn btn-pinterest me-1 mb-1 rounded mt-1 pl-1 save-unsave-btn' style={{ background: "#f12a2c !important" }}>  Save Look</button>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="cross_sell_cat me-1 ms-1">
                                                                <ul className="listview image-listview media mb-2">
                                                                    <Row>
                                                                        {crossSell.crossSell.map((cross, index) => {
                                                                            return (
                                                                                <>
                                                                                    <Col xs={6} className="cross_sell_data">
                                                                                        <li style={{ position: 'relative' }}>
                                                                                            <a href="#" className="item" onClick={e => this.handleClose(e, crossSell.sml_id, cross.title)}>
                                                                                                <Row className="newdesign">
                                                                                                    <Col xs={5} >
                                                                                                        <div className="imageWrapper">
                                                                                                            <img onClick={() => {
                                                                                                                ReactGA.event({ category: 'Home page', action: 'products modal opened', lable: 'products modal open', value: 1 });
                                                                                                                mixpanel.track("products modal opened")
                                                                                                            }} src={cross.firstImage} alt="image" className="imagednew" />
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                    <Col xs={7}>

                                                                                                        <div className="in cross">
                                                                                                            {/* <span className="cross-sell-title">{cross.title}</span> */}
                                                                                                            <div>
                                                                                                                <span className="cross-sell-title">{cross.title}</span>
                                                                                                                <div className="text-muted-11-5" style={{ display: 'flex', flexDirection: 'row' }}>
                                                                                                                    &#8377;
                                                                                                                    {typeof cross.price === 'undefined' || cross.price === null ? <span>
                                                                                                                        599
                                                                                                                    </span> : <span>
                                                                                                                        {cross.price}
                                                                                                                    </span>}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </a>

                                                                                        </li>
                                                                                    </Col>
                                                                                </>
                                                                            )
                                                                        })
                                                                        }
                                                                    </Row>
                                                                </ul>
                                                            </div>

                                                        </div>
                                                    </div>
                                                })}
                                                <Row className='px-5'>
                                                    {this.props.bloggerOpenLooksRequested ? <div className="d-flex justify-content-center mt-5">
                                                        <div className="spinner-border" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div> : <>
                                                        {
                                                            this.props.bloggerOpenLooks.length < this.props.totalCount ?
                                                                <Button variant="outline-dark" onClick={() => this.loadMore('openLooks')} className='m-1'>load more</Button>
                                                                : <div style={{ textAlign: 'center' }}>
                                                                    <span> No more data. </span>
                                                                </div>
                                                        }
                                                    </>}
                                                </Row>
                                            </>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="first">
                                            <div className="mt-2 pe-2 ps-2">
                                                {this.props.loadingState ? null : <div className="row">
                                                    {this.props.blogger_public.map((item, index) => {
                                                        return (
                                                            <>
                                                                <div className="col-4 mb-2" style={{ position: 'relative' }}>
                                                                    <Link to={`/outfit/${this.props.bloggerInfo.username}/outfit${item.outfit}`} target="_blank">
                                                                        <img onClick={() => {
                                                                            ReactGA.event({ category: 'Blogger store page viewed', action: `${this.props.bloggerInfo.username} ${item.outfit} outfit clicked`, lable: `${this.props.bloggerInfo.username} ${item.outfit} oufit viewd`, value: 1 });
                                                                            mixpanel.track(`${this.props.bloggerInfo.username} ${item.outfit} outfit clicked`);
                                                                        }} src={item.img_Url} alt="image" className="imaged w-100" />

                                                                    </Link>
                                                                    <br />
                                                                </div>

                                                            </>
                                                        )
                                                    })

                                                    }
                                                    {
                                                        this.state.pageNo * 30 < this.props.totalCount ?
                                                            <Button variant="outline-dark" onClick={this.loadMore}>load more</Button>
                                                            : <div style={{ textAlign: 'center' }}>
                                                                <span> No more data. </span>
                                                            </div>
                                                    }
                                                </div>}
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>

                                </Tab.Container>
                            </div>
                            {/* { isMobile ?
                    (/android/i.test(navigator.userAgent)) && false ? <div class="toast-box toast-bottom show bg-info" style={{bottom : '0'}}>
                    <div class="in">
                        <div class="text">
                        <a href='https://play.google.com/store/apps/' className='text-white' >Download our app to up your style game.</a>
                        </div>
                    </div>
                    <a href='https://play.google.com/store/apps/'><img style={{width: '40px', height: '40px'}} src='/static/images/explore/gplay.png'></img></a>
                    </div> : 
                    <div class="toast-box toast-bottom show bg-info" style={{bottom : '0'}}>
                    <div class="in">
                        <div class="text">
                        <a href='https://signupforservices.com/whatsapp/optin/?bId=8923b967-ec46-40d5-bce3-93aa221f85d9&bName=ShopTheLooks&s=URL&lang=en_US' className='text-white' >Get Top-10 Looks on Whatsapp everyday, as per your bodyshape to up your style game</a>
                        </div>
                    </div>
                    <a href='https://signupforservices.com/whatsapp/optin/?bId=8923b967-ec46-40d5-bce3-93aa221f85d9&bName=ShopTheLooks&s=URL&lang=en_US'><button type="button" class="btn btn-sm btn-text-light close-button" style={{backgroundColor: 'rgba(255, 255, 255, 0.1)'}}>Yes</button></a>
                    </div>: null } */}

                            {isMobile ? <div className="toast-box toast-bottom show bg-info" style={{ bottom: '0', justifyContent: 'center' }}>
                                <div className="in">
                                    <a onClick={() => {
                                        ReactGA.event({ category: 'Blogger store page viewed', action: 'bottom bar clicked', lable: 'bottom bar clicked', value: 1 });
                                        mixpanel.track("bottom bar clicked");
                                    }} href='/' className='p2'><div style={{ width: '25px', height: '25px' }} ><img src='/static/images/explore/earth.png' ></img></div></a>
                                    <div className="text" style={{ marginLeft: '7px' }}>
                                        <a onClick={() => {
                                            ReactGA.event({ category: 'Blogger store page viewed', action: 'bottom bar clicked', lable: 'bottom bar clicked', value: 1 });
                                            mixpanel.track("bottom bar clicked");
                                        }} href='/' className='text-white' > Click to explore Shop The Looks world</a>
                                    </div>
                                </div>
                            </div> : null}

                        </div>
                    </>
                }
                <Modal
                    show={this.state.showProducts}
                    onHide={this.handleClose}
                    className="product-modal my-looks-product-modal"
                    scrollable
                >
                    <Modal.Header onClick={(e) => this.handleClose(e)}>
                        <Row style={{ marginLeft: '70%' }} >

                            <Col xs={2}><X color="white" size={30} /></Col>
                        </Row>
                    </Modal.Header>

                    <Modal.Body>
                        <Row>
                            {this.props.modalloading ? <Loader type="modalmain" /> : this.props.publicProducts.map(item1 => {
                                return (
                                    <Col xs={6} md={3} lg={3} style={{ position: "relative" }}>
                                        <a href={item1.product_url} className="item" target="_blank" onClick={() => { this.countProductClick(item1.product_id) }}>
                                            <div className="imageWrapper">
                                                <img onClick={() => {
                                                    ReactGA.event({ category: 'Home page', action: `${item1.product_image_url} product clicked`, lable: 'product clicked', value: 1 });
                                                    mixpanel.track(`${item1.product_image_url} product clicked`);
                                                }} src={item1.product_image_url} alt="image" className="modalimaged fit-product-images" />

                                            </div>
                                            <div className="in">
                                                <div>
                                                    <h4 className="mb-05">{item1.title}</h4>
                                                    <div> {item1.rating >= 3.5 ? <Rating
                                                        initialValue={item1.rating}
                                                        size={20}
                                                        transition
                                                        allowHalfIcon
                                                        readonly /> : null}</div>
                                                    <div className="text-muted">
                                                        {item1.description.substring(0, 10) + "..."}
                                                        <div className="mt-05"><strong>&#8377;{item1.price}&nbsp;</strong><span>{item1.brand}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>

                                    </Col>
                                )
                            })}
                        </Row>
                    </Modal.Body>
                </Modal>
                <PublicLoginModal showModal={this.state.googleLoginModal} closeModal={this.closeModal}/>
            </>
        );
    }
}
const mapStateToProps = state => ({
    loadingState: state.loadingState,
    blogger_public: state.blogger_public,
    bloggerInfo: state.bloggerInfo,
    totalCount: state.totalCount,
    isSubscribedVal: state.isSubscribedUser,
    bloggerOpenLooks: state.bloggerOpenLooks,
    isUserAuthenticated: state.isUserAuthenticated,
    publicProducts: state.publicProducts,
    bloggerOpenLooksRequested : state.bloggerOpenLooksRequested,
});
const mapDispatchToProps = dispatch => ({
    getbloggerStyle: (pageNo, insta, categoryFilters, occasionFilters) => dispatch(bloggerStyle(pageNo, insta, occasionFilters, categoryFilters)),
    getBloggerInfo: insta => dispatch(blogger_info(insta)),
    handleSubscribe: (username, subscribe) => dispatch(handleSubscribe(username, subscribe)),
    isSubscribed: (username) => dispatch(isSubscribed(username)),
    PublicProducts: (sml_id, cat) => dispatch(fetchPublicProducts(sml_id, cat)),
    updateUserLoginState: () => dispatch(updateUserLoginState()),
    getBloggerOpenLooks: (pageNo, insta, categoryFilters, occasionFilters) => dispatch(getBloggerOpenLooks(pageNo, insta, occasionFilters, categoryFilters)),
    setBloggerOpenLooks: (page, data) => dispatch(setBloggerOpenLooks(page, data)),
    hidePreviousProducts : () => dispatch(setPublicProductsData([])),
    
    /* visibilityOff: (pageNo,insta) => dispatch(visibilityOff(pageNo,insta)) */
});
export default connect(mapStateToProps, mapDispatchToProps)(publicStore)
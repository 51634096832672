import React, { Component } from "react";
import axios from 'axios';
import LoadingSpinner from './LoadingSpinner';
import './App_old.css'
import {Layout} from "element-react";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import s from "./slick/slick_slider/slick.module.scss";
import "./slick/slick_slider/slick.css";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import logo from './images/header-img.jpeg';
import InstagramIcon from '@material-ui/icons/Instagram';


class blogger extends Component {
  constructor(props) {
    super(props);
    this.videoArray=[];
    this.state = {
      allProductData : [],
      loading: true,
      instaHandle:''
    };
    //this.setPageNumber = this.setPageNumber.bind(this);
    this.getImages = this.getImages.bind(this);
    //this.loadNextData = this.loadNextData.bind(this);
    //this.setPageSize = this.setPageSize.bind(this);

  }
  componentDidMount() {
    this.getImages(this.props);        
  }

  getImages(props)
  {
   /*  this.setState({
      loading: true
    }) */
   // console.log(this.props.insta)
    let insta = 'vee_shohe';
    let _this = this;
    axios.get(`/images/?insta=${insta}`)
    .then((response)=> {
      console.log("getting Response",response.data);
      if(response.data.error){
        this.setState({loading: false});
        //_this.props.setUserDetails({});
        Notification({
          type: "error",
          title: "Error",
          message: response.data.error,
          duration: 0
        })
      } else{
        this.setState({
          allProductData : response.data.video,
          loading: false,
          instaHandle: insta
          // ats: response.data.ats
        },()=>{
          console.log("Inside funtion",this.state.allProductData.length);
        });
      }

    }).catch(function (error) {
      _this.setState({loading: false})
    })
  }
  render() {  
    //push into array
      this.state.allProductData.map((item) => {
        this.videoArray.push( 
          <Grid xs={12} sm={4} lg={4}>
            <div className="product-img-container" key={item.vsp_id}>
              <div className="product-image">
                <img className ={item.vsp_id+"_0"} src={item.img_Url} style={{width:"100%"}}alt={item.vsp_id}/>
              </div>
              {/*
              `https://us-central1-analog-mantra-247706.cloudfunctions.net/resize/?url=${item.img_Url}&width=300&quality=60` 
              {item.crossSell.length > 0 &&
                <div className={s.option_container}>
                    <a className={s.option+ ' list-item'}>Buy Similar</a>
                </div>
              } */}
              {item.crossSell.map((ele) =>{
                  return(
                        <div className={s.option_container}>
                          <a href= {`/insta:${item.credit}/id:${item.vsp_id}/shoppable_items:${ele}`} className={s.option+' list-item'} target="_blank">
                              {ele}
                              <ArrowForwardIosIcon fontSize="small"/>
                          </a>
                        </div>
                    )
                })
              }
            </div>
            </Grid>
      )})

    return (
      <div>
          <div>
          <div className ="navbar">
            <div className = "heading">
              <a href="/">
                <img src={logo} alt="header.jpeg"/>
              </a>
            </div>
          </div>
          <div className="storeTitle">
            <Grid container direction="row" style={{justifyContent:"center"}}>
              <InstagramIcon style={{color:"red"}}/>
              <span style={{marginLeft:"5px",marginTop:"5px"}}>{this.state.instaHandle}'s Store</span>
            </Grid>
          </div>
          {
            this.state.loading ? <LoadingSpinner/>: (
              <div>
                    <div>
                      <Layout.Row>
                        <div className="products-img-list">
                          {this.videoArray}
                        </div>
                      </Layout.Row>
                     {/*  <Layout.Row>
                        <div className="next">  
                          {LoadNext}
                        </div>                    
                      </Layout.Row> */}
                  </div>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}
export default blogger;

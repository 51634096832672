import React, { Component } from 'react';
import { connect } from "react-redux";
import { Col, Row, Modal, Tab, Nav,Button } from 'react-bootstrap';
import NavBar from '../navbar';
import { Instagram, Grid3x3, List, HeartFill, Chat, ThreeDotsVertical, EyeSlashFill,
    Bootstrap, Twitter, Facebook, Envelope, Youtube , Box} from 'react-bootstrap-icons';
import Header from '../header';
import '../../styles/style.css'
import '../../styles/blogger_store.css'
import { fetchBloggerDataCurator, setoffImage, setonImage, visibilityOffCurator ,
     fetchBloggerMetadataCurator , setBloggersData , setVisdibilityOff , curatorNotCuratedData } from '../../actions';
import { updateBloggerMetadata } from '../../api/publicApis'
import Loader from '../Loader'
import { Redirect } from 'react-router';
import { NavigateBeforeSharp } from '@material-ui/icons';
import Axios from 'axios';
class CuratorStore extends Component {
    constructor(props) {
        super(props)
        let username = this.props.match.params.bloggerUsername
        props.getBlogger(1, 0,username);
        props.fetchBloggerMetadataCurator(username )
        props.visibilityOff(1,0,username)
        props.curatorNotCuratedData(username)
        this.state = {
            pageNo: 2,
            pageSize: 30,
            key: 'first',
            open: false,
            top: 0,
            left: 0,
            type: '',
            item: '',
            morePage:30,
            pg:2,
            mp:30,
            checked:false,
            username: this.props.match.params.bloggerUsername
        }
        this.setActiveKey = this.setActiveKey.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.toggleBlogger = this.toggleBlogger.bind(this)
        this.toggleBloggerPinned = this.toggleBloggerPinned.bind(this)
        this.loadMore = this.loadMore.bind(this)
        this.loadMoregrey = this.loadMoregrey.bind(this)
        this.toggle = React.createRef();
        this.toggleAvailableForSnap = this.toggleAvailableForSnap.bind(this)
        // this.gotoProduct = this.gotoProduct.bind(this)
    }
  /*    componentDidMount() {
       
     } */
    setActiveKey = (key) => {
        this.setState({
            key: key
        });
    }
    openToggle(e, item, type,pinnedType) {
        this.setState({
            open: true,
            top: e.clientY,
            left: e.clientX,
            type: type,
            pinnedType:pinnedType,
            item
        })
        if(type === 'on'){
            this.setState({checked :false})
        }
        else
            this.setState({checked :true})
        if(pinnedType === 'on')
            this.setState({pinChecked:false})
        else
            this.setState({pinChecked:true})
    }
    closeModal() {
        this.setState({
            open: false
        })
    }
    toggleBlogger(e) {
        console.log('toggle-type',this.state.type, e.target.checked)
        if ( this.state.type === 'off' && !e.target.checked) {
            this.props.bloggersetOff(this.state.item,this.state.username);
        }
        else if (this.state.type === 'on' && e.target.checked) {
            this.props.bloggersetOn(this.state.item,this.state.username);
        }
        this.setState({
            open: false
        })
    }

    toggleBloggerPinned(e) {
        let item=this.state.item,type
        let sml_id =this.state.item.sml_id,insta = this.state.item.insta
        console.log(e.target.checked)
        if (  !e.target.checked) 
            type = 'off'
        else if ( e.target.checked) 
            type = 'on'
        console.log(sml_id,type)
        Axios.post('/api/sml/setPinned',{type,sml_id,insta}).then(()=>{
            this.setState({open: false});
            this.props.getBlogger(1, 0,this.state.username);
        })
        
        this.setState()
    }

    async handleMetadataChange(type,field,value){
        let queryField,queryValue;
        queryField = field
        queryValue = value
        await updateBloggerMetadata(this.state.username,queryField,queryValue)
        this.props.fetchBloggerMetadataCurator(this.state.username )
    }


    toggleAvailableForSnap = async (sml_id,availableForSnap)=>{
        availableForSnap = !availableForSnap
        let data = await Axios.post('/curator/setAvailableForSnap',{sml_id,availableForSnap})
        let newData = this.props.blogger
        newData.forEach(o =>{ if(o.sml_id==sml_id) {o.availableForSnap=availableForSnap}} )
        let hiddenData = this.props.visibilityOffdata
        hiddenData.forEach(o =>{ if(o.sml_id==sml_id) {o.availableForSnap=availableForSnap}} )
        this.props.setBloggersData(newData);
        this.props.setVisdibilityOff(hiddenData);
    }

// load more data
   loadMore(){
       this.setState({
           pageNo:this.state.pageNo+1,
           morePage:this.state.pageNo*this.state.pageSize
       });
       console.log(this.state.pageNo, this.state.morePage)
       this.props.getBlogger(this.state.pageNo,this.props.blogger.length,this.state.username)
   }
   //load more visibility off data
   loadMoregrey(){
    this.setState({
        pg:this.state.pg+1,
        mp:this.state.pg*this.state.pageSize
    });
    //console.log(this.state.pageNo, this.state.morePage)
    this.props.visibilityOff(this.state.pageNo,this.props.visibilityOffdata.length,this.state.username)
}
    render() {
        console.log(this.props.bloggerMetadata)
        console.log(this.props.isAuthenticated)
        const modalStyle = {
            top: this.state.top + 'px',
            left: Math.abs(this.state.left - 126) + 'px',
            width: '160px'
        }
        // console.log(this.props.user)
        return (
            <>
                {(!this.props.isAuthenticated) ? <Redirect push to='/curator/login' />
                    : this.props.loadingState ? <Loader type="main"/>
                        : <>
                            <NavBar title={this.state.username + '\'s store'} page="home" />
                            <div className="appCapsule">
                                {/* <Header profile_img={this.props.user.profile_img} /> */}
                                <div className="app-header">
                                    <Row>
                                        <Col>
                                            <h1 className="section-title medium" style={{ justifyContent: 'center' }}>{this.state.username}</h1>
                                            <div className='m-3'>
                                                <h3>Skin Tone</h3><br/>
                                                <div class="btn-group" role="group">
                                                    
                                                    {["Light","Fair","Medium","Mid-brown","Dark-brown"].map(skintone=>
                                                        <>
                                                            <input type="radio" class="btn-check" name="skintone" id={skintone} checked={this.props.bloggerMetadata.skintone===skintone} /> 
                                                            <label class="btn btn-outline-secondary" for={skintone} onClick={()=>{this.handleMetadataChange("skintone","skintone",skintone)}}>{skintone} </label>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div className='m-3'>
                                                <h3>Height</h3><br/>
                                                <div class="btn-group" role="group">
                                                    
                                                    {["Short (Below 5')","Medium (5'-5'5\")","Tall (Above 5'6\")"].map(height=>
                                                        <>
                                                            <input type="radio" class="btn-check" name="height" id={height} checked={this.props.bloggerMetadata.height===height} /> 
                                                            <label class="btn btn-outline-secondary" for={height} onClick={()=>{this.handleMetadataChange("height","height",height)}}>{height} </label>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div className='m-3'>
                                                <h3>Weight</h3><br/>
                                                <div class="btn-group" role="group">
                                                    
                                                    {["Petite","Medium","Plus","Athletic"].map(weight=>
                                                        <>
                                                            <input type="radio" class="btn-check" name="weight" id={weight} checked={this.props.bloggerMetadata.weight===weight} /> 
                                                            <label class="btn btn-outline-secondary" for={weight} onClick={()=>{this.handleMetadataChange("weight","weight",weight)}}>{weight} </label>
                                                        </>
                                                    )}
                                                </div>
                                            </div>  
                                            <div className='m-3'>
                                                <h3>Bodyshape</h3><br/>
                                                <div class="btn-group" role="group">
                                                    
                                                    {["Apple","Pear","Inverted Triangle","Rectangle","Hourglass"].map(bodyshape=>
                                                        <>
                                                            <input type="radio" class="btn-check" name="bodyshape" id={bodyshape} checked={this.props.bloggerMetadata.bodyshape===bodyshape} /> 
                                                            <label class="btn btn-outline-secondary" for={bodyshape} onClick={()=>{this.handleMetadataChange("bodyshape","bodyshape",bodyshape)}}>{bodyshape} </label>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {
                                                typeof this.props.user.blog!=="undefined" && this.props.user.blog!=="" &&
                                               <a href={this.props.user.blog} target="_new"> 
                                                     &nbsp;&nbsp;<Bootstrap color='orange' size="18"></Bootstrap>
                                                </a>
                                            }
                                            {
                                                typeof this.props.user.fblink!=="undefined" && this.props.user.fblink!=="" &&
                                                <a href={this.props.user.fblink} target="_new"> 
                                                     &nbsp;&nbsp;<Facebook color='blue' size="18"/>
                                                </a>
                                            }
                                            {
                                                typeof this.props.user.twlink!=="undefined" && this.props.user.twlink!=="" &&
                                                <a href={this.props.user.twlink} target="_new"> 
                                                    &nbsp;&nbsp; <Twitter color='#1E74FD' size="18"/>
                                                </a>
                                            }
                                            {
                                                typeof this.props.user.ytlink!=="undefined" && this.props.user.ytlink!=="" &&
                                                <a href={this.props.user.ytlink} target="_new"> 
                                                    &nbsp;&nbsp; <Youtube color='red' size="18"/>
                                                </a>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                                <div className="section full mb-2">
                                    <div className="mt-2 pe-2 ps-2">
                                        <Tab.Container id="left-tabs-example" activeKey={this.state.key} onSelect={key => this.setActiveKey(key)}>

                                            <Nav>
                                                <Row style={{width:"100%"}}>
                                                    <Col xs={4}>
                                                        <Nav.Item style={{textAlign:"center"}}>
                                                            <Nav.Link eventKey="first">
                                                               <Grid3x3 size="20" color="black" style={{textAlign:'center'}}/>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                        {this.state.key === 'first'?
                                                            <hr style={{
                                                                border:"1px solid black",
                                                                width:"100%"
                                                            }}
                                                            />
                                                            : ''
                                                        }
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Nav.Item style={{textAlign:"center"}}>
                                                            <Nav.Link eventKey="third">
                                                                <Box size="20" color="black"/>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                        {this.state.key === 'third'?
                                                            <hr style={{
                                                                border:"1px solid black",
                                                                width:"100%"
                                                            }}
                                                            />
                                                            : ''
                                                        }
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Nav.Item style={{textAlign:"center"}}>
                                                            <Nav.Link eventKey="second">
                                                               <EyeSlashFill size="20" color="black"/>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                        {this.state.key === 'second'?
                                                            <hr style={{
                                                                border:"1px solid black",
                                                                width:"100%"
                                                            }}
                                                            />
                                                            : ''
                                                        }
                                                    </Col>
                                                </Row>
                                            </Nav>

                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">
                                                    <div className="row mt-2">
                                                        {this.props.totalCount > 0 ?
                                                            this.props.blogger.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        <div className="col-4 mb-2" style={{ position: 'relative' }}>
                                                                            <label className="snap-switch ">
                                                                                <input type="checkbox" onChange={() => this.toggleAvailableForSnap(item.sml_id,item.availableForSnap)} defaultChecked={item.availableForSnap}/>
                                                                                <span className="slider round"></span>
                                                                            </label>
                                                                            <div className="toggle-img" onClick={e => this.openToggle(e, item, 'off',item.pinned?'off':'on')}>
                                                                                <span><ThreeDotsVertical /></span>
                                                                            </div>
                                                                            <a href={`/curator/${this.state.username}/outfit_${item.outfit}`} target="_blank">
                                                                                <img src={item.img_Url} alt="image" className="imaged w-100" />

                                                                            </a>
                                                                            {/* <span className="imgid">{item.sml_id}</span>  */}
                                                                            <br />
                                                                        </div>

                                                                    </>
                                                                )
                                                            })
                                                            : <div style={{ textAlign: 'center' }}>
                                                                <p>No data available.</p>
                                                            </div>
                                                        }
                                                        {
                                                            this.props.blogger.length < this.props.totalCount?
                                                            <Button size="25" style={{ background: "#f12a2c !important",width : "33%", marginLeft:'33%' }} variant="outline-dark" onClick={this.loadMore} >load more</Button>
                                                            :<div style={{textAlign:'center'}}>
                                                                <span> No more data. </span>
                                                            </div>
                                                        }

                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                    <br />
                                                    {this.props.visibilityOffdata.length <= 0 ? 
                                                        <div style={{textAlign:"center"}}>
                                                            <span> No data availabe.</span>
                                                        </div>
                                                        : <div className="row mt-2">
                                                            {
                                                                this.props.visibilityOffdata.map((item, index) => {
                                                                    return (
                                                                        <div className="col-4 mb-2" style={{ position: 'relative' }}>
                                                                            <label className="snap-switch ">
                                                                                <input type="checkbox" onChange={() => this.toggleAvailableForSnap(item.sml_id,item.availableForSnap)} defaultChecked={item.availableForSnap}/>
                                                                                <span className="slider round"></span>
                                                                            </label>
                                                                            <div className="toggle-img" onClick={e => this.openToggle(e, item, 'on')}>
                                                                                <span><ThreeDotsVertical /></span>
                                                                            </div>
                                                                            <a href={`/curator/${this.state.username}/outfit_${item.outfit}`} target="_blank">
                                                                                <img src={item.img_Url} alt="image" className="imaged w-100" />
                                                                            </a>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    }
                                                     {this.props.visibilityOffdata.length < this.props.visibilityOffTotal?
                                                        <Button size="25" variant="outline-dark" onClick={this.loadMoregrey} style={{ background: "#f12a2c !important",width : "33%", marginLeft:'33%' } }>load more</Button>
                                                        :<div style={{textAlign:'center'}}>
                                                            <span> No more data. </span>
                                                        </div>
                                                    }
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="third">
                                                    <div className="row mt-2">
                                                        {this.props.unCuratedData.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        <div className="col-4 mb-2" style={{ position: 'relative' }}>
                                                                            <label className="snap-switch ">
                                                                                <input type="checkbox" onChange={() => this.toggleAvailableForSnap(item.sml_id,item.availableForSnap)} defaultChecked={item.availableForSnap}/>
                                                                                <span className="slider round"></span>
                                                                            </label>
                                                                            <div className="toggle-img" onClick={e => this.openToggle(e, item, 'off',item.pinned?'off':'on')}>
                                                                                <span><ThreeDotsVertical /></span>
                                                                            </div>
                                                                            <a href={`/curator/${this.state.username}/outfit_${item.outfit}`} target="_blank">
                                                                                <img src={item.img_Url} alt="image" className="imaged w-100" />

                                                                            </a>
                                                                            {/* <span className="imgid">{item.sml_id}</span>  */}
                                                                            <br />
                                                                        </div>

                                                                    </>
                                                                )
                                                            })
                                                        }

                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>

                                        </Tab.Container>
                                    </div>
                                </div>
                                <div className="blogger-count full mb-2">
                                    <div className='counterVal'>Page Visits <strong>{this.props.pageVisits}</strong></div>
                                </div>
                            </div>
                        </>
                }
                <Modal
                    show={this.state.open}
                    onHide={this.closeModal}
                    className='set-img-visibility'
                    style={modalStyle}
                >
                    <Modal.Body>
                        <Row className="mb-1">
                            <Col xs={8}>
                                <span>visibility</span>
                            </Col>
                            <Col xs={4}>
                                <label className="switch">
                                    <input type="checkbox" onChange={this.toggleBlogger} defaultChecked={this.state.checked}/>
                                    <span className="slider round"></span>
                                </label>
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Col xs={8}>
                                <span>pin image</span>
                            </Col>
                            <Col xs={4}>
                                <label className="switch">
                                    <input type="checkbox" onChange={this.toggleBloggerPinned} defaultChecked={this.state.pinChecked}/>
                                    <span className="slider round"></span>
                                </label>
                            </Col>
                        </Row>
                        {/*  <Row className="mt-1">
                               <Col xs={8}>
                                    <span>Delete</span>
                               </Col>
                               <Col xs={4}>
                                    <label className="switch">
                                        <input type="checkbox" onChange={()=>alert('abc')}/>
                                        <span className="slider round"></span>
                                    </label>
                               </Col>
                           </Row> */}
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}
const mapStateToProps = state => ({
    isAuthenticated: state.isCuratorAuthenticated,
    user: state.user,
    loadingState: state.loadingState,
    blogger: state.blogger,
    totalCount: state.totalCount,
    visibilityOffdata: state.visibilityOffdata,
    visibilityOffTotal: state.visibilityOffTotal,
    pageVisits: state.pageVisits,
    bloggerMetadata : state.bloggerMetadata,
    unCuratedData : state.unCuratedData,
});
const mapDispatchToProps = dispatch => ({
    getBlogger: (pageNo,skip, insta) => dispatch(fetchBloggerDataCurator(pageNo,skip, insta)),
    bloggersetOff: (item,username) => dispatch(setoffImage(item,username)),
    bloggersetOn: (item,username) => dispatch(setonImage(item,username)),
    visibilityOff: (pageNo,skip,insta) => dispatch(visibilityOffCurator(pageNo,skip,insta)),
    curatorNotCuratedData: (username) => dispatch(curatorNotCuratedData(username)),
    fetchBloggerMetadataCurator: (username ) => dispatch(fetchBloggerMetadataCurator(username )),
    setBloggersData : (data) => dispatch(setBloggersData(data)),
    setVisdibilityOff: (data) => dispatch(setVisdibilityOff(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CuratorStore)
import React from 'react';
import { Component } from 'react';
import { isMobile } from "react-device-detect";
import { Col, Container, Row, Image, Card, Button, Modal } from 'react-bootstrap';
import {userLogin, userLogout} from '../../actions';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import NavBar from '../navbar';
import '../../styles/style.css'
import '../../styles/bloggerShop.css';
import { X, ThreeDotsVertical, ChevronDoubleLeft , BookmarksFill } from 'react-bootstrap-icons'
import { connect } from "react-redux";
import { fetchPublicCrossSellData, fetchPublicProducts , 
    getSavedLooks , addLook , deleteLook , setPublicCrossSellData, blogger_info , updateUserLoginState } from '../../actions'
import Loader from '../Loader'
import axios from 'axios';
import { Rating } from 'react-simple-star-rating'
import {InlineShareButtons} from 'sharethis-reactjs';
import { NavLink } from 'react-router-dom';
import { WhatsappShareButton} from "react-share";
import { WhatsappIcon} from "react-share";
import mixpanel, { init } from 'mixpanel-browser';
import ReactGA from 'react-ga';
mixpanel.init("1a8ebbbb5ab0d0b35dc91b5e3b1578e1" ,{
    debug :true,
    ignore_dnt: true
})


const clientId = "806676170053-kdqovn15piaombk6nubcaurib6ds9f09.apps.googleusercontent.com";
class publicShop extends Component {
    constructor(props) {
        super(props)
       /*  let insta = document.location.pathname.split('/');
        insta.pop();
        insta = insta.pop();
        insta = insta.pop(); */
        const {params} = this.props.match
        console.log(params);
        props.getPublicCrossSell(params.insta,params.outfitid) 
        this.props.updateUserLoginState()
        this.state = {
            username:params.insta,
            outfit_id:params.outfitid,
            show: false,
            open: false,
            top: 0,
            left: 0,
            type: '',
            sml_id: '',
            product_id: '',
            cat: '',
            modalState: true,
            count: 0,
            // index: params.index.substr(1),
            // img_id:params.img_id.substr(1),
            propslength: props.publicCrossSell.length,
            isSavedLook:false,
            showGoogleLogin:false
        }
        this.handleClose = this.handleClose.bind(this)
        this.handleShow = this.handleShow.bind(this)
        this.handleSaveLooks = this.handleSaveLooks.bind(this)
    }
    async componentDidMount(){
        if( isMobile && !window.navigator.userAgent.toLowerCase().includes('wv'))
            window.location = "https://play.google.com/store/apps/details?id=com.shopthelooks";

        axios.post(`/count/outfit/${this.state.username}/${this.state.outfit_id}`)
        await this.props.getPublicCrossSell(this.state.username,this.state.outfit_id) 
        await this.props.getSavedLooks();
        this.updateIsSavedLook()
        mixpanel.track("Outfit Page Loaded", {
            "Page Viewed": "Outfit Page",
        });
    }
    handleClose(e,sml_id, cat) {
        e.preventDefault()
        if (this.state.show) {

            this.setState({ show: false })
        }
        else {
            this.setState({
                show: true,
                sml_id,
                cat
            })
            this.props.PublicProducts(sml_id, cat)
        }
    }
    handleShow() {
        this.setState({ modalState: !this.state.modalState });
    }
    async handleSaveLooks(){
        ReactGA.event({category: 'Outfit page', action:`saved button clicked`,label:'saved button clicked', value: 1})
        mixpanel.track("saved button clicked")
        if(this.props.isUserAuthenticated){
            if(this.props.publicCrossSell[0].isSavedLook===false){
                await  this.props.addLook(this.props.publicCrossSell[0].sml_id)
                
            } else {
                await this.props.deleteLook(this.props.publicCrossSell[0].sml_id)
            }
            let data = this.props.publicCrossSell
            data[0].isSavedLook = !data[0].isSavedLook 
            this.props.setPublicCrossSellData(data);
        } else {
            this.setState({
                showGoogleLogin:true
            })
        }
    }
    handleIncClick = () => {
        this.setState((prevState, { count }) => ({
            count: prevState.count + 1
          }));
          console.log(this.state.count)
      };

    handleDecClick = () => {
        this.setState((prevState, { count }) => ({
            count: prevState.count - 1
          }));
          console.log(this.state.count)
      };

    countProductClick=(product_id)=>{
        axios.post(`/count/product/${this.state.username}/${this.state.outfit_id}/${product_id}`)
    }
    handleGoogleLoginClose = ()=>{
        this.setState({
            showGoogleLogin:false
        })
    }
    googleResponse = async (response) =>{
        let details = response.profileObj
        this.setState({
          reqLogin: true
        })
        await this.props.googleUserLogin(details)
        ReactGA.set({userId: details.name});
        mixpanel.register({
            "First Login Date": new Date().toISOString(),
            "Visitor type" : "End user"
        });
        mixpanel.identify(details.email);
        mixpanel.track("User sigup",{
                name: details.name,
                email: details.email,
                googleId: details.googleid,
            })
        mixpanel.alias(details.email)
        mixpanel.people.set({
                "company": "sml_User",
                "$name": details.name,
                "$email": details.email,
                        })
        this.handleGoogleLoginClose()
        await this.handleSaveLooks()
    }
    render() {
        let target = window.navigator.userAgent.toLowerCase().includes('wv')?'_self':'_blank'; 
        return (
            <>
            {this.props.loadingState?<Loader type="main" />:<>
                <NavBar title={<NavLink  style={{color:"white"}} to={`/${this.state.username}`}>{`${this.props.bloggerName}'s Store`}</NavLink>} page ='home'/>
                <div className="appCapsule">
                    <div className="pt-2 pb-2 ps-1 pe-1">
                        {this.props.publicCrossSell.map((element,i) => {
                            return (
                                <>
                                    <div className="cross-sell mt-1" id={"list"+i}>
                                        <div className="street_style">
                                            <img src={element.img_Url} alt="image" className="imagedbox" style={{ maxWidth: '100%' }} />
                                            <div className="cart" onClick={()=>this.handleSaveLooks()}>
                                                {element.isSavedLook?
                                                    <button onClick={this.handleDecClick} size="40" className='btn btn-light-unsave me-1 mb-1 rounded mt-1 save-unsave-btn'>Unsave</button>:
                                                    <button onClick={this.handleIncClick} size="25" className='btn btn-pinterest me-1 mb-1 rounded mt-1 pl-1 save-unsave-btn' style={{background: "#f12a2c !important"}}>  Save Look</button>
                                                }   
                                            </div>
                                        </div>
                                        <div className="cross_sell_cat me-1 ms-1">
                                            <ul className="listview image-listview media" style={{borderBottom: '0px'}}>
                                                <Row>
                                                    {element.crossSell.map((cross,index)=>{
                                                        return (
                                                            <>
                                                                <Col xs={6} className="cross_sell_data">
                                                                    <li style={{position:'relative'}}>
                                                                        <a href="#" className="item" onClick={e => this.handleClose(e,element.sml_id, cross.title)}>
                                                                            <Row className="newdesign">
                                                                                <Col xs={5} >
                                                                                    <div className="imageWrapper">
                                                                                        <img onClick={()=>{ReactGA.event({category: 'Outfit Page', action:'products modal opened', lable:'products modal open', value: 1});
                                                                                     mixpanel.track("products modal opened");}} src={cross.firstImage} alt="image" className="imagednew" />
                                                                                    </div>
                                                                                </Col>
                                                                                <Col xs={7}>
                                                                                   
                                                                                    <div className="in cross">
                                                                                    {/* <span className="cross-sell-title">{cross.title}</span> */}
                                                                                        <div>
                                                                                            <span className="cross-sell-title">{cross.title}</span>
                                                                                            <div className="text-muted-11-5" style={{ display: 'flex', flexDirection: 'row' }}>
                                                                                                &#8377;
                                                                                                {typeof cross.price==='undefined'|| cross.price===null?<span>
                                                                                                599
                                                                                                </span>:<span>
                                                                                                    {cross.price}
                                                                                                   </span>}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </a>
                                                                    
                                                                    </li>
                                                                </Col>
                                                            </>
                                                        )})
                                                    }
                                                </Row>
                                            </ul>
                                        </div>
                                        <div>
                                            <ul class="listview image-listview media">
                                                    {/* <li>
                                                        <div class="item">
                                                            <img src= '/static/images/explore/whatsapp.png' alt="image" class="image" />
                                                            <div class="in cross-sell-title">
                                                                <a className='text-black' href='/'><div>Click to share the outfit</div></a>
                                                            </div>
                                                        </div>
                                                    </li> */}
                                                    {isMobile ? <><li>
                                                    <div class="item">
                                                        <img src= '/static/images/explore/whatsapp.png' alt="image" class="image" /> 
                                                            <a onClick={()=>{ReactGA.event({category: 'Outfit Page', action:"whatsapp button clicked", lable: "whatsapp button clicked", value: 1});
                                                                                     mixpanel.track("whatsapp button clicked");}} ><div class="in cross-sell-title" >
                                                                <WhatsappShareButton style={{width:"100%", textAlign: 'left'}}
                                                                    url={ window.location.toString()}
                                                                    title={`${this.state.username}'s outfit`}
                                                                    hashtag={"#shopthelooks"}
                                                                    description={`Checkout this look of ${this.state.username}`}
                                                                    // className="Demo__some-network__share-button"
                                                                    image= {this.props.publicCrossSell[0]?this.props.publicCrossSell[0].img_Url:""}
                                                                > Click to share the outfit
                                                                </WhatsappShareButton>
                                                            </div></a>
                                                    </div>
                                                    </li>  </> :null}                                                
                                                    <li>
                                                        <div class="item">
                                                            <img src= {this.props.bloggerInfo.profile_img} alt="image" class="image" />
                                                            <div class="in cross-sell-title">
                                                                <a onClick={()=>{ReactGA.event({category: 'Outfit Page', action:`${this.state.username} store viewed`, lable:`${this.state.username} store viewed`, value: 1});
                                                                                     mixpanel.track(`${this.state.username} store viewed`);}}className='text-black' href={`/${this.state.username}`} text='black'><div>Click to Explore {this.props.bloggerName}'s store</div></a>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="item">
                                                            <img src= '/static/images/explore/earth.png' alt="image" class="image" />
                                                            <div class="in cross-sell-title">
                                                                <a onClick={()=>{ReactGA.event({category: 'Outfit Page', action:"explore page link clicked", lable:"explore page link clicked", value: 1});
                                                                                     mixpanel.track("explore page link clicked");}} className='text-black' href='/'><div>Click to Explore ShopTheLooks World</div></a>
                                                            </div>
                                                        </div>
                                                    </li>
                                            </ul>
                                        </div>
                                        {/* {isMobile ?<div style={{marginRight:'0.5rem'}}>
                                        <InlineShareButtons
                                            config={{
                                                alignment: 'right',  // alignment of buttons (left, center, right)
                                                color: 'social',      // set the color of buttons (social, white)
                                                enabled: true,        // show/hide buttons (true, false)
                                                font_size: 16,        // font size for the buttons
                                                labels: 'cta',        // button labels (cta, counts, null)
                                                language: 'en',       // which language to use (see LANGUAGES)
                                                networks: [   
                                                'whatsapp'
                                                ],
                                                padding: 12,          // padding within buttons (INTEGER)
                                                radius: 4,            // the corner radius on each button (INTEGER)
                                                size: 40,             // the size of each button (INTEGER)

                                                // OPTIONAL PARAMETERS
                                                url: window.location.toString(), // (defaults to current url)
                                                image: this.props.publicCrossSell[0]?this.props.publicCrossSell[0].img_Url:"",  // (defaults to og:image or twitter:image)
                                                description: `Checkout this look of ${this.state.username}`,       // (defaults to og:description or twitter:description)
                                                title:`${this.state.username}'s outfit`,            // (defaults to og:title or twitter:title)
                                            }}
                                            />
                                            </div>:null} */}
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
            </> }
                <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    className="product-modal"
                    scrollable 
                >
                    <Modal.Header>
                        <a href="#" onClick={this.handleClose} style={{ marginLeft: '90%' }}>
                            <X color="white" size={30} />
                        </a>
                    </Modal.Header>

                    <Modal.Body>
                        <Row>
                            {this.props.modalloading?<Loader type="modalmain"/>:this.props.publicProducts.map(item1 =>{
                                return (
                                    <Col xs={6} md={3} lg={3} style={{ position: "relative" }}>
                                        <a href={item1.product_url} className="item" target="_blank" onClick={()=>{ this.countProductClick(item1.product_id) }}>
                                                <div className="imageWrapper">
                                                    <img onClick={()=>{ReactGA.event({category: 'Outfit Page', action:`${item1.product_image_url} product clicked`, lable:'product clicked', value: 1});
                                                mixpanel.track(`${item1.product_image_url} product clicked`);}} src={item1.product_image_url} alt="image" className="modalimaged fit-product-images" />

                                                </div>
                                                <div className="in">
                                                    <div>
                                                        <h4 className="mb-05">{item1.title}</h4>
                                                           <div> {item1.rating >= 3.5 ? <Rating
                                                                                        initialValue={item1.rating}
                                                                                        size={20}
                                                                                        transition
                                                                                        allowHalfIcon
                                                                                        readonly/> : null }</div>                           
                                                        <div className="text-muted">
                                                            {item1.description.substring(0, 10)+ "..."}
                                                            <div className="mt-05"><strong>&#8377;{item1.price}&nbsp;</strong><span>{item1.brand}</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </a> 
                                   
                                    </Col>
                                )
                            })}
                        </Row>
                    </Modal.Body>
                </Modal>
                <Modal
                    show={this.state.showGoogleLogin}
                    onHide={this.handleGoogleLoginClose}
                    className="product-modal google-login-modal"
                >
                    <Modal.Header>
                        <a href="#" onClick={this.handleGoogleLoginClose} style={{ marginLeft: '90%' }}>
                            <X color="white" size={30} />
                        </a>
                    </Modal.Header>

                    <Modal.Body>
                        <Row>
                            <h2>Please login to save this look</h2>
                          <div className="google-login">
                                {this.props.isLogin?
                                <GoogleLogout
                                clientId={clientId}
                                buttonText="Logout"
                                // onLogoutSuccess={logout}
                                />
                                :<GoogleLogin
                                    clientId={clientId}
                                    buttonText="Sign-in with Google"
                                    onSuccess={this.googleResponse}
                                    // onFailure={this.googleResponse}
                                    cookiePolicy={'single_host_origin'}
                                />
                                }   
                            </div>
                        </Row>
                    </Modal.Body>
                </Modal>
                        
            </>
        )
    }
}
const mapStateToProps = state => ({
    loadingState: state.loadingState,
    publicCrossSell: state.publicCrossSell,
    publicProducts: state.publicProducts,
    totalCount: state.totalCount,
    bloggerInfo: state.bloggerInfo,        
    modalloading: state.modalloading,
    isUserAuthenticated : state.isUserAuthenticated,
    userSavedLooks: state.userSavedLooks,
    EndUserInfo : state.EndUserInfo,
    bloggerName : state.bloggerName,
    bloggerInfo: state.bloggerInfo,
});
const mapDispatchToProps = dispatch => ({
    updateUserLoginState: () => dispatch(updateUserLoginState()),
    getPublicCrossSell: (insta,outfit) => dispatch(fetchPublicCrossSellData(insta,outfit)),
    PublicProducts: (sml_id, cat) => dispatch(fetchPublicProducts(sml_id, cat)),
    getSavedLooks :() =>dispatch(getSavedLooks()),
    addLook :(sml_id) => dispatch(addLook(sml_id)),
    deleteLook :(sml_id) => dispatch(deleteLook(sml_id)),
    googleUserLogin : (googleProfile) => dispatch(userLogin(googleProfile)),
    userLogout:()=>dispatch(userLogout()),
    setPublicCrossSellData : (data) => dispatch(setPublicCrossSellData(data)),
    getBloggerInfo: insta => dispatch(blogger_info(insta)),
});
export default connect(mapStateToProps, mapDispatchToProps)(publicShop);

import React from 'react'
import PublicNavbar from './publicNavbar'
import { Button, Col,  Modal, Tab, Nav, Row , NavItem, Tabs} from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { getUserSavedLooks , getUserExploreLooks , getUserMyLooks } from '../../actions'
class GridStructure extends React.Component {
    
    render() {
        let arraysData =[]
        this.props.LooksArray.forEach((e,i)=>{
            if(i%3==0)
                arraysData.push([e])
            else 
                arraysData[arraysData.length-1].push(e)
        })
        return (
              <div className='m-1'>
            <Row >
                  {arraysData.map((arr,index)=>{
                        if(index%5==2){
                              return  <Row key={index} style={{ marginLeft: "0", marginRight: "0", padding:"0" }}>
                                          <div className="col-4 mb-2" style={{position:'relative'}}> 
                                                <Row style={{ height:"47.7%" , marginBottom:"16px"}}>
                                                      <div className="col-12 mb-2" style={{position:'relative',height:"100%"}}> 
                                                            <Link to={`/outfit/${arr[0].username}/outfit${arr[0].outfit}`} target="_blank" style={{height:"100%"}}>
                                                                  <img src={arr[0].img_Url} alt="image" className="imaged w-100" style={{"height":"100%"}}/>
                                                            
                                                            </Link>
                                                            <br/>
                                                      </div>
                                                </Row>
                                                <Row style={{ height:"47.7%" }}>
                                                      {arr[2]?<div className="col-12" style={{position:'relative'}}> 
                                                            <Link to={`/outfit/${arr[2].username}/outfit${arr[2].outfit}`} target="_blank">
                                                                  <img src={arr[2].img_Url} alt="image" className="imaged w-100" />
                                                            
                                                            </Link>
                                                            <br/>
                                                      </div>:null}
                                                </Row>
                                                <br/>
                                          </div>
                                          {arr[1]?<div className="col-8 mb-2" style={{position:'relative'}}> 
                                                <Link to={`/outfit/${arr[1].username}/outfit${arr[1].outfit}`} target="_blank">
                                                      <img src={arr[1].img_Url} alt="image" className="imaged w-100" />
                                                
                                                </Link>
                                                <br/>
                                          </div>:null}
                                    </Row>
                              
                        }
                        else if(index%5==4){
                              return 
                                    <Row key={index} style={{ marginLeft: "0", marginRight: "0", padding:"0" }}>
                                          <div className="col-8 mb-2" style={{position:'relative'}}> 
                                                <Link to={`/outfit/${arr[0].username}/outfit${arr[0].outfit}`} target="_blank">
                                                      <img src={arr[0].img_Url} alt="image" className="imaged w-100" />
                                                
                                                </Link>
                                                <br/>
                                          </div>
                                          <div className="col-4 mb-2" style={{position:'relative'}}> 
                                                {arr[1]?<Row style={{ height:"47.7%", marginBottom:"16px"}}>
                                                      <div className="col-12 mb-2" style={{position:'relative',height:"100%"}}> 
                                                            <Link to={`/outfit/${arr[1].username}/outfit${arr[1].outfit}`} target="_blank">
                                                                  <img src={arr[1].img_Url} alt="image" className="imaged w-100" height="100%" />
                                                            
                                                            </Link>
                                                            <br/>
                                                      </div>
                                                </Row>:null}
                                                {arr[2]?<Row style={{ height:"47.7%" }}>
                                                      <div className="col-12" style={{position:'relative'}}> 
                                                            <Link to={`/outfit/${arr[2].username}/outfit${arr[2].outfit}`} target="_blank">
                                                                  <img src={arr[2].img_Url} alt="image" className="imaged w-100" style={{height:"100%"}}/>
                                                            
                                                            </Link>
                                                            <br/>
                                                      </div>
                                                </Row>:null}
                                                <br/>
                                          </div>
                                    </Row>
                        }
                        return arr.map(item=>{
                              return(
                              <>
                                    <div key={item.outfit} className="col-4 mb-2" style={{position:'relative'}}> 
                                          <Link to={`/outfit/${item.username}/outfit${item.outfit}`} target="_blank">
                                                <img src={item.img_Url} alt="image" className="imaged w-100" />
                                          
                                          </Link>
                                          <br/>
                                    </div>      
                              </>
                        )})
                        })   
                  }                                        

            </Row>
            </div>
        )
    }
}
export default GridStructure
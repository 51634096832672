import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import NavBar from './../navbar';
import { userLogin } from '../../actions';
import { connect } from "react-redux";
import 'react-image-upload/dist/index.css'
import Axios from 'axios';
import { isMobile } from 'react-device-detect';



class LensResult extends Component {
      constructor(props) {
            console.log(props);
            super(props);
            this.state = {
                  requested: false,
                  requestedImagePath: null,
                  errored: false,
                  loading: true,
                  responseData: [],
                  currentCat: null,
                  responseReceived : false,
                  dailyLimitReached : false
            };
            this.masterCatsList = ["Topwear", "Bottomwear", "One-Piece", "Outerwear", "Footwear", "Bags", "Accesories"]
            this.getResponse()
      }

      getResponse = async() =>{
            let data = {
                  searchHash : this.props.match.params.searchHash
            }
            let result = await Axios.post('/lens-result-from-hash', data)
            if (result.data.searchResult) {
                  let responseData = []
                  let { response , imgUrl }= result.data.searchResult
                  this.masterCatsList.forEach(cat => {
                        if (response[cat] && response[cat].length > 0) {
                              responseData.push({
                                    masterCategory: cat,
                                    products: response[cat]
                              })
                        }

                  })
                  let currentCat = responseData[0]?responseData[0].masterCategory:null;
                  let loading = false
                  let dailyLimitReached = result.data.dailyLimitReached==true?true:false;
                  if(responseData.map(c => c.masterCategory).includes("Topwear") && responseData.map(c => c.masterCategory).includes("Bottomwear")){
                        responseData = responseData.filter(c => c.masterCategory!="One-Piece");
                  }
                  this.setState({imgUrl, loading , responseData, currentCat });
            } else {
                  this.setState({errored: true})
            }
      }


      componentDidMount = async () => {


      }
      
      updateCurrentCat = (cat)=>{
            this.setState({currentCat: cat})
      }


      render() {
            let isWebview = window.navigator.userAgent.toLowerCase().includes('wv')?true:false; 
            console.log(this.state);
            let products = this.state.responseData.length>0?this.state.responseData.filter(cat => cat.masterCategory == this.state.currentCat)[0].products.map(item1 => {
                        return <Col xs={6} md={3} lg={3} style={{ position: "relative" }} className='mb-2'>
                        <a href={item1.product_url} className="item" target="_blank" onClick={() => { this.countProductClick(item1.product_id) }}>
                              <div className="imageWrapper">
                                    <img src={item1.image_url} alt="image" className="modalimaged fit-product-images" />
                              </div>
                              <div className="in">
                                    <div>
                                          <h4 className="mb-05">{item1.title}</h4>
                                          <div className="text-muted">
                                                {item1.description.substring(0, 10)+ "..."}
                                                <div className="mt-05"><strong>&#8377;{item1.price}&nbsp;</strong><span>{item1.ecommerce}</span></div>
                                          </div>
                                    </div>
                              </div>
                        </a>

                  </Col>}):null
            return (
                  <>

                        <NavBar title={<img style={{ height: '1em', width:'50%' }} src='/static/images/stl_logo.JPG' />} page="home" />
                        <div className="appCapsule">

                              {this.state.imgUrl && <div className="app-header">
                                    <div className='upload-image-wrapper-class'>
                                          <img className='lens-request-image' src={this.state.imgUrl}/>
                                    </div>

                              </div>}
                              { this.state.loading == false || this.state.errored ==true
                              ?
                              this.state.responseData.length ?
                                     <div>
                                          <div className='mx-2 my-1'>
                                                {this.state.responseData.map(cat => <button className={cat.masterCategory == this.state.currentCat ? 'btn btn-dark  me-1 mb-1' : 'btn btn-outline-dark me-1 mb-1 mt-1'} onClick={()=>this.updateCurrentCat(cat.masterCategory)}>{cat.masterCategory}</button>)}
                                          </div>
                                          <Row className='px-1'>
                                                {products}
                                          </Row>
                                    </div>:<center><div className='m-5'><h3>No history found...!</h3></div></center> 
                              : <div className='m-5'><center>
                                    <div className="spinner-border mx-5 my-2" role="status">
                                          <span className="sr-only">Loading...</span>
                                    </div>
                                    <h3>Finding similar products...</h3>
                              </center></div>}
                              { !isWebview && isMobile && 
                                    <center className='mt-5'>
                                          <h5>ShopTheLook Lens will help you find celebrity/influencer clothes, and clothes similar to premium brands in just 1-tap, all within your budget. Download Now.</h5>
                                          <div style={{width:"100px"}} className=''>
                                                <a href='https://play.google.com/store/apps/details?id=com.shopthelooks' target='_blank'>
                                                      <img src='/static/images/playstore_icon.png'/>
                                                </a>
                                          </div>
                                    </center>
                              }
                        </div>
                  </>
            );
      }
}


const mapStateToProps = state => ({
      isUserAuthenticated: state.isUserAuthenticated,
      lensData: state.lensData
})
const mapDispatchToProps = dispatch => ({
      googleUserLogin: (googleProfile) => dispatch(userLogin(googleProfile)),
})
export default connect(mapStateToProps, mapDispatchToProps)(LensResult);
import React from 'react'
import Swal from 'sweetalert2'
import { resetPassword } from '../api/publicApis'
let passErr = {}
class ResetPassword extends React.Component {
    constructor(props) {
        super(props)
        let { email, token } = this.props.match.params
        console.log(email.substr(1), token.substr(1))
        this.state = {
            password1: '',
            password2: '',
            passErr: {},
            reset: false,
            email: email.substr(1),
            token: token.substr(1),
            resetMsg: '',
            success: false
        }
        this.checkedPwd = this.checkedPwd.bind(this)
        this.savePwd = this.savePwd.bind(this)
    }
    checkedPwd(e) {
        if (e.target.id === "password1") {
            if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(e.target.value)) {
                this.setState({
                    password1: e.target.value
                })
                passErr[e.target.id] = '';
            }
            else {
                passErr[e.target.id] = "Password must contain atleast 8 characters, one uppercase letter, one lowercase letter, one number and one special character."
            }
        }
        else if (e.target.id === "password2") {
            if (e.target.value !== '' && e.target.value === this.state.password1) {
                this.setState({
                    password2: e.target.value
                })
                passErr[e.target.id] = ''
            }
            else {
                passErr[e.target.id] = 'Password do not match'
            }
        }
        this.setState({
            passErr
        })
    }
    async savePwd(e) {
        e.preventDefault()
        let res = await resetPassword(this.state.email, this.state.password1, this.state.token)
        if (res.data.statusText === 'OK') {
            this.setState({
                reset: true,
                success:true,
                resetMsg: 'Your password have been reset successfully.'
            })
        }
        else if (res.data.statusText === "invalid") {
            this.setState({
                reset: true,
                success:false,
                resetMsg: 'The validity for the reset password link is outdated, please re-request the new link.'
            })
        }
        else if (res.data.statusText === "error") {
            Swal.fire({
                icon: 'error',
                title: 'Opps',
                text: "something went wrong please try again."
            })
        }
    }
    render() {
        return (
            <div id="appCapsule">
                <div className="login-form">
                    {this.state.reset
                    ?<>
                        <div className="section">
                            <h4>{this.state.resetMsg}</h4>
                            {this.state.success &&
                            <h4>
                                <a href="/shop/login" target="_self">
                                    Login
                                </a>
                            </h4>
                            }
                        </div> 
                    </>
                    :
                    <>
                    <div className="section">
                        <h1>Reset Password</h1>
                        <h4>Type your new password</h4>
                    </div>
                    <div className="section mt-2 mb-5">
                        <form>
                            <div className="form-group boxed">
                                <div className="input-wrapper">
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password1"
                                        placeholder="New Password"
                                        onChange={this.checkedPwd}
                                    />
                                    <div className="validation">{this.state.passErr.password1}</div>
                                </div>
                            </div>
                            <div className="form-group boxed">
                                <div className="input-wrapper">
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password2"
                                        placeholder="Re-enter new password"
                                        onChange={this.checkedPwd}
                                    />
                                    <div className="validation">{this.state.passErr.password2}</div>
                                </div>
                            </div>
                            <div className="form-button-group">
                                <button type="submit" className="btn btn-primary btn-block btn-lg" onClick={this.savePwd}>
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                    </>
                    }
                </div>
            </div>
        )
    }
}
export default ResetPassword
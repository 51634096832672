import React, {Component} from 'react';
import {connect} from "react-redux";
import { Col,Row,Button} from 'react-bootstrap';
import PublicNavbar from '../navbar';
import {Instagram} from 'react-bootstrap-icons';
import { exploreData } from './../../api/publicApis';
import Header from '../header';
import '../../styles/style.css'
import '../../styles/blogger_store.css'
import {bloggerStyle, blogger_info} from '../../actions';
import Loader from '../Loader'
import { Link } from 'react-router-dom';
import axios from 'axios';


class publicExplore extends Component{
    constructor(props){
        super(props)
        let pageData = [];
        this.state={
            tabActive: 'first',
            looks :pageData
        }
        this.loadMore = this.loadMore.bind(this)
    }
    componentDidMount(){
      this.loadMore();
    }
   /*  componentWillReceiveProps(nextProps) {
        this.setActiveKey();
    } */
    setActiveKey=(key)=>{   
        this.setState({
            tabActive: key
        });
    } 
    // load more data
      async loadMore(){
            let pageData = await exploreData()
            let arraysData =[]
            pageData.forEach((e,i)=>{
                  if(i%3==0)
                        arraysData.push([e])
                  else 
                        arraysData[arraysData.length-1].push(e)
            })
            let looks = [...this.state.looks,...arraysData]
            this.setState({
                  looks:looks
            });
      }


    render()
    {
      let target = window.navigator.userAgent.toLowerCase().includes('wv')?'_self':'_blank'; 
        return(
            <>
                {this.props.loadingState?<Loader/>
                :<>
                <PublicNavbar title="Influencer looks" page="home"/> 
                <div className="appCapsule">
                    <Header profile_img={"./static/images/desktop/women-chill.gif"}/>
                    <div className="app-header">
                        <Row>
                            <Col>
                                <span>The best of instagram fashion</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span> with everything shoppable</span>
                            </Col>
                        </Row>
                    </div>
                    <div className="section full mb-2">
                        <div className="mt-2 pe-2 ps-2">
                            <div className="row">
                                {this.state.looks.map((arr,index)=>{
                                          if(index%5==2){
                                                return <>
                                                      <Row style={{ marginLeft: "0", marginRight: "0", padding:"0" }}>
                                                            <div className="col-4 mb-2" style={{position:'relative'}}> 
                                                                  <Row style={{ height:"47.7%" , marginBottom:"16px"}}>
                                                                        <div className="col-12 mb-2" style={{position:'relative',height:"100%"}}> 
                                                                              <Link to={`/outfit/${arr[0].username}/outfit${arr[0].outfit}`} target="_blank" style={{height:"100%"}}>
                                                                                    <img src={arr[0].img_Url} alt="image" className="imaged w-100" style={{"height":"100%"}}/>
                                                                              
                                                                              </Link>
                                                                              <br/>
                                                                        </div>
                                                                  </Row>
                                                                  <Row style={{ height:"47.7%" }}>
                                                                        <div className="col-12" style={{position:'relative'}}> 
                                                                              <Link to={`/outfit/${arr[1].username}/outfit${arr[1].outfit}`} target="_blank">
                                                                                    <img src={arr[1].img_Url} alt="image" className="imaged w-100" />
                                                                              
                                                                              </Link>
                                                                              <br/>
                                                                        </div>
                                                                  </Row>
                                                                  <br/>
                                                            </div>
                                                            <div className="col-8 mb-2" style={{position:'relative'}}> 
                                                                  <Link to={`/outfit/${arr[2].username}/outfit${arr[2].outfit}`} target="_blank">
                                                                        <img src={arr[2].img_Url} alt="image" className="imaged w-100" />
                                                                  
                                                                  </Link>
                                                                  <br/>
                                                            </div>
                                                      </Row>
                                                </>
                                          }
                                          else if(index%5==4){
                                                return <>
                                                      <Row style={{ marginLeft: "0", marginRight: "0", padding:"0" }}>
                                                            <div className="col-8 mb-2" style={{position:'relative'}}> 
                                                                  <Link to={`/outfit/${arr[0].username}/outfit${arr[0].outfit}`} target="_blank">
                                                                        <img src={arr[0].img_Url} alt="image" className="imaged w-100" />
                                                                  
                                                                  </Link>
                                                                  <br/>
                                                            </div>
                                                            <div className="col-4 mb-2" style={{position:'relative'}}> 
                                                                  <Row style={{ height:"47.7%", marginBottom:"16px"}}>
                                                                        <div className="col-12 mb-2" style={{position:'relative',height:"100%"}}> 
                                                                              <Link to={`/outfit/${arr[1].username}/outfit${arr[1].outfit}`} target="_blank">
                                                                                    <img src={arr[1].img_Url} alt="image" className="imaged w-100" height="100%" />
                                                                              
                                                                              </Link>
                                                                              <br/>
                                                                        </div>
                                                                  </Row>
                                                                  <Row style={{ height:"47.7%" }}>
                                                                        <div className="col-12" style={{position:'relative'}}> 
                                                                              <Link to={`/outfit/${arr[2].username}/outfit${arr[2].outfit}`} target="_blank">
                                                                                    <img src={arr[2].img_Url} alt="image" className="imaged w-100" style={{height:"100%"}}/>
                                                                              
                                                                              </Link>
                                                                              <br/>
                                                                        </div>
                                                                  </Row>
                                                                  <br/>
                                                            </div>
                                                      </Row>
                                                </>
                                          }
                                          return arr.map(item=>{
                                                return(
                                                <>
                                                      <div className="col-4 mb-2" style={{position:'relative'}}> 
                                                            <Link to={`/outfit/${item.username}/outfit${item.outfit}`} target="_blank">
                                                                  <img src={item.img_Url} alt="image" className="imaged w-100" />
                                                            
                                                            </Link>
                                                            <br/>
                                                      </div>      
                                                </>
                                            )})
                                          })
                                        
                                    }
                                    {
                                         this.state.looks.length < 6000?
                                         <Button variant="outline-dark" onClick={this.loadMore}>load more</Button>
                                         :<div style={{textAlign:'center'}}>
                                            <span> No more data. </span>
                                        </div>
                                    }
                            </div>
                        </div>
                    </div>
                </div>
                </>
                }
            </>
        );
    }
}
const mapStateToProps  = state =>({
    loadingState: state.loadingState,
    blogger_public: state.blogger_public,
    bloggerInfo: state.bloggerInfo,
    totalCount: state.totalCount
});
const mapDispatchToProps = dispatch =>({
    getbloggerStyle: (pageNo,insta) => dispatch(bloggerStyle(pageNo,insta)),
    getBloggerInfo: insta => dispatch(blogger_info(insta))
    /* visibilityOff: (pageNo,insta) => dispatch(visibilityOff(pageNo,insta)) */
});
export default connect(mapStateToProps,mapDispatchToProps)(publicExplore)
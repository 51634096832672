import React from 'react'
import bckimg from '../images/blogger-store-header(400x1000).png';
const Header =(props)=>{
    return(
        <div className="profile-section" style={{backgroundImage:`url(${bckimg})`,
            backgroundRepeat:'no-repeat', backgroundSize:'80% auto',
            backgroundPosition:'center'    
        }}>
            <img src={props.profile_img} alt="profile-img" className="profile-img"/>
        </div>
    )
}
export default Header
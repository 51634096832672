import React from 'react'
import Navbar from '../navbar'
import { Button, Col, Modal, Tab, Nav, Row, NavItem, Tabs } from 'react-bootstrap'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom';
import {
    getUserSavedLooks, getUserExploreLooks, getUserMyLooks, userMyLooks, fetchPublicProducts,
    setPublicProductsData, setLooksToEmpty, getBoardData, updateUserLoginState, setIsUserAuthenticated
    , exploreUserLooksNextPage, exploreUserLooks
} from '../../actions'
import { ArrowRightSquareFill, X } from 'react-bootstrap-icons';
import { Rating } from 'react-simple-star-rating';
import Loader from '../Loader'
import GridStructure from './grid15structure';
import Preferences from './preferences'
import { GetUserPreferences, addSavedLook, removeSavedLook } from './../../api/publicApis'
import PublicLoginModal from './publicLoginModal'
import { isMobile } from "react-device-detect";
import { InlineShareButtons } from 'sharethis-reactjs';
import queryString from 'query-string';
import Axios from 'axios';
import mixpanel, { init } from 'mixpanel-browser';
import ReactGA from 'react-ga'
mixpanel.init("1a8ebbbb5ab0d0b35dc91b5e3b1578e1", {
    debug: true,
    ignore_dnt: true
})
class Cart extends React.Component {
    constructor(props) {
        super(props)
        let tabKeyValue = window.location.pathname.toString().slice(1)
        tabKeyValue = tabKeyValue == "" ? "explore" : tabKeyValue
        let username = queryString.parse(this.props.location.search).username
        this.props.getSavedLooks(1, "All", "All", username)
        this.props.getExploreLooks(1, "All", "All", [])
        this.props.getMyLooks(1, "All", "All", [])
        this.props.updateUserLoginState()
        this.myRef = React.createRef()
        if (username)
            this.props.getBoardData(username)
        this.state = {
            tabKey: tabKeyValue,
            exploreLooks: [],
            savedLooks: [],
            myLooks: [],
            currentSavedLooksPage: 1,
            categoriesFiltered: "All",
            occasionFiltered: "All",
            allCats: {
                "All": "All",
                "Topwear": "Topwear",
                "dresses": "Dresses",
                "pants": "Trousers",
                "jeans": "Jeans",
                "skirts": "Skirts",
                "jumpsuits": "One-Piece"
            },
            allOccasions: ["All", "Officewear", "Casual", "Party", "Sportwear", "Loungewear"
                , "Date Wear", "Ethnic casual", "Indo-western"],
            loadingRequested: false,
            showLogin: true,
            showProducts: false,
            username,
            seenMyLooks: 0,
            requestedMyLooksData: false,

        }
        this.currentObservedPosts = 0;
        this.seenPosts = []
        this.hideLogin = this.hideLogin.bind(this);
        this.postObserver = new IntersectionObserver(entries => {
            let sml_id = entries.filter(e => e.isIntersecting).map(e => e.target.id)[0]
            if (sml_id) this.markPostSeen(sml_id)
        }, { root: null, threshold: 0.5 })
    }
    componentDidUpdate() {
        let tabKeyValue = window.location.pathname.toString().slice(1)
        tabKeyValue = tabKeyValue == "" ? "explore" : tabKeyValue
        if (this.state.tabKey != tabKeyValue)
            this.setState({
                tabKey: tabKeyValue
            })
        if (this.currentObservedPosts != this.props.myLooks.length) {
            if (this.props.myLooks.length < this.currentObservedPosts) this.currentObservedPosts = 0
            if (this.currentObservedPosts == 0) this.postObserver.disconnect()
            let lastObserved = this.currentObservedPosts
            this.currentObservedPosts = this.props.myLooks.length
            let allPosts = document.querySelectorAll('.my-looks-outfit-post')
            for (let i = 0; i < allPosts.length; i++) {
                this.postObserver.observe(allPosts[i])
            }
        }
    }

    hideLogin() {
        this.setState({ showModal: false, showLogin: false })
    }


    //function takes sm_id as paramter and marks post as seen
    markPostSeen = async (sml_id) => {
        let element = document.querySelector(`#${sml_id}`)
        this.postObserver.unobserve(element)
        if (!this.seenPosts.includes(sml_id)) {
            this.seenPosts.push(sml_id)
            await Axios.post('/api/user/seenPost', { sml_id })
        }
    }

    onScroll = async () => {
        if (this.state.loadingRequested == false) {
            let dims = document.querySelector('body').getClientRects()[0]
            if (dims.height + dims.top < 1000) {
                //set loading state to true
                this.setState({ loadingRequested: true })
                if (this.state.tabKey == "explore" && this.props.exploreLooksHasNextPage) {
                    ReactGA.event({ category: 'Home page', action: `explore page scrolled`, value: 1 })
                    await this.props.getExploreLooks(2, this.state.occasionFiltered, this.state.categoriesFiltered, this.props.exploreLooks.map(c => c.sml_id))
                } else if (this.state.tabKey == "my-looks") {
                    ReactGA.event({ category: 'Home page', action: `bodyshape page scrolled`, value: 1 })
                    await this.props.getMyLooks(2, this.state.occasionFiltered, this.state.categoriesFiltered, this.props.myLooks.map(c => c.sml_id))
                }
                this.setState({ loadingRequested: false })
            }
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.onScroll)
    }


    componentDidMount = async () => {
        window.addEventListener('scroll', this.onScroll)
        mixpanel.track("Explore Page Loaded", {
            "Page Viewed": "Explore Page",
        });
    }

    handleClose(e, sml_id, cat) {
        e.preventDefault()
        if (this.state.showProducts) {

            this.setState({ showProducts: false })
            this.props.hidePreviousProducts()
        }
        else {
            this.setState({
                showProducts: true,
                sml_id,
                cat
            })
            this.props.PublicProducts(sml_id, cat)
        }
    }
    handleSaveLooks = async (sml_id, value, tabKey) => {
        let result;
        let button_value
        if (value) {
            button_value = "saved"
        } else {
            button_value = "unsaved"
        }
        ReactGA.event({ category: 'Home page', action: `${button_value} button clicked`, label: `${button_value}`, value: 1 })
        mixpanel.track(`${button_value} button clicked`)
        if (value)
            result = await addSavedLook(sml_id)
        else
            result = await removeSavedLook(sml_id)
        if (result.data.status === "OK") {
            let crossSellData = tabKey == 'my-looks' ? [...this.props.myLooks] : [...this.props.exploreLooks]
            crossSellData.forEach(cross => {
                if (cross.sml_id == sml_id)
                    cross.isSavedLook = value
            })
            if (tabKey == 'my-looks')
                this.props.userMyLooks(1, crossSellData)
            else
                this.props.exploreUserLooks(1, crossSellData)
            this.props.getSavedLooks(1, this.state.occasionFiltered, this.state.categoriesFiltered, this.state.username)
        } else {
            this.props.setIsUserAuthenticated(false);
        }
        if (!this.props.isUserAuthenticated) {
            this.setState({ showModal: true })
        }
    }
    updateFilters = async (type, value) => {
        ReactGA.event({ category: 'Home page', action: `${type} Filter clicked`, lable: `${value} filter Clicked`, value: 1 })
        mixpanel.track(`${type} Filter clicked & ${value} filter Clicked`)
        this.props.setLooksToEmpty()
        let stateObj = {}
        console.log(this.state)
        let filterName = type == 'occasions' ? 'occasionFiltered' : 'categoriesFiltered'
        stateObj[filterName] = value
        if (this.state.tabKey == 'saved-looks') {
            console.log("saved Looks")
            let AllFilter = type == 'occasions' ? 'categoriesFiltered' : 'occasionFiltered'
            stateObj[AllFilter] = "All"
            console.log(stateObj)
        }
        console.log(stateObj)
        await new Promise(res => this.setState(stateObj, res))
        await this.props.getExploreLooks(1, this.state.occasionFiltered, this.state.categoriesFiltered, [])
        await this.props.getSavedLooks(1, this.state.occasionFiltered, this.state.categoriesFiltered, this.state.username)
        await this.props.getMyLooks(1, this.state.occasionFiltered, this.state.categoriesFiltered, [])
    }
    render() {
        let target = window.navigator.userAgent.toLowerCase().includes('wv')?'_self':'_blank'; 
        let CatsButtons = Object.keys(this.state.allCats).map(cat => this.state.categoriesFiltered != cat ?
            <button key={cat} type="button" className="btn btn-outline-dark outlined rounded shadowed me-1 mb-1" onClick={() => this.updateFilters('categories', cat)}>{this.state.allCats[cat]}</button>
            : <button key={cat} type="button" className="btn btn-dark rounded shadowed me-1 mb-1">{this.state.allCats[this.state.categoriesFiltered]}</button>)

        let occasionsButtons = this.state.allOccasions.map(occ => this.state.occasionFiltered != occ ?
            <button key={occ} type="button" className="btn btn-outline-dark outlined rounded shadowed me-1 mb-1" onClick={() => this.updateFilters('occasions', occ)}>{occ}</button>
            : <button key={occ} type="button" className="btn btn-dark rounded shadowed me-1 mb-1">{this.state.occasionFiltered}</button>)
        return (
            <>

                <Navbar title={<a href='/'><img style={{ height: '1em' }} src='/static/images/stl_logo.JPG' /></a>} page='home' />
                <div className="appCapsule" >
                    <div className="appCapsule extraHeader p-0">
                        <ul role="tablist" className="nav nav-tabs lined">
                            <li className="nav-item">
                                <NavLink onClick={() => {
                                    ReactGA.event({ category: 'Home page', action: 'explore tab clicked', lable: 'explore tab Opened', value: 1 });
                                    mixpanel.track("Explore tab clicked");
                                }} className={this.state.tabKey == "explore" ? "nav-link active" : "nav-link"} data-bs-toggle="tab" to="/explore" role="tab" aria-selected="true">
                                    Explore Looks
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={() => {
                                    ReactGA.event({ category: 'Home page', action: 'bodyshape tab clicked', lable: 'bodyshape viewd', value: 1 });
                                    mixpanel.track("BodyShape tab clicked");
                                }} className={this.state.tabKey == "my-looks" ? "nav-link active" : "nav-link"} data-bs-toggle="tab" to="/my-looks" role="tab" aria-selected="false">
                                    <img style={{ width: '2em !important', height: '2em' }} src='/static/images/explore/body.png'></img>
                                    Bodyshape
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={() => {
                                    ReactGA.event({ category: 'Home page', action: 'Saved Looks tab clicked', lable: 'Saved looks viewd', value: 1 });
                                    mixpanel.track("Saved-Looks tab clicked");
                                }} className={this.state.tabKey == "saved-looks" ? "nav-link active" : "nav-link"} data-bs-toggle="tab" to="/saved-looks" role="tab" aria-selected="false">
                                    Saved Looks
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div id="appCapsule" >
                        <div className=" full mb-2">

                            <Tab.Container id="left-tabs-example" activeKey={this.state.tabKey}>

                                <Tab.Content>
                                    <Tab.Pane eventKey="explore" >
                                        {isMobile ? <div>
                                            <img src="/static/images/explore/mobile_banner.png" alt="alt" className="imaged w-100" />
                                        </div> : <div>
                                            <img src="/static/images/explore/desktop_banner.png" alt="alt" className="imaged w-100" />
                                        </div>}
                                        <div className='horizontal-buttons-div mb-1 mt-1'><button type="button" className="btn btn-text-dark me-1 mb-1">Occasions  : </button>{occasionsButtons}</div>
                                        <div className='horizontal-buttons-div mb-1 mt-1'><button type="button" className="btn btn-text-dark me-1 mb-1">Categories : </button>{CatsButtons}</div>
                                        <>
                                            {this.props.exploreLooks.map((crossSell, i) => {
                                                return <div key={crossSell.sml_id} id={crossSell.sml_id} className='pt-2 pb-2 ps-1 pe-1 my-looks-outfit-post'>
                                                    <a onClick={() => {
                                                        ReactGA.event({ category: 'Home page', action: `${crossSell.username} Profile visited`, lable: `${crossSell.username} outfit page visited`, value: 1 });
                                                        mixpanel.track(`${crossSell.username} Profile visited`);
                                                    }} href={`/${crossSell.username}`} target={target}>
                                                        <div className="profile-head">

                                                            <div className="avatar">
                                                                <img src={crossSell.profile_photo} alt="avatar" className="imaged w64 h64 rounded" />
                                                            </div>
                                                            <div className="in">
                                                                <h3 className="name">{crossSell.name.split(' ').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ')}</h3>
                                                            </div>

                                                        </div>
                                                    </a>
                                                    <div className="cross-sell mt-1" id={"list" + i}>
                                                        <div className="street_style">
                                                            {/* <a href={`/outfit/${crossSell.username}/outfit${crossSell.outfit}`} target={target}> */}
                                                            <img src={crossSell.img_Url} alt="image" className="imagedbox" style={{ maxWidth: '100%' }} />
                                                            {/* </a> */}
                                                            <div className="cart" onClick={() => this.handleSaveLooks(crossSell.sml_id, !crossSell.isSavedLook, 'explore')}>
                                                                {crossSell.isSavedLook ?
                                                                    <button size="25" className='btn btn-light-unsave me-1 mb-1 rounded mt-1 save-unsave-btn'>Unsave</button> :
                                                                    <button size="25" className='btn btn-pinterest me-1 mb-1 rounded mt-1 pl-1 save-unsave-btn' style={{ background: "#f12a2c !important" }}>  Save Look</button>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="cross_sell_cat me-1 ms-1">
                                                            <ul className="listview image-listview media mb-2">
                                                                <Row>
                                                                    {crossSell.crossSell.map((cross, index) => {
                                                                        return (
                                                                            <>
                                                                                <Col xs={6} className="cross_sell_data">
                                                                                    <li style={{ position: 'relative' }}>
                                                                                        <a href="#" className="item" onClick={e => this.handleClose(e, crossSell.sml_id, cross.title)}>
                                                                                            <Row className="newdesign">
                                                                                                <Col xs={5} >
                                                                                                    <div className="imageWrapper">
                                                                                                        <img onClick={() => {
                                                                                                            ReactGA.event({ category: 'Home page', action: 'products modal opened', lable: 'products modal open', value: 1 });
                                                                                                            mixpanel.track("products modal opened")
                                                                                                        }} src={cross.firstImage} alt="image" className="imagednew" />
                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col xs={7}>

                                                                                                    <div className="in cross">
                                                                                                        {/* <span className="cross-sell-title">{cross.title}</span> */}
                                                                                                        <div>
                                                                                                            <span className="cross-sell-title">{cross.title}</span>
                                                                                                            <div className="text-muted-11-5" style={{ display: 'flex', flexDirection: 'row' }}>
                                                                                                                &#8377;
                                                                                                                {typeof cross.price === 'undefined' || cross.price === null ? <span>
                                                                                                                    599
                                                                                                                </span> : <span>
                                                                                                                    {cross.price}
                                                                                                                </span>}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </a>

                                                                                    </li>
                                                                                </Col>
                                                                            </>
                                                                        )
                                                                    })
                                                                    }
                                                                </Row>
                                                            </ul>
                                                        </div>

                                                    </div>
                                                </div>
                                            })}
                                        </>
                                        {this.props.exploreLooksHasNextPage && <div className="d-flex justify-content-center">
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="my-looks">
                                        {this.props.hasUserFilledPreferences == false || this.props.isUserAuthenticated == false ?
                                            <Preferences preferencesSet={this.preferencesSet} /> :
                                            <>
                                                <div className='horizontal-buttons-div mb-1 mt-1'><button type="button" className="btn btn-text-dark me-1 mb-1">Occasions  : </button>{occasionsButtons}</div>
                                                <div className='horizontal-buttons-div mb-1 mt-1'><button type="button" className="btn btn-text-dark me-1 mb-1">Categories : </button>{CatsButtons}</div>

                                                {this.props.myLooks.map((crossSell, i) => {
                                                    return <div key={crossSell.sml_id} id={crossSell.sml_id} className='pt-2 pb-2 ps-1 pe-1 my-looks-outfit-post'>
                                                        <a onClick={() => {
                                                            ReactGA.event({ category: 'Home page', action: `${crossSell.username} Profile visited`, lable: `${crossSell.username} outfit page visited`, value: 1 });
                                                            mixpanel.track(`${crossSell.username} Profile visited`);
                                                        }} href={`/${crossSell.username}`} target={target}>
                                                            <div className="profile-head">

                                                                <div className="avatar">
                                                                    <img src={crossSell.profile_photo} alt="avatar" className="imaged w64 h64 rounded" />
                                                                </div>
                                                                <div className="in">
                                                                    <h3 className="name">{crossSell.name.split(' ').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ')}</h3>
                                                                </div>

                                                            </div>
                                                        </a>
                                                        <div className="cross-sell mt-1" id={"list" + i}>
                                                            <div className="street_style">
                                                                {/* <a href={`/outfit/${crossSell.username}/outfit${crossSell.outfit}`} target={target}> */}
                                                                <img src={crossSell.img_Url} alt="image" className="imagedbox" style={{ maxWidth: '100%' }} />
                                                                {/* </a> */}
                                                                <div className="badge badge-dark cart1" style={{ opacity: '80%' }}><span>As per your bodyshape</span></div>
                                                                <div className="cart" onClick={() => this.handleSaveLooks(crossSell.sml_id, !crossSell.isSavedLook, 'my-looks')}>
                                                                    {crossSell.isSavedLook ?
                                                                        <button size="25" className='btn btn-light-unsave me-1 mb-1 rounded mt-1 save-unsave-btn'>Unsave</button> :
                                                                        <button size="25" className='btn btn-pinterest me-1 mb-1 rounded mt-1 pl-1 save-unsave-btn' style={{ background: "#f12a2c !important" }}>  Save Look</button>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="cross_sell_cat me-1 ms-1">
                                                                <ul className="listview image-listview media mb-2">
                                                                    <Row>
                                                                        {crossSell.crossSell.map((cross, index) => {
                                                                            return (
                                                                                <>
                                                                                    <Col xs={6} className="cross_sell_data">
                                                                                        <li style={{ position: 'relative' }}>
                                                                                            <a href="#" className="item" onClick={e => this.handleClose(e, crossSell.sml_id, cross.title)}>
                                                                                                <Row className="newdesign">
                                                                                                    <Col xs={5} >
                                                                                                        <div className="imageWrapper">
                                                                                                            <img onClick={() => {
                                                                                                                ReactGA.event({ category: 'Home page', action: 'products modal opened', lable: 'products modal open', value: 1 });
                                                                                                                mixpanel.track("products modal opened")
                                                                                                            }} src={cross.firstImage} alt="image" className="imagednew" />
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                    <Col xs={7}>

                                                                                                        <div className="in cross">
                                                                                                            {/* <span className="cross-sell-title">{cross.title}</span> */}
                                                                                                            <div>
                                                                                                                <span className="cross-sell-title">{cross.title}</span>
                                                                                                                <div className="text-muted-11-5" style={{ display: 'flex', flexDirection: 'row' }}>
                                                                                                                    &#8377;
                                                                                                                    {typeof cross.price === 'undefined' || cross.price === null ? <span>
                                                                                                                        599
                                                                                                                    </span> : <span>
                                                                                                                        {cross.price}
                                                                                                                    </span>}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </a>

                                                                                        </li>
                                                                                    </Col>
                                                                                </>
                                                                            )
                                                                        })
                                                                        }
                                                                    </Row>
                                                                </ul>
                                                            </div>

                                                        </div>
                                                    </div>
                                                })}
                                                <div className="d-flex justify-content-center">
                                                    <div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            </>}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="saved-looks">
                                        {this.props.isUserAuthenticated && !this.state.username ? <div className="profile-head m-2">

                                            <div className="avatar">
                                                <img src={this.props.endUserProfile.imageUrl} alt="avatar" className="imaged w64 h64 rounded" />
                                            </div>
                                            <div className="in">
                                                <h3 className="name">{this.props.endUserProfile.name}</h3>
                                            </div>
                                            {isMobile && this.props.endUserProfile && this.props.endUserProfile.username ? <div className='ml-3' style={{ marginLeft: '6rem', float: 'right' }}>
                                                <InlineShareButtons
                                                    config={{
                                                        alignment: 'right',  // alignment of buttons (left, center, right)
                                                        color: 'social',      // set the color of buttons (social, white)
                                                        enabled: true,        // show/hide buttons (true, false)
                                                        font_size: 16,        // font size for the buttons
                                                        labels: 'cta',        // button labels (cta, counts, null)
                                                        language: 'en',       // which language to use (see LANGUAGES)
                                                        networks: [
                                                            'whatsapp'
                                                        ],
                                                        padding: 12,          // padding within buttons (INTEGER)
                                                        radius: 4,            // the corner radius on each button (INTEGER)
                                                        size: 40,             // the size of each button (INTEGER)

                                                        // OPTIONAL PARAMETERS
                                                        url: window.location.toString() + `/${this.props.endUserProfile.username}`, // (defaults to current url)
                                                        // image: this.props.publicCrossSell[0]?this.props.publicCrossSell[0].img_Url:"",  // (defaults to og:image or twitter:image)
                                                        // description: `Checkout this look of ${this.state.username}`,       // (defaults to og:description or twitter:description)
                                                        // title:`${this.state.username}'s outfit`,            // (defaults to og:title or twitter:title)
                                                    }}
                                                />
                                            </div> : null}
                                        </div> : null}
                                        {this.state.username && <div className="profile-head m-2">

                                            <div className="avatar">
                                                <img src={this.props.boardProfile.imageUrl} alt="avatar" className="imaged w64 h64 rounded" />
                                            </div>
                                            <div className="in">
                                                <h3 className="name">{this.props.boardProfile.name}</h3>
                                            </div>
                                        </div>}
                                        <div className='horizontal-buttons-div mb-1 mt-1'><button type="button" className="btn btn-text-dark me-1 mb-1">Occasions  : </button>{occasionsButtons}</div>
                                        <div className='horizontal-buttons-div mb-1 mt-1'><button type="button" className="btn btn-text-dark me-1 mb-1">Categories : </button>{CatsButtons}</div>
                                        {this.props.loadingState ? <Loader type="main" /> : this.props.savedLooks.length != 0 ? <GridStructure LooksArray={this.props.savedLooks} /> :
                                            !this.state.username ?
                                                <div className='m-3'>
                                                    {this.state.categoriesFiltered != "All" && this.state.occasionFiltered == "All" && <h2 className="text-center">Go save some looks for this category, and it will show here.</h2>}
                                                    {this.state.categoriesFiltered == "All" && this.state.occasionFiltered != "All" && <h2 className="text-center">Go save some looks for this occasion, and it will show here.</h2>}
                                                    {this.state.categoriesFiltered == "All" && this.state.occasionFiltered == "All" && <h2 className="text-center">Go save some looks, and it will show here.</h2>}
                                                </div>
                                                : <div className='m-3'>
                                                    {this.state.categoriesFiltered != "All" && this.state.occasionFiltered == "All" && <h2 className="text-center">No looks saved for this category by user.</h2>}
                                                    {this.state.categoriesFiltered == "All" && this.state.occasionFiltered != "All" && <h2 className="text-center">No looks saved for this occasion by user.</h2>}
                                                    {this.state.categoriesFiltered == "All" && this.state.occasionFiltered == "All" && <h2 className="text-center">No looks saved by user.</h2>}
                                                </div>
                                        }
                                        {this.props.savedLooksLength > this.state.currentSavedLooksPage * 30 ?
                                            <Button style={{ width: "100%" }} variant="outline-dark" onClick={() => { this.props.getSavedLooks(this.state.currentSavedLooksPage + 1, this.state.occasionFiltered, this.state.categoriesFiltered, this.state.username); this.setState({ currentSavedLooksPage: this.state.currentSavedLooksPage + 1 }) }}>Load More</Button>
                                            : null
                                        }
                                        {this.props.isUserAuthenticated == false && this.state.tabKey == "saved-looks" && this.state.showLogin && <PublicLoginModal showModal={true} closeModal={this.hideLogin} />}
                                    </Tab.Pane>
                                </Tab.Content>

                            </Tab.Container>
                        </div>
                    </div>
                </div>
                {isMobile && !window.navigator.userAgent.toLowerCase().includes('wv') ? <>
                    {/* (/android/i.test(navigator.userAgent)) && false ? <div className="toast-box toast-bottom show bg-info" style={{bottom : '0'}}>
                    <div className="in">
                        <div className="text">
                        <a href='https://play.google.com/store/apps/' className='text-white' >Download our app</a>
                        </div>
                    </div>
                    <a href='https://play.google.com/store/apps/'><img style={{width: '40px', height: '40px'}} src='/static/images/explore/gplay.png'></img></a>
                    </div> :  */}
                    <div className="toast-box toast-bottom show bg-info" style={{ bottom: '0' }}>
                        <div className="in">
                            <div className="text">
                                <a onClick={() => {
                                    ReactGA.event({ category: 'Home page', action: 'whatsapp bottom bar clicked', lable: 'whatsapp bar', value: 1 });
                                    mixpanel.track('whatsapp bottom bar clicked');
                                }} href='https://signupforservices.com/whatsapp/optin/?bId=8923b967-ec46-40d5-bce3-93aa221f85d9&bName=ShopTheLooks&s=URL&lang=en_US' className='text-white' >Get Top-10 Looks on Whatsapp everyday, as per your bodyshape to up your style game</a>
                            </div>
                        </div>
                        <a onClick={() => {
                            ReactGA.event({ category: 'Home page', action: 'whatsapp bottom bar clicked', lable: 'whatsapp bar', value: 1 });
                            mixpanel.track('whatsapp bottom bar clicked');
                        }} href='https://signupforservices.com/whatsapp/optin/?bId=8923b967-ec46-40d5-bce3-93aa221f85d9&bName=ShopTheLooks&s=URL&lang=en_US'><button type="button" className="btn btn-sm btn-text-light close-button" style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>Yes</button></a>
                    </div> </> : null
                }
                <PublicLoginModal showModal={this.state.showModal} closeModal={this.hideLogin} />
                <Modal
                    show={this.state.showProducts}
                    onHide={this.handleClose}
                    className="product-modal my-looks-product-modal"
                    scrollable
                >
                    <Modal.Header onClick={(e) => this.handleClose(e)}>
                        <Row style={{ marginLeft: '70%' }} >

                            <Col xs={2}><X color="white" size={30} /></Col>
                        </Row>
                    </Modal.Header>

                    <Modal.Body>
                        <Row>
                            {this.props.modalloading ? <Loader type="modalmain" /> : this.props.publicProducts.map(item1 => {
                                return (
                                    <Col xs={6} md={3} lg={3} style={{ position: "relative" }}>
                                        <a href={item1.product_url} className="item" target="_blank" onClick={() => { this.countProductClick(item1.product_id) }}>
                                            <div className="imageWrapper">
                                                <img onClick={() => {
                                                    ReactGA.event({ category: 'Home page', action: `${item1.product_image_url} product clicked`, lable: 'product clicked', value: 1 });
                                                    mixpanel.track(`${item1.product_image_url} product clicked`);
                                                }} src={item1.product_image_url} alt="image" className="modalimaged fit-product-images" />

                                            </div>
                                            <div className="in">
                                                <div>
                                                    <h4 className="mb-05">{item1.title}</h4>
                                                    <div> {item1.rating >= 3.5 ? <Rating
                                                        initialValue={item1.rating}
                                                        size={20}
                                                        transition
                                                        allowHalfIcon
                                                        readonly /> : null}</div>
                                                    <div className="text-muted">
                                                        {item1.description.substring(0, 10) + "..."}
                                                        <div className="mt-05"><strong>&#8377;{item1.price}&nbsp;</strong><span>{item1.brand}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>

                                    </Col>
                                )
                            })}
                        </Row>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}
const mapStateToProps = state => ({
    isUserAuthenticated: state.isUserAuthenticated,
    exploreLooks: state.exploreLooks,
    savedLooks: state.savedLooks,
    myLooks: state.myLooks,
    savedLooksLength: state.savedLooksLength,
    publicProducts: state.publicProducts,
    modalloading: state.modalloading,
    endUserProfile: state.endUserProfile,
    boardProfile: state.boardProfile,
    loadingState: state.loadingState,
    hasUserFilledPreferences: state.hasUserFilledPreferences,
    exploreLooksHasNextPage: state.exploreLooksHasNextPage,
})
const mapDispatchToProps = dispatch => ({
    getExploreLooks: (page, occasionFilters, categoryFilters, renderedPosts) => dispatch(getUserExploreLooks(page, occasionFilters, categoryFilters, renderedPosts)),
    getMyLooks: (page, occasionFilters, categoryFilters, renderedPosts) => dispatch(getUserMyLooks(page, occasionFilters, categoryFilters, renderedPosts)),
    getSavedLooks: (page, occasionFilters, categoryFilters, username) => dispatch(getUserSavedLooks(page, occasionFilters, categoryFilters, username)),
    PublicProducts: (sml_id, cat) => dispatch(fetchPublicProducts(sml_id, cat)),
    userMyLooks: (page, data) => dispatch(userMyLooks(page, data)),
    exploreUserLooks: (page, data) => dispatch(exploreUserLooks(page, data)),
    hidePreviousProducts: () => dispatch(setPublicProductsData([])),
    setLooksToEmpty: () => dispatch(setLooksToEmpty()),
    getBoardData: (username) => dispatch(getBoardData(username)),
    updateUserLoginState: () => dispatch(updateUserLoginState()),
    setIsUserAuthenticated: (value) => dispatch(setIsUserAuthenticated(value)),
    resetExploreUserLooksNextPage: () => dispatch(exploreUserLooksNextPage(true)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Cart)
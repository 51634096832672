import React from 'react';
import { Component } from 'react';
import './catalogDashBoard.css'


class AskPassword extends Component {

      render() {
            return (
                  <>
                        <div className='password-wrapper'>
                              <div className='password-box'>
                                    <label>Username</label><input type={'text'} value={"admin"} /><br/>
                                    <label>Password</label> <input type={"password"} name={"admin-password"} id={"admin-password"} /><br/>
                                    <button onClick={(e)=>{  
                                          this.props.handleSubmit();
                                    }}>Submit</button>
                              </div>
                        </div>
                  </>
            )
      }
}

export default AskPassword;
export const actions = {
    SET_ACTIVE_NAMES: 'set_active_names',
    TOGGLE_LOADING_STATE: 'toggle_loading_state',
    SET_LOADING_TEXT: 'set_loading_text',
    SET_BLOGGER: 'set_blogger',
    UPDATE_PROFILE: 'update_profile',
    SET_TOTAL_BLOGGER_COUNT: 'set_total_count',
    TOGGLE_POST: 'toggle_post',
    SET_USER: 'set_user',
    SET_END_USER:"set_end_user",
    SET_LOGIN_STATUS: 'login_status',
    SET_CROSSSELL: 'set_crosssell',
    SET_PRODUCTS: 'set_products',
    SET_LOGIN_TEXT: 'login_text',
    IS_AUTHENTICATED: 'is_authenticated',
    IS_USER_AUTHENTICATED: 'is_user_authenticated',
    SET_GREY_DATA: 'grey_data',
    TOGGLE_GREY: 'toogle_grey',
    APPEND_GREY: 'append_grey',
    APPEND_BLOGGER: 'append_blogger',
    UPDATE_PIMG:'update_pimg',
    SET_BLOGGER__PUBLIC: "blogger_public",
    SET_BLOGGER_OPEN_LOOKS : 'set_blogger_open_looks',
    SET_BLOGGER_INFO: "blogger_info",
    PUBLIC_PROFILE:'public_profile',
    SET_PUBLICCROSSSELL:'set_publiccrosssell',
    SET_PUBLICPRODUCTS:'set_publicproducts',
    SET_CROSSSELL_VISIBILITY_OFF:'set_crossSell_visiblity_off',
    SET_PRODUCTS_VISIBILITY_OFF: 'set_product_visibility_off',
    SET_CROSSSELL_VISIBILITY_ON:'set_crossSell_visiblity_on',
    SET_PRODUCTS_VISIBILITY_ON: 'set_product_visibility_on',
    BLOGGER_CONCAT:'concat_blogger',
    BLOGGER_STYLE_PAGEDATA: 'public_page_data',
    SET_GREYDATA_PAGEDATA: 'grey_page_data',
    SET_GREYDATA_TOTAL: 'grey_data_total',
    MODAL_LOADING_STATE:'modal_loading_state',
    SET_BLOGGER_LIST: "set_blogger_list",
    SET_BLOGGER_LIST_PAGEDATA:"set_blogger_list_pagedata",
    BLOGGER_LIST_TOTAL:"blogger_list_total",
    PAGE_VISITS : "page_visits",
    BLOGGER_COUNTS : "blogger_counts",
    SAVED_LOOKS:"saved_looks",
    SET_SML_ID:"sml_id",
    USER_PROFILE:"user_profile",
    SUBSCRIBE:"subscribe",
    CURATOR_LOGIN : "curator_login",
    SET_OUTFIT_METADATA: "set_outfit_metadata",
    SET_BLOGGER_METADATA: "set_blogger_metadata",
    USER_SAVED_LOOKS_LENGTH : "user_saved_looks_length",
    USER_SAVED_LOOKS : "user_saved_looks",
    USER_EXPLORE_LOOKS : 'user_explore_looks',
    USER_EXPLORE_LOOKS_NEXT_PAGE : 'user_explore_looks_next_page',
    USER_MY_LOOKS : 'user_my_looks',
    BLOGGER_NAME : 'blogger_name',
    BOARD_PROFILE : 'board_profile',
    FILLED_USER_PREFERENCES : 'filled_user_preferences',
    NOT_CURATED_DATA : 'not_curated_data',
    SET_BLOGGER_OPEN_LOOKS_REQUESTED : 'set_blogger_open_looks_requested',
  }
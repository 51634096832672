import React from 'react'
import '../styles/loader.css'
const Loader = (props) =>{
    return(
        <>
            {props.type === 'main' &&
                <button className="btn btn-light mainLoader" type="button" disabled>
                    <span className="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
                    Loading...
                </button>
            }
             {props.type === 'modalmain' &&
                <button className="btn btn-light ModalLoader" type="button" disabled>
                    <span className="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
                    Products Loading...
                </button>
            }
        </>
    )
}
export default Loader;
import React, {useState} from 'react'
import imgsrc from '../../images/vector4.png';
import '../../styles/style.css'
import '../../styles/login.css'
import {connect} from "react-redux";
import {googleCuratorLogin, isCuratorLogin } from '../../actions'
import { Redirect } from 'react-router';
import PublicNavbar from './../public/publicNavbar';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
const clientId = "806676170053-kdqovn15piaombk6nubcaurib6ds9f09.apps.googleusercontent.com";
class CuratorLogin extends React.Component
{
    constructor(props){
      super(props)
      this.props.isCuratorLogin()
      this.state = {
        reqLogin:false
      }
      /* this.onSignIn = this.onSignIn.bind(this)
      this.signInFail = this.signInFail.bind(this) */
    }
    componentDidUpdate(prevState, prevProps){ 
      if(this.props.isCuratorAuthenticated){
          window.location.href = '/curator/bloggers'
      }
    }

    render()
    {
      const googleResponse = (response) =>{
        let details = response.profileObj
        this.setState({
          reqLogin: true
        })
        console.log(details)
        this.props.googleCuratorLogin(details)
      }
      console.log("curator ",this.props.isCuratorAuthenticated)
        return (
            <>
            {/* <div id="loader">
              <div className="spinner-border text-primary" role="status" />
            </div> */}
            <PublicNavbar title="Login"/> 
            <div id="appCapsule" className="pt-0">
              <div className="login-form" style={{marginTop:"40%"}}>
                <h3>Please Login with google to save looks</h3>
                <div className="section">
                  <img
                    src={imgsrc}
                    alt="image"
                    className="form-image"
                  />
                </div>
                
                
                <div className="google-login">
                  {this.props.isLogin?
                   <GoogleLogout
                   clientId={clientId}
                   buttonText="Logout"
                  />
                  :<GoogleLogin
                    clientId={clientId}
                    buttonText="Sign-in with Google"
                    onSuccess={googleResponse}
                    onFailure={googleResponse}
                    cookiePolicy={'single_host_origin'}
                  />
                  }   
                </div>
              </div>
            </div>
          </>
        )
    }
}
const mapStateToProps = state => ({
    isCuratorAuthenticated : state.isCuratorAuthenticated
})
const mapDisparchToProps = (dispatch) =>({
  googleCuratorLogin : (googleProfile) => dispatch(googleCuratorLogin(googleProfile)),
  isCuratorLogin : ()=> dispatch(isCuratorLogin())
})
export default connect(mapStateToProps, mapDisparchToProps)(CuratorLogin);

import React, { Component, Suspense } from 'react';
import { Col, Row, Modal, Tab, Nav, Button } from 'react-bootstrap';
import NavBar from './../navbar';
import { updateUserLoginState } from '../../actions';
import { connect } from "react-redux";
import Swal from 'sweetalert2';
import { SaveUserPreferences, GetUserPreferences, trackMixPanelEvent } from './../../api/publicApis'
import 'react-image-upload/dist/index.css'
import Axios from 'axios';
import { Link } from 'react-router-dom'
import LinkLogo from './../../images/hyperlink-icon.svg'
import { Blurhash } from 'react-blurhash';
import LensDailyImage from './LensDailyImage';


class LensDailyImages extends Component {
	constructor(props) {
		super(props);
		this.state = {
			allFilters: ['All', 'Casual', 'Party', 'Officewear', 'Loungewear', 'Sportswear'],
			filteredName: 'All',
			page: 1,
			loading: true,
			currentCat: null,
			responseReceived: false,
			nextApiCallMade: false,
			pageImages : [],
			hasNextPage: true
		};

		this.getResponse()
	}

	updateFilters = async function ( name) {
		let updateObject = {};
		// if (type == 'occasions' && name != this.state.occasionFiltered) updateObject['occasionFiltered'] = name;
		// else if (name != this.state.categoryFiltered) updateObject['categoryFiltered'] = name
		if(this.state.filteredName != name ) 
			updateObject['filteredName'] = name
		updateObject.page = 1;
		updateObject.loading = true
		updateObject.pageImages = []
		await new Promise(res => this.setState(updateObject, res))
		this.getResponse()

	}

	loadMore = async()=>{
		await new Promise(res => {
			this.setState({page : this.state.page+1},res)
		}) 
		await this.getResponse()
	}

	gridRender = (arr) => {
		let arraysData =[]
        	// arr.forEach((e,i)=>{
		// 	if(i%3==0)
		// 		arraysData.push([e])
		// 	else 
		// 		arraysData[arraysData.length-1].push(e)
		// })
		

		return (
			<div className="mt-2 pe-2 ps-2">
				<div className="row">
					{arr.map((item, index) => {
						return (
							<LensDailyImage post_url={item.post_url} blurHash={item.blurHashString} image_url={item.image_url} thumb_id={item.thumb_id} trackMixPanelEvent={trackMixPanelEvent} />
						)
					})

					}
				</div>
			</div>

		)
	}


	getResponse = async () => {
		let previousThumbIds = this.state.pageImages.map(img => img.thumb_id);
		let result = await Axios.post("/api/lens-daily",{filterName : this.state.filteredName, pageNumber: this.state.page, previousThumbIds})
		if (result.data.resultArray != undefined){
			const { hasNextPage, resultArray } = result.data
			const loading = false;
			const pageImages = [...this.state.pageImages, ...resultArray];
			this.setState({hasNextPage,pageImages,loading})
		} else {
			this.setState({hasNextPage:false,loading:false})
		}
	}

	render() {


		return (
			<>

				<div className="appCapsule" style={{paddingTop:"10px"}}>
					<div className="section full mb-2">
						<div className='horizontal-buttons-div '><button type="button" className="btn btn-text-dark me-1 mb-1">Occasions  : </button>{this.state.allFilters.map(occ => this.state.filteredName != occ ?
							<button key={occ} type="button" className="btn btn-outline-dark outlined rounded shadowed me-1 mb-1 btn-sm" onClick={() => this.updateFilters(occ)}>{occ}</button>
							: <button key={occ} type="button" className="btn btn-dark rounded shadowed me-1 mb-1 btn-sm">{this.state.filteredName}</button>)}</div>
						{this.gridRender(this.state.pageImages)}
						{this.state.hasNextPage?<center><button className=' btn  btn-outline-secondary px-5' disabled={this.state.loading} onClick={this.loadMore}>Load more</button></center>:<center>No more data...</center>}
					</div>


				</div>
			</>
		);
	}
}


const mapStateToProps = state => ({
	isUserAuthenticated: state.isUserAuthenticated,
	lensData: state.lensData
})


const mapDispatchToProps = dispatch => ({
	updateUserLoginState: () => dispatch(updateUserLoginState()),
})
export default connect(mapStateToProps, mapDispatchToProps)(LensDailyImages);
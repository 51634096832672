import React from 'react';
import {
    Modal, TextField, Typography, Grid, FormControlLabel,
    Checkbox, Button,
} from '@material-ui/core';
// import { Accordion, Card } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card'
import "bootstrap/dist/css/bootstrap.min.css";
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import Agreement from './components/agreement';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { brandData } from './api/publicApis';
import './mobile.css';
import Navbar from '../src/components/navbar'


function getModalStyle() {
    const top = '20%'
    const left = '20%'

    return {
        top: top,
        left: left,
        transform: `translate(-${top}, -${left})`,
    };
}
const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: 'scroll',
        height: '80%'
    },
}));
//form sign up data
const formData = {
    name: '',
    email: '',
    number: '',
    brandName: '',
    cc: ''
};

export default function MobileView() {
    const classes = useStyles();
    //states for the function
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const [emailOtpRequested, setEmailOtpRequested] = React.useState(false);
    const [phoneOtpRequested, setPhoneOtpRequested] = React.useState(false);
    const [nameError, setNameError] = React.useState(false);
    const [emailError, setEmailError] = React.useState(false);
    const [ccError, setCcError] = React.useState(false);
    const [numberError, setNumberError] = React.useState(false);
    const [instaError, setInstaError] = React.useState(false);
    const [isCheckedAgreement, setCheckedAgreement] = React.useState(false);
    const [phoneVerified, setPhoneVerified] = React.useState(false);
    const [mailVerified, setMailVerified] = React.useState(false);
    const [resentOtp, setResentOtp] = React.useState(false)
    const [openmodal, setOpenmodal] = React.useState(false)
    const [scroll, setScroll] = React.useState('paper');
    const descriptionElementRef = React.useRef(null);
    const [validateMessage, setValidateMessage] = React.useState()
    const [mailOTPMatch, setMailOTPMatch] = React.useState(false)
    const [phoneOTPMacht, setPhoneOTPMatch] = React.useState(false)
    React.useEffect(() => {
        if (openmodal) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [openmodal]);

    //open close modal
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setResentOtp(false);
        Object.keys(formData).forEach(key => formData[key] = '');
        setNameError(false);
        setNumberError(false);
        setEmailError(false);
        setCcError(false);
        setCheckedAgreement(false);
        setEmailOtpRequested(false);
        setPhoneOtpRequested(false);
        setPhoneVerified(false);
        setMailVerified(false);
        setValidateMessage()
        setPhoneOTPMatch(false)
        setMailOTPMatch(false)
    };
    const closeModal = () => {
        setOpenmodal(false)
    }

    //form submit function
    const formSubmit = async (event) => {
        event.preventDefault();
        if (!nameError && !emailError && !numberError && !instaError) {
            let res = await brandData(formData)
            if (res.data.data === 'success')
                setValidateMessage(<p style={{ color: 'green' }}>Sucessfully Signup.</p>)
            else if (res.data.data === 'exists')
                setValidateMessage(<p style={{ color: 'red' }}>This user is already registered.</p>)
            else setValidateMessage(<p style={{ color: 'red' }}>Opps! Something went Wrong.</p>)
        }
        else {
            setValidateMessage(<p style={{ color: 'red' }}>Opps! All the form fileds are not entererd or some values are incorrect.</p>)
        }
    }

    //check the validity of form
    const handelFormChange = (e, type) => {
        if (type === 'name') {
            if (e.target.value != '') {
                setNameError(false)
                formData.name = e.target.value;
            }
            else setNameError(true);
        }
        else if (type === 'phone') {
            if (e.target.value.match(/^[0-9]{10}$/g)) {
                setNumberError(false);
                formData.number = (e.target.value).toString();
            }
            else {
                setResentOtp(false)
                setPhoneVerified(false)
                setPhoneOtpRequested(false);
                setNumberError(true);
            }
        }
        else if (type === 'email') {
            if (e.target.value.match(/^[A-Za-z0-9+-._]+@[A-Za-z0-9]+\.[a-zA-Z]+$/)) {
                setEmailError(false)
                formData.email = e.target.value;
            }
            else {
                setMailVerified(false)
                setEmailOtpRequested(false)
                setEmailError(true)
            }
        }
        else if (type === 'insta') {
            if (e.target.value != '') {
                formData.brandName = e.target.value;
                setInstaError(false)
            }
            else setInstaError(true)
        }
        else {
            if (e.target.value.match(/[0-9]{2}/)) {
                formData.cc = e.target.value;
                setCcError(false)
            }
            else setCcError(true)
        }
    }

    return (
        <>
        <Navbar title= {<a href='/'><img style={{height: '1em'}} src='/static/images/stl_logo.JPG' /></a>} page = 'home'/>
        <div className="mt-5">
            <div className="header">
                <img src='./static/images/mobile/brand_m/logo.gif' alt='animated-header' className="imgcss" />
            </div>
            <div className="animated-chill">
                <img src='./static/images/mobile/brand_m/01.png' alt='animation-chill' className="imgcss" />
                <div className="waitlist">
                    {/* <img src='./static/images/mobile/c_03.gif' alt="waitlist" onClick={handleOpen}  className="imgcss"/> */}
                </div>
            </div>
            <div className="mid">
                <img src='./static/images/mobile/brand_m/02.png' alt="old-vs-new" className="imgcss" />
                <div className="join-waitlist">
                    {/* <img src='./static/images/mobile/c_05.gif' alt="animated-header-waitlist" className="imgcss" onClick={handleOpen}/> */}
                </div>
            </div>
            <div className="mid">
                <a href='/peri' target="_blank" >
                    <img src='./static/images/mobile/brand_m/peri_store.png' alt="old-vs-new" className="imgcss" /></a>
                <div className="join-waitlist">
                    {/* <img src='./static/images/mobile/c_05.gif' alt="animated-header-waitlist" className="imgcss" onClick={handleOpen}/> */}
                </div>
            </div>

            <div className="mid">
                <img src='./static/images/mobile/brand_m/03.png' alt="old-vs-new" className="imgcss" />
                <div className="join-waitlist">
                    {/* <img src='./static/images/mobile/c_05.gif' alt="animated-header-waitlist" className="imgcss" onClick={handleOpen}/> */}
                </div>
            </div>

            <div className="mid">
                <button className='button' > <img src='./static/images/mobile/brand_m/03_integrate.png' alt="integrate" className="imgcss" onClick={handleOpen} /></button>
            </div>

            <div className="mid">
                <img src='./static/images/mobile/brand_m/line.png' alt="line" className="imgcss" />
            </div>
            <div className="faqs">
                <img src='./static/images/mobile/brand_m/faq.png' className="imgcss" alt="FAQ's" />
            </div>

            <div className="faq-question">
                <div className="m-question">
                    <Accordion className="faqscss">
                        <Accordion.Item className="faqscss" eventKey="0">
                            <Accordion.Header className="faqscss"> When is the expected launch date? </Accordion.Header>
                            <Accordion.Body className="faqscss">
                                Our expected launch is set for mid- February 2022.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className="faqscss" eventKey="1">
                            <Accordion.Header className="faqscss"> What is the on boarding process and how long does it take?</Accordion.Header>
                            <Accordion.Body className="faqscss">
                                Usual integration takes two days, kindly get in touch with us to sign up.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className="faqscss" eventKey="2">
                            <Accordion.Header className="faqscss">How does the daily product sync happen?</Accordion.Header>
                            <Accordion.Body className="faqscss">
                                After signing up with us, our system will automatically and daily sync the products
                                available on your website hence there is no manual effort required from your end.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className="faqscss" eventKey="3">
                            <Accordion.Header className="faqscss"> How are the transactions handled?</Accordion.Header>
                            <Accordion.Body className="faqscss">
                                Transactions are done in two ways-
                                <ol>
                                    <li>Users are currently directed to the partner store.</li>
                                    <li>Checkout for users on STL is a work in progress, this would eventually be the primary mode of transaction.</li>
                                </ol>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className="faqscss" eventKey="4">
                            <Accordion.Header className="faqscss">What’s the fee?</Accordion.Header>
                            <Accordion.Body className="faqscss">
                                Kindly connect with us to discuss this further. </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className="faqscss" eventKey="5">
                            <Accordion.Header className="faqscss">How do I get in touch with you?</Accordion.Header>
                            <Accordion.Body className="faqscss">
                                Kindly write to us -
                                <a href="mailto:brands@shopthelooks.com" target="_blank">
                                    &nbsp;brands@shopthelooks.com
                                </a>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>

            <div className="mid">
                <img src='./static/images/mobile/brand_m/04.png' alt="press-mentions" className="imgcss" />

            </div>

            <div className="footer">
                <img src='./static/images/mobile/brand_m/footer.png' alt="footer-img" />
            </div>
            <div className="toast-box toast-bottom show bg-info" style={{bottom : '0', justifyContent: 'center'}}>
                    <div className="in">
                    <a href='/' className='p2'><div style={{width: '25px', height: '25px'}} ><img src= '/static/images/explore/earth.png' ></img></div></a>
                        <div className="text" style={{marginLeft: '7px'}}>
                            <a href='/' className='text-white' > Click to explore Shop The Looks world</a>
                        </div>
                    </div>
            </div> 
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={classes.paper} style={modalStyle}>
                    <Typography id="simple-modal-title" component="h1" variant="h6" align="center">Sign Up</Typography>
                    <div id="simple-modal-description">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    required id="name"
                                    name="name"
                                    label="Your Name"

                                    onChange={e => handelFormChange(e, 'name')}
                                    helperText={nameError ? 'Name field cannot be empty.' : ''}
                                    error={nameError}
                                    fullWidth />
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    required id="email"
                                    name="email" label="Email"
                                    onChange={e => handelFormChange(e, 'email')}
                                    helperText={emailError ? 'Please enter valid email' : ''}
                                    error={emailError}
                                    fullWidth />
                            </Grid>
                            <Grid item xs={4}>
                                {/* {(mailVerified)?<CheckBoxIcon style={{color:'green'}}/>
                                    :(emailOtpRequested)?<TextField required id="mail" name="mail" label="OTP" inputProps={{maxLengt:4}} onChange={e=>verifyOtp(e,'email')} error={mailOTPMatch}/>
                                    :<Button variant="contained" onClick={e=>verifyEmail(e)}>Verify</Button>
                                } */}
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    required
                                    id="cc" name="cc"
                                    label="CC"
                                    inputProps={{ maxLength: 2 }}

                                    onChange={e => handelFormChange(e, 'cc')}
                                    helperText={ccError ? 'CC is required' : ''}
                                    error={ccError}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    required
                                    id="number"
                                    name="number"
                                    label="Mobile No."
                                    fullWidth

                                    onChange={e => handelFormChange(e, 'phone')}
                                    inputProps={{ maxLength: 10 }}
                                    helperText={numberError ? 'Phone Number cannot be empty and must be of 10 digits.' : ''}
                                    error={numberError}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                {/* {(phoneVerified)?<CheckBoxIcon style={{color:'green'}}/>
                                    :(phoneOtpRequested)?<TextField required id="phone_otp" name="phone_otp" label="OTP" inputProps={{maxLength:4}} onChange={e=>verifyOtp(e,'phone')} error={phoneOTPMacht}/>
                                    :<Button variant="contained" onClick={e=>verifyPhone(e)}>Verify</Button>
                                } */}
                            </Grid>
                            <Grid item xs={6} style={{ display: "flex", flexDirection: "row-reverse" }}>
                                {/* {resentOtp && !phoneVerified &&<Button variant="contained" onClick={requestVoiceOtp}
                                        helperText="By clicking on resend you will recieve OTP via call"
                                    >
                                        Resend Otp</Button>
                                    } */}
                            </Grid>
                            <Grid item xs={12}>
                                <h6>CC = Country Code</h6>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="instaHandle"
                                    name="brand"
                                    label="Brand Name"
                                    fullWidth
                                    onChange={e => handelFormChange(e, 'insta')}
                                    error={instaError}
                                />
                            </Grid>
                            {/* <Grid item xs={12}> 
                                <FormControlLabel control={<Checkbox color="secondary" name="agreement" value="yes" checked={isCheckedAgreement}/>}
                                    label={
                                        <div>
                                            <span>I accept</span> <a href="#" onClick={e=>openAgreement(e)}> the agreement.</a>
                                        </div>
                                    } 
                                />
                            </Grid> */}
                            <div style={{ textAlign: 'center', width: '100%' }}>
                                <Button variant="contained"
                                    style={{ width: "70%" }}
                                    onClick={formSubmit}
                                >
                                    Sign Up
                                </Button>
                            </div>
                            <div style={{ textAlign: 'center', width: '100%' }}>
                                {validateMessage}
                            </div>
                        </Grid>
                    </div>
                </div>
            </Modal>
            <Dialog
                open={openmodal}
                onClose={closeModal}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth
                maxWidth='xl'
            >
                <DialogTitle id="scroll-dialog-title">
                    <span style={{ display: 'flex', float: 'right', cursor: 'pointer', opacity: 0.5 }} onClick={closeModal}>X</span>
                </DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        style={{ width: "100%" }}
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <Agreement />
                    </DialogContentText>
                </DialogContent>
                {/* <DialogActions>
                    <Button variant="contained" onClick={e=>agreementClick(e)} color="primary">
                        Disagree
                    </Button>
                    <Button  variant="contained" onClick={e=>agreementClick(e)} color="primary">
                        Agree
                    </Button>
                </DialogActions> */}
            </Dialog>
        </div>
        </>
    )
}

import axios from 'axios';
async function bloggerData(data)
{
  try
  {
      let result =await axios.post('api/sml/bloggerSignUp',{data});
      return result;
  }
  catch(err)
  {
      //console.log(err);
      return ('Error');
  } 
}

async function brandData(data)
{
  try
  {
      let result =await axios.post('api/sml/BrandSignUp',{data});
      return result;
  }
  catch(err)
  {
      //console.log(err);
      return ('Error');
  } 
}

async function sendOTPToMobile(countryCode,number){
    console.log(countryCode, number)
    let response = await axios.post('/api/sml/sendOtp',{
      number,
      countryCode
    });
    return response;
  }
  async function sendVoiceOTPToMobile(countryCode,number){
    let response = await axios.post('/api/sml/resendOtp',{
      number,
      countryCode
    });
    return response;
  }
  async function verifyNumberOTP(countryCode,number,otp){
    let response = await axios.post('/api/sml/verifyOtp',{
      number,
      countryCode,
      otp
    });
    return response;
  }
  async function sendOTPToEmail(email){
    let response = await axios.post('/api/sml/sendOTPEmail',{
      email
    });
    return response;
  }
  async function userRegister(user){
    let response = await axios.post('/api/sml/userRegister',{
      user});
    return response;
  }
  async function endUserRegister(user){
    console.log("user")
    let response = await axios.post('/api/sml/publicRegister',{
      user});
    return response;
  }
  async function verifyEmailOTP(email,otp){
    let response = await axios.post('/api/sml/verifyOTPEmail',{
      email,
      otp
    });
    return response;
  }
  const getBlogger = async(pageNo,skip,insta)=>{
    console.log(pageNo,insta)
    let response = await axios.get(`/api/sml/fetchBlogger/?pageNo=${pageNo}&insta=${insta}&skip=${skip}`);
    return response;
  }

  const trackMixPanelEvent = async(EventName)=>{
    let response = await axios.post("/api/mixpanel-event",{EventName});
    return response;
  }

  const Login = async(email, pwd) => {
    let params = {
      email,
      password: pwd
    }
    let response = await axios.post('/api/sml/login',params)
    return response;
  }
  //get crossSell data
  const getCrossSell = async(insta) =>
  {
    let response = await axios.get(`/sml/CrossSellGet/?outfit_id=${insta}`);
    return response;
  }
  const getBloggerCounts = async(insta) =>
  {
    console.log(insta)
    let response = await axios.get(`/api/sml/fetchCounts/?insta=${insta}`);
    return response;
  }
  const getBloggerSubscriberCount = async(insta) =>
  {
    console.log(insta)
    let response = await axios.get(`/api/sml/totalSubscriber/?insta=${insta}`);
    return response;
  }

  const getProducts = async(sml_id,cat) =>
  {
    console.log(sml_id,cat)
    let response = await axios.get(`/sml/getProducts/?sml_id=${sml_id}&cat=${cat}`);
    return response;
  }
  
  const getImageData = async(product_id)=>
  {
    let response = await axios.get(`/sml/getImage/?id=${product_id}`)
    return response
  }


  //PUBLIC PAGE API

  const getPublicCrossSell = async(insta,outfit) =>
  {
    let response = await axios.get(`/public/publicCrossSellGet/?insta=${insta}&outfit=${outfit}`);
    return response;
  }

  const getPublicProducts = async(sml_id,cat) =>
  {
    console.log(sml_id,cat)
    let response = await axios.get(`/public/PublicProducts/?sml_id=${sml_id}&cat=${cat}`);
    //console.log(response)
    return response;
  }


  const logout = async() =>{
    let response = await axios.post('/api/sml/logout');
    return response;
  }

  const logoutUser = async() =>{
    let response = await axios.post('/api/sml/userLogout');
    return response;
  }

  const updateProfileImg = async(imgdata) =>{
    let response = await axios.post('/api/sml/uploadpimg',imgdata)
    return response;
  }

  const updateProfile = async(userdata) =>{
    console.log(userdata)
    let response = await axios.post('/api/sml/editProfile',userdata);
    return response;
  }

  const showtoPublic = async(type,sml_id,username)=>{
    let response = await axios.post('/api/sml/setShowtoPublic',{type,sml_id,username})
    return response;
  }
 
  const getPublic = async(pageNo,insta) =>{
    let response = await axios.post(`/api/sml/getpublic?pageNo=${pageNo}&insta=${insta}`)
    return response;
  }

  const uploadProfileImage = async(file) =>{
    let response = await axios.post('/api/sml/uploadpimg',file)
    return response;
  }
const fetchBloggerStyle = async(pageNo, insta,occassion,category) =>{
  let response = await axios.get(`/public/fetctBlogger/?pageNo=${pageNo}&insta=${insta}&occassion=${occassion}&category=${category}`)
  return response;
}

const fetchBloggerOpenLooks = async(pageNo, insta,occassion,category) =>{
  let response = await axios.get(`/public/fetctBloggerOpenLooks/?pageNo=${pageNo}&insta=${insta}&occassion=${occassion}&category=${category}`)
  return response;
}
const bloggerinfo = async(insta)=>{
  console.log(insta)
  let response = await axios.get(`/public/bloggerInfo/?insta=${insta}`);
  return response;
}
const toggleCrossSell=async(type,sml_id,product_id,insta,cat,action)=>{
  let response = await axios.post('/api/sml/setCrossSellVisibility',{
    type,
    sml_id,
    product_id,
    insta,
    cat,
    action
  })
  return response;
}
const getshowToPublicoff= async(pageNo,skip,insta)=>{
  let response = axios.get(`/api/sml/getShowtopublicOFF/?pageNo=${pageNo}&insta=${insta}&skip=${skip}`)
  return response;
}

const checkUsername = async(username,_id) =>{
  let response = await axios.post(`/api/sml/checkUsername`,{username,_id})
  return response;
}
const checkEmail = async(email,_id) =>{
  let response = await axios.post(`/api/sml/checkEmail`,{email,_id})
  return response;
}
const checkNumber = async(number,_id) =>{
  let response = await axios.post(`/api/sml/checkNumber`,{number,_id})
  return response;
}
const checkInstahandle = async(insta,_id) =>{
  let response = await axios.post('/api/sml/checkInstahandle',{insta,_id})
  return response;
}
const updatePassword = async(_id,oldpwd,newpwd) =>{
  let response = await axios.post('/api/sml/updatePassword',{_id,oldpwd,newpwd})
  return response;
}
const forgot_Password = async(email) =>{
  let response = await axios.post('/api/sml/forgotPassword',{email})
  return response;
}
const resetPassword = async(email,pwd,token)=>{
  let response = await axios.post('/api/sml/resetPassword',{email,pwd,token})
  return response
}
const bloggerlist = async(pageNo) =>{
  let response = await axios.get(`/api/public/getAllBloggers/?pageNo=${pageNo}`)
  return response
}

const exploreData = async() => {
  let response = await axios.get('/public/fetchExplore');
  return response.data.pageData;
}

const UserLogin = async(userProfile) => {
  let response = await axios.post('/api/public/login',{userProfile});
  return response;
}
//save looks
const fetchSavedLooks = async()=>{
  let response = await axios.post('/api/user/fetchSavedLooks')
  return response
}
const removeSavedLook = async(sml_id)=>{
  let response = await axios.post('/api/user/deleteSavedLook',{sml_id})
  return response
}

const addSavedLook = async(sml_id)=>{
  let response = await axios.post('/api/user/addSavedLook',{sml_id})
  return response
}


//curator actions
const curatorLogin = async(curatorProfile) => {
  let response = await axios.post('/api/curator/login',{curatorProfile});
  return response;
}
const curatorVerify = async() => {
  let response = await axios.post('/api/curator/verify',{});
  return response;
}

const getBloggerCurator = async(pageNo,skip,insta)=>{
  console.log(pageNo,insta)
  let response = await axios.get(`/curator/sml/fetchBlogger/?pageNo=${pageNo}&insta=${insta}&skip=${skip}`);
  return response;
}

const getshowToPublicoffCurator= async(pageNo,skip,insta)=>{
  let response = axios.get(`/curator/sml/getShowtopublicOFF/?pageNo=${pageNo}&insta=${insta}&skip=${skip}`)
  return response;
}

const getNotCuratedDataCurator= async(insta)=>{
  let response = axios.get(`/curator/sml/getNotCuratedData/?insta=${insta}`)
  return response;
}


// subscriber
const handleSubscribeRequest = async (username,subscribe)=>{
  let response = await axios.post('/api/user/subscribe',{username,subscribe})
  return response
}

const handleIsSubscriber = async (username)=>{
  let response = await axios.post('/api/user/issubscribed',{username})
  return response
}

const getCrossSellCurator = async(username,outfit) =>
  {
    let response = await axios.get(`/curator/CrossSellGet/?username=${username}&outfit_id=${outfit}`);
    return response;
  }
  const getOutfitMetadata = async(username,outfit) =>
  {
    let response = await axios.get(`/curator/getOutfitMetadata/?username=${username}&outfit_id=${outfit}`);
    return response;
  }

  const updateOutfitMetadata = async(username,outfit,field,value) =>
  {
    let obj = {}
    obj[field] = value
    let response = await axios.post(`/curator/setOutfitMetadata/?username=${username}&outfit_id=${outfit}`,obj);
    return response;
  }
  const updateBloggerMetadata = async(username,field,value) =>
  {
    let obj = {}
    obj[field] = value
    let response = await axios.post(`/curator/setBloggerMetadata/?username=${username}`,obj);
    return response;
  }

  const getBloggerMetadata = async(username) =>
  {
    let response = await axios.get(`/curator/getBloggerMetadata/?username=${username}`);
    return response;
  }

const fetchUserSavedLooks = async(page,occasions,categories,username) =>
{
  let response = await axios.post(`/public/saved-looks`,{page,occasions,categories,username});
  return response;
}

const fetchUserExploreLooks = async(page,occasions,categories,sml_id_array) =>
{
  let response = await axios.post(`/public/explore-looks`,{page,occasions,categories,sml_id_array});
  return response;
}

const fetchUserMyLooks = async(page,occasions,categories,sml_id_array) =>
{
  let response = await axios.post(`/public/my-looks`,{page,occasions,categories,sml_id_array});
  return response;
}

const SaveUserPreferences = async(preferences,email,profile) =>
{
  let response = await axios.post(`/public/set-user-preferences`,{preferences,email,profile});
  return response;
}

const GetUserPreferences = async() =>
{
  let response = await axios.post(`/public/get-user-preferences`);
  return response.data;
}

const SaveBankDetails = async(bankDetails) =>
{
  let response = await axios.post(`/api/sml/set-bank-details`,{bankDetails});
  return response;
}

const GetBankDetails = async() =>
{
  let response = await axios.post(`/api/sml/get-bank-details`);
  return response.data;
}


const GetBoardProfile = async (username) => 
{
  let response = await axios.post(`/api/user/fetchBoardData`,{username});
  return response;
}


export {
  bloggerData,
  brandData,
  sendOTPToMobile,
  sendVoiceOTPToMobile,
  uploadProfileImage,
  verifyNumberOTP,
  sendOTPToEmail,
  verifyEmailOTP,
  getBlogger,
  getProducts,
  getCrossSell,
  Login,
  userRegister,
  logout,
  logoutUser,
  showtoPublic,
  updateProfile,
  updateProfileImg,
  fetchBloggerStyle,
  bloggerinfo,
  getPublic,
  getPublicCrossSell,
  getPublicProducts,
  toggleCrossSell,
  getImageData,
  getshowToPublicoff,
  checkUsername,
  checkEmail,
  checkNumber,
  checkInstahandle,
  updatePassword,
  forgot_Password,
  resetPassword,
  bloggerlist,
  getBloggerCounts,
  getBloggerSubscriberCount,
  endUserRegister,
  UserLogin,
  fetchSavedLooks,
  removeSavedLook,
  addSavedLook,
  exploreData,
  handleSubscribeRequest,
  handleIsSubscriber,
  curatorLogin,
  curatorVerify,
  getBloggerCurator,
  getshowToPublicoffCurator,
  getCrossSellCurator,
  getOutfitMetadata,
  updateOutfitMetadata,
  updateBloggerMetadata,
  getBloggerMetadata,
  fetchUserSavedLooks,
  fetchUserExploreLooks,
  fetchUserMyLooks,
  SaveUserPreferences,
  GetUserPreferences,
  GetBankDetails,
  SaveBankDetails,
  GetBoardProfile,
  getNotCuratedDataCurator,
  fetchBloggerOpenLooks,
  trackMixPanelEvent
}

import React, { useState } from 'react'
import imgsrc from '../../images/vector4.png';
import '../../styles/style.css'
import '../../styles/login.css'
import { connect } from "react-redux";
import { isCuratorLogin } from '../../actions'
import { Redirect } from 'react-router';
import PublicNavbar from './../public/publicNavbar';
import Axios from 'axios';
import { Col, Row, Modal, Tab, Nav,Button } from 'react-bootstrap';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import blogger from '../../blogger';
const clientId = "806676170053-kdqovn15piaombk6nubcaurib6ds9f09.apps.googleusercontent.com";
class CuratorNewSignups extends React.Component {
      constructor(props) {
            super(props)
            this.state = {
                  reqLogin: false,
                  bloggersData: []
            }
            console.log("fcdakshjbkha")
            /* this.onSignIn = this.onSignIn.bind(this)
            this.signInFail = this.signInFail.bind(this) */
      }

      async updateBloggers() {
            let response = await Axios.post('/api/curator/fetch-new-bloggers')
            let bloggers = response.data.bloggersData;
            this.setState({
                  bloggersData: bloggers
            })
      }

      componentDidMount(){
            this.updateBloggers()
      }

      handleAccept = async(value,username)=>{
            await Axios.post('/curator/accept-signup',{accepted: value=='accept', username})
            await this.updateBloggers()
      }

      render() {
            
            
            return (
                  <>
                        {/* <div id="loader">
              <div className="spinner-border text-primary" role="status" />
            </div> */}
                        <PublicNavbar title="Blogger List" />
                        <div id="appCapsule" className="pt-0" style={{marginTop:"5rem"}}>
					<table className='table table-striped' style={{margingTop:"100px"}}>
						<thead>
							<tr>
								<th>Sr No</th>
								<th>Name</th>
								<th>Username</th>
								<th>instahandle</th>
                                                <th>Signup Date</th>
							</tr>
						</thead>
						<tbody>
							{this.state.bloggersData.map((blogger,i) =>
									<tr>
										<td>{i}</td>
										<td>{blogger.name}</td>
										<td>
											      {blogger.username}
										</td>
										<td>
                                                                  <a href={`https://www.instagram.com/${blogger.instahandle}`} target='_blank'>
											      {blogger.instahandle}
											</a>
                                                            </td>
                                                            <td>{blogger.dateOfCreation}</td>
                                                            <td><Button className='btn btn-success m-2' onClick={()=> this.handleAccept('accept',blogger.username)}>Accept</Button>
                                                            <Button className='btn btn-danger m-2'  onClick={()=> this.handleAccept('reject',blogger.username)}>Reject</Button></td>
									</tr>
							)}
						</tbody>
					</table>
                        </div>
                  </>
            )
      }
}
const mapStateToProps = state => ({
      isCuratorAuthenticated: state.isCuratorAuthenticated
})
const mapDisparchToProps = (dispatch) => ({
      isCuratorLogin: () => dispatch(isCuratorLogin())
})
export default connect(mapStateToProps, mapDisparchToProps)(CuratorNewSignups);

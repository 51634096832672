import React from 'react'
import Swal from 'sweetalert2'
import {forgot_Password} from '../api/publicApis'

class forgotPassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            emailErr:'',
            verified:false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handleChange(e){
        if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)){
            this.setState({
                email:e.target.value,
                emailErr:''
            })
        }
        else{
            this.setState({
                emailErr: 'invalid email.'
            })
        }
    }
    async handleSubmit(e){
        e.preventDefault();
        if(this.state.emailErr === ''){
            let res = await forgot_Password(this.state.email)
            console.log(res)
            if(res.data.statusText === 'OK'){
                this.setState({
                    verified:true
                })
            }
            else if(res.data.statusText === 'notexist'){
                this.setState({
                    emailErr: 'The email you entered does not exist.'
                })
            }
            else if(res.data.statusText === 'error'){
                Swal.fire({
                    icon:'error',
                    title: 'Opps!',
                    text:'Something went wrong, Please try again.'
                })
            }
        }
    }   
    render() {
        return (
            <>
               {/*   <div class="right">
                    <a href="/shop/login" class="headerButton">
                        Login
                    </a>
                </div> */}
                {/*  App Capsule  */}
                <div id="appCapsule">
                    {this.state.verified?
                    <div className="login-form">
                        <div className="section">
                            <span>The reset password link has been sent to your registered email.</span>
                        </div>
                    </div>
                    :<div className="login-form">
                        <div className="section">
                            <h1>Reset Password</h1>
                            <h4>Type your email to reset your password</h4>
                        </div>
                        <div className="section mt-2 mb-5">
                            <form>
                                <div className="form-group boxed">
                                    <div className="input-wrapper">
                                        <input type="email" className="form-control" id="name1" placeholder="Email address" onChange={this.handleChange}/>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                        <div className="validation">{this.state.emailErr}</div>
                                    </div>
                                </div>
                                <div className="form-button-group">
                                    <button type="submit" className="btn btn-primary btn-block btn-lg" onClick={this.handleSubmit}>Reset</button>
                                </div>

                            </form>
                        </div>
                    </div>
                }
                </div>
                {/*  App Capsule */}
            </>
        )
    }
}
export default forgotPassword
import { connect } from 'react-redux';
import React from 'react'
import { Redirect } from 'react-router';
import '../styles/editprofile.css';
import NavBar from './navbar';
class Csv extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      instahandle: "",
      mobileNumber: "",
      profile_img: "",
      description: "",
      amazon_tracking_id: "",
      username: "",
     
    }
    
  }
  componentDidMount() {
    this.setState({
      name: this.props.user.name,
      email: this.props.user.email,
      instahandle: this.props.user.instahandle,
      mobileNumber: this.props.user.mobileNumber,
      amazon_tracking_id: this.props.user.amazon_tracking_id,
    })
    console.log(this.state)
  } 
  render() {
    return (
        <>
            {!this.props.isAuthenticated ? <Redirect push to='/shop/login' /> :
            <>
            <NavBar title= "Amazon Sales" page="home"></NavBar>
              <div className='appCapsule'>
                <div className ="section full mb-2">
                    <div className ="wide-block p-0">
                      <div className ="table-responsive">
                          <table className ="table table-striped">
                                            <thead>
                                              <tr>
                                                <th scope="col">Content</th>
                                                <th scope="col">Value</th>
                                              </tr>
                                            </thead>
                                            <tbody>  
                                                  <tr>
                                                      <th scope="row">Blogger Name</th>
                                                      <td>{this.props.user.name}</td>
                                                  </tr>
                                                  <tr>
                                                      <th scope="row">Affiliate Link</th>
                                                      <td>{this.props.user.amazon_tracking_id}</td>
                                                  </tr>
                                                  <tr>
                                                      <th scope="row">Clicks</th>
                                                      <td></td>
                                                  </tr>
                                                  <tr>
                                                      <th scope="row">Revenue (INR)</th>
                                                      <td></td>
                                                  </tr>
                                                  <tr>
                                                      <th scope="row">Items Ordered</th>
                                                      <td></td>
                                                  </tr>
                                                  <tr>
                                                      <th scope="row">Items Shipped</th>
                                                      <td></td>
                                                  </tr>
                                              </tbody>
                          </table>
                      </div>
                    </div>
                </div>
              </div>
            </>
            }
        </>          
    )
  }
}
const mapStateToProps = state => (
  {
    user: state.user,
    isAuthenticated: state.isAuthenticated
  }
)
export default connect(mapStateToProps)(Csv);
import {Notification} from "element-react";
import {getBlogger, Login, logout, showtoPublic,getCrossSell,
   getProducts, updateProfile,getPublicCrossSell, getPublicProducts, 
   updateProfileImg, fetchBloggerStyle, bloggerinfo, getPublic,
   toggleCrossSell, getshowToPublicoff,bloggerlist, getBloggerCounts, 
   UserLogin, fetchSavedLooks, removeSavedLook, addSavedLook , 
   handleSubscribeRequest , handleIsSubscriber,
   curatorLogin, curatorVerify , getBloggerCurator , getshowToPublicoffCurator,
   getCrossSellCurator,getOutfitMetadata, getBloggerMetadata ,
   fetchUserSavedLooks , fetchUserExploreLooks , fetchUserMyLooks , GetBoardProfile ,logoutUser
   , getNotCuratedDataCurator , fetchBloggerOpenLooks
  } 
   from '../api/publicApis';
import {actions} from '../constants'
import Swal from 'sweetalert2';
import swal from "sweetalert";
var actionId=0

 function showError(type,msg,title){
  Notification({
    type,
    title,
    message: msg,
    duration: 4000
  })
} 

export function setUser(data){
  return{
    id: actionId++,
    type: actions.SET_USER,
    data
  }
}


export function setprofile(value){
  return{
    id: actionId++,
    type: actions.UPDATE_PROFILE,
    value
  }
}
export function setIsAuthenticated(value){
  return{
    id: actionId++,
    type: actions.IS_AUTHENTICATED,
    value
  }
}

export function setIsUserAuthenticated(value){
  return{
    id: actionId++,
    type: actions.IS_USER_AUTHENTICATED,
    value
  }
}

export function setprofileimg(value){
  // console.log(value)
  return{
    id:actionId++,
    type:actions.UPDATE_PIMG,
    value
  }
}

export function setPublicData(data){
  return{
    id:actionId++,
    type:actions.PUBLIC_PROFILE,
    data
  }
}

export function setLoadingState(loading){
  return {
    type: actions.TOGGLE_LOADING_STATE,
    id: actionId++,
    loading
  }
}


export function modalloading(loading){
  return {
    type: actions.MODAL_LOADING_STATE,
    id: actionId++,
    loading
  }
}
export function setLoadingText(text){
  return {
    type: actions.SET_LOADING_TEXT,
    id: actionId++,
    text
  }
}
export function setBloggersData(blogger){
  return {
    type: actions.SET_BLOGGER,
    id: actionId++,
    blogger
  }
}
export function setCrossSellData(crossSell){
  return {
    id: actionId++,
    type: actions.SET_CROSSSELL,
    crossSell
  }
}

export function setProductsData(products){
  return{    
    id: actionId++,
    type: actions.SET_PRODUCTS,
    products
  }
}

//PUBLIC PAGE SET
export function setPublicCrossSellData(publicCrossSell){
  return {
    id: actionId++,
    type: actions.SET_PUBLICCROSSSELL,
    publicCrossSell
  }
}

export function setPublicProductsData(publicProducts){
  return{
    id: actionId++,
    type: actions.SET_PUBLICPRODUCTS,
    publicProducts
  }
}

export function setTotalBloggersCount(count){
  return{
    id: actionId++,
    type: actions.SET_TOTAL_BLOGGER_COUNT,
    total: count
  }
}

export function setPageVisitsCount(count){
  return{
    id: actionId++,
    type: actions.PAGE_VISITS,
    pageVisits: count
  }
}

export function setLogintext(text){
  return{
    id: actionId++,
    type: actions.SET_LOGIN_TEXT,
    text
  }
}
export function setVisdibilityOff(data){
  return{
    id:actionId++,
    type: actions.SET_GREY_DATA,
    data
  }
}
export function setShowtoPublicOff(item){
  return{
    id:actionId++,
    type: actions.TOGGLE_POST,
    item
  }
}
export function setShowtoPublicOn(item){
  return{
    id:actionId++,
    type: actions.TOGGLE_GREY,
    item
  }
}
export function appendBlogger(item){
  return{
    id: actionId++,
    type: actions.APPEND_BLOGGER,
    item
  }
}
export function appendVisibiltyOff(item){
  return{
    id: actionId++,
    type: actions.APPEND_GREY,
    item
  }
}
export function BloggerStyle(data){
  return{
    id:actionId++,
    type: actions.SET_BLOGGER__PUBLIC,
    data
  }
}

//functions
export function fetchBloggerData(pageNo,skip,insta){
  return async function(dispatch){
    try {
      if(Number(pageNo) === 1){
        dispatch(setLoadingText('Loading...'))
        dispatch(setLoadingState(true))
      }
      let data = await getBlogger(pageNo,skip,insta);
      // console.log(data.data.pageData
      dispatch(setLoadingState(false))
      if(data.data.message === 'invalidToken'){
        dispatch(setIsAuthenticated(false))
        dispatch(setLoadingState(false))
      }
      else{
        //console.log(data)
        dispatch(setIsAuthenticated(true))
        if(Number(pageNo) === 1){
          dispatch(setBloggersData(data.data.pageData))
        }
        else{
          console.log('okay')
          dispatch(bloggerDataConcat(data.data.pageData))
        }
        //dispatch(setVisdibilityOff(data.data.greydata))
        dispatch(setTotalBloggersCount(data.data.total))
        dispatch(setLoadingState(false))
        dispatch(setPageVisitsCount(data.data.pageVisits))
      }
    } catch (error) {
      dispatch(setLoadingState(false))
      console.log('Error: ',error)
    }
  }
}
export function bloggerDataConcat(data){
  return{
    id:actionId++,
    type:actions.BLOGGER_CONCAT,
    data
  }
}

export function bloggerName(name){
  return{
    id:actionId++,
    type: actions.BLOGGER_NAME,
    name
  }
}
//#region PublicVisibilityOFF data
export function visibilityOff(pageNo,skip,insta){
  return async function(dispatch){
    let res = await getshowToPublicoff(pageNo,skip,insta)
    console.log('grey',res)
    if(res.data.data === 'invalidToken'){
      dispatch(setIsAuthenticated(false))
    }
    
    else{
      if(Number(pageNo)===1){
        dispatch(setVisdibilityOff(res.data.data))
      }
      else{
        dispatch(setVisibilityOffPageData(res.data.data))
      }
      dispatch(greydataTotal(res.data.total))
    }
  }
}
export function setVisibilityOffPageData(data){
  return{
    id:actionId++,
    type: actions.SET_GREYDATA_PAGEDATA,
    data
  }
}
export function greydataTotal(total){
  return{
    id:actionId++,
    type: actions.SET_GREYDATA_TOTAL,
    total
  }
}
//#endregion PublicVisibilityOFF data
export function getPublicProfile(pageNo,insta)
{
  return async function(dispatch){
    try
    {
      dispatch(setLoadingText('Loading...'))
      dispatch(setLoadingState(true))
      let data = await getPublic(pageNo,insta);
      dispatch(setPublicData(data.data.pageData))
      dispatch(setLoadingState(false))
    }
    catch(error)
    {
      dispatch(setLoadingState(false))
      console.log('Error: ', error)
    }
  }
}


export function fetchCrossSellData(insta)
{
  return async function(dispatch)
  {
    try
    {
      dispatch(setLoadingText('Loading...'))
      dispatch(setLoadingState(true))
      let data = await getCrossSell(insta);
      //console.log(data)
      dispatch(setCrossSellData(data.data.pageData))
      dispatch(setLoadingState(false))
      dispatch(setSMLID(data.data.pageData[0].sml_id))
      dispatch(setPageVisitsCount(data.data.pageVisits))
    }
    catch(error)
    {
      dispatch(setLoadingState(false))
      console.log('Error: ', error)
    }
  }
}

export function setSMLID(sml_id){
  return {
    type: actions.SET_SML_ID,
    id: actionId++,
    sml_id
  }
}

export function fetchBloggerCounts(insta)
{
  return async function(dispatch)
  {
    try
    {
      dispatch(setLoadingText('Loading...'))
      dispatch(setLoadingState(true))
      let data = await getBloggerCounts(insta);
      //console.log(data)
      dispatch(setBloggerCounts(data.data.result))
      dispatch(setLoadingState(false))
    }
    catch(error)
    {
      dispatch(setLoadingState(false))
      console.log('Error: ', error)
    }
  }
}

export function setBloggerCounts(bloggercounts){
  return{
    id: actionId++,
    type: actions.BLOGGER_COUNTS,
    bloggercounts: bloggercounts
  }
}

export function fetchProducts(sml_id,cat)
{
  return async function(dispatch)
  {
    try
    {
      dispatch(modalloading(true))
      let data = await getProducts(sml_id,cat);
      console.log(data)
      dispatch(setProductsData(data.data.pageData))
      dispatch(modalloading(false))
    }
    catch(error)
    {
      //dispatch(setLoadingState(false))
      console.log('Error: ', error)
    }
  }
}


//curator actions

export function fetchBloggerDataCurator(pageNo,skip,insta){
  return async function(dispatch){
    try {
      if(Number(pageNo) === 1){
        dispatch(setLoadingText('Loading...'))
        dispatch(setLoadingState(true))
      }
      let data = await getBloggerCurator(pageNo,skip,insta);
      // console.log(data.data.pageData
      dispatch(setLoadingState(false))
      if(data.data.message === 'invalidToken'){
        dispatch(setIsAuthenticated(false))
        dispatch(setLoadingState(false))
      }
      else{
        //console.log(data)
        dispatch(setIsAuthenticated(true))
        if(Number(pageNo) === 1){
          dispatch(setBloggersData(data.data.pageData))
        }
        else{
          console.log('okay')
          dispatch(bloggerDataConcat(data.data.pageData))
        }
        //dispatch(setVisdibilityOff(data.data.greydata))
        dispatch(setTotalBloggersCount(data.data.total))
        dispatch(setLoadingState(false))
        dispatch(setPageVisitsCount(data.data.pageVisits))
      }
    } catch (error) {
      dispatch(setLoadingState(false))
      console.log('Error: ',error)
    }
  }
}

export function visibilityOffCurator(pageNo,skip,insta){
  return async function(dispatch){
    let res = await getshowToPublicoffCurator(pageNo,skip,insta)
    console.log('grey',res)
    if(res.data.data === 'invalidToken'){
      dispatch(setIsAuthenticated(false))
    }
    else if(res.data.data === 'error'){
      
    }
    else{
      if(Number(pageNo)===1){
        dispatch(setVisdibilityOff(res.data.data))
      }
      else{
        dispatch(setVisibilityOffPageData(res.data.data))
      }
      dispatch(greydataTotal(res.data.total))
    }
  }
}

export function curatorNotCuratedData(username){
  return async function(dispatch){
    let res = await getNotCuratedDataCurator(username)
    if(res.data.data === 'invalidToken'){
      dispatch(setIsAuthenticated(false))
    }
    else if(res.data.data === 'error'){
      
    }
    else{
      dispatch(setToCurateData(res.data.data))
    }
  }
}

export function setToCurateData(data){
  return{
    is:actionId++,
    type: actions.NOT_CURATED_DATA,
    data
  }
}


export function fetchCrossSellDataCurator(username,outfit)
{
  return async function(dispatch)
  {
    try
    {
      dispatch(setLoadingText('Loading...'))
      dispatch(setLoadingState(true))
      let data = await getCrossSellCurator(username,outfit);
      //console.log(data)
      dispatch(setCrossSellData(data.data.pageData))
      dispatch(setLoadingState(false))
      dispatch(setSMLID(data.data.pageData[0].sml_id))
      dispatch(setPageVisitsCount(data.data.pageVisits))
    }
    catch(error)
    {
      dispatch(setLoadingState(false))
      console.log('Error: ', error)
    }
  }
}

export function fetchOutfitMetadataCurator(username,outfit)
{
  return async function(dispatch)
  {
    try
    {
      let data = await getOutfitMetadata(username,outfit);
      dispatch(setOutfitMetadata(data.data))
    }
    catch(error)
    {
      dispatch(setLoadingState(false))
      console.log('Error: ', error)
    }
  }
}

export function fetchBloggerMetadataCurator(username)
{
  return async function(dispatch)
  {
    try
    {
      let data = await getBloggerMetadata(username);
      dispatch(setBloggerMetadata(data.data))
    }
    catch(error)
    {
      dispatch(setLoadingState(false))
      console.log('Error: ', error)
    }
  }
}

export function setOutfitMetadata(data){
  return{
    is:actionId++,
    type: actions.SET_OUTFIT_METADATA,
    data
  }
}

export function setBloggerMetadata(data){
  return{
    is:actionId++,
    type: actions.SET_BLOGGER_METADATA,
    data
  }
}


export function  LoginAction(email, pwd){
  return async function(dispatch){
    try{
      let data = await Login(email,pwd);
      if(data.data.statustext === 'success'){
        dispatch(setUser(data.data.user))
        dispatch(setIsAuthenticated(true))
        dispatch(setLogintext('ok'))
      }
      else if(data.data.statustext === 'invalid'){
          dispatch(setLogintext('invalid'))
      }
      else{
          dispatch(setLogintext('not_exist'))
      }
    }
    catch(err){
      console.log("Login Error",err.message);
    }
  }
}
export function setoffImage(item,username){
  return async function(dispatch){
    try{
    /*   dispatch(setLoadingText('Loading...'))
      dispatch(setLoadingState(true)) */
      console.log(item)
      let res = await showtoPublic('off',item.sml_id,username)
      if(res.data.status === 'OK'){
        dispatch(setShowtoPublicOff(item))
        dispatch(appendVisibiltyOff(item))
        //dispatch(setLoadingState(false))
      }
      else if(res.data.status === 'invalidToken')
        dispatch(setIsAuthenticated(false))
    }
    catch(err){
      console.log(err)
    }
  }
}
export function setonImage(item,username){
  return async function(dispatch){
    try{
      /* dispatch(setLoadingText('Loading...'))
      dispatch(setLoadingState(true)) */
      console.log(item)
      let res = await showtoPublic('on',item.sml_id,username)
      if(res.data.status === 'OK'){
        dispatch(setShowtoPublicOn(item))
        dispatch(appendBlogger(item))
       // dispatch(setLoadingState(false))
      }
      else if(res.data.status === 'invalidToken')
        dispatch(setIsAuthenticated(false))
      else if(res.data.status === 'error')
        showError('Opps! some error occured.')
    }
    catch(err){
      console.log(err)
    }
  }
}

export function Logout(){
  return async function(dispatch){
    try{
      dispatch(setLoadingText('Loading...'))
      dispatch(setLoadingState(true))
      let res = await logout();
      if(res.data.statustext === 'OK'){
        dispatch(setIsAuthenticated(false))
      } 
      dispatch(setLoadingState(false))
    }
    catch(err){
      console.log(err.message)
    }
  }
}
export function UserLogout(){
  return async function(dispatch){
    try{
      dispatch(setLoadingText('Loading...'))
      dispatch(setLoadingState(true))
      let res = await logoutUser();
      if(res.data.statustext === 'OK'){
        dispatch(setIsUserAuthenticated(false))
      } 
      dispatch(setLoadingState(false))
    }
    catch(err){
      console.log(err.message)
    }
  }
}
export function editProfile(userdata){
  return async function(dispatch){
    try{
      dispatch(setLoadingText('Loading...'))
      dispatch(setLoadingState(true))
      let res = await updateProfile(userdata);
      if(res.data.statustext === 'OK'){
        Swal.fire({
          icon: 'success',
          title: 'Updated',
          text: 'Profile changes made!',
          footer: '<a href="/shop/mystore">Navigate back to Mystore</a>'
        })
        dispatch(setIsAuthenticated(true))
        dispatch(setprofile(userdata))
      } else if(res.data.statustext=="Username already taken" || res.data.statustext=="Instahandle already taken"){
        Swal.fire({
          icon: 'error',
          title: 'Duplicate Entry',
          text: 'res.data.statustext'
        })
      }
      dispatch(setLoadingState(false))
    }
    catch(err){
      dispatch(setLoadingState(false))
      console.log(err.message)
    }
  }
}

export function uploadProfileImage(imgdata,userdata){
  return async function(dispatch){
    try{
      dispatch(setLoadingText('Loading...'))
      dispatch(setLoadingState(true))
      let res = await updateProfileImg(imgdata);
      if(res.data.statustext === 'ok')
      {
        Swal.fire({
          icon: 'success',
          title: 'Updated',
          text: 'Profile image updated!',
          footer: '<a href="/shop/mystore">Navigate back to Mystore</a>'
        })
        // showError('success','Profile image Changed.','Profile Image')
        dispatch(setIsAuthenticated(true))
        dispatch(setUser(res.data.user))
       
      } 
      dispatch(setLoadingState(false))
    }
    catch(err){
      console.log(err.message)
    }
  }
}
export function bloggerStyle(pageNo,insta,occasionFilters,categoryFilters){
    return async function(dispatch){
      try{
        if(Number(pageNo) === 1){
          dispatch(setLoadingText('Loading...'))
          dispatch(setLoadingState(true))
        }
        let res = await fetchBloggerStyle(pageNo,insta,occasionFilters,categoryFilters)
        if(res){
          if(Number(pageNo) === 1){
            dispatch(BloggerStyle(res.data.pageData))
            dispatch(setLoadingState(false))
            if(res.data.pageData==='not found'){
              window.location.href = '/blogger-not-found';
            }
          }else{
            dispatch(appendBloggerStyle(res.data.pageData))
          }
          dispatch(setTotalBloggersCount(res.data.total))
            
        }
      }
      catch(err){
        console.log(err.message)
      }
  }
}

export function getBloggerOpenLooks(pageNo,insta,occasionFilters,categoryFilters){
  return async function(dispatch){
    try{
      dispatch(setBloggerOpenLooksRequested(true))
      let res = await fetchBloggerOpenLooks(pageNo,insta,occasionFilters,categoryFilters)
      dispatch(setBloggerOpenLooksRequested(false))
      dispatch(setBloggerOpenLooks(pageNo, res.data.pageData))
    }
    catch(err){
      console.log(err.message)
    }
}
}

export function setBloggerOpenLooksRequested(data){
  return{
    id:actionId++,
    type: actions.SET_BLOGGER_OPEN_LOOKS_REQUESTED,
    data
  }
}


export function setBloggerOpenLooks(page,data){
  return{
    id:actionId++,
    type: actions.SET_BLOGGER_OPEN_LOOKS,
    data,
    page
  }
}

export function appendBloggerStyle(data){
  return{
    id:actionId++,
    type: actions.BLOGGER_STYLE_PAGEDATA,
    data
  }
}
export function blogger_info(insta){
  return async function(dispatch){
    let res = await bloggerinfo(insta)
    if(res.data.data=='not found'){
      window.location.href = '/blogger-not-found';
    } else dispatch(setBloggerinfo(res.data.data))
  }
}

//PUBLIC API PAGES

export function fetchPublicCrossSellData(insta,outfit)
{
  return async function(dispatch)
  {
    try
    {
      dispatch(setLoadingText('Loading...'))
      dispatch(setLoadingState(true))
      let data = await getPublicCrossSell(insta,outfit);
      dispatch(setPublicCrossSellData(data.data.pageData))
      dispatch(setLoadingState(false))
      dispatch(bloggerName(data.data.name))
    }
    catch(error)
    {
      dispatch(setLoadingState(false))
      console.log('Error: ',error)
    }
  }
}


export function fetchPublicProducts(sml_id,cat)
{
  // console.log(vsp_id,cat)
  return async function(dispatch)
  {
    try
    {
      console.log(sml_id,cat)
      
      dispatch(modalloading(true))
      let data = await getPublicProducts(sml_id,cat);
      
      dispatch(setPublicProductsData(data.data.pageData))
      dispatch(modalloading(false))
    }
    catch(error)
    {
      dispatch(modalloading(false))
      console.log('Error: ', error)
    }
  }
}

export function setBloggerinfo(data){
  return{
    is:actionId++,
    type: actions.SET_BLOGGER_INFO,
    data
  }
}
// toggle crossSell and product visibility
export function setCrossSellVisibility(type,sml_id,product_id,insta,cat,action)
{
  return async function(dispatch){
    try{
      let res = await toggleCrossSell(type,sml_id,product_id,insta,cat,action) 
      if(res.data.statustext === 'OK'){
        console.log('all goes well')
        if(type === 'crossSell'){
          if(action === 'off'){
              dispatch(setOFFcrossSellvisibility(sml_id,cat))
          }
          else{
            dispatch(setONcrossSellvisibility(sml_id,insta,cat))
          }
        }
        else if(type === 'product'){
          if(action === 'off'){
            dispatch(setOFFproductVisibility(sml_id,insta,cat,product_id))
          }
          else{
            dispatch(setONproductVisibility(sml_id,insta,cat,product_id))
          }
        }
      }
      else{
       
      }
   }
   catch(err){
    console.log('crossSellvisibility',err.message)
  }
  }
}

export function setOFFcrossSellvisibility(sml_id,cat){
  return{
    id:actionId++,
    type: actions.SET_CROSSSELL_VISIBILITY_OFF,
    sml_id,
    cat
  }
}
export function setOFFproductVisibility(sml_id,insta,cat,product_id){
  return{
    id:actionId++,
    type: actions.SET_PRODUCTS_VISIBILITY_OFF,
    sml_id,
    insta,
    cat,
    product_id
  }
}
export function setONcrossSellvisibility(sml_id,insta,cat){
  return{
    id:actionId++,
    type: actions.SET_CROSSSELL_VISIBILITY_ON,
    sml_id,
    insta,
    cat
  }
}
export function setONproductVisibility(sml_id,insta,cat,product_id){
  return{
    id:actionId++,
    type: actions.SET_PRODUCTS_VISIBILITY_ON,
    sml_id,
    insta,
    cat,
    product_id
  }
}
//#region fetch all blogger
export function getBloggerlist(pageNo){
  return async function(dispatch){
    try{
    if(Number(pageNo) ===1){
      dispatch(setLoadingState(true))
    }
    let res = await bloggerlist(pageNo)
    if(res.data.statustext === 'OK'){
      if(Number(pageNo === 1)){
        dispatch(setBloggerList(res.data.pageData))
      }
      else{
        dispatch(setBloggerlistPageData(res.data.pageData))
      }
      dispatch(setTotalLit(res.data.total))
      dispatch(setLoadingState(false))
    }
    else{
      throw({
        "message":"custom error"
      })
    }
  }
  catch(err){
    console.log(err.message)
   
  }
  }
}
export function setBloggerList(value){
  return {
    id:actionId++,
    type:actions.SET_BLOGGER_LIST,
    value
  }
}
export function setBloggerlistPageData(value){
  return {
    id:actionId++,
    type: actions.SET_BLOGGER_LIST_PAGEDATA,
    value
  }
}
export function setTotalLit(total){
  return{
    id:actionId++,
    type: actions.BLOGGER_LIST_TOTAL,
    total
  }
}
export function userLogin(userProfile){
  return async function(dispatch){
    try{
      
      let res = await UserLogin(userProfile)
      if(res.data.statustext === 'success')
      {
        dispatch(setIsUserAuthenticated(true));
        dispatch(setEndUserProfile(res.data.userProfile));
      }
      else{
        throw({
          "message": "custom error"
        })
      }
    }
    catch(err){
      console.log(err.message)
    
    }
  }
}
export function saveLooks(value){
  return{
    id:actionId++,
    type: actions.SAVED_LOOKS,
    value
  }
}

export function setEndUserProfile(profile){
  return{
    id:actionId++,
    type: actions.USER_PROFILE,
    profile
  }
}

// export function saveProducts(value){
//   return{
//     id:actionId++,
//     type: actions.SET_SAVEPRODUCTS,
//     value
//   }
// }
export function setIsLogin(value){
  return {
    id:actionId++,
    type:actions.USER_LOGIN,
    value
  }
}
//#endregion
//#region user logout
export function userLogout(){
  return async function(dispatch){
    dispatch(setIsLogin(false))
  }
}

export function getSavedLooks(){
  return async function(dispatch){
    try{
      let res = await fetchSavedLooks()
      if(res.data.statustext === 'success')
      {
        dispatch(saveLooks(res.data.result))
      }
      else{
        throw({
          "message": "custom error"
        })
      }
    }
    catch(err){
      console.log(err.message)
   
    }
  }
 
}

export function addLook(sml_id){
  return async function(dispatch){
    try{
      let response = await addSavedLook(sml_id)
      dispatch(getSavedLooks())
    }
    catch(err){
      console.log(err.message)
    
    }
  }
 
}

export function deleteLook(sml_id){
  return async function(dispatch){
    try{
      let response = await removeSavedLook(sml_id)
      dispatch(getSavedLooks())
    }
    catch(err){
      console.log(err.message)
    
    }
  }
 
}

export function googleCuratorLogin(curatorProfile){
  return async function(dispatch){
    try{
      
      let res = await curatorLogin(curatorProfile)
      dispatch(isCuratorLogin());
      if(res.data.statustext === 'failed' ) {
        Swal.fire({
          icon:"error",
          title:"Login failed",
          text:"Curator account not found"
        })
      } else if(res.data.statustext != 'success'){
        throw({
          "message": "custom error"
        })
      }
    }
    catch(err){
      console.log(err.message)
    }
  }
}

export function isCuratorLogin(){
  return async function(dispatch){
    let curatorLoggedIn = (await curatorVerify()).data.isLoggedIn
    dispatch(setCuratorLogin(curatorLoggedIn))
  }
}

export function setCuratorLogin(value){
  return {
    id:actionId++,
    type:actions.CURATOR_LOGIN,
    value
  }
}







export function handleSubscribe(username,subscribe){
  return async function(dispatch){
    try{
      await handleSubscribeRequest(username,subscribe)
      dispatch(isSubscribed(username))
    }
    catch(err){
      console.log(err.message)
     
    }
  }
}

export function isSubscribed(username){
  return async function(dispatch){
    try{
      let subscribe = (await handleIsSubscriber(username)).data.isSubscribedUser;
      dispatch(setIsSubscribed(subscribe))
    }
    catch(err){
      console.log(err.message)
    
    }
  }
}
export function setIsSubscribed(subscribe){
  return {
    id:actionId++,
    type:actions.SUBSCRIBE,
    subscribe
  }
}


//explore-looks, saved-looks,my-looks actions 

export function setLooksToEmpty(){
  return function(dispatch){
    try{
        dispatch(setLoadingText('Loading...'))
        dispatch(setLoadingState(true))
        dispatch(savedUserLooks(1,[]))
        dispatch(savedUserLooksLength(0))
        dispatch(userMyLooks(1,[]))
        dispatch(exploreUserLooks(1,[]))
      
    }
    catch(err){
      console.log(err.message)
     
    }
  }
 
}


export function getUserSavedLooks(pageNumber,occasionFilters,categoryFilters,username){
  return async function(dispatch){
    try{
      dispatch(setLoadingText('Loading...'))
      dispatch(setLoadingState(true))
      let res = await fetchUserSavedLooks(pageNumber,occasionFilters,categoryFilters,username)
      if(res.data.pageData)
      {
        dispatch(setLoadingState(false))
        dispatch(savedUserLooks(pageNumber,res.data.pageData))
        dispatch(savedUserLooksLength(res.data.total))
      }
      else{
        throw({
          "message": "custom error"
        })
      }
    }
    catch(err){
      console.log(err.message)
     
    }
  }
 
}

export function savedUserLooks(page,data){
  return {
    id:actionId++,
    type:actions.USER_SAVED_LOOKS,
    page,
    data
  }
}

export function setUserPreferences(hasFilled){
  return {
    id:actionId++,
    type:actions.FILLED_USER_PREFERENCES,
    hasFilled
  }
}
export function setbnkDetail(hasFilled){
  return {
    id:actionId++,
    type:actions.FILLED_BANK_DETAILS,
    hasFilled
  }
}

export function savedUserLooksLength(total){
  return {
    id:actionId++,
    type:actions.USER_SAVED_LOOKS_LENGTH,
    total
  }
}



export function getUserMyLooks(pageNumber,occasionFilters,categoryFilters,sml_id_array){
  return async function(dispatch){
    try{
      let res = await fetchUserMyLooks(pageNumber,occasionFilters,categoryFilters,sml_id_array)
      if(res.data.pageData)
      {
        dispatch(userMyLooks(pageNumber,res.data.pageData))
      }
      else{
        throw({
          "message": "custom error"
        })
      }
    }
    catch(err){
      console.log(err.message)
     
    }
  }
 
}

export function userMyLooks(page,data){
  return {
    id:actionId++,
    type:actions.USER_MY_LOOKS,
    page,
    data
  }
}

export function updateUserLoginState(){
  return async function(dispatch){
    if(document.cookie.includes('njwtUserToken'))
      dispatch(setIsUserAuthenticated(true))
    else 
      dispatch(setIsUserAuthenticated(false)) 
  }
}

export function getUserExploreLooks(pageNumber,occasionFilters,categoryFilters,renderedPosts){
  return async function(dispatch){
    try{
      let res = await fetchUserExploreLooks(pageNumber,occasionFilters,categoryFilters,renderedPosts)
      if(res.data.pageData && res.data.pageData.length>0)
      {
        dispatch(exploreUserLooks(pageNumber,res.data.pageData))
        if(res.data.pageData.length==6)
          dispatch(exploreUserLooksNextPage(true))
        else dispatch(exploreUserLooksNextPage(false))
      }
      else{
        dispatch(exploreUserLooksNextPage(false))
      }
    }
    catch(err){
      console.log(err)
    
    }
  }
 
}

export function exploreUserLooks(page,data){
  return {
    id:actionId++,
    type:actions.USER_EXPLORE_LOOKS,
    page,
    data
  }
}

export function exploreUserLooksNextPage(value){
  return {
    id:actionId++,
    type:actions.USER_EXPLORE_LOOKS_NEXT_PAGE,
    value
  }
}


//board actions 
export function getBoardData(username){
  return async function(dispatch){
    try{
      let res = await GetBoardProfile(username)
      if(res.data.user)
      {
        dispatch(setBoardData(res.data.user))
      }
      else{
        throw({
          "message": "custom error"
        })
      }
    }
    catch(err){
      console.log(err)
    
    }
  }
}

export function setBoardData(data){
  console.log(data)
  return {
    id:actionId++,
    type:actions.BOARD_PROFILE,
    data
  }
}